import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { headerLayoutStyle, wrapperStyles } from './styles'
import { useSlideNavControls } from './hooks'
import { HEADER_TYPE, Header } from 'src/components/header'
import { DECK_PAGE_MODE, IDeckPageLayout } from './types'
import {
  useActiveCanvas,
  useActiveCssVariables,
  useDecksApi,
  useFlags,
} from 'src/hooks'
import { useDispatch } from 'react-redux'
import { clearCanvas, clearEdit, setSelectedComponents } from 'src/store'
import { Global, css } from '@emotion/react'
import { getDeckDetailedResponse } from 'src/types/api/responseObjects'

export const DeckPageLayout: React.FC<IDeckPageLayout> = React.memo(
  ({ mode = DECK_PAGE_MODE.EDIT }) => {
    const flags = useFlags()
    const isEditMode = useMemo(() => mode === DECK_PAGE_MODE.EDIT, [])
    const params = useParams()
    const dispatch = useDispatch()
    const { getDeck, getPublicDeckData, postDeckVisit, isLoading } =
      useDecksApi()

    // TODO: when removing flag, check if useActiveCssVariables hook used in some other places.
    // Since it's using an old structure, this hook should be deprecated.
    const oldCssVariables = useActiveCssVariables()
    const { cssVariables } = useActiveCanvas({})

    useSlideNavControls(!isEditMode)

    const isCustomDomain = useMemo(() => {
      return (
        !window.location.hostname.includes('.decktopus.com') &&
        !window.location.host.includes('localhost')
      )
    }, [window.location.hostname])

    const [publicDeckDetail, setPublicDeckDetail] =
      useState<getDeckDetailedResponse['data']>()
    // deck change fetch trigger
    useEffect(() => {
      if (location.pathname.includes('share')) {
        fetchPublicDeckData()
      } else if (params?.id) {
        getDeck({ deckId: parseInt(params?.id) })
      }
    }, [params.id])

    const fetchPublicDeckData = useCallback(async () => {
      const publicDeckData = await getPublicDeckData({
        shareKey: params.id!,
        customDomain: isCustomDomain ? window.location.hostname : undefined,
      })
      setPublicDeckDetail(publicDeckData)
      sendVisit(publicDeckData, true)
    }, [])

    useEffect(() => {
      return () => {
        dispatch(clearEdit())
        dispatch(clearCanvas())
      }
    }, [])

    // slide change component reset
    useEffect(() => {
      dispatch(setSelectedComponents())
    }, [params.slide])

    useEffect(() => {
      if (location.pathname.includes('share')) {
        sendVisit()
      }
    }, [params.slide, publicDeckDetail])

    const sendVisit = useCallback(
      (deckData?: getDeckDetailedResponse['data'], deckVisit?: boolean) => {
        const publicDeck = deckData ?? publicDeckDetail

        if (location.pathname.includes('share') && publicDeck?.deck?.id) {
          const slides = publicDeck.deckData?.data.slides
          const sortedSlides = slides
            ?.slice()
            .filter(({ isDeleted }) => !isDeleted)
            .sort((a, b) => a.orderIndex - b.orderIndex)
          postDeckVisit({
            deckId: publicDeck.deck?.id,
            ...(sortedSlides &&
              !deckVisit && {
                body: {
                  slideId:
                    sortedSlides[params.slide ? Number(params.slide) - 1 : 0]
                      ?.slideId,
                },
              }),
          })
        }
      },
      [publicDeckDetail, params.slide],
    )

    if (isLoading) {
      return null
    }
    return (
      <>
        <Global
          styles={css`
            body {
              ${flags.FE_FIX_DEFAULT_COLOR_SELECTION
                ? cssVariables
                : oldCssVariables};
            }
          `}
        />
        {isEditMode && (
          <Header type={HEADER_TYPE.DECK_EDIT} css={headerLayoutStyle} />
        )}
        <div css={wrapperStyles({ isEditMode })}>
          <Outlet />
        </div>
      </>
    )
  },
)
