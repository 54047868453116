import React from 'react'

import { CHART_TYPE, ChartOptions, IChart } from './types'
import { ChartPercentage } from './ChartPercentage'
import { ChartXYLine } from './ChartXYLine'
import { ChartXYBar } from './ChartXYBar'

type RequiredOptions = Required<ChartOptions>
export const Chart: React.FC<IChart> = React.memo(
  ({
    chartType = CHART_TYPE.PIE_CHART,
    series,
    options,
    size,
    width,
    height,
    className,
    dataAttr,
  }) => {
    const defaultOptions: RequiredOptions = {
      title: '',
      type: chartType,
      titleSize: 24,
      donutInnerRadius: chartType === CHART_TYPE.DONUT_CHART ? 50 : 0,
      legends: false,
      stroke: {
        width: 1,
      },
      bullet: {
        size: 3,
        marker: 10,
      },
      labels: {
        x: '',
        showX: false,
        y: '',
        showY: false,
      },
      showGrids: true,
      ...options,
    }

    return chartType === CHART_TYPE.PIE_CHART ||
      chartType === CHART_TYPE.DONUT_CHART ? (
      <ChartPercentage
        series={series}
        options={defaultOptions}
        size={size}
        width={width}
        height={height}
        className={className}
        {...dataAttr}
      />
    ) : chartType === CHART_TYPE.LINE_CHART ? (
      <ChartXYLine
        series={series}
        options={defaultOptions}
        size={size}
        width={width}
        height={height}
        className={className}
        {...dataAttr}
        chartType={chartType}
      />
    ) : (
      <ChartXYBar
        series={series}
        options={defaultOptions}
        size={size}
        width={width}
        height={height}
        className={className}
        {...dataAttr}
        chartType={chartType}
      />
    )
  },
)

Chart.displayName = 'Chart'
