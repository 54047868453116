import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IEditDesign } from './types'
import {
  editDesignStyles,
  loadingOverlayStyles,
  themeCardStyles,
  themeCardsStyles,
  styleTemplateStyles,
  styleItemContainerStyles,
} from './styles'
import {
  DROPDOWN_SIZE,
  DROPDOWN_THEME,
  DROPDOWN_WIDTH,
  Dropdown,
  IDropdown,
  IDropdownItem,
  Spinner,
  TABS_THEME,
  Tabs,
} from 'src/lib'
import { RootState, resetThemes, setCachedColorMapping } from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import { StringServices } from 'src/services'
import {
  useDecksApi,
  useLanguage,
  useThemeApi,
  useFlags,
  useActiveColorMap,
} from 'src/hooks'
import { ColorPalette } from './components/color-palette'
import { FontFamily } from './components/font-family'
import { FontsList } from './components/fonts-list/FontsList'
import { ColorPaletteList } from './components/color-palette-list'
import { ColorMapping } from './components/color-mapping'

export const EditDesign: React.FC<IEditDesign> = React.memo(
  ({ className, dataAttr }) => {
    const { t, lang } = useLanguage()
    const dispatch = useDispatch()
    const { getThemeCategories, getThemeCategory, getThemeColors } =
      useThemeApi()
    const {
      updateUserThemeColorMap,
      updateUserThemeColorMapWithCachedMapping,
      isLoading,
    } = useDecksApi()
    const colorMap = useActiveColorMap({})
    const flags = useFlags()

    const [activeTab, setActiveTab] = useState(0)
    const [fontsActive, setFontsActive] = useState(false)
    const [paletteActive, setPaletteActive] = useState(false)

    const {
      themeCategories,
      selectedTheme,
      themes,
      isLoadingThemes,
      isDeckLoading,
      activeThemeFont,
      activeThemeColors,
      fontsFromUrl,
      selectedThemeId,
      cachedColorMaps,
    } = useSelector(({ theme, edit }: RootState) => ({
      themeCategories: theme.categories,
      themes: theme.themes,
      isLoadingThemes: theme.isThemesLoading,
      isLoadingCat: theme.isCategoryLoading,
      selectedTheme: edit.activeDeck.data?.deckData?.theme,
      isDeckLoading: edit.activeDeck.isLoading,
      activeThemeFont: edit.activeDeck.data?.deckData?.themeFont.data,
      activeThemeColors: edit.activeDeck.data?.deckData?.themeColor.data,
      fontsFromUrl: theme.fontsFromUrl,
      selectedThemeId: edit.activeDeck.data?.deckData?.theme.id,
      cachedColorMaps: edit.cachedColorMaps,
    }))

    useEffect(() => {
      getThemeCategories()
      if (selectedTheme?.themeCategoryId && !themes.length) {
        getThemeCategory({ themeCategoryId: selectedTheme?.themeCategoryId })
      }
      if (selectedTheme?.themeCategoryId) {
        getThemeColors({
          themeCategoryId: selectedTheme?.themeCategoryId as number,
        })
      }
    }, [selectedTheme?.themeCategoryId])

    const dropdownItems = useMemo<IDropdownItem[]>(() => {
      return (
        themeCategories.map(
          ({ name, id }) =>
            ({
              name: StringServices.capitalCase(name),
              value: id,
            }) as IDropdownItem,
        ) || []
      )
    }, [themeCategories])

    const handleCategoryChange = useCallback<
      NonNullable<IDropdown['onChange']>
    >((value) => {
      dispatch(resetThemes())
      value && getThemeCategory({ themeCategoryId: value.value as number })
    }, [])

    const handleThemeChange = useCallback(
      (themeId: number) => {
        if (colorMap) {
          // Set current mapping data as cachedMap value for using it afterwards
          dispatch(
            setCachedColorMapping({
              id: selectedThemeId!.toString(),
              mapping: colorMap!,
            }),
          )
        }

        const mappingIndex = cachedColorMaps.findIndex(
          (cachedMap) => cachedMap.id === themeId?.toString(),
        )
        // If there is no mapping cached for selected theme, use & fetch default color mapping
        if (selectedThemeId !== themeId && mappingIndex === -1) {
          updateUserThemeColorMap({ field: undefined, themeId })
        } else {
          // use cached color mapping if there is one for the selected theme
          updateUserThemeColorMapWithCachedMapping({
            mapping: cachedColorMaps[mappingIndex].mapping,
            themeId,
          })
        }
      },
      [colorMap, selectedThemeId, cachedColorMaps],
    )

    const renderThemeCards = useMemo(
      () =>
        themes.map((theme) => (
          <div
            key={theme.id}
            css={themeCardStyles({
              isSelected: theme.id === selectedTheme?.id,
            })}
            style={{ backgroundImage: `url(${theme.thumbnailUrl})` }}
            onClick={() => handleThemeChange(theme.id)}
          />
        )),
      [selectedTheme, themes],
    )

    const themesTemplate = useMemo(
      () => (
        <>
          {dropdownItems?.length ? (
            <Dropdown
              theme={DROPDOWN_THEME.DARK}
              width={DROPDOWN_WIDTH.FULL}
              size={DROPDOWN_SIZE.BIG}
              selected={selectedTheme?.themeCategoryId}
              items={dropdownItems}
              onChange={handleCategoryChange}
            />
          ) : null}

          <div css={themeCardsStyles}>{renderThemeCards}</div>
        </>
      ),
      [renderThemeCards, dropdownItems, selectedTheme, isLoadingThemes],
    )

    const selectedItemFontTemplate = useMemo(
      () => (
        <div css={styleItemContainerStyles}>
          <div className="selected-font">
            <span className="style-item-name">
              {t('edit.design.font_family')}
            </span>
            <FontFamily
              primary={activeThemeFont?.primary}
              secondary={activeThemeFont?.secondary}
              displayOnly={true}
            />
            <span
              className="style-item-change"
              onClick={() => setFontsActive(true)}
            >
              {t('common.actions.change')}
            </span>
          </div>
        </div>
      ),
      [activeThemeFont, fontsActive, lang],
    )

    const selectedItemPaletteTemplate = useMemo(
      () => (
        <div css={styleItemContainerStyles}>
          <div className="selected-palette">
            <span className="style-item-name">
              {t('edit.design.color_palette')}
            </span>
            <ColorPalette
              first={activeThemeColors?.first}
              second={activeThemeColors?.second}
              third={activeThemeColors?.third}
              fourth={activeThemeColors?.fourth}
              displayOnly={true}
            />
            <span
              className="style-item-change"
              onClick={() => setPaletteActive(true)}
            >
              {t('common.actions.change')}
            </span>
          </div>
        </div>
      ),
      [activeThemeColors, paletteActive, lang],
    )
    const styleTemplate = useMemo(
      () => (
        <div css={styleTemplateStyles}>
          <div className="style-title">{t('edit.design.customize_design')}</div>
          {fontsActive ? (
            <FontsList
              fontsFromUrl={fontsFromUrl}
              onClick={() => setFontsActive(false)}
            />
          ) : (
            selectedItemFontTemplate
          )}
          <div className="border" />
          {paletteActive ? (
            <ColorPaletteList onClick={() => setPaletteActive(false)} />
          ) : (
            selectedItemPaletteTemplate
          )}
        </div>
      ),
      [fontsActive, paletteActive, fontsFromUrl, lang],
    )

    const colorsTemplate = useMemo(() => {
      return <ColorMapping />
    }, [])

    const tabTexts = useMemo(() => {
      if (flags.FE_71_COLOR_MAPPING) {
        return [
          t('edit.panel.menu_tabs.themes'),
          t('edit.panel.menu_tabs.style'),
          t('edit.panel.menu_tabs.colors'),
        ]
      }
      return [t('edit.panel.menu_tabs.themes'), t('edit.panel.menu_tabs.style')]
    }, [flags, lang])

    return (
      <div css={editDesignStyles} className={className} {...dataAttr}>
        {(isLoading || isDeckLoading) && (
          <>
            <div css={loadingOverlayStyles} />
            <div className="edit-design-loading-spinner-wrapper">
              <Spinner className="edit-design-loading-spinner" size={36} />
            </div>
          </>
        )}
        <Tabs
          theme={TABS_THEME.DARK}
          tabs={tabTexts}
          activeTab={activeTab}
          onChange={setActiveTab}
          className={isLoading || isDeckLoading ? 'tabs-is-loading' : ''}
        />
        {activeTab === 0 && themesTemplate}
        {activeTab === 1 && styleTemplate}
        {activeTab === 2 && colorsTemplate}
      </div>
    )
  },
)

EditDesign.displayName = 'EditDesign'
