import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ICreateWithAiForm } from './types'
import {
  buttonAreaStyles,
  buttonStyles,
  closeButtonAreaStyles,
  titleStyles,
  wrapperStyles,
} from './styles'

import decktoHugImage from 'src/assets/images/deckto-hug.png'
import documentsImage from 'src/assets/images/documents.png'
import { BUTTON_THEME, BUTTON_TYPE, Button, icons } from 'src/lib'
import { useLanguage, useWindowSize } from 'src/hooks'
import { useTheme } from 'src/theme'
import {
  AI_FLOW_STEP,
  RootState,
  setAiFlowId,
  setAiFlowStep,
  setAim,
  setAudience,
  setOutline,
  setPdfImportState,
  setPdfName,
  setPrompt,
  setTheme,
} from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  AiTemplates,
  AiOutline,
  AiAudience,
  AiAim,
  AiPrompt,
  PdfImport,
} from 'src/components/ai-modals'
import { v4 as uuidv4 } from 'uuid'

export const CreateWithAiForm: React.FC<ICreateWithAiForm> = React.memo(
  ({ className, dataAttr, onCloseClick }) => {
    const size = useWindowSize()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { t } = useLanguage()

    const { aiFlowStep, credits, workspaceId } = useSelector(
      ({ aiFlow, user, workspace }: RootState) => ({
        aiFlowStep: aiFlow.currentStep,
        credits: user.aicredits.total,
        workspaceId: workspace.id,
      }),
    )

    useEffect(() => {
      const uuid = uuidv4()
      dispatch(setAiFlowId(uuid))
      sessionStorage.setItem('aiFlowId', uuid)
    }, [])

    const [seeAiModal, setSeeAiModal] = useState(true)

    const showTexts = useMemo(
      () => size.width >= theme.breakpoints.tablet,
      [size],
    )

    const selectTopic = useMemo(() => {
      switch (aiFlowStep) {
        case AI_FLOW_STEP.PROMPT:
          return t('create_with_ai.prompt_title')
        case AI_FLOW_STEP.AUDIENCE:
          return t('create_with_ai.audience_title')
        case AI_FLOW_STEP.AIM:
          return t('create_with_ai.aim_title')
        case AI_FLOW_STEP.OUTLINE:
          return t('create_with_ai.outline_title')
        case AI_FLOW_STEP.THEME:
          return t('create_with_ai.theme_title')
        case AI_FLOW_STEP.IMPORT:
          return t('pdf_import.import_pdf_file')
        case AI_FLOW_STEP.PDF_PROMPT:
          return t('create_with_ai.prompt_title')
        case AI_FLOW_STEP.LOADING:
          return ''
        case AI_FLOW_STEP.ERRORED:
          return ''
        default:
          return t('create_with_ai.create_with_ai')
      }
    }, [aiFlowStep])

    const getPage = useMemo(() => {
      switch (aiFlowStep) {
        case AI_FLOW_STEP.PROMPT:
          return <AiPrompt />
        case AI_FLOW_STEP.AUDIENCE:
          return <AiAudience />
        case AI_FLOW_STEP.AIM:
          return <AiAim />
        case AI_FLOW_STEP.OUTLINE:
          return <AiOutline />
        case AI_FLOW_STEP.THEME:
          return <AiTemplates onClose={onCloseClick} />
        case AI_FLOW_STEP.PDF_PROMPT:
          return <AiPrompt />
        case AI_FLOW_STEP.LOADING:
          return <AiTemplates onClose={onCloseClick} />
        case AI_FLOW_STEP.ERRORED:
          return <AiTemplates onClose={onCloseClick} />
        default:
          return <PdfImport />
      }
    }, [aiFlowStep])

    const selectCreateWithAi = useCallback(async () => {
      setSeeAiModal(!seeAiModal)
      clearPreviousData()
      dispatch(setPdfImportState(false))
      dispatch(setAiFlowStep(AI_FLOW_STEP.PROMPT))
    }, [seeAiModal, workspaceId, credits])

    const selectPdfImport = useCallback(async () => {
      setSeeAiModal(!seeAiModal)
      clearPreviousData()
      dispatch(setAiFlowStep(AI_FLOW_STEP.IMPORT))
    }, [seeAiModal, workspaceId, credits])

    const clearPreviousData = () => {
      dispatch(setPrompt({ prompt: '' }))
      dispatch(setAudience(''))
      dispatch(setAim(''))
      dispatch(setOutline([]))
      dispatch(setTheme('0'))
      dispatch(setPdfName(''))
    }

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div css={closeButtonAreaStyles}>
          <Button
            icon={icons.close}
            theme={BUTTON_THEME.GRAY}
            type={BUTTON_TYPE.GHOST}
            isLink
            iconSize={20}
            onClick={onCloseClick}
          />
        </div>
        <div css={titleStyles(aiFlowStep == AI_FLOW_STEP.MODAL)}>
          {selectTopic}
        </div>
        {aiFlowStep == AI_FLOW_STEP.MODAL && (
          <div css={buttonAreaStyles}>
            <div css={buttonStyles} onClick={selectCreateWithAi}>
              <div className="image">
                <img src={decktoHugImage} alt="" />
              </div>
              <div className="title">{t('create_with_ai.create_with_ai')}</div>
              {showTexts && (
                <div className="text">{t('create_with_ai.ai_subtitle')}</div>
              )}
            </div>
            <div css={buttonStyles} onClick={selectPdfImport}>
              <div className="image">
                <img src={documentsImage} alt="" />
              </div>
              <div className="title">{t('create_with_ai.import_pdf')}</div>
              {showTexts && (
                <div className="text">{t('create_with_ai.pdf_subtitle')}</div>
              )}
            </div>
          </div>
        )}
        {!(aiFlowStep == AI_FLOW_STEP.MODAL) && getPage}
      </div>
    )
  },
)
