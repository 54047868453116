import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react'

import { IQuote } from './types'
import { quoteAuthorStyles, quoteStyles, quoteTextStyles } from './styles'
import {
  ComponentQuoteDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { Icon, icons } from 'src/lib'
import { COLOR } from 'src/theme'
import { TextAlignmentsHorizontal } from 'src/types/api/enums'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { componentsUpdate, SAVE_STATE, setSaveState } from 'src/store'
import { useCanvas, useFlags } from 'src/hooks'
import { CANVAS_TYPE } from 'src/components/canvas/types'
import { cssProps } from 'src/types'

export const Quote: React.FC<IQuote> = React.memo(
  ({ data, className, dataAttr, scale, canvasType }) => {
    const flags = useFlags()
    const dispatch = useDispatch()
    const componentData: ComponentQuoteDataSchema = useMemo(
      () => data.data as ComponentQuoteDataSchema,
      [data.data],
    )

    const baseSize = useMemo(() => {
      return (
        Math.min(data.positions.width || 1980, data.positions.height || 1980) /
        50
      )
    }, [data])

    const alignment: cssProps = useMemo(() => {
      switch (componentData.style.quote.font.alignmentHorizontal) {
        case TextAlignmentsHorizontal.LEFT:
          return {
            alignItems: 'flex-start',
            textAlign: 'left',
          }
        case TextAlignmentsHorizontal.RIGHT:
          return {
            alignItems: 'flex-end',
            textAlign: 'right',
          }
        default:
          return {
            alignItems: 'center',
            textAlign: 'center',
          }
      }
    }, [componentData.style.quote.font.alignmentHorizontal])

    const { isComponentFixing } = useCanvas()
    const textAreaRef = useRef<HTMLDivElement>(null)

    const updateBoxHeight = useCallback(() => {
      const currentHeight = data.positions.height || 1
      const contentHeight =
        (textAreaRef.current?.getBoundingClientRect()?.height || 1) /
        (scale || 1)
      if (contentHeight > currentHeight) {
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponent<UpdateComponentSchema>({
            components: [data],
            partialUpdate: {
              positions: {
                height: contentHeight,
              },
            },
          })
        dispatch(
          componentsUpdate({
            components: updatedComponents,
            isAutoProcess: true,
          }),
        )
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }
    }, [textAreaRef.current, scale, data])

    useLayoutEffect(() => {
      if (flags.FE_304_QUOTE_IMPROVEMENTS_2) {
        if (!isComponentFixing && canvasType === CANVAS_TYPE.DND) {
          updateBoxHeight()
        }
      }
    }, [
      flags.FE_304_QUOTE_IMPROVEMENTS_2,
      componentData,
      componentData.style.quote.font.size,
      componentData.style.author.font.size,
    ])

    return (
      <div
        css={quoteStyles}
        style={{
          fontSize: flags.FE_304_QUOTE_IMPROVEMENTS_2
            ? undefined
            : `${baseSize}em`,
          alignItems: alignment.alignItems,
          textAlign: alignment.textAlign,
        }}
        className={className}
        {...dataAttr}
      >
        {flags.FE_304_QUOTE_IMPROVEMENTS_2 ? (
          <>
            <div ref={textAreaRef}>
              <Icon
                icon={icons.quote}
                size={parseInt(componentData.style.quote.font.size || '') * 6}
                color={componentData.style.quote.color.text.colors[0] as COLOR}
              />
              <div
                css={quoteTextStyles}
                style={{
                  color: componentData.style.quote.color.text.colors[0],
                  fontSize: componentData.style.quote.font.size || undefined,
                }}
                dangerouslySetInnerHTML={{ __html: componentData.quote }}
              />
              <div
                css={quoteAuthorStyles}
                style={{
                  color: componentData.style.author.color.text.colors[0],
                  fontSize: componentData.style.author.font.size || undefined,
                }}
                dangerouslySetInnerHTML={{ __html: componentData.author }}
              />
            </div>
          </>
        ) : (
          <>
            <Icon
              icon={icons.quote}
              size={baseSize * 6}
              color={componentData.style.quote.color.text.colors[0] as COLOR}
            />
            <div
              css={quoteTextStyles}
              style={{ color: componentData.style.quote.color.text.colors[0] }}
              dangerouslySetInnerHTML={{ __html: componentData.quote }}
            />
            <div
              css={quoteAuthorStyles}
              style={{ color: componentData.style.author.color.text.colors[0] }}
              dangerouslySetInnerHTML={{ __html: componentData.author }}
            />
          </>
        )}
      </div>
    )
  },
)

Quote.displayName = 'Quote'
