import React from 'react'

import { BOX_BUTTON_ICON_FRAME, IPropBoxButton } from './types'
import {
  iconFrameStyles,
  mediaDisplayContentStyles,
  mediaDisplayStyles,
  mediaDisplayNameStyles,
} from './styles'
import { Icon } from 'src/lib'
import { useTheme } from 'src/theme'

export const PropBoxButton: React.FC<IPropBoxButton> = React.memo(
  ({
    name,
    icon,
    hoverIcon,
    onClick,
    iconFrame = BOX_BUTTON_ICON_FRAME.DEFAULT,
    iconSize = 36,
    isActive,
    className,
    dataAttr,
  }) => {
    const { colors } = useTheme()
    return (
      <div
        css={mediaDisplayStyles({ isActive })}
        onClick={onClick}
        className={className}
        {...dataAttr}
      >
        <div css={mediaDisplayContentStyles}>
          <div className="icon-wrapper">
            {icon &&
              (name ? (
                <Icon
                  icon={icon}
                  size={iconSize}
                  color={colors.white.DEFAULT}
                />
              ) : (
                <div css={iconFrameStyles({ iconFrame })}>
                  <Icon
                    icon={icon}
                    size={iconSize}
                    color={colors.white.DEFAULT}
                  />
                </div>
              ))}
            {hoverIcon && (
              <div className="hover-icon">
                <Icon icon={hoverIcon} color={colors.white.DEFAULT} />
              </div>
            )}
          </div>
          <div css={mediaDisplayNameStyles}>{name}</div>
        </div>
      </div>
    )
  },
)

PropBoxButton.displayName = 'PropBoxButton'
