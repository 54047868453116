import React, { useCallback, useMemo, useState } from 'react'

import { IPdfImport } from './types'
import {
  changeButtonStyles,
  imageStyles,
  inputStyles,
  pdfImportAreaStyles,
  pdfImportStyles,
  loadingStyles,
  pdfImportTextStyles,
  sizeErrorStyles,
  subTitleStyles,
  titleStyles,
  uploadedPdfStyles,
} from './styles'
import { buttonStyles } from '../ai-prompt/styles'
import {
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  INPUT_SIZE,
  INPUT_WIDTH,
  Input,
  Loader,
  icons,
} from 'src/lib'
import { useLanguage, useWindowSize } from 'src/hooks'
import { useDropzone } from 'react-dropzone'
import pdfImport from 'src/assets/images/pdf-import.svg'
import pdfImportWhite from 'src/assets/images/pdf-import-white.svg'
import { useDispatch } from 'react-redux'
import {
  AI_FLOW_STEP,
  setAiFlowStep,
  setPdfImportState,
  setPdfName,
} from 'src/store'
import { useTheme } from '@emotion/react'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'

import * as pdfjsLib from 'pdfjs-dist'
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs'
import { APP_CONFIG } from 'src/config'

export const PdfImport: React.FC<IPdfImport> = React.memo(
  ({ className, dataAttr, onClose }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const theme = useTheme()
    const size = useWindowSize()

    const smallScreenSize = useMemo(
      () => size.width < theme.breakpoints.tablet,
      [size],
    )

    const { getPdfImportAudience } = useAiFlowApi()

    const [loadingState, setLoadingState] = useState(false)

    const [fileName, setFileName] = useState('')
    const [fileSize, setFileSize] = useState(0)
    const [pdfContent, setPdfContent] = useState('')
    const [isFileUnsupported, setIsFileUnsupported] = useState(false)

    const isFileOversized = useMemo(
      () => fileSize > APP_CONFIG.pdfImportAllowedSize,
      [fileSize, APP_CONFIG.pdfImportAllowedSize],
    )

    const onDrop = async (files: any) => {
      setLoadingState(true)
      if (!files.length) {
        setFileSize(0)
        setIsFileUnsupported(true)
        setLoadingState(false)
        return
      }
      setFileSize(files[0].size)

      // If its more than APP_CONFIG.pdfImportAllowedSize handle loading state and show the error before setting the setFileName
      if (files[0].size > APP_CONFIG.pdfImportAllowedSize) {
        setIsFileUnsupported(false)
        setLoadingState(false)
        return
      }
      // Set file name if controls passed
      setFileName(files[0].path)

      try {
        const buffer = await files[0].arrayBuffer()
        const pdf = await pdfjsLib.getDocument(buffer).promise
        let pdfText = ''

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          const page = await pdf.getPage(pageNum)
          const textContent = await page.getTextContent()

          textContent.items.forEach((item: any) => {
            pdfText += item.str + ''
          })
        }

        setPdfContent(pdfText.replace(/\s+/g, ' '))
      } catch (error) {
        console.error(error)
      } finally {
        setLoadingState(false)
      }
    }

    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
      onDrop,
      accept: { 'application/pdf': ['.pdf'] },
      multiple: false,
    })

    const nextButton = useCallback(async () => {
      setLoadingState(true)
      dispatch(setPdfName(fileName))
      const apiResult = await getPdfImportAudience({
        pdfContent: pdfContent,
      })
      if (apiResult) {
        dispatch(setAiFlowStep(AI_FLOW_STEP.AUDIENCE))
        dispatch(setPdfImportState(true))
      }
      setLoadingState(false)
    }, [fileName, pdfContent])

    const backButton = useCallback(async () => {
      dispatch(setAiFlowStep(AI_FLOW_STEP.MODAL))
      onClose && onClose()
    }, [])

    const onChangePdfImport = useCallback((param: any) => {
      if (!param) {
        setFileName('')
        setFileSize(0)
      }
    }, [])

    return (
      <div css={pdfImportStyles} className={className} {...dataAttr}>
        {loadingState && (
          <div css={loadingStyles}>
            <Loader size={33} />
          </div>
        )}
        {!fileName && !loadingState && (
          <div>
            <div css={pdfImportAreaStyles(isDragAccept)} {...getRootProps()}>
              <div css={pdfImportTextStyles}>
                <input type="file" {...getInputProps()} />
                {isDragAccept ? (
                  <>
                    <div css={imageStyles}>
                      <img src={pdfImportWhite} />
                    </div>
                    <div css={titleStyles(true)}>
                      {t('pdf_import.drop_the_file_to_upload')}
                    </div>
                  </>
                ) : (
                  <>
                    {!smallScreenSize ? (
                      <>
                        <div css={imageStyles}>
                          <img src={pdfImport} />
                        </div>
                        <div css={titleStyles(false)}>
                          {t('pdf_import.drag_and_drop_file')}
                        </div>
                        <div css={subTitleStyles}>
                          {t('common.or')} &nbsp;
                          <span> {t('pdf_import.browse_file')}</span> &nbsp;
                          {t('pdf_import.on_your_computer')}
                        </div>
                      </>
                    ) : (
                      <>
                        <div css={imageStyles}>
                          <img src={pdfImport} />
                        </div>
                        <div css={titleStyles(false)}>
                          {t('pdf_import.browse_file_on_your_device')}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {isFileOversized && (
              <div css={sizeErrorStyles}>{t('pdf_import.pdf_size_error')}</div>
            )}
            {isFileUnsupported && (
              <div css={sizeErrorStyles}>{t('pdf_import.use_pdf_format')}</div>
            )}
          </div>
        )}
        {fileName && !loadingState && (
          <div>
            <div css={uploadedPdfStyles}>
              <Input
                value={fileName}
                size={INPUT_SIZE.MEDIUM}
                width={INPUT_WIDTH.FULL}
                icon={icons.pdf}
                css={inputStyles}
                onChange={onChangePdfImport}
              />
              <Button
                text={t('common.actions.change')}
                type={BUTTON_TYPE.GHOST}
                isLink
                css={changeButtonStyles}
                onClick={() => setFileName('')}
              />
            </div>
          </div>
        )}
        <div css={buttonStyles}>
          <Button
            type={BUTTON_TYPE.GHOST}
            text={t('common.actions.back')}
            icon={icons.chevron_left}
            isLink
            onClick={backButton}
          />
          <Button
            text={t('common.actions.next')}
            theme={BUTTON_THEME.GRADIENT}
            onClick={nextButton}
            disabled={!fileName || loadingState || isFileOversized}
            isLoading={loadingState}
          />
        </div>
      </div>
    )
  },
)

PdfImport.displayName = 'PdfImport'
