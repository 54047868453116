import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'src/lib/button/Button'
import { GetShareLink } from './components/get-share-link'
import { useLanguage } from 'src/hooks'
import { useDecksApi } from 'src/hooks/api'
import { IShareLink } from './types'
import {
  bottomSpaceWrapper,
  spanWrapperStyles,
  deckNameStyles,
} from '../../styles'
import { UpgradeModalManager } from 'src/components/upgrade-modals'
import { PlanNames } from 'src/types/api/enums'
import { ACTION_CLICKS } from 'src/plugins/google/consts'
import { deckNameFontWeight } from '../export/styles'

export const ShareLink: React.FC<IShareLink> = React.memo(
  ({
    deckShareCode,
    deckName,
    deckId,
    isActive,
    deckOrganizationId,
    customDomain,
    customDomainSlug,
    context,
  }) => {
    const { t } = useLanguage()
    const { shareDeck, isLoading } = useDecksApi()

    const [shortCode, setShortCode] = useState(deckShareCode)
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)

    const handleGetShareLinkClick = useCallback(async () => {
      if (!shortCode && !isLoading) {
        await getShareCode()
      }
    }, [shortCode, isLoading])

    const getShareCode = useCallback(async () => {
      const code = await shareDeck(
        { deckId: deckId! },
        deckOrganizationId,
        context,
      )
      setShortCode(code)
    }, [deckId, deckOrganizationId])

    useEffect(() => {
      if (deckOrganizationId && !isUpgradeModalOpen) {
        setIsUpgradeModalOpen(false)
      }
    }, [deckOrganizationId, isUpgradeModalOpen])
    const handleConnectCustomDomain = useCallback(
      (isCallback: boolean) => {
        if (isCallback) {
          setIsUpgradeModalOpen(false)
        }
        if (!isCallback && !deckOrganizationId) {
          setIsUpgradeModalOpen(true)
          return false
        }
        return true
      },
      [deckOrganizationId],
    )

    return (
      <div>
        <div css={bottomSpaceWrapper}>
          <div css={deckNameStyles}>
            <span css={spanWrapperStyles}>
              {t('common.actions.share')}:
              <span css={deckNameFontWeight}>{deckName}</span>
            </span>
          </div>
        </div>
        {!shortCode || !deckName ? (
          <div css={bottomSpaceWrapper}>
            <Button
              text={t('share_modal.get_share_link')}
              onClick={handleGetShareLinkClick}
            />
          </div>
        ) : (
          <GetShareLink
            shortCode={shortCode}
            deckName={deckName}
            deckId={deckId}
            isActive={isActive}
            onClose={() => {}}
            onConnectDomainClick={() => handleConnectCustomDomain(false)}
            cusDomain={customDomain}
            customDomainSlug={customDomainSlug}
          />
        )}
        {isUpgradeModalOpen && (
          <UpgradeModalManager
            isOpen={isUpgradeModalOpen}
            callback={() => handleConnectCustomDomain(true)}
            availablePlanNames={[PlanNames.BUSINESS]}
            context={ACTION_CLICKS.CUSTOM_DOMAIN_CLICK}
            onClose={() => setIsUpgradeModalOpen(false)}
          />
        )}
      </div>
    )
  },
)
