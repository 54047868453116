import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const fontUploadModalStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  border-radius: ${theme.borderRadius[10]};
  min-height: 356px;
  width: 100%;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    overflow-y: auto;
    width: 600px;
  }

  .close-button-area {
    position: relative;
    display: flex;
    justify-content: end;
    top: 30px;
    right: 30px;
  }
`

export const contentAreaStyles = (theme: ITheme) => css`
  padding: ${theme.spacing.medium};
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.small};
  max-width: 93%;
  justify-content: center;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    padding: ${theme.spacing['4xlarge']};
    padding-top: ${theme.spacing.medium};
  }

  & .title-row {
    font-size: ${theme.fontSize.large};
    font-weight: ${theme.fontWeight.medium};
  }

  & .subtitle-row {
    font-weight: ${theme.fontWeight.medium};
  }

  & .responsibility-row {
    font-size: ${theme.fontSize.small};
    padding-bottom: 13px;
  }

  & .upload-row {
    color: ${theme.colors.primary.DEFAULT};
    display: flex;
    align-items: center;
    column-gap: ${theme.spacing['2xsmall']};
    text-decoration: underline;
    font-weight: ${theme.fontWeight.medium};
    cursor: pointer;
    margin-bottom: 23px;
    width: max-content;
  }
`
