export const values = {
  formElementHeight: {
    xsmall: 25,
    DEFAULT: 35,
    medium: 40,
    big: 46,
  },
  buttonHeight: {
    DEFAULT: 35,
    xsmall: 35,
    small: 41,
    large: 44,
    xlarge: 46,
  },
  buttonWidth: {
    DEFAULT: 107,
    small: 129,
    medium: 145,
    large_medium: 148,
    large: 177,
    xlarge: 248,
  },
  iconSize: {
    DEFAULT: 12,
    medium: 18,
  },
  columnWidth: {
    DEFAULT: 282,
  },
  cardHeight: {
    DEFAULT: 160,
    small: 48,
  },
  spanWidth: {
    DEFAULT: 270,
    small: 300,
  },
  headerHeight: {
    DEFAULT: 60,
    big: 100,
  },
  headerHeightWithBanner: {
    DEFAULT: 120,
    big: 120,
  },
  headerInputWidth: {
    DEFAULT: 450,
    medium: 374,
    small: 220,
  },
  settingsPanelWidth: {
    DEFAULT: 425,
    small: 360,
  },
  sideMenuItemHeight: {
    DEFAULT: 38,
  },
  webhookModalWidth: {
    DEFAULT: 587,
  },
  planBadgeHeight: {
    DEFAULT: 82,
  },
  organizationHeaderHeight: {
    DEFAULT: 60,
    long: 70,
  },
  organizationMenuWidth: {
    DEFAULT: 425,
    small: 300,
  },
  organizationDetailInputWidth: {
    DEFAULT: 328,
    small: 400,
  },
  horizontalProgressHeight: {
    DEFAULT: 10,
  },
  stepsHeight: {
    DEFAULT: 6,
  },
  deckInitImageWidth: {
    DEFAULT: 450,
    small: 300,
  },
  emptyMemberImageWidth: {
    DEFAULT: 100,
    small: 150,
  },
  adjustSeatsWidth: {
    DEFAULT: 363,
  },
  adjustSeatsHeight: {
    DEFAULT: 343,
  },
  emptyMemberPlaceholderWidth: {
    DEFAULT: 294,
    small: 252,
  },
  memberRowHeight: {
    DEFAULT: 52,
  },
  userMailWidth: {
    DEFAULT: 200,
  },
  addMembersModalWidth: {
    DEFAULT: 618,
  },
  addMembersModalHeight: {
    DEFAULT: 356,
  },
  addMembersModalLeftColWidth: {
    DEFAULT: 198,
  },
  addMembersModalMailItemHeight: {
    DEFAULT: 38,
  },
  leaveOrganizationWidth: {
    DEFAULT: 328,
    small: 637,
  },
  leaveOrganizationHeight: {
    DEFAULT: 130,
    small: 162,
  },
  initPageFooterHeight: {
    DEFAULT: 92,
  },
  remainingSeatsHeight: {
    DEFAULT: 110,
  },
  editPanelWidth: {
    DEFAULT: 100,
  },
  editPanelItemSize: {
    DEFAULT: 80,
  },
  avatarFontSize: {
    DEFAULT: 24,
    small: 48,
  },
  iconButtonWidth: {
    DEFAULT: 40,
  },
  canvasDefaultWidth: {
    DEFAULT: 1920,
  },
  menuWidth: {
    DEFAULT: 326,
    xsmall: 100,
    small: 187,
    medium: 250,
    large: 274,
  },
  menuHeight: {
    DEFAULT: 57,
    small: 67,
  },
  aiModalWidth: {
    DEFAULT: 305,
    small: 385,
    medium: 512,
    large: 636,
  },
  aiModalHeight: {
    DEFAULT: 290,
    small: 280,
    prompt: 300,
    medium: 330,
    large: 342,
  },
  aiModalExampleHeight: {
    'DEFAULT': 160,
    '3xsmall': 140,
    '2xsmall': 150,
    'xsmall': 170,
    'small': 190,
    'medium': 212,
    'large': 240,
    'xlarge': 250,
    '2xlarge': 265,
    '3xlarge': 300,
  },
  aiModalImageWidth: {
    DEFAULT: 186,
    xsmall: 160,
  },
  aiModalImageHeight: {
    DEFAULT: 105,
    xsmall: 90,
  },
  awardHeight: {
    DEFAULT: 90,
  },
  pdfImportImageHeight: {
    DEFAULT: 40,
    small: 52,
    medium: 62,
  },
  pdfImportImageWidth: {
    DEFAULT: 32,
    small: 42,
    medium: 50,
  },
  pdfImportWidth: {
    DEFAULT: 317,
    small: 370,
  },
  pdfImportUploadWidth: {
    DEFAULT: 518,
    small: 636,
  },
  pdfImportUploadHeight: {
    DEFAULT: 256,
  },
  pdfImportInputWidth: {
    DEFAULT: 432,
    small: 550,
  },
  aiModalOutlineHeight: {
    DEFAULT: 230,
    small: 270,
  },
  layoutCardWidth: {
    DEFAULT: 263,
  },
  suspendedWidth: {
    DEFAULT: 287,
  },
  previewHeaderFooter: {
    DEFAULT: 48,
  },
  slideNavigationButtons: {
    DEFAULT: 36,
  },
  colorMappingItem: {
    DEFAULT: 64,
  },
  colorMappingItemColorBox: {
    DEFAULT: 36,
  },
  initPageTemplateModal: {
    DEFAULT: 900,
    width: 900,
    height: 624,
  },
  initPageTemplateModalTablet: {
    DEFAULT: 646,
    width: 646,
    height: 624,
  },
  initPageTemplateModalMobile: {
    DEFAULT: 312,
    width: 312,
    height: 624,
  },
  banner: {
    DEFAULT: 70,
    big: 100,
  },
  paymentConfirmation: {
    DEFAULT: 640,
    width: 640,
    height: 730,
  },
}
