export enum AICreditSpendReasonNames {
  GENERATE_DECK = '0',
  GENERATE_ICON = '1',
  GENERATE_IMAGE = '2',
  GENERATE_LIST_ITEM = '3',
  GENERATE_TIPS = '9',
  GENERATE_QUESTIONS_ANSWERS = '10',
  GENERATE_SINGLE_SLIDE_NOTES = '11',
  GENERATE_MULTIPLE_SLIDE_NOTES = '12',
  AI_TEXT_EDIT = '14',
  GENERATE_PDF_DECK = '15',
}

export enum AICreditLoadReasonNames {
  REGISTER_GIFT = '4',
  PRO_RENEWAL = '5',
  BUSINESS_RENEWAL = '6',
  REFERRER_GIFT = '7',
  REFERRED_GIFT = '8',
  ADMIN_GIFT = '13',
}

export enum AuditLogTypes {
  INFO = '0',
  ERROR = '1',
}

export enum CancelReasonTypes {
  TECHNICAL = '0',
  EXPENSIVE = '1',
  TIME = '2',
  QUESTION = '3',
  PLUS = '4',
  NO_NEED = '5',
  OTHER = '6',
}

export enum ComponentTypes {
  TEXT = '0',
  MEDIA = '1',
  LIST = '2',
  TABLE = '3',
  CHART = '4',
  FORM = '5',
  BUTTON = '6',
  DEVICE = '7',
  QUOTE = '8',
}

export enum MediaTypes {
  IMAGE = '0',
  GIF = '1',
  LOGO = '2',
  ICON = '3',
  VIDEO = '4',
  WEBSITE = '5',
  SHAPE = '6',
}

export enum ListLayoutDirections {
  VERTICAL = '0',
  HORIZONTAL = '1',
}

export enum ListTypes {
  BULLET = '0',
  NUMBER = '1',
  MEDIA = '2',
  TIMELINE = '3',
  PLAIN = '4',
}

export enum ListItemImageTypes {
  ICON = '0',
  IMAGE = '1',
  LOGO = '2',
}

export enum FormQuestionTypes {
  TEXT = '0',
  OPTIONS = '1',
  RATE = '2',
}

export enum FormQuestionOptionsTypes {
  CHECKBOX = '0',
  SELECT = '1',
}

export enum ComponentRadiusTypes {
  CIRCLE = '0',
  ROUNDED = '1',
  FLAT = '2',
  OUTLINE = '3',
}

export enum ComponentMediaZoomTypes {
  FILL = '0',
  FIT = '1',
  CROP = '2',
  DISTORT = '3',
}

export enum ButtonTypes {
  TEXT = '0',
  ICON = '1',
}

export enum ViewModes {
  PRIVATE = '0',
  TEAM = '1',
  ORGANIZATION = '2',
}

export enum DeckAnalyticTypes {
  VIEW = '0',
}

export enum DeckInviteStates {
  PENDING = '0',
  DECLINED = '1',
  ACCEPTED = '2',
}

export enum DeckInvitePermissions {
  READ = '0',
  READWRITE = '1',
}

export enum DecktopusVideoTypes {
  TIP = '0',
}

export enum DeckPermissions {
  READ = '0',
  READWRITE = '1',
}

export enum OrganizationInviteStates {
  PENDING = '0',
  DECLINED = '1',
  ACCEPTED = '2',
}

export enum PasswordResetRequestStates {
  PENDING = '0',
  DONE = '1',
  EXPIRED = '2',
}

export enum PlanNames {
  PRO = '0',
  BUSINESS = '1',
}

export enum PlanPeriods {
  MONTHLY = '0',
  ANNUAL = '1',
}

export enum ReferalStates {
  PENDING = '0',
  DONE = '1',
}

export enum SlideAnalyticTypes {
  VIEW = '0',
}

export enum UserProviders {
  DECKTOPUS = '0',
  GOOGLE = '1',
}

export enum UserLanguages {
  TR_TR = '0',
  EN_US = '1',
  FR_CA = '2',
  AR_SA = '3',
}

export enum UserCurrency {
  TRY = '0',
  USD = '1',
  EUR = '2',
  GBP = '3',
  INR = '4',
  EGP = '5',
  BDT = '6',
  NPR = '7',
  PKR = '8',
  IDR = '9',
  BRL = '10',
  COP = '11',
  // JOD = '12', //
  BGN = '13',
  PLN = '14',
  QAR = '15',
  ZAR = '16',
  AED = '17',
  // KWD = '18', //
  SAR = '19',
  CHF = '20',
  ARS = '21',
  MXN = '22',
  LBP = '23',
}

export enum UserOnboardStates {
  NONE = '0',
  CLASSIFY = '1',
}

export enum UserClassifyQuestionTypes {
  CLASS = '0',
  AREA_OF_USAGE = '1',
  HEARD_US_FROM = '2',
}

export enum UserPlanStates {
  ACTIVE = '0',
  INACTIVE = '1',
}

export enum UserPlanPaymentStates {
  PAYMENT_PENDING = '0',
  PAYMENT_SUCCESS = '1',
  PAYMENT_FAILED = '2',
  PAYMENT_ACTION_REQUIRED = '3',
}

export enum WebhookTypes {
  DECK_RESPONSE = '0',
}

export enum TextAlignmentsHorizontal {
  LEFT = '0',
  CENTER = '1',
  RIGHT = '2',
}

export enum TextAlignmentsVertical {
  TOP = '0',
  CENTER = '1',
  BOTTOM = '2',
}

export enum DeckStates {
  PROCESSING = '0',
  FINAL = '1',
  ERRORED = '2',
  READY_FOR_TRANSFER = '3',
  IN_QUEUE = '4',
}

export enum AssetTypes {
  IMAGE = '0',
  AIIMAGE = '1',
  SHAPE = '2',
  GIF = '3',
  LOGO = '4',
  ICON = '5',
  VIDEO = '6',
}

export enum DecksSortByFields {
  NAME = '0',
  CREATED_AT = '1',
  UPDATED_AT = '2',
}

export enum SortDirections {
  ASC = '0',
  DESC = '1',
}

export enum PivotDeckCategories {
  ALL = '0',
  BUSINESS = '1',
  PROPOSAL = '2',
  ONLINE_COURSE = '3',
  EDUCATION = '4',
  EXECUTIVE_SUMMARY = '5',
  FORM = '6',
  NUTRITION = '7',
  CASE_STUDY = '8',
  STARTUP_PITCH = '9',
}

export enum DeckResponseAnswerTypes {
  STRING = '0',
  NUMBER = '1',
  BOOLEAN = '2',
}

export enum IconProviders {
  FLATICON = '0',
  ICON8 = '1',
  FREEPIK = '2',
}

export enum StockMediaProviders {
  UNSPLASH = '0',
  GIPHY = '1',
}

export enum StockMediaQualities {
  HIGH = '0',
  MEDIUM = '1',
  LOW = '2',
}

export enum ComponentTextTypes {
  TITLE = '0',
  SUBTITLE = '1',
  LABEL = '2',
  QUOTE = '3',
  BODY = '4',
  JUMBO = '5',
}

export enum ComponentTextFormats {
  NAME = '0',
  DATE = '1',
}

export enum DeckTypes {
  STANDARD = '0',
  AI = '1',
}

export enum DeckPromptTypes {
  TOPIC = '0',
  PDF = '1',
}

export enum DeckExportTypes {
  PDF = '0',
  PPTX = '1',
}

export enum DeckExportStates {
  PROCESSING = '0',
  FINAL = '1',
  ERRORED = '2',
}

export enum ComponentDeviceTypes {
  DESKTOP = '0',
  TABLET = '1',
  PHONE = '2',
}

export enum ComponentChartTypes {
  PIE = '0',
  LINE = '1',
  BAR = '2',
  DONUT = '3',
}

export enum UserLifecycleStages {
  FREE = '0',
  CUSTOMER = '1',
  OTHER = '2',
}

export enum IconStyleColor {
  BLACK = '0',
  COLOR = '1',
  GRADIENT = '2',
}

export enum IconStyleShape {
  OUTLINE = '0',
  FILL = '1',
  LINEALCOLOR = '2',
  HANDDRAWN = '3',
}

export enum Sides {
  TOP_LEFT = '0',
  TOP = '1',
  TOP_RIGHT = '2',
  LEFT = '3',
  RIGHT = '4',
  BOTTOM_LEFT = '5',
  BOTTOM = '6',
  BOTTOM_RIGHT = '7',
}

export enum UserStates {
  ACTIVE = '0',
  INACTIVE = '1',
}

export enum SVGTypes {
  BLANK = 'BLANK',
  COVER_RIGHT_1 = 'COVER_RIGHT_1',
  COVER_RIGHT_2 = 'COVER_RIGHT_2',
  COVER_LEFT_1 = 'COVER_LEFT_1',
  COVER_LEFT_2 = 'COVER_LEFT_2',
  COVER_ALPHA_1 = 'COVER_ALPHA_1',
  MASK_RIGHT_1 = 'MASK_RIGHT_1',
  MASK_RIGHT_2 = 'MASK_RIGHT_2',
  MASK_LEFT_1 = 'MASK_LEFT_1',
  MASK_LEFT_2 = 'MASK_LEFT_2',
  MASK_BOTTOM_1 = 'MASK_BOTTOM_1',
  MASK_BOTTOM_2 = 'MASK_BOTTOM_2',
  GENERIC_1 = 'GENERIC_1',
  GENERIC_2 = 'GENERIC_2',
  GENERIC_3 = 'GENERIC_3',
  GENERIC_4 = 'GENERIC_4',
}

export enum AiTextEditTypes {
  SHORTEN = 'SHORTEN',
  EXTEND = 'EXTEND',
  FIX = 'FIX',
  REWRITE = 'REWRITE',
  OTHER = 'OTHER',
}

export enum SlackChannelNames {
  ADMIN_LOGIN_AS_USER_LOGS = 'admin-login-as-user-logs',
  BOT_ALERTS = 'bot-alerts',
  DEPLOYMENT_LOGS = 'deployment-logs',
}
