import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ISlideCard } from './types'

type ISlideCardStyles = Pick<ISlideCard, 'isActive'> & {
  isDragging: boolean
}
export const slideCardStyles =
  ({ isActive, isDragging }: ISlideCardStyles) =>
  ({ colors, borderRadius }: ITheme) => css`
    background: ${colors.black[10]};
    position: relative;
    outline: 1px solid ${colors.elements.DEFAULT};
    height: 136px;
    width: 240px;
    border-radius: ${borderRadius[4]};
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    ${isDragging &&
    `
		outline: none;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background: ${colors.elements.DEFAULT};
			}
		`}

    ${isActive
      ? `outline: 3px solid ${colors.primary.DEFAULT};`
      : `&:hover {
					outline: 3px solid ${colors.primary[60]};
				}`}
  `

export const draggableAreaStyles = css`
  width: 100%;
  height: 100%;
`
