import { dataAttrType } from 'src/types/common'
import { BUTTON_THEME, IButton } from '../button'
import { IInput } from '../input'
import { ValidationRules } from 'src/hooks'
import { icons } from '../icon'

export enum DIALOG_THEME {
  DARK = 'dark',
  LIGHT = 'light',
  COLORFUL = 'colorful',
}

export enum DIALOG_PADDING {
  DEFAULT = 'default',
  BIG = 'big',
}

export interface IDialog {
  title?: string
  text?: string[]
  theme?: DIALOG_THEME
  padding?: DIALOG_PADDING

  onCloseClick?: IButton['onClick']

  submitLabel?: IButton['text']
  submitTheme?: BUTTON_THEME
  cancelTheme?: BUTTON_THEME
  onSubmit: IButton['onClick']
  submitIcon?: icons
  submitDisabled?: boolean

  cancelLabel?: IButton['text']
  onCancel?: IButton['onClick']

  promptLabel?: IInput['label']
  promptPlaceholder?: IInput['placeholder']
  promptValue?: IInput['value']
  onPromptChange?: IInput['onChange']
  promptValidationRules?: ValidationRules

  image?: string

  className?: string
  dataAttr?: dataAttrType
  showCloseButton?: boolean
}
