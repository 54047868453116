import { dataAttrType } from 'src/types'
import { IMenuItem, AvatarItem, icons } from 'src/lib'
import React from 'react'

export enum CARD_TYPE {
  DASHBOARD = 'dashboard',
  SLIDE = 'slide',
}

export interface ICard {
  id?: number
  type?: CARD_TYPE
  avatar?: AvatarItem[]
  disabled?: boolean
  footerIcon?: icons
  footerText?: string
  footerTextInfo?: string
  isLoading?: boolean
  menuItems?: IMenuItem[]
  title?: string
  subtitle?: string
  subtitleInfo?: string
  className?: string
  primaryButtons?: React.ReactElement[]
  secondaryButtons?: React.ReactElement[]
  newDeckButtons?: React.ReactElement[]
  dataAttr?: dataAttrType
  thumbnailUrl?: string | null
  hasError?: boolean
  isNewDeck?: boolean
  isDeckReadyForTransfer?: boolean
  isDeckFinal?: boolean
  publishButton?: React.ReactElement
  onClickDelete?: () => void
}

export interface ICardAction {
  deckId: ICard['id']
  type: CARD_TYPE
  isLoading?: boolean
  showActions: boolean
  primaryButtons: ICard['primaryButtons']
  secondaryButtons: ICard['secondaryButtons']
  thumbnailUrl?: string | null
  isDeckFinal?: boolean
}
export enum CARD_ACTION_SIZE {
  SMALL = 'small',
  BIG = 'big',
}

export interface ICardActionItem {
  background?: string
  content: React.ReactElement
  image?: string
  name: string
}
