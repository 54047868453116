import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setSvgCache } from 'src/store'
import { useFlags } from './useFlags'

// TODO: option.distorted will force the hook to return distorted, aspectRatio otherwise
// to achieve this both content should be stored in cached item
export const useCachedSvg = (
  url?: string | null,
  options?: {
    isCached?: boolean
    id?: string
    distort?: boolean
  },
): { url?: string; content?: string } => {
  const dispatch = useDispatch()
  const flags = useFlags()
  const mediaUrl = useMemo(() => url, [url])

  const { cache } = useSelector(({ cache }: RootState) => ({
    cache: cache.svg,
  }))

  const svgContent = useMemo(() => {
    return mediaUrl && cache[mediaUrl]
  }, [cache, mediaUrl])

  const [nonCached, setNonCached] = useState({})

  const fetchSvgData = useCallback(async () => {
    if (!mediaUrl || (options?.isCached && cache[mediaUrl] !== undefined)) {
      return
    }
    const template = await fetch(mediaUrl)
    const svgText = await template.text()

    const pattern = /<svg\s+([^>]*)\s*(?:width="[^"]*")?\s*(?:height="[^"]*")?/
    const distortedSvg = svgText.replace(pattern, (match, attributes) => {
      const updatedAttributes = attributes.replace(
        /(?:width|height)="[^"]*"/g,
        '',
      )
      return `<svg ${updatedAttributes} width="100%" height="100%" preserveAspectRatio="none"`
    })
    const templateText = options?.distort ? distortedSvg : svgText

    const templateTextWithBg =
      flags.FE_SVG_DECOR && options?.id
        ? templateText
            .replace(
              '</svg>',
              `<defs>
							<linearGradient id="${options?.id}" x1="$gradient-x1" x2="$gradient-x2" y1="$gradient-y1" y2="$gradient-y2" gradientUnits="userSpaceOnUse">
								<stop stop-color="$gradient-start-color" />
								<stop offset="1" stop-color="$gradient-stop-color" />
							</linearGradient>
						</defs>
						</svg>`,
            )
            .replace('#5DDDDD', `url(#${options.id})`)
        : templateText

    const blob = new Blob([templateTextWithBg], { type: 'image/svg+xml' })
    const url = URL.createObjectURL(blob)

    setNonCached({
      url,
      content: templateTextWithBg,
    })
    dispatch(
      setSvgCache({
        key: mediaUrl,
        content: {
          url,
          content: templateTextWithBg,
          distorted: '',
        },
      }),
    )
  }, [cache, mediaUrl, options?.distort])

  // useEffect(() => {
  // 	const currentSvgData = mediaUrl && cache[mediaUrl]
  // }, [options?.distort, mediaUrl, cache])

  useEffect(() => {
    if (mediaUrl) {
      fetchSvgData()
      dispatch(
        setSvgCache({
          key: mediaUrl,
        }),
      )
    }
  }, [mediaUrl])

  return options?.isCached ? svgContent || {} : nonCached
}
