import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  width: 600px;
  height: 423px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.white.DEFAULT};
  border-radius: ${theme.borderRadius[6]};
  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 336px;
    height: 256px;
  }
`

export const innerStyles = (theme: ITheme) => css`
  width: 400px;
  height: 295px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 272px;
    height: 192px;
  }
`

export const titleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.xlarge};
  font-weight: ${theme.fontWeight.medium};
  padding: ${theme.gap['3xlarge']}px 0;
  @media (max-width: ${theme.breakpoints.tablet}px) {
    font-size: ${theme.fontSize.base};
    padding: ${theme.gap.xlarge}px 0;
  }
`

export const imageStyles = (theme: ITheme) => css`
  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100px;
    height: 100px;
  }
`

export const descriptionStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.regular};
  @media (max-width: ${theme.breakpoints.tablet}px) {
    font-size: ${theme.fontSize.small};
  }
`
