import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editAiAssistantStyles = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.gap.medium}px;
  flex-direction: column;
  margin-top: ${theme.gap.xlarge}px;
`

export const tipStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.medium}px;
`

export const paddingStyles = (theme: ITheme) => css`
  padding: 0 ${theme.gap.xlarge}px;
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.medium}px;
}
`

export const animationStyles = () => css`
  width: 200px;
}
`
export const answerStyles = (theme: ITheme) => css`
  padding: 0 ${theme.gap.small}px;
`
export const questionStyles = (theme: ITheme) => css`
  background: ${theme.colors.dark2[90]};
  padding: 10px ${theme.gap.small}px;
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  border-radius: ${theme.borderRadius[4]};
  text-align: start;
`
export const qaStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.small}px;
`
export const tipTitleStyles = (theme: ITheme) => css`
  background: ${theme.colors.dark.DEFAULT};
  padding: ${theme.gap.xsmall}px ${theme.gap.xlarge}px;
  width: 100%;
  text-align: start;
`
export const tipContentStyles = () => css`
  text-align: start;
`
export const createQaStyles = (theme: ITheme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.medium}px;
  align-items: center;
  justify-content: center;
  text-align: center;
`
