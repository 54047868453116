import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editDesignStyles = ({ gap, zIndex }: ITheme) => css`
  position: relative;
  padding: ${gap.xlarge}px;
  display: flex;
  flex-direction: column;
  gap: ${gap.medium}px;
  min-height: 100%;

  & .edit-design-loading-spinner-wrapper {
    height: 0;
    width: 100%;
    overflow: visible;
    position: sticky;
    top: 46%;
    transform: translateY(-46%);
    z-index: ${zIndex.toaster};

    & .edit-design-loading-spinner {
      position: sticky;
      left: 50%;
      transform: translateX(-50%);
      height: 36px;
    }
  }

  & .tabs-is-loading {
    margin-top: -16px;
  }
`

export const themeCardsStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-wrap: wrap;
  gap: ${gap.medium}px;
  justify-content: space-between;
`

interface IThemeCardStyles {
  isSelected?: boolean
}
export const themeCardStyles =
  ({ isSelected }: IThemeCardStyles) =>
  ({ colors, borderRadius, gap }: ITheme) => css`
    height: 90px;
    width: calc(50% - ${gap.medium / 2}px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: border-box;
    cursor: pointer;
    border-radius: ${borderRadius[4]};

    ${isSelected &&
    `
		  border: 2px solid ${colors.primary.DEFAULT};
	  `}
  `

export const loadingOverlayStyles = ({ zIndex }: ITheme) => css`
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${zIndex.modal};
`

export const styleTemplateStyles = ({
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  spacing,
}: ITheme) => css`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.large};

  & .style-title {
    font-size: ${fontSize.medium};
    font-weight: ${fontWeight.medium};
    padding-top: ${spacing['2xsmall']};
  }

  & .border {
    height: 1px;
    background-color: ${colors.outline.DEFAULT};
    border-radius: ${borderRadius[2]};
  }
`

export const styleItemContainerStyles = ({
  breakpoints,
  fontSize,
  fontWeight,
  spacing,
}: ITheme) => css`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.medium};

  & > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & > .selected-font > .box {
    height: 60px;
  }

  & .style-item-name {
    width: 80px;
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.small};

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: ${fontSize.medium};
      width: 103px;
    }
  }

  & .style-item-change {
    cursor: pointer;
    text-decoration: underline;
    font-size: ${fontSize.small};
    text-align: right;
  }

  & .font-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: ${spacing.small};
  }

  & .upload-font {
    text-decoration: underline;
    cursor: pointer;
    font-size: ${fontSize.small};
  }

  & .edit-palette-box {
    width: 58px;
    height: 35px;
  }

  & .palettes {
    display: flex;
    flex-wrap: wrap;
    row-gap: ${spacing.small};
  }
`
