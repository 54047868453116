import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

interface IWrapperStyles {
  isEditMode?: boolean
}
export const wrapperStyles =
  ({ isEditMode }: IWrapperStyles) =>
  (theme: ITheme) => css`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    ${isEditMode &&
    `
  		max-height: calc(100vh - ${theme.dimensions.headerHeight.DEFAULT}px);
		`}
  `

export const headerLayoutStyle = (theme: ITheme) => css`
  position: sticky;
  top: 0;
  z-index: ${theme.zIndex.header};
`
