import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
export const wrapperStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: row;
  width: 624px;
  height: 356px;
  border-radius: ${theme.borderRadius[6]};
  background: ${theme.colors.primary[10]};
  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`
export const leftStyles = (theme: ITheme) => css`
  background: ${theme.colors.primary[20]};
  border-radius: ${theme.borderRadius[6]} ${theme.borderRadius[0]}
    ${theme.borderRadius[0]} ${theme.borderRadius[6]};
  width: 220px;
  height: 356px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding: ${theme.gap.xlarge}px;
  @media (max-width: ${theme.breakpoints.tablet}px) {
    background: none;
  }
`
export const rightStyles = css`
  display: flex;
  width: 404px;
  height: 356px;
  flex-direction: column;
  align-items: center;
`
export const textWrapperStyles = (theme: ITheme) => css`
  padding-top: ${theme.gap['6xlarge']}px;
  @media (max-width: ${theme.breakpoints.tablet}px) {
    padding-top: ${theme.gap.medium}px;
  }
`
export const stepStyles = (theme: ITheme) => css`
  width: 404px;
  padding: ${theme.gap.small}px ${theme.gap.small}px 0 ${theme.gap.small}px;
  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 336px;
  }
`
export const titleStyles = (theme: ITheme) => css`
  background: ${theme.gradient.PURPLE};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  padding-bottom: ${theme.gap.xsmall}px;
  text-align: center;
  text-wrap: balance;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
export const descriptionStyles = (theme: ITheme) => css`
  color: ${theme.colors.text.DEFAULT};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
`
export const buttonsStyles = (theme: ITheme) => css`
  display: flex;
  padding-top: ${theme.gap.xlarge}px;
  gap: ${theme.gap.small}px;
  @media (max-width: ${theme.breakpoints.tablet}px) {
    flex-wrap: wrap;
    width: 250px;
    justify-content: center;
  }
`
export const linkButtonStyles = css`
  text-decoration: underline;
`
export const buttonStyles = (theme: ITheme) => css`
  display: flex;
  width: 350px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${theme.gap.xsmall}px;
`
export const continueButtonStyles = (theme: ITheme) => css`
  margin-top: ${theme.gap.medium}px;
`
export const backButtonStyles = (theme: ITheme) => css`
  position: absolute;
  margin-top: ${theme.gap.small}px;
  margin-left: ${theme.gap.small}px;
`
