import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const formInner = (theme: ITheme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.medium};
`

export const titleContainerStyles = css`
  width: 100%;
`

export const gradientTextStyles = ({
  gradient,
  fontSize,
  fontWeight,
  gap,
}: ITheme) => css`
  background: ${gradient.DEFAULT};
  font-size: ${fontSize.xlarge};
  font-weight: ${fontWeight.bold};
  margin-bottom: ${gap.small};
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const actionsStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing.xsmall};

  .info-text {
    color: ${theme.colors.text[2]};
    font-size: ${theme.fontSize.small};
    text-align: left;
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    text-align: center;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    gap: ${theme.spacing.xsmall};

    .info-text {
      color: ${theme.colors.text[2]};
      font-size: ${theme.fontSize.base};
    }
  }
`
