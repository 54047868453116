import React, { useCallback, useEffect, useRef, useState } from 'react'

import { IAiOutline } from './types'
import { aiOutlineStyles, outlineStyles } from './styles'
import { buttonStyles, viewStyles } from '../ai-prompt/styles'
import { Steps } from 'src/lib/steps'
import { BUTTON_THEME, BUTTON_TYPE, Button, Icon, icons } from 'src/lib'
import { useDispatch, useSelector } from 'react-redux'
import {
  AI_FLOW_STEP,
  RootState,
  setAiFlowStep,
  updateSelectedOutline,
} from 'src/store'
import { useClickOutside, useLanguage } from 'src/hooks'
import { ListEditor } from '../../list-editor'
import { clearHtmlTags } from 'src/services/stringServices'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'
import { pdfNameStyles, stepStyles } from '../styles'

export const AiOutline: React.FC<IAiOutline> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const outlineArea = useRef(null)

    const { getAiFlowTheme } = useAiFlowApi()
    const { selectedOutline, pdfName, pdfImport, promptId } = useSelector(
      ({ aiFlow }: RootState) => ({
        selectedOutline: aiFlow.selectedOutline,
        pdfName: aiFlow.pdfFileName,
        pdfImport: aiFlow.pdfImport,
        promptId: aiFlow.selectedPromptId,
      }),
    )

    const [focus, setFocus] = useState(false)

    const outsideClick = useCallback(() => {
      setFocus(false)
    }, [focus])

    useClickOutside(outlineArea, outsideClick)

    useEffect(() => {
      const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !focus) {
          event.preventDefault()
          if (selectedOutline.length > 2) {
            nextButton()
          }
        }
      }
      document.addEventListener('keydown', keyDownHandler)
      return () => {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }, [focus, selectedOutline])

    const [loadingState, setLoadingState] = useState(false)

    const nextButton = async () => {
      setLoadingState(true)
      const cleanData = clearHtmlTags(selectedOutline)
      dispatch(updateSelectedOutline(cleanData))
      const apiResult = await getAiFlowTheme({
        outline: cleanData,
        deckPromptId: promptId,
      })
      if (apiResult) {
        dispatch(setAiFlowStep(AI_FLOW_STEP.THEME))
      }
      setLoadingState(false)
    }

    const backButton = () => {
      dispatch(setAiFlowStep(AI_FLOW_STEP.AIM))
      dispatch(
        updateSelectedOutline(
          selectedOutline.filter((line) => line.trim() !== ''),
        ),
      )
    }

    const focusActive = useCallback(() => {
      setFocus(true)
    }, [focus])
    return (
      <div className={className} {...dataAttr}>
        <div css={viewStyles(false)}>
          {!pdfName && <Steps steps={5} current={4} css={stepStyles} />}
          {pdfImport && (
            <div css={pdfNameStyles}>
              <Icon icon={icons.pdf}></Icon>
              {pdfName}
            </div>
          )}
          <div css={aiOutlineStyles} ref={outlineArea} onClick={focusActive}>
            <ListEditor css={outlineStyles} />
          </div>
        </div>
        <div css={buttonStyles}>
          <Button
            type={BUTTON_TYPE.GHOST}
            text={t('common.actions.back')}
            icon={icons.chevron_left}
            isLink
            onClick={backButton}
          />
          <Button
            text={t('common.actions.next')}
            theme={BUTTON_THEME.GRADIENT}
            onClick={nextButton}
            disabled={selectedOutline.length < 3}
            isLoading={loadingState}
          />
        </div>
      </div>
    )
  },
)

AiOutline.displayName = 'AiOutline'
