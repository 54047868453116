import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDecksApi, useLanguage } from 'src/hooks'
import {
  templateCardStyles,
  templateCardCanvasStyles,
  staticSlideCardStyles,
  overlayStyles,
} from './styles'
import { Canvas, CANVAS_DATA_TYPE, CANVAS_TYPE } from 'src/components/canvas'

import { dataAttrType } from 'src/types'
import {
  DeckData_DataSchema,
  ThemeColorDataSchema,
  ThemeColorMapSchema,
  ThemeFontDataSchema,
} from 'src/types/api/requestObjects'
import {
  getDeckDetailedResponse,
  getSlideDataResponse,
} from 'src/types/api/responseObjects'
import { FontImporter } from '../deck-page/components/font-importer'
import {
  Button,
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  icons,
  Loader,
} from 'src/lib'

type ISlideType = DeckData_DataSchema['slides'][0]

export interface ITemplateCard {
  data: ISlideType
  deck?: getDeckDetailedResponse['data']
  deckId?: number | null
  dataType?: CANVAS_DATA_TYPE
  defaultColorMap?: ThemeColorMapSchema
  colorMap?: ThemeColorMapSchema
  themeColors?: ThemeColorDataSchema
  shareKey?: string
  providedThemeFontData?: {
    id: number
    data: ThemeFontDataSchema
  }
  useActions?: boolean
  onPrepared?: () => void
  onTemplateSelection?: () => void
  onPreviewSelection?: () => void
  className?: string
  dataAttr?: dataAttrType
}

export const TemplateCard: React.FC<ITemplateCard> = React.memo(
  ({
    data,
    deck,
    deckId,
    dataType = CANVAS_DATA_TYPE.DECK,
    defaultColorMap,
    colorMap,
    themeColors,
    shareKey,
    providedThemeFontData,
    useActions,
    onPrepared,
    onTemplateSelection,
    onPreviewSelection,
    className,
    dataAttr,
  }) => {
    const { t } = useLanguage()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const { getPublicSlideData } = useDecksApi()
    const [slideData, setSlideData] =
      useState<getSlideDataResponse['data']['slideData']>()

    useEffect(() => {
      if (!deckId) return
      prepareSlideData()
    }, [deckId, data])

    const prepareSlideData = useCallback(async () => {
      if (!deckId || !data.slideId || !data.slideDataId) return

      const slideDataResponse = await getPublicSlideData(
        {
          deckId,
          slideId: data.slideId!,
          slideDataId: data.slideDataId,
        },
        useActions,
      )

      setSlideData(slideDataResponse)
      onPrepared && onPrepared()
      setIsLoading(false)
    }, [deckId, data, setSlideData])

    const components = useMemo(
      () => slideData?.slideDataComponents.flatMap((slide) => slide.component),
      [slideData],
    )

    const isSwapped = useMemo(() => slideData?.isSwapColor, [slideData])
    const [hoveredOver, setHoveredOver] = useState(false)

    return (
      <div css={staticSlideCardStyles} className={className} {...dataAttr}>
        {slideData && (
          <div
            css={templateCardStyles}
            className={isSwapped ? `theme-swapped` : `theme-default`}
            onMouseEnter={() => setHoveredOver(true)}
            onMouseLeave={() => setHoveredOver(false)}
          >
            {isLoading || !slideData.id ? (
              <Loader size={32} />
            ) : (
              <>
                <div css={templateCardCanvasStyles}>
                  <Canvas
                    components={components}
                    canvasType={CANVAS_TYPE.STATIC}
                    dataType={dataType}
                    dataProps={{
                      deck,
                      slideData,
                      deckId,
                      slideDataId: data.slideDataId,
                      defaultColorMap,
                      colorMap,
                      themeColors,
                      shareKey,
                    }}
                  />
                </div>
                {useActions && (
                  <div css={overlayStyles({ hoveredOver })}>
                    <Button
                      text={t('common.actions.select')}
                      onClick={onTemplateSelection}
                      icon={icons.checked}
                      isRounded
                      size={BUTTON_SIZE.SMALL}
                    />
                    <Button
                      text={t('common.actions.preview')}
                      onClick={onPreviewSelection}
                      theme={BUTTON_THEME.WHITE}
                      type={BUTTON_TYPE.GHOST}
                      icon={icons.eye}
                      isLink
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <FontImporter providedThemeFontData={providedThemeFontData} />
      </div>
    )
  },
)

TemplateCard.displayName = 'TemplateCard'
