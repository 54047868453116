import React, { useEffect, useMemo, useState } from 'react'

import { listPropsStyles } from './styles'
import { IEditPropSection } from '../../types'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ListItem } from './ListItem'
import { NewListItem } from './NewListItem'
import { ListTypes } from 'src/types/api/enums'

export const ListItems: React.FC<IEditPropSection> = React.memo(
  ({ components, isGrid, className, dataAttr }) => {
    const [expandedIndex, setExpandedIndex] = useState<number>()

    useEffect(() => {
      const listener = ({ detail }: any) => {
        setExpandedIndex(detail)
      }
      document.addEventListener('list-item-click', listener)
      return () => {
        document.removeEventListener('list-item-click', listener)
      }
    }, [])

    const selectedItems = useMemo<
      ComponentListDataSchema['listItems'] | undefined
    >(
      () =>
        components?.reduce(
          (
            a: ComponentListDataSchema['listItems'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const listItems = (c.data as ComponentListDataSchema).listItems
            if (a === undefined && listItems) {
              return listItems
            }

            return listItems !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedListType = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentListDataSchema['listType'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const listType = (c.data as ComponentListDataSchema).listType
            if (a === undefined && listType) {
              return listType
            }

            return listType !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const isMaxItemCount = useMemo(() => {
      switch (selectedListType) {
        case ListTypes.TIMELINE:
          return (selectedItems?.length || 0) >= 8
        default:
          return (selectedItems?.length || 0) >= 12
      }
    }, [selectedItems, selectedListType])

    const selectedMediaType = useMemo(() => {
      if (selectedListType === ListTypes.MEDIA) {
        return selectedItems?.[0]?.image?.type
      }
    }, [selectedItems, selectedListType])

    return (
      <div css={listPropsStyles} className={className} {...dataAttr}>
        {selectedItems?.map((item, index) => (
          <ListItem
            key={item.id}
            data={item}
            index={index}
            isExpanded={expandedIndex === index}
            onClick={setExpandedIndex}
            listItems={selectedItems}
            components={components}
            type={selectedListType}
            isGrid={isGrid}
          />
        ))}
        {!isMaxItemCount && (
          <NewListItem
            selectedMediaType={selectedMediaType}
            selectedItems={selectedItems}
            components={components}
            onNewItemClick={setExpandedIndex}
          />
        )}
      </div>
    )
  },
)

ListItems.displayName = 'ListItems'
