import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const collaboratorStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing['xsmall']};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
`

export const addCollaboratorStyles = (theme: ITheme) => css`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  direction: row;
  flex-wrap: wrap;
  column-gap: ${theme.spacing['small']};
  margin-bottom: ${theme.spacing['xlarge']};

  @media (min-width: ${theme.breakpoints.small}px) {
    display: block;
  }
`

export const smallSpanWrapperStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  margin-bottom: ${theme.gap['3xsmall']}px;
`

export const hasNotCollaboratorStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.xsmall};
`

export const pendingCollaboratorStyles = (theme: ITheme) => css`
  margin-bottom: ${theme.spacing['2xsmall']};
`
export const seeMoreStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.xsmall};
  color: ${theme.colors.primary.DEFAULT};
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`
