import React, { useState, useCallback } from 'react'
import { Button } from 'src/lib/button/Button'
import {
  customDomainContainerStyles,
  inputContainerStyles,
  shareTextStyles,
  spanWrapperStyles,
} from 'src/components/share-view/styles'
import {
  Input,
  INPUT_SIZE,
  BUTTON_TYPE,
  BUTTON_THEME,
  BUTTON_WIDTH,
  INPUT_WIDTH,
  Icon,
  icons,
} from 'src/lib'
import { ConnectCustomDomainProps } from './types'
import { CustomDomain } from '../custom-domain/'
import { useOrgApi, useLanguage } from 'src/hooks'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'

export const ConnectCustomDomain: React.FC<ConnectCustomDomainProps> =
  React.memo(({ onClose, cusDomain, isVerified }) => {
    const { t } = useLanguage()
    const { addCustomDomain, isLoading } = useOrgApi()

    const { workspaceId } = useSelector(({ workspace }: RootState) => ({
      workspaceId: workspace.id,
    }))

    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [customDomain, setCustomDomain] = useState(cusDomain)
    const [isCustomDomainAdded, setIsCustomDomainAdded] = useState(!!cusDomain)

    const handleConnectClick = useCallback(async () => {
      if (customDomain && !isLoading) {
        const res = await addCustomDomain(
          { domain: customDomain },
          workspaceId!,
        )
        setIsCustomDomainAdded(res)
      }
    }, [workspaceId, customDomain])

    const handleBackClick = useCallback(() => {
      onClose()
    }, [])

    return (
      <div>
        {!isCustomDomainAdded ? (
          <>
            <div css={customDomainContainerStyles}>
              <Icon
                icon={icons.chevron_left}
                size={20}
                onClick={() => onClose()}
              />
              <span css={spanWrapperStyles}>
                {t('share_modal.custom_domain.connect_your_custom_domain')}
              </span>
            </div>
            <span css={shareTextStyles}>
              {t('share_modal.custom_domain.add_your_subdomain')}
            </span>
            <div css={inputContainerStyles}>
              <Input
                width={INPUT_WIDTH.FULL}
                size={INPUT_SIZE.SMALL}
                placeholder={t(
                  'share_modal.custom_domain.share_link_placeholder',
                )}
                value={customDomain}
                onChange={(value) => {
                  setCustomDomain(value)
                  setIsButtonDisabled(value === '')
                }}
              />
              <Button
                text={t('common.actions.connect')}
                type={BUTTON_TYPE.DEFAULT}
                width={BUTTON_WIDTH.DEFAULT}
                theme={BUTTON_THEME.PRIMARY}
                disabled={isButtonDisabled}
                onClick={handleConnectClick}
              />
            </div>
          </>
        ) : (
          <CustomDomain
            onClose={handleBackClick}
            customDomain={customDomain!}
            isVerified={isVerified}
          />
        )}
      </div>
    )
  })
