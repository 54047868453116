import React, { useEffect, useState, useCallback } from 'react'
import { Button } from 'src/lib/button/Button'
import {
  shareModalInnerStyles,
  separatorStyles,
  shareModalWrapperStyles,
  shareTextStyles,
  switchContainerStyles,
  linkButtonStyles,
  copyTextStyles,
  iconStyles,
} from 'src/components/share-view/styles'
import {
  Input,
  INPUT_SIZE,
  BUTTON_SIZE,
  BUTTON_TYPE,
  BUTTON_THEME,
  Switch,
  icons,
  Icon,
  SWITCH_SIZE,
  Dropdown,
  DROPDOWN_WIDTH,
  DROPDOWN_MENU_POSITION,
  IDropdownItem,
} from 'src/lib'
import { GetShareLinkProps } from './types'
import { APP_CONFIG } from 'src/config'
import {
  ConnectCustomDomain,
  ConnectCustomDomainV2,
} from '../connect-custom-domain'
import { useDecksApi, useOrgApi } from 'src/hooks/api'
import { useFlags, useNotification, useLanguage } from 'src/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setCustomDomainId, setDeckShare } from 'src/store'
import { colors } from 'src/theme'
import env from 'react-dotenv'

export const GetShareLink: React.FC<GetShareLinkProps> = React.memo(
  ({
    shortCode,
    deckName,
    deckId,
    isActive,
    onConnectDomainClick,
    cusDomain,
    customDomainSlug,
  }) => {
    const { t } = useLanguage()
    const { getOrgCustomDomainInfo, isLoading: isOrgLoading } = useOrgApi()
    const { updateDeckShare, isLoading: isDeckLoading } = useDecksApi()
    const dispatch = useDispatch()
    const { success } = useNotification()
    const flags = useFlags()

    const [copied, setCopied] = useState(false)
    const [isLoadingForSharePublic, setIsLoadingForSharePublic] =
      useState(false)
    const [customDomainId, setCusDomainId] = useState<number | null>(null)
    const [customDomain, setCustomDomain] = useState(cusDomain)
    const [customDomainVerified, setCustomDomainVerified] = useState(false)
    const [showConnectDomain, setShowConnectDomain] = useState(false)
    const [slug, setSlug] = useState(customDomainSlug || shortCode)
    const [isSlugUpdated, setIsSlugUpdated] = useState(false)

    const [allDomains, setAllDomains] = useState<
      {
        id: number
        domain: string
        isVerified: boolean
      }[]
    >([])
    const [verifiedDomains, setVerifiedDomains] = useState<
      {
        id: number
        domain: string
        isVerified: boolean
      }[]
    >([])

    const { workspaceId } = useSelector(({ workspace }: RootState) => ({
      workspaceId: workspace.id,
    }))

    useEffect(() => {
      if (flags.FE_292_MULTIPLE_CUSTOMDOMAINS_ENABLED) {
        // Ignore this function if flag enabled
        return
      }

      if (
        customDomainId &&
        customDomainVerified &&
        cusDomain !== customDomain &&
        customDomainSlug !== shortCode
      ) {
        updateDeckShare(deckId, {
          customDomainId,
          slug: shortCode,
          isActive,
        })
      }
    }, [
      customDomainId,
      customDomainVerified,
      isActive,
      flags.FE_292_MULTIPLE_CUSTOMDOMAINS_ENABLED,
    ])

    useEffect(() => {
      const fetchCustomDomains = async () => {
        try {
          const res = await getOrgCustomDomainInfo({
            organizationId: workspaceId!,
          })
          if (res.length) {
            if (flags.FE_292_MULTIPLE_CUSTOMDOMAINS_ENABLED) {
              res.sort((a, b) => (a.domain > b.domain ? 1 : -1))

              setAllDomains(res)
              setVerifiedDomains(
                [
                  {
                    id: 0,
                    domain: env.SHARE_URL_BASE,
                    isVerified: true,
                  },
                  ...res.filter((cd) => cd.isVerified),
                ].filter((el) => !!el),
              )

              setCusDomainId(
                res.find((cd) => cd.domain === cusDomain)?.id || null,
              )
            } else {
              dispatch(setCustomDomainId(res[0].id))
              setCustomDomain(res[0].domain)
              setCusDomainId(res[0].id)
              setCustomDomainVerified(res[0].isVerified)
            }
          }
        } catch {
          return false
        }
      }
      fetchCustomDomains()
    }, [
      workspaceId,
      flags.FE_292_MULTIPLE_CUSTOMDOMAINS_ENABLED,
      showConnectDomain,
    ])

    const handleConnectClick = useCallback(() => {
      const canRender = onConnectDomainClick()
      if (canRender) {
        setShowConnectDomain(true)
      }
    }, [])

    const getFullLink = useCallback(() => {
      const DECKTOPUS_SHARE_LINK = env.SHARE_URL_BASE
      return `${customDomain ? customDomain : DECKTOPUS_SHARE_LINK}/${
        slug || shortCode
      }`
    }, [customDomain, shortCode, slug])

    const handleCopyClick = useCallback(async () => {
      try {
        await navigator.clipboard.writeText(getFullLink())
        setCopied(true)
      } catch {
        setCopied(false)
      }
    }, [customDomainVerified, customDomain, shortCode, slug])

    useEffect(() => {
      let timer: NodeJS.Timeout
      if (copied) {
        timer = setTimeout(() => {
          setCopied(false)
        }, APP_CONFIG.copyButtonTimeout)
      }
      return () => clearTimeout(timer)
    }, [copied])

    const handleShareLinkStatus = useCallback(async () => {
      setIsLoadingForSharePublic(true)
      await updateDeckShare(deckId, { isActive: !isActive })
      setIsLoadingForSharePublic(false)
    }, [deckId, isActive])

    const handleSlugChange = useCallback(async () => {
      if (isSlugUpdated && customDomainId && !isDeckLoading) {
        const res = await updateDeckShare(deckId, {
          customDomainId,
          slug,
          isActive,
        })
        if (res) {
          success(t('share_modal.share_link_updated'))
          dispatch(
            setDeckShare({
              deckId,
              params: {
                slug,
              },
              domain: customDomain!,
            }),
          )
        }
        setIsSlugUpdated(false)
      }
    }, [deckId, slug, isDeckLoading, isActive, customDomainId, isSlugUpdated])

    const onDomainChange = useCallback(
      async (val?: IDropdownItem) => {
        if (!val?.value || isDeckLoading) {
          return
        }

        setCustomDomain(val.value as string)

        if (val.value === env.SHARE_URL_BASE) {
          setSlug(shortCode)
          setCusDomainId(null)
        } else {
          setSlug(shortCode)
          setCusDomainId(
            verifiedDomains.find((cd) => cd.domain === val.value)?.id || null,
          )
        }

        const xCustomDomainId =
          val.value === env.SHARE_URL_BASE
            ? null
            : verifiedDomains.find((cd) => cd.domain === val.value)?.id || null

        const res = await updateDeckShare(deckId, {
          customDomainId: xCustomDomainId,
          slug: shortCode,
          isActive,
        })
        if (res) {
          success(t('share_modal.share_link_updated'))
          dispatch(
            setDeckShare({
              deckId,
              params: {
                slug: shortCode,
              },
              domain: val.value as string,
            }),
          )
        }
      },
      [verifiedDomains, shortCode, isActive, isDeckLoading],
    )

    return (
      <div>
        {!showConnectDomain ? (
          <>
            <div css={shareModalWrapperStyles}>
              {flags.FE_292_MULTIPLE_CUSTOMDOMAINS_ENABLED ? (
                <>
                  {verifiedDomains.length > 1 ? (
                    <Dropdown
                      items={verifiedDomains.map((cd) => {
                        return {
                          name: cd.domain.replace(/http(s)?\:\/\//, ''),
                          value: cd.domain,
                        }
                      })}
                      onChange={onDomainChange}
                      selected={customDomain || env.SHARE_URL_BASE}
                      width={DROPDOWN_WIDTH.NORMAL}
                      menuPosition={DROPDOWN_MENU_POSITION.DEFAULT}
                    />
                  ) : (
                    <Input
                      placeholder={env.SHARE_URL_BASE?.replace(
                        /http(s)?\:\/\//,
                        '',
                      )}
                      size={INPUT_SIZE.SMALL}
                      disabled={true}
                      css={shareModalInnerStyles}
                    />
                  )}
                </>
              ) : (
                <Input
                  placeholder={
                    customDomainVerified && customDomain
                      ? customDomain?.replace(/http(s)?\:\/\//, '')
                      : env.SHARE_URL_BASE?.replace(/http(s)?\:\/\//, '')
                  }
                  size={INPUT_SIZE.SMALL}
                  disabled={true}
                  css={shareModalInnerStyles}
                />
              )}

              <span css={separatorStyles}>/</span>
              <Input
                clearButton={false}
                size={INPUT_SIZE.SMALL}
                css={shareModalInnerStyles}
                value={slug}
                disabled={!customDomainId || isDeckLoading}
                onChange={(val) => {
                  setSlug(val)
                  setIsSlugUpdated(true)
                }}
                onEnter={handleSlugChange}
              />
              {!copied ? (
                <Icon
                  icon={icons.copy}
                  size={20}
                  css={iconStyles}
                  color={colors.outline[2]}
                  onClick={handleCopyClick}
                />
              ) : (
                <span css={copyTextStyles}>
                  {t('common.informative.copied')}
                </span>
              )}
            </div>
            <div css={switchContainerStyles}>
              <span css={shareTextStyles}>
                {t('share_modal.make_the_share_link_public')}
              </span>
              <Switch
                size={SWITCH_SIZE.XSMALL}
                onChange={() => handleShareLinkStatus()}
                selected={isActive}
                disabled={isLoadingForSharePublic}
              />
            </div>
            <Button
              css={linkButtonStyles}
              size={BUTTON_SIZE.SMALL}
              type={BUTTON_TYPE.GHOST}
              isLink
              theme={BUTTON_THEME.PRIMARY}
              text={t('share_modal.custom_domain.connect_your_custom_domain')}
              onClick={handleConnectClick}
              disabled={isOrgLoading}
            />
          </>
        ) : (
          <>
            {flags.FE_292_MULTIPLE_CUSTOMDOMAINS_ENABLED ? (
              <ConnectCustomDomainV2
                allDomains={allDomains}
                onClose={() => setShowConnectDomain(false)}
              />
            ) : (
              <ConnectCustomDomain
                deckName={deckName}
                onClose={() => setShowConnectDomain(false)}
                shortCode={shortCode}
                cusDomain={customDomain}
                isVerified={customDomainVerified || false}
              />
            )}
          </>
        )}
      </div>
    )
  },
)
