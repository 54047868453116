import React, { useCallback, useMemo } from 'react'

import { IDragPreview } from './types'
import { useDragLayer } from 'react-dnd'
import { CANVAS_ITEM_TYPE, CANVAS_TYPE } from '../../types'
import { dragPreviewStyles } from './styles'
import { DragController } from '../drag-controller'

export const DragPreview: React.FC<IDragPreview> = React.memo(({ scale }) => {
  const {
    item,
    itemType,
    isDragging,
    initialOffset,
    currentOffset,
    sourceOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getClientOffset(),
    sourceOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const renderPreview = useCallback(() => {
    switch (itemType) {
      case CANVAS_ITEM_TYPE.CANVAS_ITEM:
        return (
          <DragController
            hasBorder
            scale={scale}
            canvasType={CANVAS_TYPE.FIXED}
            data={item[0]}
            zIndexList={[]}
          />
        )
      case CANVAS_ITEM_TYPE.SIDEBAR_ITEM:
        return (
          <DragController
            hasBorder
            scale={scale}
            canvasType={CANVAS_TYPE.FIXED}
            data={item[0]}
            zIndexList={[]}
          />
        )
      default:
        return null
    }
  }, [item, itemType])

  const canRender = useMemo(
    () => initialOffset && currentOffset,
    [initialOffset, currentOffset],
  )

  const { x, y } = useMemo(() => {
    switch (itemType) {
      case CANVAS_ITEM_TYPE.CANVAS_ITEM:
        return {
          x: sourceOffset?.x,
          y: sourceOffset?.y,
        }
      default:
        return {
          x: currentOffset?.x,
          y: currentOffset?.y,
        }
    }
  }, [itemType, currentOffset, sourceOffset])

  return isDragging ? (
    <div css={dragPreviewStyles} data-font-family-exception="true">
      {canRender && (
        <div
          style={{
            transformOrigin: 'left top',
            transform: `translate(${x}px, ${y}px) scale(${scale})`,
            WebkitTransform: `translate(${x}px, ${y}px) scale(${scale})`,
          }}
        >
          {renderPreview()}
        </div>
      )}
    </div>
  ) : null
})

DragPreview.displayName = 'DragPreview'
