import { RefObject, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedComponents } from 'src/store'

export const useUnSelect = (
  dataAttr: string,
  ref: RefObject<HTMLDivElement>,
) => {
  const dispatch = useDispatch()

  const hasParentWithAttribute = useCallback(
    (target: HTMLElement, dataAttr: string): boolean => {
      if (target?.tagName === 'BODY') {
        return false
      }

      if (target?.getAttribute(dataAttr)) {
        return true
      }

      return hasParentWithAttribute(
        target?.parentElement as HTMLElement,
        dataAttr,
      )
    },
    [],
  )

  const dragWrapperListener = useCallback((event: Event) => {
    if (
      hasParentWithAttribute(event.target as HTMLElement, 'data-canvas') &&
      !hasParentWithAttribute(event.target as HTMLElement, dataAttr)
    ) {
      dispatch(setSelectedComponents())
    }
  }, [])

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('mousedown', dragWrapperListener)
      ref.current.addEventListener('touchstart', dragWrapperListener)
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('mousedown', dragWrapperListener)
        ref.current.removeEventListener('touchstart', dragWrapperListener)
      }
    }
  }, [ref])
}
