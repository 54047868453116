import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const templatesPageStyles = ({
  gap,
  dimensions,
  breakpoints,
}: ITheme) => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${gap.xlarge}px 0
    ${dimensions.initPageFooterHeight.DEFAULT + gap.xlarge}px;
  gap: ${gap.xlarge}px;
  overflow-y: auto;

  img {
    width: ${dimensions.deckInitImageWidth.small}px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    gap: ${gap['5xlarge']}px;
    img {
      width: ${dimensions.deckInitImageWidth.DEFAULT}px;
    }
  }
`

export const cardWrapperStyles = ({ colors, borderRadius }: ITheme) => css`
  cursor: pointer;
  width: 276px;
  height: 190px;
  flex-direction: column;
  border-radius: ${borderRadius[4]};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.dark.DEFAULT};
`

export const templateModalWrapperStyles = ({
  colors,
  boxShadow,
  borderRadius,
  breakpoints,
  dimensions,
}: ITheme) => css`
  background-color: ${colors.dark2.DEFAULT};
  width: ${dimensions.initPageTemplateModal.width}px;
  height: ${dimensions.initPageTemplateModal.height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${boxShadow[2]};
  border-radius: ${borderRadius[10]};

  @media (max-width: ${breakpoints.laptop}px) {
    width: ${dimensions.initPageTemplateModalTablet.width}px;
    height: ${dimensions.initPageTemplateModalTablet.height}px;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 100%;
    height: 100%;
  }
`

export const templateModalCloseButtonAreaStyles = ({ gap }: ITheme) => css`
  margin-bottom: ${gap.medium}px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 ${gap['4xlarge']}px;
  padding-top: ${gap['2xlarge']}px;
`

export const templateModalTitleStyles = ({
  fontSize,
  fontWeight,
  colors,
}: ITheme) => css`
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
  color: ${colors.white.DEFAULT};
`

export const templateModalSlidesCentererStyles = css`
  display: flex;
  justify-content: center;
`

export const templateModalSlidesWrapperStyles = ({
  gap,
  breakpoints,
}: ITheme) => css`
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  justify-content: start;
  width: 100%;
  padding: ${gap['xsmall']}px ${gap['3xlarge']}px;
  height: 470px;
  overflow-y: auto;
  gap: ${gap.xlarge}px;

  @media (max-width: ${breakpoints.laptop - 1}px) {
    padding: ${gap['xsmall']}px ${gap['3xlarge']}px;
    justify-content: center;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: ${gap['xsmall']}px;
    justify-content: center;
    height: calc(100vh - 150px);
  }
`

export const templateModalFooterStyles = ({ gap }: ITheme) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${gap.medium}px 0;
`

export const loaderWrapperStyles = ({ gap }: ITheme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${gap['6xlarge']}px;
`

export const deckNameStyles = ({ colors, gap }: ITheme) => css`
  max-width: 276px;
  text-align: center;
  color: ${colors.white.DEFAULT};
  padding: ${gap.xsmall}px ${gap.medium}px 0 ${gap.medium}px;
`
export const deckNameTextStyles = () => css`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  max-width: 276px;
  text-overflow: ellipsis;
`

export const templateCardStyles = css`
  position: relative;
  width: 100%;
  height: 144px;
  cursor: pointer;
  overflow: hidden;
`

export const templateCardCanvasStyles = css`
  position: relative;
  width: 100%;
  height: 144px;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
`

export const overlayStyles =
  ({ hoveredOver }: { hoveredOver: boolean }) =>
  ({ gap, gradient }: ITheme) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: ${gap.small}px;
    transition: all 0.3s;
    background: ${gradient.CARD};
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    visibility: ${hoveredOver ? 'visible' : 'hidden'};
    opacity: ${hoveredOver ? 1 : 0};
  `

export const staticSlideCardStyles = ({ colors, borderRadius }: ITheme) => css`
  background: ${colors.black[10]};
  position: relative;
  outline: 6px solid ${colors.dark.DEFAULT};
  height: 142px;
  width: 256px;
  border-radius: ${borderRadius[4]};
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

export const infiniteObserverStyles = css`
  left: 0;
  display: block;
  width: 100%;
  height: 4px;
`
