import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const chartStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: 1.5em;
`
