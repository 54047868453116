import React, { useMemo, useState } from 'react'
import {
  IMenuItem,
  icons,
  Button,
  BUTTON_THEME,
  BUTTON_TYPE,
  Icon,
} from 'src/lib'
import { IShareView } from './types'
import { ShareLink } from './components/share-link'
import { AddCollaborator } from './components/add-collaborator'
import { Export } from './components/export'
import { EmbedCode } from './components/embed-code'
import {
  closeWrapperStyles,
  iconStyles,
  leftWrapperStyles,
  menuStyles,
  rightWrapperStyles,
  wrapperStyles,
} from './styles'
import { useLanguage } from 'src/hooks'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { DeckPermissions } from 'src/types/api/enums'

export const ShareView: React.FC<IShareView> = React.memo(
  ({ className, onClickClose, deckId, deckShareCode }) => {
    const { decks, deckDetails, workspaceId } = useSelector(
      ({ decks, edit, workspace }: RootState) => ({
        decks: decks.list.data,
        deckDetails: edit.activeDeck.data?.deck,
        workspaceId: workspace.id,
      }),
    )
    const [currentTab, setCurrentTab] = useState<number>(0)

    const location = useLocation()

    const { t } = useLanguage()

    const activeDeck = useMemo(() => {
      return decks?.find((deck) => deck.deck.id === deckId)?.deck
    }, [decks, deckId, workspaceId])

    const activeDeckName = useMemo(() => {
      if (location.pathname.includes('deck/')) return deckDetails?.name
      return activeDeck?.name
    }, [location, deckDetails?.name, activeDeck?.name])

    const activeDeckId = useMemo(() => {
      if (location.pathname.includes('deck/')) return deckDetails?.id
      return activeDeck?.id
    }, [location, deckDetails?.id, activeDeck?.id])

    const activeDeckViewMode = useMemo(() => {
      if (location.pathname.includes('deck/')) return deckDetails?.viewMode
      return activeDeck?.viewMode
    }, [location, deckDetails?.viewMode, activeDeck?.viewMode])

    const activeDeckPermission = useMemo(() => {
      if (location.pathname.includes('deck/')) return DeckPermissions.READWRITE
      return activeDeck?.organizationDecks.find(
        (og) => og.organizationId === activeDeck?.organizationId,
      )?.permission
    }, [location, activeDeck?.organizationDecks])

    const activeDeckShare = useMemo(() => {
      if (location.pathname.includes('deck/')) return deckDetails?.deckShare
      return activeDeck?.deckShare
    }, [location, deckDetails?.deckShare, activeDeck?.deckShare])

    const activeDeckOrganizationId = useMemo(() => {
      if (location.pathname.includes('deck/')) return workspaceId
      return activeDeck?.organizationId
    }, [location, workspaceId, activeDeck?.organizationId])

    const activeDeckUserDecks = useMemo(() => {
      if (location.pathname.includes('deck/')) return deckDetails?.userDecks
      return activeDeck?.userDecks
    }, [location, deckDetails?.userDecks, activeDeck?.userDecks])

    const activeDeckOwnerUserId = useMemo(() => {
      if (location.pathname.includes('deck/')) return deckDetails?.ownerUser?.id
      return activeDeck?.ownerUser?.id
    }, [location, deckDetails?.ownerUser, activeDeck?.ownerUser])

    const activeDeckOwner = useMemo(() => {
      if (location.pathname.includes('deck/')) return deckDetails?.ownerUser
      return activeDeck?.ownerUser
    }, [location, deckDetails?.ownerUser, activeDeck?.ownerUser])

    const context = useMemo(
      () => (location.pathname.includes('deck/') ? 'deck' : 'dashboard'),
      [location],
    )

    const items = useMemo<IMenuItem[]>(
      () => [
        {
          label: t('share_modal.share_link'),
          icon: icons.share,
          onClick: () => setCurrentTab(0),
          isSelected: currentTab === 0,
        },
        {
          label: t('share_modal.embed_code'),
          icon: icons.web,
          onClick: () => setCurrentTab(1),
          isSelected: currentTab === 1,
        },
        {
          label: t('share_modal.add_collaborators'),
          icon: icons.team,
          onClick: () => setCurrentTab(2),
          isSelected: currentTab === 2,
        },
        {
          label: t('common.actions.export'),
          icon: icons.download,
          onClick: () => setCurrentTab(3),
          isSelected: currentTab === 3,
        },
      ],
      [currentTab],
    )

    const tabs = [
      <ShareLink
        key="share-link"
        deckName={activeDeckName}
        deckId={activeDeckId!}
        deckOrganizationId={activeDeckOrganizationId}
        deckShareCode={deckShareCode ?? activeDeckShare?.shortCode.value}
        isActive={activeDeckShare?.isActive}
        customDomain={activeDeckShare?.customDomainSlug?.customDomain?.domain}
        customDomainSlug={activeDeckShare?.customDomainSlug?.slug}
        context={context}
      />,
      <EmbedCode
        key="embed-code"
        deckId={activeDeckId!}
        deckOrganizationId={activeDeckOrganizationId}
        deckShareCode={deckShareCode ?? activeDeckShare?.shortCode.value}
        deckName={activeDeckName}
        context={context}
      />,
      <AddCollaborator
        key="add-collaborator"
        deckId={activeDeckId!}
        workspaceId={workspaceId}
        viewMode={activeDeckViewMode}
        deckPermission={activeDeckPermission}
        userDecks={activeDeckUserDecks}
        ownerUserId={activeDeckOwnerUserId}
        activeDeckOwner={activeDeckOwner}
      />,
      <Export key="export" deckId={activeDeckId} deckName={activeDeckName} />,
    ]

    const CurrentComponent = useMemo(() => {
      return tabs[currentTab]
    }, [currentTab, activeDeck, deckDetails])

    return (
      <div className={className} css={wrapperStyles}>
        <Button
          css={closeWrapperStyles}
          icon={icons.close}
          type={BUTTON_TYPE.GHOST}
          onClick={onClickClose}
          theme={BUTTON_THEME.GRAY}
        />
        <div css={leftWrapperStyles}>
          {items.map((item, index) => (
            <div
              key={index}
              css={menuStyles(!!item.isSelected)}
              onClick={() => setCurrentTab(index)}
            >
              {item.icon && <Icon icon={item.icon} css={iconStyles} />}
              {item.label}
            </div>
          ))}
        </div>
        <div css={rightWrapperStyles}>{CurrentComponent}</div>
      </div>
    )
  },
)

ShareView.displayName = 'ShareView'
