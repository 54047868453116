import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const orgMembersStyles = ({
  gap,
  colors,
  breakpoints,
  dimensions,
}: ITheme) => css`
  position: relative;
  height: calc(
    100% -
      ${dimensions.remainingSeatsHeight.DEFAULT +
      dimensions.organizationHeaderHeight.DEFAULT}px
  );
  overflow-y: auto;
  padding: ${gap.medium}px;
  background: ${colors.white.DEFAULT};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: ${gap['2xlarge']}px;
    height: calc(100% - ${dimensions.organizationHeaderHeight.DEFAULT}px);
  }

  @media (min-width: ${breakpoints.laptop}px) {
    padding: ${gap.xlarge}px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${gap['2xlarge']}px;
    height: calc(100% - ${dimensions.organizationHeaderHeight.long}px);
  }

  @media (min-width: ${breakpoints.large}px) {
    padding: ${gap['4xlarge']}px;
  }

  @media (min-width: ${breakpoints.xlarge}px) {
    padding: ${gap['4xlarge']}px ${gap['5xlarge']}px;
  }
`

export const inlineWrapperStyles = css`
  width: 100%;
  height: 100%;
  position: relative;
`

export const stickySeatsStyles = (theme: ITheme) => css`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;

  @media (min-width: ${theme.breakpoints.small}px) {
    border-top: 1px solid ${theme.colors.outline.DEFAULT};
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    position: relative;
    bottom: unset;
  }
`

export const separatorStyles = (theme: ITheme) => css`
  display: none;
  &:before,
  &:after {
    border-top: 1px solid ${theme.colors.outline.DEFAULT};
  }
  @media (min-width: ${theme.breakpoints.small}px) {
    display: block;
    height: 1px;
  }
`

export const contentStyles = (theme: ITheme) => css`
  padding: ${theme.gap.medium}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    padding: ${theme.gap.medium}px 0;
  }
`
