import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IEditControls } from './types'
import { editControlsStyles } from './styles'
import { BUTTON_SIZE, Button, icons } from 'src/lib'
import { useSelector } from 'react-redux'
import { RootState, SAVE_STATE } from 'src/store'
import { useNavigate, useParams } from 'react-router-dom'
import { useDecksApi } from 'src/hooks'
import { useTranslation } from 'react-i18next'

export const EditControls: React.FC<IEditControls> = React.memo(
  ({ className, dataAttr }) => {
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { saveChanges } = useDecksApi()

    const [activeSlide, setActiveSlide] = useState<number | ''>(
      params.slide ? parseInt(params.slide) : 1,
    )
    const { activeSlides, saveState } = useSelector(
      ({ edit, canvas }: RootState) => ({
        activeSlides: edit.activeDeck.data?.deckData?.data.slides,
        saveState: canvas.saveState,
      }),
    )

    const maxSlide = useMemo(
      () => activeSlides?.filter(({ isDeleted }) => !isDeleted)?.length || 0,
      [activeSlides],
    )

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetNumber =
          e.currentTarget.value === '' ? '' : parseInt(e.currentTarget.value)
        setActiveSlide(targetNumber)
      },
      [],
    )

    const handleInputKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          const targetSlide =
            activeSlide === ''
              ? 1
              : activeSlide > maxSlide
              ? maxSlide
              : activeSlide
          setActiveSlide(targetSlide)
          navigate(`/deck/${params.id}/${targetSlide}`)
        }
      },
      [activeSlide],
    )

    const onInputClickHandler = useCallback(
      (e: React.MouseEvent<HTMLInputElement>) => {
        e.currentTarget.select()
      },
      [],
    )

    const onPreviewClick = useCallback(async () => {
      if (saveState === SAVE_STATE.NOT_SAVED) {
        await saveChanges()
      }
      window.open(`/preview/${params.id}/1`, '_blank', 'noopener,noreferrer')
    }, [params, saveState, saveChanges])

    useEffect(() => {
      params.slide && setActiveSlide(parseInt(params.slide))
    }, [params.slide])

    if (!maxSlide) {
      return null
    }

    return (
      <div css={editControlsStyles} className={className} {...dataAttr}>
        <div className="slide-control">
          <input
            value={activeSlide}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onClick={onInputClickHandler}
          />
          <span>/</span>
          <div className="max-slides">{maxSlide}</div>
        </div>
        <Button
          icon={icons.play}
          size={BUTTON_SIZE.XSMALL}
          text={t('common.actions.play')}
          onClick={onPreviewClick}
        />
      </div>
    )
  },
)

EditControls.displayName = 'EditControls'
