import React, { useState, useCallback, ChangeEvent, useEffect } from 'react'
import { VALIDATION_RULE_TYPES, useLanguage } from 'src/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setIsOrgMenuOpen } from 'src/store'
import { useOrgApi } from 'src/hooks/api/useOrgApi'
import { OrganizationHeader } from '../OrganizationHeader'
import {
  BUTTON_SIZE,
  Button,
  INPUT_SIZE,
  INPUT_WIDTH,
  ImageUpload,
  Input,
} from 'src/lib'
import { LeaveOrganization } from '../LeaveOrganization'
import { IOrganizationDetails } from './types'
import {
  orgDetailStyles,
  lineStyles,
  firstBlockStyles,
  gap,
  inputStyles,
  titleDetailStyles,
  titleStyles,
  orgDetailFormStyles,
  buttonStyles,
} from './styles'
import { theme } from 'src/theme'

export const OrganizationDetails: React.FC<IOrganizationDetails> = React.memo(
  () => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const [organizationNameValid, setOrganizationNameValid] = useState(false)
    const [isUpdateOrgLoading, setIsUpdateOrgLoading] = useState(false)

    const {
      name,
      description,
      logoUrl,
      orgID,
      isOrgMenuOpen,
      isCurrentUserAdmin,
    } = useSelector(({ org, user }: RootState) => ({
      name: org.details?.name,
      description: org.details?.description,
      logoUrl: org.details?.logoUrl,
      orgID: org.details?.id,
      isOrgMenuOpen: org.isOrgMenuOpen,
      isCurrentUserAdmin: org.users?.find(
        (orgUser) => orgUser.user?.id === user.data?.id,
      )?.isAdmin,
    }))

    const [orgName, setOrgName] = useState(name)
    const [orgDescription, setOrgDescription] = useState(description!)
    const [orgImage, setOrgImage] = useState(logoUrl!)

    useEffect(() => {
      setOrgName(name)
      if (description) setOrgDescription(description)
      if (logoUrl) setOrgImage(logoUrl)
    }, [name, description, logoUrl])

    const handleClick = useCallback(() => {
      dispatch(setIsOrgMenuOpen(!isOrgMenuOpen))
    }, [isOrgMenuOpen])

    const { updateOrgDetails } = useOrgApi()

    const onOrgDetailUpdateHandler = useCallback(
      async ({
        orgName,
        orgDescription,
        logoUrl,
      }: {
        orgName?: string
        orgDescription?: string
        logoUrl?: ChangeEvent<HTMLInputElement>
      }) => {
        setIsUpdateOrgLoading(true)
        logoUrl && setOrgImage(logoUrl as unknown as string)
        orgID &&
          (await updateOrgDetails({
            id: orgID,
            name: orgName,
            description: orgDescription || '',
            logoUrl,
          }))
        setIsUpdateOrgLoading(false)
      },
      [orgID],
    )
    return (
      <>
        <OrganizationHeader
          onMenuClick={() => handleClick()}
          title={t('organization.organization_details')}
        />
        <div css={orgDetailStyles}>
          <div css={orgDetailFormStyles}>
            <div css={firstBlockStyles}>
              <div css={gap}>
                <div css={titleStyles}>
                  {t('organization.organization_team_logo')}
                </div>
                <div css={titleStyles}>
                  <ImageUpload
                    defaultImage={orgImage}
                    isRight
                    onChange={(logoUrl) =>
                      onOrgDetailUpdateHandler({
                        logoUrl,
                      })
                    }
                    text={name}
                    disabled={!isCurrentUserAdmin}
                  />
                </div>
              </div>
              <div css={titleDetailStyles}>
                <div css={inputStyles}>
                  <Input
                    label={t('organization.organization_name_label')}
                    width={INPUT_WIDTH.FULL}
                    size={INPUT_SIZE.SMALL}
                    labelTheme={theme.colors.text.DEFAULT}
                    validation={{
                      [VALIDATION_RULE_TYPES.MIN]: {
                        value: 3,
                        text: t('validation.min_char', {
                          name: t('common.name'),
                          value: 3,
                        }),
                      },
                    }}
                    onValidation={setOrganizationNameValid}
                    value={orgName}
                    onChange={setOrgName}
                    clearButton={isCurrentUserAdmin}
                    disabled={!isCurrentUserAdmin}
                  />
                </div>
              </div>

              <div css={titleDetailStyles}>
                <div css={inputStyles}>
                  <Input
                    label={t('organization.organization_desc_label')}
                    width={INPUT_WIDTH.FULL}
                    labelTheme={theme.colors.text.DEFAULT}
                    size={INPUT_SIZE.SMALL}
                    value={orgDescription}
                    onChange={setOrgDescription}
                    clearButton={isCurrentUserAdmin}
                    disabled={!isCurrentUserAdmin}
                  />
                </div>
              </div>
              <Button
                text={t('profile.update.save_changes')}
                disabled={!organizationNameValid || !isCurrentUserAdmin}
                isLoading={isUpdateOrgLoading}
                size={BUTTON_SIZE.AUTO}
                css={buttonStyles}
                onClick={() =>
                  onOrgDetailUpdateHandler({ orgName, orgDescription })
                }
              />
            </div>
            <div css={lineStyles}></div>
            <LeaveOrganization />
          </div>
        </div>
      </>
    )
  },
)
