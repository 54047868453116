import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IDragController } from './types'
import { CANVAS_TYPE } from '../../types'

interface IDragControllerStyles extends Pick<IDragController, 'canvasType'> {
  isSelected?: boolean
}
export const dragControllerStyles =
  ({ isSelected, canvasType }: IDragControllerStyles) =>
  ({ colors }: ITheme) => css`
    position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    ${isSelected && `outline: 2px solid ${colors.primary.DEFAULT};`}

    ${canvasType === CANVAS_TYPE.DND &&
    `
			&:hover {
				outline: 2px solid ${colors.primary.DEFAULT};
			}
		`}

		${canvasType === CANVAS_TYPE.STATIC &&
    `
			pointer-events: none;
		`}
  `
