import { SVGTypes } from '../../types/api/enums'
import theme_1 from './build/theme_1'
import theme_10 from './build/theme_10'
import theme_11 from './build/theme_11'
import theme_12 from './build/theme_12'
import theme_13 from './build/theme_13'
import theme_14 from './build/theme_14'
import theme_15 from './build/theme_15'
import theme_16 from './build/theme_16'
import theme_17 from './build/theme_17'
import theme_18 from './build/theme_18'
import theme_19 from './build/theme_19'
import theme_2 from './build/theme_2'
import theme_20 from './build/theme_20'
import theme_21 from './build/theme_21'
import theme_22 from './build/theme_22'
import theme_23 from './build/theme_23'
import theme_24 from './build/theme_24'
import theme_25 from './build/theme_25'
import theme_26 from './build/theme_26'
import theme_27 from './build/theme_27'
import theme_28 from './build/theme_28'
import theme_29 from './build/theme_29'
import theme_3 from './build/theme_3'
import theme_30 from './build/theme_30'
import theme_31 from './build/theme_31'
import theme_32 from './build/theme_32'
import theme_33 from './build/theme_33'
import theme_34 from './build/theme_34'
import theme_35 from './build/theme_35'
import theme_36 from './build/theme_36'
import theme_37 from './build/theme_37'
import theme_38 from './build/theme_38'
import theme_39 from './build/theme_39'
import theme_4 from './build/theme_4'
import theme_40 from './build/theme_40'
import theme_41 from './build/theme_41'
import theme_42 from './build/theme_42'
import theme_5 from './build/theme_5'
import theme_6 from './build/theme_6'
import theme_7 from './build/theme_7'
import theme_8 from './build/theme_8'
import theme_9 from './build/theme_9'
export const decorMap: Record<string, Partial<Record<SVGTypes, string>>> = {
  1: theme_1,
  10: theme_10,
  11: theme_11,
  12: theme_12,
  13: theme_13,
  14: theme_14,
  15: theme_15,
  16: theme_16,
  17: theme_17,
  18: theme_18,
  19: theme_19,
  2: theme_2,
  20: theme_20,
  21: theme_21,
  22: theme_22,
  23: theme_23,
  24: theme_24,
  25: theme_25,
  26: theme_26,
  27: theme_27,
  28: theme_28,
  29: theme_29,
  3: theme_3,
  30: theme_30,
  31: theme_31,
  32: theme_32,
  33: theme_33,
  34: theme_34,
  35: theme_35,
  36: theme_36,
  37: theme_37,
  38: theme_38,
  39: theme_39,
  4: theme_4,
  40: theme_40,
  41: theme_41,
  42: theme_42,
  5: theme_5,
  6: theme_6,
  7: theme_7,
  8: theme_8,
  9: theme_9,
}
