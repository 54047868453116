import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const aiModalsStyles = (theme: ITheme) => css`
  ${theme.colors.white.DEFAULT};
`
export const pdfNameStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  font-weight: ${theme.fontWeight.medium};
  column-gap: ${theme.spacing['2xsmall']};
`
export const stepStyles = (theme: ITheme) => css`
  margin-bottom: ${theme.gap.small}px;
`
