import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const scratchPageStyles = ({
  gap,
  dimensions,
  breakpoints,
  colors,
}: ITheme) => css`
  color: ${colors.white.DEFAULT};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${gap.xlarge}px 0
    ${dimensions.initPageFooterHeight.DEFAULT + gap.xlarge}px;
  gap: ${gap.xlarge}px;
  overflow-y: auto;

  img {
    width: ${dimensions.deckInitImageWidth.small}px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    gap: ${gap['5xlarge']}px;
    img {
      width: ${dimensions.deckInitImageWidth.DEFAULT}px;
    }
  }
`
export const footerStyles = ({
  breakpoints,
  dimensions,
  colors,
  gap,
}: ITheme) => css`
  height: ${dimensions.initPageFooterHeight.DEFAULT}px;
  background: ${colors.dark2.DEFAULT};
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: ${dimensions.columnWidth.DEFAULT}px;
  }

  .edge {
    &:last-child {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0 ${gap['6xlarge']}px;
    .inner {
      width: 100%;
    }
    .edge {
      flex-grow: 1;
      width: 30%;

      &:last-child {
        display: block;
      }
    }
  }
`
