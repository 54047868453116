import React, { useEffect, useMemo } from 'react'
import { Awards } from 'src/lib/awards'
import { AWARD_ITEMS } from './consts'
import { Testimony } from 'src/lib/testimony'
import { AUTH_PAGE_LAYOUT, IAuthPageLayout } from './types'
import {
  backgroundOverlayStyles,
  backgroundStyles,
  bgImageStyles,
  contentStyles,
  leftAreaImageStyles,
  leftAreaStyles,
  leftAwardWrapperStyles,
  formCardStyles,
  rightAreaStyles,
  wrapperStyles,
  awardContainerStyles,
  formStyles,
  contentAreaStyles,
  bannerStyles,
} from './styles'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useNotification, useLanguage } from 'src/hooks'

export const AuthPageLayout: React.FC<IAuthPageLayout> = React.memo(
  ({ children, type }) => {
    const { error } = useNotification()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const isSessionExpired = useMemo(
      () => searchParams.get('session') === 'expired',
      [searchParams],
    )
    const { t } = useLanguage()

    useEffect(() => {
      const token = localStorage.getItem('token')
      if (token) {
        navigate('/dashboard', { replace: true })
        return
      }

      localStorage.removeItem('persist:workspace')
      localStorage.removeItem('token')
      localStorage.removeItem('tokenCreatedAt')
    }, [])

    useEffect(() => {
      if (isSessionExpired) {
        error('login.session_expired')
      }
    }, [isSessionExpired])

    return (
      <div css={wrapperStyles}>
        <div css={backgroundStyles}>
          <div css={bgImageStyles} />
          <div css={backgroundOverlayStyles({ type })} />
        </div>
        <div css={contentStyles}>
          <div css={leftAreaStyles}>
            <div css={leftAreaImageStyles}>
              {type === AUTH_PAGE_LAYOUT.SIGN_UP ? (
                <div css={bannerStyles}>{t('signup.banner')}</div>
              ) : (
                <div css={contentAreaStyles}>
                  <Testimony />
                </div>
              )}
            </div>
            <div css={leftAwardWrapperStyles}>
              <Awards items={AWARD_ITEMS} />
            </div>
          </div>
          <div css={rightAreaStyles}>
            <div css={formCardStyles}>
              <div css={formStyles}>{children}</div>
            </div>
            <div css={awardContainerStyles}>
              <Awards items={AWARD_ITEMS} />
            </div>
          </div>
        </div>
      </div>
    )
  },
)
