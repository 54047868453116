import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useThemeApi, useFlags } from 'src/hooks'
import { RootState } from 'src/store'
import { IUploadedFonts } from '../edit-design'
import {
  setGoogleFontsImportUrl,
  setFontsFromUrl,
  setGoogleFontNamesAccumulatively,
  setFontsFromUrlsAccumulatively,
} from 'src/store'
import { Global, css } from '@emotion/react'
import { FontServices } from 'src/services'
import { ThemeFontDataSchema } from 'src/types/api/requestObjects'

export interface IFontImporter {
  providedThemeFontData?: {
    id: number
    data: ThemeFontDataSchema
  }
}

export const FontImporter: React.FC<IFontImporter> = React.memo(
  ({ providedThemeFontData }) => {
    const dispatch = useDispatch()
    const flags = useFlags()

    const {
      selectedTheme,
      fontsFromUrl,
      googleFontsImportUrl,
      themeFont,
      themeFontData,
      themeFontId,
      googleFontNames,
    } = useSelector(({ edit, theme }: RootState) => ({
      selectedTheme: edit.activeDeck.data?.deckData?.theme,
      fontsFromUrl: theme.fontsFromUrl,
      googleFontsImportUrl: theme.googleFontsImportUrl,
      themeFont: edit.activeDeck.data?.deckData?.themeFont,
      themeFontData: edit.activeDeck.data?.deckData?.themeFont.data,
      themeFontId: edit.activeDeck.data?.deckData?.themeFont.id,
      googleFontNames: theme.googleFontNames,
    }))
    const { getThemeFonts } = useThemeApi()

    const locationContext = useMemo(() => {
      const contextArray = location.pathname.split('/')
      return contextArray[1]
    }, [location.pathname])

    useEffect(() => {
      if (providedThemeFontData) {
        if (
          !providedThemeFontData?.data.primaryUrl &&
          !providedThemeFontData?.data.secondaryUrl
        ) {
          dispatch(
            setGoogleFontNamesAccumulatively([
              providedThemeFontData.data.primary,
              providedThemeFontData.data.secondary,
            ]),
          )
        } else {
          const primary = {
            fontFamily: providedThemeFontData.data.primary,
            src: providedThemeFontData.data.primaryUrl!,
          }
          const secondary = {
            fontFamily: providedThemeFontData.data.secondary,
            src: providedThemeFontData.data.secondaryUrl!,
          }
          dispatch(
            setFontsFromUrlsAccumulatively({
              id: providedThemeFontData.id,
              primary,
              secondary,
            }),
          )
        }
      } else if (selectedTheme?.themeCategoryId && locationContext === 'deck') {
        handleFontImports()
      } else if (selectedTheme?.themeCategoryId) {
        if (!themeFontData?.primaryUrl && !themeFontData?.secondaryUrl) {
          const fontNames = [themeFontData?.primary, themeFontData?.secondary]
          const fontsLink =
            'https://fonts.googleapis.com/css2?family=' +
            fontNames
              .map(
                (font) =>
                  FontServices.fontFamilyCleaner(font)?.replace(/ /g, '+') +
                  FontServices.fontWeightGoogleValue(font),
              )
              .join('&family=') +
            `&display=swap`
          dispatch(setGoogleFontsImportUrl(fontsLink))
        } else {
          const primary = {
            fontFamily: themeFontData?.primary,
            src: themeFontData.primaryUrl!,
          }
          const secondary = {
            fontFamily: themeFontData?.secondary,
            src: themeFontData.secondaryUrl!,
          }
          dispatch(
            setFontsFromUrl([
              {
                id: themeFontId!,
                primary,
                secondary,
              },
            ]),
          )
        }
      }
    }, [selectedTheme?.id, providedThemeFontData])

    const handleFontImports = useCallback(async () => {
      const themeFonts = await getThemeFonts({
        themeCategoryId: selectedTheme?.themeCategoryId as number,
      })
      const primaryFonts = Object.values(themeFonts)
        ?.flatMap((values) => values)
        ?.filter((data) => !data.data.primaryUrl)
        ?.map((data) => data.data.primary)
      const secondaryFonts = Object.values(themeFonts)
        ?.flatMap((values) => values)
        ?.filter((data) => !data.data.secondaryUrl)
        ?.map((data) => data.data.secondary)

      const fonts = primaryFonts.concat(secondaryFonts)
      const fontsLink =
        'https://fonts.googleapis.com/css2?family=' +
        fonts
          .map(
            (font) =>
              FontServices.fontFamilyCleaner(font)?.replace(/ /g, '+') +
              FontServices.fontWeightGoogleValue(font),
          )
          .join('&family=') +
        `&display=swap`
      dispatch(setGoogleFontsImportUrl(fontsLink))

      const fontsFromUrl = Object.values(themeFonts)
        ?.flatMap((values) => values)
        ?.filter((data) => data.data.primaryUrl && data.data.secondaryUrl)

      if (flags.FE_COLLABORATED_DECK_UPLOADED_FONT) {
        // Add selected font of active deck if the font is missing in the font list
        const activeDeckFontIndexInList = fontsFromUrl.findIndex(
          (font) => font.id === themeFont?.id,
        )
        if (activeDeckFontIndexInList === -1 && themeFont) {
          fontsFromUrl.push(themeFont)
        }
      }
      if (fontsFromUrl.length) {
        const fonts: IUploadedFonts[] | null = []
        fontsFromUrl.forEach((font) => {
          const id = font.id
          const primary = {
            fontFamily: font.data.primary,
            src: font.data.primaryUrl!,
          }
          const secondary = {
            fontFamily: font.data.secondary,
            src: font.data.secondaryUrl!,
          }
          fonts.push({
            id,
            primary,
            secondary,
            viewMode: font.viewMode,
            organizationId: font.organizationId || undefined,
          })
          return
        })
        dispatch(setFontsFromUrl(fonts))
      }
    }, [selectedTheme?.id, flags.FE_COLLABORATED_DECK_UPLOADED_FONT])

    const googleFontsImportUrlWithNames = useMemo(() => {
      if (locationContext === 'init' && googleFontNames.length) {
        return (
          'https://fonts.googleapis.com/css2?family=' +
          googleFontNames
            ?.map(
              (font) =>
                FontServices.fontFamilyCleaner(font)?.replace(/ /g, '+') +
                FontServices.fontWeightGoogleValue(font),
            )
            .join('&family=') +
          '&display=swap'
        )
      }
      if (!googleFontNames.length || locationContext !== 'dashboard') return
    }, [googleFontNames])

    const defaultFonts = useMemo(
      () =>
        'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
      [],
    )

    return (
      <>
        <Global
          styles={css`
            @import url('${defaultFonts}');
          `}
        />
        <Global
          styles={css`
            @import url('${googleFontsImportUrlWithNames ??
            googleFontsImportUrl}');

            ${fontsFromUrl?.map((font) => {
              return `
              @font-face {
                font-family: ${font.primary.fontFamily};
                src: url(${font.primary.src});
              };
              @font-face {
                font-family: ${font.secondary.fontFamily};
                src: url(${font.secondary.src});
              };
            `
            })}
          `}
        />
      </>
    )
  },
)
