import React, { useCallback, useEffect, useRef } from 'react'

import { scratchPageStyles } from './styles'

import { CardListLayout } from 'src/layouts/card-list-layout/CardListLayout'
import { CARD_LIST_ALIGN } from 'src/layouts/card-list-layout/type'
import { useDecksApi, useLanguage, useNotification } from 'src/hooks'
import { useThemeApi } from 'src/hooks/api/useThemeApi'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, resetThemes } from 'src/store'
import { CardTheme } from 'src/lib/card-theme'
import { useNavigate, useParams } from 'react-router-dom'
import { DeckStates } from 'src/types/api/enums'
import { APP_CONFIG } from 'src/config'

export const ScratchPage: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const { getThemeCategories, getThemeCategory } = useThemeApi()
  const { getDecks, newDeck, getSingleDeck } = useDecksApi()
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { success, error } = useNotification()

  const { categories, themes, aiCreatedDeckId } = useSelector(
    ({ theme, aiFlow }: RootState) => ({
      categories: theme.categories,
      themes: theme.themes,
      aiCreatedDeckId: aiFlow.aiCreatedDeckId,
    }),
  )

  useEffect(() => {
    getDecks()
    if (!categories?.length) {
      getThemeCategories()
    }
  }, [categories])

  useEffect(() => {
    if (params.category) {
      getThemeCategory({ themeCategoryId: parseInt(params.category) })
    } else {
      dispatch(resetThemes())
    }
  }, [params.category])

  const timeRef = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (aiCreatedDeckId) {
      timeRef.current = setInterval(async () => {
        const deckState = await getSingleDeck({ deckId: aiCreatedDeckId })
        if (deckState === DeckStates.FINAL) {
          clearInterval(timeRef.current)
          success('common.informative.your_deck_is_ready')
          navigate('/dashboard', { replace: true })
        } else if (deckState === DeckStates.ERRORED) {
          clearInterval(timeRef.current)
          error('common.informative.ai_high_demand_text')
        }
      }, APP_CONFIG.aiDeckInterval)
    }

    return () => clearInterval(timeRef.current)
  }, [aiCreatedDeckId])

  const handleCategoryClick = useCallback((id: number) => {
    navigate(`/init/scratch/${id}`, { replace: true })
  }, [])

  const handleThemeClick = useCallback(async (id: number) => {
    const resp = await newDeck(id)
    if (resp) {
      window.open(`/deck/${resp}`, '_blank')
    }
  }, [])

  return (
    <div css={scratchPageStyles}>
      <CardListLayout align={CARD_LIST_ALIGN.CENTER}>
        {t('init.category_select_info')}
      </CardListLayout>
      <CardListLayout>
        {(params.category ? themes : categories)?.map((cat) => (
          <CardTheme
            onClick={() =>
              params.category
                ? handleThemeClick(cat.id)
                : handleCategoryClick(cat.id)
            }
            key={cat.id}
            thumbnail={cat.thumbnailUrl}
          />
        ))}
      </CardListLayout>
    </div>
  )
})
