import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = ({
  dimensions,
  colors,
  breakpoints,
}: ITheme) => css`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - ${dimensions.headerHeight.big * 2}px);
  overflow: hidden;
  background: ${colors.dark2.DEFAULT};

  @media (min-width: ${breakpoints.tablet}px) {
    max-height: calc(100vh - ${dimensions.headerHeight.big}px);
  }
`

export const contentAreaStyles = css`
  width: 100%;
  height: 100%;
  position: relative;
  top: 100px;
  transition: top 0.3s;
  // force to render with gpu
  transform: translateZ(0);

  &.enter {
    top: 0;
  }

  &.enter-active {
    top: 0;
  }

  &.enter-done {
    top: 0;
  }

  &.exit {
    top: 100px;
    transition: top 0s;
  }

  &.exit-done {
    top: 100px;
  }
`

export const headerLayoutStyle = (theme: ITheme) => css`
  position: sticky;
  top: 0;
  z-index: ${theme.zIndex.header};
`
