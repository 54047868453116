import { css } from '@emotion/react'
import { CARD_TYPE, ICardAction } from '../../types'
import { ITheme } from 'src/theme'

type IWrapperStyles = Pick<ICardAction, 'type'>

export const wrapperStyles =
  ({ type }: IWrapperStyles) =>
  (theme: ITheme) => css`
    ${type === CARD_TYPE.DASHBOARD
      ? `
		background: ${theme.colors.white.DEFAULT};
		padding-bottom: ${theme.spacing.small};
	`
      : `
		background: ${theme.colors.dark.DEFAULT};
		padding-bottom: ${theme.spacing.xsmall};
	`};
  `

type IContentStyles = Pick<ICardAction, 'showActions'>
export const contentStyles =
  ({ showActions }: IContentStyles) =>
  (theme: ITheme) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: ${theme.spacing.small};
    opacity: ${showActions ? 1 : 0};
    transition: opacity 0.3s;
    background: ${theme.gradient.CARD};
    z-index: 2;
    position: relative;
  `

type IContentWrapperStyles = Pick<ICardAction, 'type'>
export const contentWrapperStyles =
  ({ type }: IContentWrapperStyles) =>
  (theme: ITheme) => css`
    position: relative;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity 0.3s;
    border-radius: ${theme.borderRadius[3]};
    ${type === CARD_TYPE.DASHBOARD
      ? `
			border: 2px solid ${theme.colors.text[2]};
			height: 141px;
		`
      : `
			height: 146px;
			`};
  `

export const loadingStyles = ({ isLoading }: { isLoading: boolean }) => css`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: ${isLoading ? `flex` : `none`};
`

export const cardCanvasStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
`
