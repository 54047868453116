import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { CARD_TYPE, ICard } from './types'

type IWrapperStyles = Pick<ICard, 'type' | 'disabled'>

export const wrapperStyles =
  ({ disabled, type }: IWrapperStyles) =>
  (theme: ITheme) => css`
    position: relative;
    border-radius: ${theme.borderRadius[4]};
    width: ${theme.dimensions.columnWidth.DEFAULT}px;

    ${disabled ? `pointer-events: none;` : ``}

    ${type === CARD_TYPE.DASHBOARD
      ? {
          padding: theme.spacing.medium,
          background: theme.colors.white.DEFAULT,
          boxShadow: theme.boxShadow[1],
        }
      : {
          padding: theme.spacing.xsmall,
          background: theme.colors.dark.DEFAULT,
        }}
  `

interface IOverlayStyles {
  isMenuOpen: boolean
}

export const overlayStyles =
  ({ isMenuOpen }: IOverlayStyles) =>
  (theme: ITheme) => css`
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    transition: opacity 0.3s;
    border-radius: ${theme.borderRadius[4]};
    background: ${theme.gradient.CARD};
    opacity: ${isMenuOpen ? 1 : 0};
  `

export const newDeckCoverStyles = (theme: ITheme) => css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-radius: ${theme.borderRadius[4]};
  // background: ${theme.gradient.CARD};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const readyForTransferStyles = (theme: ITheme) => css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-radius: ${theme.borderRadius[4]};
  background: ${theme.gradient.CARD};
  color: ${theme.colors.white.DEFAULT};
  font-size: ${theme.fontSize.large};
  display: flex;
  align-items: center;
  justify-content: center;
`
