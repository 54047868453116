import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ICardFooter } from './types'
import { CARD_TYPE } from '../../types'

type IWrapperStyles = Pick<ICardFooter, 'type'>

export const cardFooterWrapperStyles = ({ type }: IWrapperStyles) => css`
  display: flex;
  justify-content: ${type === CARD_TYPE.DASHBOARD ? `space-between` : `center`};
`

export const cardFooterLeftStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing['2xsmall']};
  color: ${theme.colors.text[2]};
  font-size: ${theme.fontSize.small};
`

export const cardFooterFolderNameStyles = css`
  max-width: 125px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const cardFooterRightStyles = ({
  spacing,
  colors,
  fontSize,
}: ITheme) => css`
  display: flex;
  align-items: center;
  gap: ${spacing['2xsmall']};
  color: ${colors.text[2]};
  font-size: ${fontSize.small};
`

export const cardFooterBulletStyles = ({ borderRadius, colors }: ITheme) => css`
  width: 3px;
  height: 3px;
  border-radius: ${borderRadius.DEFAULT};
  background: ${colors.text[2]};
`

export const slideTextStyles = ({ colors }: ITheme) => css`
  color: ${colors.white.DEFAULT};
`
