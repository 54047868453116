import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { postSaveTopicPromptBody } from 'src/types/api/requestObjects'
import { postGetAIThemesResponse } from 'src/types/api/responseObjects'

export enum AI_FLOW_STEP {
  SELECT = 'select',
  IMPORT = 'import',
  MODAL = 'modal',
  PROMPT = 'prompt',
  AUDIENCE = 'audience',
  AIM = 'aim',
  OUTLINE = 'outline',
  THEME = 'theme',
  PDF_PROMPT = 'pdf prompt',
  LOADING = 'loading',
  ERRORED = 'errored',
}

export interface changeOutlineParamaters {
  value: string
  index: number
}

const initialState: {
  aiFlowId: string
  currentStep?: AI_FLOW_STEP
  audienceSug: string[]
  aimSug: string[]
  selectedPrompt: string
  selectedPromptId: number
  selectedAudience: string
  selectedAim: string
  selectedOutline: string[]
  selectedTheme: string
  seeExample: boolean
  templates: postGetAIThemesResponse | null
  pdfFileName: string
  pdfImport: boolean
  aiCreatedDeckId: number | null
} = {
  aiFlowId: '',
  currentStep: AI_FLOW_STEP.SELECT,
  audienceSug: [],
  aimSug: [],
  selectedPrompt: '',
  selectedPromptId: 0,
  selectedAudience: '',
  selectedAim: '',
  selectedOutline: [],
  selectedTheme: '',
  seeExample: false,
  templates: null,
  pdfFileName: '',
  pdfImport: false,
  aiCreatedDeckId: null,
}

export const aiFlowSlice = createSlice({
  name: 'aiFlow',
  initialState,
  reducers: {
    setAiFlowId: (state, action: PayloadAction<string>) => {
      state.aiFlowId = action.payload
    },
    setAiFlowStep: (state, action: PayloadAction<AI_FLOW_STEP>) => {
      state.currentStep = action.payload
    },
    setPrompt: (state, action: PayloadAction<postSaveTopicPromptBody>) => {
      state.selectedPrompt = action.payload.prompt
    },
    setPromptId: (state, action: PayloadAction<number>) => {
      state.selectedPromptId = action.payload
    },
    setAudience: (state, action: PayloadAction<string>) => {
      state.selectedAudience = action.payload
    },
    setAim: (state, action: PayloadAction<string>) => {
      state.selectedAim = action.payload
    },
    setOutline: (state, action: PayloadAction<string[]>) => {
      state.selectedOutline = action.payload
    },
    setTheme: (state, action: PayloadAction<string>) => {
      state.selectedTheme = action.payload
    },
    setSeeExample: (state, action: PayloadAction<boolean>) => {
      state.seeExample = action.payload
    },
    setTemplates: (state, action: PayloadAction<postGetAIThemesResponse>) => {
      state.templates = action.payload
    },
    setPdfName: (state, action: PayloadAction<string>) => {
      state.pdfFileName = action.payload
    },
    setPdfImportState: (state, action: PayloadAction<boolean>) => {
      state.pdfImport = action.payload
    },
    updateAudienceSuggestion: (state, action: PayloadAction<string[]>) => {
      state.audienceSug = action.payload
    },
    updateAimSuggestion: (state, action: PayloadAction<string[]>) => {
      state.aimSug = action.payload
    },
    updateSelectedOutline: (state, action: PayloadAction<string[]>) => {
      state.selectedOutline = action.payload
    },
    updateOutlineLine: (
      state,
      action: PayloadAction<changeOutlineParamaters>,
    ) => {
      state.selectedOutline[action.payload.index] = action.payload.value
    },
    setAiCreatedDeckId: (state, action: PayloadAction<number>) => {
      state.aiCreatedDeckId = action.payload
    },
  },
})

export const {
  setAiFlowId,
  setAiFlowStep,
  updateAudienceSuggestion,
  updateAimSuggestion,
  updateSelectedOutline,
  updateOutlineLine,
  setPrompt,
  setPromptId,
  setAudience,
  setAim,
  setOutline,
  setTheme,
  setSeeExample,
  setTemplates,
  setPdfName,
  setPdfImportState,
  setAiCreatedDeckId,
} = aiFlowSlice.actions

export default aiFlowSlice.reducer
