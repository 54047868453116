import { css } from '@emotion/react'
import { ITheme, zIndex } from 'src/theme'
import { IFontFamily } from './types'

interface IThemeCardStyles {
  isSelected?: boolean
  deleteMode?: boolean
  context?: IFontFamily['context']
}

export const fontBoxStyles =
  ({ isSelected, deleteMode, context }: IThemeCardStyles) =>
  ({ breakpoints, colors, borderRadius, spacing }: ITheme) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 113px;
    height: 60px;
    background-color: ${colors.dark.DEFAULT};
    border-radius: ${borderRadius[4]};
    padding: 0 ${spacing.small};
    row-gap: ${spacing['3xsmall']};
    cursor: pointer;
    ${isSelected && `outline: 2px solid ${colors.primary.DEFAULT};`}

    @media (min-width: ${breakpoints.desktop}px) {
      width: 150px;
    }

    & .border {
      width: 100%;
      height: 1px;
      background-color: ${colors.outline[2]};
      border-radius: ${borderRadius[1]};
    }

    & .font-display {
      text-align: center;
      width: 100%;
      height: 16px;
      line-height: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    & .font-box-icon {
      position: absolute;
      top: -10px;
      right: -3px;
      border-radius: ${borderRadius.DEFAULT};
      background-color: ${colors.primary.DEFAULT};
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 1px;
      padding-left: 1px;
      z-index: ${zIndex.fontBoxIcon};
    }

    & .font-family-actions {
      display: ${deleteMode ? 'flex' : 'none'};
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s;
      background: rgba(0, 0, 0, 0.6);
      z-index: ${zIndex.fontFamilyActions};
    }

    &:hover {
      & .font-family-actions {
        opacity: 1;
      }
      & .fonts-tooltip {
        display: ${context === 'predefined' && deleteMode
          ? 'block'
          : 'none !important'};
      }
    }

    & .fonts-tooltip {
      display: none;
      position: absolute;
      bottom: -41px;
      width: 130px;
      z-index: ${zIndex.tooltip};
    }
  `
