import React, { PropsWithChildren } from 'react'
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'
import env from 'react-dotenv'

export const PostHogProviderWrapper = ({ children }: PropsWithChildren) => {
  if (window.location.href.includes('/export')) {
    return children
  }

  posthog.init(env.POSTHOG_API_KEY || '', {
    api_host: 'https://app.posthog.com',
  })
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
