import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const upgradeModalsStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  height: 100%;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    min-width: 688px;
    width: min-content;
    height: 831px;
    overflow-y: auto;
  }

  @media (min-width: ${theme.breakpoints.laptop}px) {
    height: 601px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    height: 702px;
  }
  .close-button-area {
    position: absolute;
    display: flex;
    justify-content: end;
    top: 10px;
    right: 10px;
  }
`
