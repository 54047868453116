import { useEffect } from 'react'
import env from 'react-dotenv'
import { APP_CONFIG } from 'src/config'
import Gleap from 'gleap'
import { useFlags } from 'src/hooks'

export const initializeGleap = () => {
  const flags = useFlags()

  useEffect(() => {
    if (
      APP_CONFIG.chatWidget.disabled.some((route) =>
        window.location.href.includes(route),
      )
    ) {
      Gleap.showFeedbackButton(false)
    } else {
      Gleap.showFeedbackButton(true)
    }
  }, [window.location.href])

  useEffect(() => {
    flags.FE_GLEAP_INTEGRATION && Gleap.initialize(env.GLEAP_API_KEY || '')
  }, [flags.FE_GLEAP_INTEGRATION])
}
