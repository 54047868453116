/* eslint-disable */
import { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import { getSlideLayoutsResponse } from 'src/types/api/responseObjects'
import {
  NewComponentSchema,
  postCreateSlideLayoutBody,
  putUpdateSlideLayoutBody,
  sharedModelsQuery,
  slideLayoutIdParams,
} from 'src/types/api/requestObjects'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setLayouts } from 'src/store'
import { looseObject } from 'src/types'

export const useLayoutsApi = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { organizationId, slideData, themeData } = useSelector(
    ({ workspace, edit }: RootState) => ({
      organizationId: workspace.id,
      slideData: edit.activeSlideData.data,
      themeData: edit.activeDeck.data?.deckData?.theme,
    }),
  )

  const getLayouts = useCallback(async () => {
    setIsLoading(true)
    try {
      const params: sharedModelsQuery = {
        preDefined: true,
        own: true,
        sharedOnOrg: false,
        organizationId,
      }

      const res: RequestResponse<getSlideLayoutsResponse, any> =
        await RequestServices.request({
          method: 'GET',
          url: API_CONFIG.LAYOUTS,
          params,
        })

      dispatch(setLayouts(res.data.data))
    } finally {
      setIsLoading(false)
    }
  }, [organizationId])

  const saveLayout = useCallback(
    async (name: string, slideDataId: number) => {
      if (!themeData) {
        return false
      }
      setIsLoading(true)
      try {
        const componentsData: NewComponentSchema[] =
          slideData
            ?.find(({ id }) => id === slideDataId)
            ?.slideDataComponents.map(({ component }) => {
              const { id, sessionId, ...componentData } = component
              return {
                ...componentData,
                tempId: uuidv4(),
              }
            }) || []

        const tempSvg = slideData?.find(({ id }) => id === slideDataId)?.tempSvg
        const svgType = slideData?.find(({ id }) => id === slideDataId)?.svgType
        const background = slideData?.find(({ id }) => id === slideDataId)
          ?.background

        const data: postCreateSlideLayoutBody = {
          name,
          themeColorId: themeData.themeColorId,
          themeFontId: themeData.themeFontId,
          components: componentsData,
          organizationId,
          tempSvg: (tempSvg as looseObject) || {},
          svgType,
          background,
        }

        await RequestServices.request({
          method: 'POST',
          url: API_CONFIG.LAYOUTS,
          data,
        })

        getLayouts()
        return true
      } catch {
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [organizationId, slideData, themeData],
  )

  const updateLayout = useCallback(
    async (id: number, slideDataId: number) => {
      setIsLoading(true)
      try {
        const componentsData: NewComponentSchema[] =
          slideData
            ?.find(({ id }) => id === slideDataId)
            ?.slideDataComponents.map(({ component }) => {
              const { id, sessionId, ...componentData } = component
              return {
                ...componentData,
                tempId: uuidv4(),
              }
            }) || []

        const tempSvg = slideData?.find(({ id }) => id === slideDataId)?.tempSvg
        const svgType = slideData?.find(({ id }) => id === slideDataId)?.svgType
        const background = slideData?.find(({ id }) => id === slideDataId)
          ?.background

        const data: putUpdateSlideLayoutBody = {
          components: componentsData,
          organizationId,
          tempSvg: (tempSvg as looseObject) || {},
          svgType,
          background,
        }

        await RequestServices.request({
          method: 'PUT',
          url: `${API_CONFIG.LAYOUTS}/${id}`,
          data,
        })

        getLayouts()
        return true
      } catch {
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [organizationId, slideData],
  )

  const deleteLayout = useCallback(
    async ({ slideLayoutId }: slideLayoutIdParams) => {
      setIsLoading(true)
      try {
        await RequestServices.request({
          method: 'DELETE',
          url: `${API_CONFIG.LAYOUTS}/${slideLayoutId}`,
          params: {
            organizationId,
          },
        })

        getLayouts()
        return true
      } catch {
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [organizationId],
  )

  return {
    getLayouts,
    saveLayout,
    updateLayout,
    deleteLayout,
    isLoading,
  }
}
