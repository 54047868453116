import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ITabItem, TABS_THEME } from '../types'

type IWrapperStyles = Pick<ITabItem, 'theme'> & {
  isActive?: boolean
}
export const wrapperStyles =
  ({ theme: tabTheme, isActive }: IWrapperStyles) =>
  (theme: ITheme) => css`
    cursor: pointer;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    border-radius: ${theme.borderRadius[4]};

    background: ${!isActive
      ? `transparent`
      : tabTheme === TABS_THEME.LIGHT
      ? theme.colors.white.DEFAULT
      : theme.colors.dark2.DEFAULT};
  `
