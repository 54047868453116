import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  spanWrapperStyles,
  deckNameStyles,
  bottomSpaceWrapper,
} from '../../styles'
import { useDecksApi, useInterval, useLanguage } from 'src/hooks'
import { BUTTON_TYPE, Button, Icon, TABS_THEME, Tabs, icons } from 'src/lib'
import { COLOR } from 'src/theme'
import {
  deckNameFontWeight,
  exportPageStyles,
  exportTitleStyles,
  iconStyles,
  latestExportStyles,
  spanAndTextStyles,
  spanStyles,
  tabStyles,
  underlineStyles,
  wrapperStyles,
} from './styles'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { DeckExportStates, DeckExportTypes } from 'src/types/api/enums'
import { IExport } from './types'
import { UpgradeModalManager } from 'src/components/upgrade-modals'
import { ACTION_CLICKS } from 'src/plugins/google/consts'
import { setSelectedExportTab } from 'src/store'
import { useDispatch } from 'react-redux'

export const Export: React.FC<IExport> = React.memo(({ deckId, deckName }) => {
  const { t } = useLanguage()
  const dispatch = useDispatch()

  const { selectedExportTab, getExportedData, orgId, userPlan } = useSelector(
    ({ decks, workspace, user }: RootState) => ({
      selectedExportTab: decks.selectedExportTab,
      getExportedData: decks.exportedFileData,
      orgId: workspace.id,
      userPlan: user.data?.activeUserPlan?.plan.name,
    }),
  )

  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    exportedData()
  }, [selectedExportTab, activeTab, deckId])

  const exportedData = useCallback(async () => {
    if (deckId)
      await getDownloadedExportList(
        {
          organizationId: orgId,
          exportType: selectedExportTab
            ? DeckExportTypes.PPTX
            : DeckExportTypes.PDF,
        },
        deckId,
      )
  }, [deckId, orgId, selectedExportTab])

  useInterval(() => {
    getStatus().length > 0 && exportedData()
  }, 8000)

  const getStatus = () => {
    return getExportedData.filter(
      (line) => line.state == DeckExportStates.PROCESSING,
    )
  }

  const { getExport, getDownloadedExportList } = useDecksApi()

  const onExportClickHandler = async ({
    type,
    isCallback,
  }: {
    type: boolean
    isCallback: boolean
  }) => {
    if (isCallback) {
      setIsUpgradeModalOpen(false)
      return
    }
    if (!userPlan && !orgId && !isCallback) {
      setIsUpgradeModalOpen(true)
      return
    }
    deckId && (await getExport(type, deckId))
    exportedData()
  }

  const onDownloadClickHandler = useCallback((item: any) => {
    window.open(item.fileUrl, '_blank')

    const downloadedItemIds = localStorage.getItem('exportedFileIds')

    if (!downloadedItemIds?.includes(item.id)) {
      if (downloadedItemIds) {
        const newValues = JSON.parse(downloadedItemIds).concat([item.id])
        localStorage.setItem('exportedFileIds', JSON.stringify(newValues))
      } else localStorage.setItem('exportedFileIds', JSON.stringify([item.id]))
    }
    exportedData()
  }, [])

  const exportedDataTemplate = useMemo(() => {
    const exportedFileIds = localStorage.getItem('exportedFileIds')

    return getExportedData.map((exportItem, index) => (
      <div css={exportPageStyles} key={index}>
        {((selectedExportTab === 0 && exportItem.fileName.includes('pdf')) ||
          (selectedExportTab === 1 &&
            exportItem.fileName.includes('pptx'))) && (
          <>
            <div css={spanAndTextStyles}>
              <Icon
                icon={
                  exportedFileIds?.includes(exportItem.id.toString())
                    ? icons.checked_checkbox
                    : icons.folder
                }
                color={
                  exportedFileIds?.includes(exportItem.id.toString())
                    ? COLOR.green_100
                    : COLOR.primary_100
                }
                size={16}
                css={iconStyles}
              />
              <span css={spanStyles}>{exportItem.fileName}</span>
            </div>
            {exportItem.state == '0' && (
              <div css={underlineStyles}>
                {t('common.informative.processing')}
              </div>
            )}
            {exportItem.fileUrl && exportItem.state == '1' && (
              <Button
                css={underlineStyles}
                onClick={() => onDownloadClickHandler(exportItem)}
                text={t('common.actions.download')}
                type={BUTTON_TYPE.GHOST}
                isLink
              />
            )}
            {exportItem.state == '2' && (
              <div css={underlineStyles}>{t('common.informative.fail')}</div>
            )}
          </>
        )}
      </div>
    ))
  }, [getExportedData, selectedExportTab])

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)

  const activeTabChangeHandler = useCallback((index: number) => {
    setActiveTab(index)
    dispatch(setSelectedExportTab(index!))
  }, [])

  return (
    <div css={wrapperStyles}>
      <div css={bottomSpaceWrapper}>
        <div css={deckNameStyles}>
          <span css={spanWrapperStyles}>
            {t('common.actions.export')}:
            <span css={deckNameFontWeight}>{deckName}</span>
          </span>
        </div>
      </div>
      <div css={bottomSpaceWrapper}>
        <Tabs
          activeTab={activeTab}
          onChange={activeTabChangeHandler}
          tabs={[t('common.files.pdf'), t('common.files.powerpoint')]}
          theme={TABS_THEME.LIGHT}
          css={tabStyles}
        />
      </div>
      <div css={exportTitleStyles}>
        <UpgradeModalManager
          isOpen={isUpgradeModalOpen}
          callback={() =>
            onExportClickHandler({
              type: selectedExportTab == 0 ? false : true,
              isCallback: true,
            })
          }
          context={ACTION_CLICKS.EXPORT_CLICK}
          onClose={() => setIsUpgradeModalOpen(false)}
        />
        <Button
          text={
            selectedExportTab == 0
              ? t('share_modal.pdf_export')
              : t('share_modal.pptx_export')
          }
          css={underlineStyles}
          disabled={getStatus().length > 0}
          onClick={() =>
            onExportClickHandler({
              type: selectedExportTab == 0 ? false : true,
              isCallback: false,
            })
          }
          type={BUTTON_TYPE.GHOST}
          isLink
        />
      </div>
      <div css={latestExportStyles(getExportedData.length > 0)}>
        {t('share_modal.latest_exports')}
      </div>
      {exportedDataTemplate}
    </div>
  )
})
