import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const formTableStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  border-radius: 0 0 ${theme.borderRadius[10]} ${theme.borderRadius[10]};
`
export const downloadContainer = () => css`
  width: 123px;
`

export const dropdownContainer = (theme: ITheme) => css`
  width: 121px;
  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 400px;
  }
`
export const ratingCellStyle = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.gap['xsmall']}px;
`

export const ratingStyle = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.gap['3xsmall']}px;
  position: relative;
`
export const tableRowStyles = (rowIndex: number) => (theme: ITheme) => css`
  height: auto;
  background: ${rowIndex % 2 === 0
    ? theme.colors.background.DEFAULT
    : theme.colors.white.DEFAULT};
  color: ${rowIndex % 2 === 0
    ? theme.colors.primary.DEFAULT
    : theme.colors.text.DEFAULT};

  & td {
    padding: ${theme.gap.small}px ${theme.gap.xlarge}px;
    text-align: left;
    white-space: nowrap;
  }
`
export const deletedQuestionCell = () => css`
  opacity: 0.3;
  position: relative;
`
export const deletedTooltipStyle = (theme: ITheme) => css`
  position: absolute;
  left: -10px;
  width: 110px;
  background: ${theme.colors.background.DEFAULT};
  text-align: center;
  color: ${theme.colors.text.DEFAULT};
  font-size: ${theme.fontSize.xsmall};
`
export const ratingTooltipStyle = (theme: ITheme) => css`
  position: absolute;
  left: -20px;
  top: 20px;
  width: 110px;
  background: ${theme.colors.background.DEFAULT};
  text-align: center;
  color: ${theme.colors.text.DEFAULT};
  font-size: ${theme.fontSize.xsmall};
`
export const footerStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  display: flex;
  justify-content: space-between;
  padding: ${theme.gap.xlarge}px;
  border-top: 1px solid ${theme.colors.elements.DEFAULT};
  border-radius: 0 0 ${theme.borderRadius[10]} ${theme.borderRadius[10]};
  width: 100%;
  overflow: visible;
`

export const tableStyles = () => css`
  border-collapse: collapse;
  width: 100%;
`
export const tableWrapperStyles = () => css`
  width: auto;
  height: 100%;
  overflow: auto;
`
export const tableHeaderStyles = (theme: ITheme) => css`
  height: 44px;
  th {
    padding: 12px 24px;
    text-align: left;
    color: ${theme.colors.elements.DEFAULT};
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.regular};
    white-space: nowrap;
  }
`
export const tableBodyStyles = (theme: ITheme) => css`
  height: auto;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.regular};
`
