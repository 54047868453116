import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const headerStyles = (theme: ITheme) => css`
  height: ${theme.dimensions.headerHeight.DEFAULT}px;
  padding: 0 ${theme.spacing['2xlarge']};
  display: flex;
  align-items: center;
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  @media (max-width: ${theme.breakpoints.tablet - 1}px) {
    justify-content: space-between;
    padding: 0 ${theme.spacing.medium};
  }

  .left-side,
  .right-side {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: row;
  }

  .right-side {
    justify-content: flex-end;
  }
`

export const inputContainerStyles = (theme: ITheme) => css`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 ${theme.gap.large}px 0 ${theme.gap['5xlarge']}px;

  @media (min-width: ${theme.breakpoints.desktop}px) {
    margin: 0 ${theme.spacing['5xlarge']};
  }

  @media (max-width: ${theme.breakpoints.laptop - 1}px) {
    width: ${theme.dimensions.headerInputWidth.medium}px;
    margin: 0 ${theme.spacing['2xlarge']};
  }
  @media (max-width: ${theme.breakpoints.tablet - 1}px) {
    width: ${theme.dimensions.headerInputWidth.small}px;
    margin: 0 ${theme.spacing.medium};
  }
  @media (min-width: ${theme.breakpoints.laptop}px) {
    width: ${theme.dimensions.headerInputWidth.DEFAULT}px;
  }
`

export const workspaceStyles = ({ zIndex }: ITheme) => css`
  z-index: ${zIndex.header};
`
