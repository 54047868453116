import React, { useCallback, useEffect, useState } from 'react'
import env from 'react-dotenv'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
  Button,
  INPUT_SIZE,
  INPUT_WIDTH,
  Input,
} from 'src/lib'
import { Separator } from 'src/lib/separator'
import { ISignUpForm } from './types'
import {
  VALIDATION_RULE_TYPES,
  useAuthApi,
  useLanguage,
  useFlags,
} from 'src/hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  actionsStyles,
  formInner,
  gradientTextStyles,
  titleContainerStyles,
} from './styles'
import { LottieButton } from '../lottie-button'

export const SignUpForm: React.FC<ISignUpForm> = React.memo(
  ({ onGoogleAuth, className, dataAttr }) => {
    const navigate = useNavigate()
    const { t, Trans } = useLanguage()
    const { signUp, isLoading } = useAuthApi()
    const [fullName, setFullName] = useState('')
    const [fullNameValid, setFullNameValid] = useState(false)
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)
    const flags = useFlags()

    const [searchParams] = useSearchParams()

    const onSubmitHandler = useCallback(() => {
      signUp({
        fullName,
        email,
        password,
        referralCode: searchParams.get('referralCode') || undefined,
      })
    }, [fullName, email, password])

    const onLoginClickHandler = useCallback(() => {
      navigate('/login', { replace: true })
    }, [])

    const onGoogleClick = () => {
      window.location.href = `${env.BASE_URL}auth/google`
    }

    const openTerms = () => {
      window.open('https://www.decktopus.com/terms', '_blank')
    }

    const openPrivacy = () => {
      window.open('https://www.decktopus.com/privacy', '_blank')
    }
    const [isFormValid, setIsFormValid] = useState(false)

    useEffect(() => {
      setIsFormValid(fullNameValid && emailValid && passwordValid)
    }, [fullNameValid, emailValid, passwordValid])

    return (
      <div css={formInner} className={className} {...dataAttr}>
        <div css={titleContainerStyles}>
          <div css={gradientTextStyles}>{t('signup_page_title')}</div>
        </div>
        <Button
          text={t('login.continue_with_google')}
          theme={BUTTON_THEME.GOOGLE}
          width={BUTTON_WIDTH.FULL}
          onClick={flags.FE_343_GAUTH_TAGMANAGER ? onGoogleAuth : onGoogleClick}
        />
        <Separator text={t('common.or')} />
        <Input
          size={INPUT_SIZE.SMALL}
          width={INPUT_WIDTH.FULL}
          label={t('signup.full_name')}
          value={fullName}
          onChange={setFullName}
          onEnter={onSubmitHandler}
          validation={{
            [VALIDATION_RULE_TYPES.REQUIRED]: {
              text: t('validation.error.required', {
                name: t('common.full_name'),
              }),
            },
          }}
          onValidation={setFullNameValid}
          errorAlignRight
        />
        <Input
          size={INPUT_SIZE.SMALL}
          width={INPUT_WIDTH.FULL}
          label={t('common.email_address')}
          onChange={setEmail}
          value={email}
          onEnter={onSubmitHandler}
          validation={{
            [VALIDATION_RULE_TYPES.REQUIRED]: {
              text: t('validation.error.required', {
                name: t('common.email'),
              }),
            },
            [VALIDATION_RULE_TYPES.VALIDMAIL]: {
              text: t('validation.error.email'),
            },
          }}
          onValidation={setEmailValid}
          errorAlignRight
        />
        <Input
          size={INPUT_SIZE.SMALL}
          width={INPUT_WIDTH.FULL}
          label={t('common.password')}
          type="password"
          onChange={setPassword}
          value={password}
          onEnter={onSubmitHandler}
          validation={{
            [VALIDATION_RULE_TYPES.REQUIRED]: {
              text: t('validation.error.required', {
                name: t('common.password'),
              }),
            },
            [VALIDATION_RULE_TYPES.MIN]: {
              value: 6,
              text: t('validation.min_char', {
                name: t('common.password'),
                value: 6,
              }),
            },
            [VALIDATION_RULE_TYPES.MAX]: {
              value: 72,
              text: t('validation.max_char', {
                name: t('common.password'),
                value: 72,
              }),
            },
          }}
          onValidation={setPasswordValid}
          errorAlignRight
        />
        <div css={actionsStyles}>
          <div className="info-text">
            <Trans
              i18nKey={'signup.create_account_info'}
              components={[
                <Button
                  key="terms_link"
                  isLink
                  size={BUTTON_SIZE.SMALL}
                  text={t('signup.terms')}
                  theme={BUTTON_THEME.PRIMARY}
                  type={BUTTON_TYPE.GHOST}
                  onClick={openTerms}
                />,
                <Button
                  key="privacy_link"
                  isLink
                  size={BUTTON_SIZE.SMALL}
                  text={t('signup.privacy_policy')}
                  theme={BUTTON_THEME.PRIMARY}
                  type={BUTTON_TYPE.GHOST}
                  onClick={openPrivacy}
                />,
              ]}
            />
          </div>

          {isFormValid ? (
            <LottieButton
              text={t('signup.create_account')}
              isLoading={isLoading}
              onClick={onSubmitHandler}
            />
          ) : (
            <Button
              size={BUTTON_SIZE.SMALL}
              text={t('signup.create_account')}
              theme={BUTTON_THEME.GRADIENT}
              width={BUTTON_WIDTH.FULL}
              onClick={onSubmitHandler}
              isLoading={isLoading}
              disabled={!isFormValid}
            />
          )}
          <div>
            {t('signup.already_member')}
            <Button
              isLink
              size={BUTTON_SIZE.LARGE}
              text={t('login.login')}
              theme={BUTTON_THEME.PRIMARY}
              type={BUTTON_TYPE.GHOST}
              onClick={onLoginClickHandler}
            />
          </div>
        </div>
      </div>
    )
  },
)
