import { texts } from './texts'
import { planFeatures } from './planFeatures'
import { errors } from './errors'
import { apiEnums } from './apiEnums'

export default {
  ...texts,
  ...planFeatures,
  ...errors,
  ...apiEnums,
}
