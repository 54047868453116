import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const textAreaStyles = ({ gap, colors, borderRadius }: ITheme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${gap.xsmall}px;
  background: ${colors.dark2.DEFAULT};
  gap: ${gap.small}px;
  border-radius: ${borderRadius[2]};
`

export const labelStyles = ({ colors, fontSize }: ITheme) => css`
  font-size: ${fontSize.xsmall};
  color: ${colors.text[2]};
`
export const textAreaElementStyles =
  (note?: boolean) =>
  ({ colors }: ITheme) => css`
    ${note && `height: 100%;`}
    resize: none;
    outline: none;
    background: transparent;
    border: none;
    color: ${colors.white.DEFAULT};
  `
