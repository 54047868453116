import React, { useCallback, useMemo } from 'react'

import { ICategorySelect } from './types'
import { categorySelectStyles, menuStyles, nativeStyles } from './styles'
import { CategoryItem } from './components'
import { Icon, icons } from '../icon'
import { useTheme } from 'src/theme'

export const CategorySelect: React.FC<ICategorySelect> = React.memo(
  ({ items, selected, onChange, className, dataAttr }) => {
    const { colors } = useTheme()

    const selectedItem = useMemo(() => {
      return items.find(({ value }) => value === selected)
    }, [items, selected])

    const handleSelectChange = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange?.(e.target.value)
      },
      [],
    )

    return (
      <div css={categorySelectStyles} className={className} {...dataAttr}>
        <div css={nativeStyles}>
          <Icon icon={icons.sort} size={16} color={colors.white.DEFAULT} />
          {selectedItem?.label}
          <select value={selected} onChange={handleSelectChange}>
            {items.map(
              (item) =>
                item.hidden && (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ),
            )}
          </select>
        </div>
        <div css={menuStyles}>
          {items.map(
            (item) =>
              item.hidden && (
                <CategoryItem
                  key={item.value}
                  {...item}
                  selected={item.value === selected}
                  onClick={onChange}
                />
              ),
          )}
        </div>
      </div>
    )
  },
)
