import { configureStore } from '@reduxjs/toolkit'
import foldersReducer from './folders/foldersSlice'
import planReducer from './plan/planSlice'
import userReducer from './user/userSlice'
import orgReducer from './org/orgSlice'
import themeReducer from './theme/themeSlice'
import decksReducer from './decks/decksSlice'
import editReducer from './edit/editSlice'
import canvasReducer from './canvas/canvasSlice'
import aiFlowReducer from './ai-flow/aiFlowSlice'
import workspaceReducer from './workspace/workspaceSlice'
import layoutsReducer from './layouts/layoutsSlice'
import cacheReducer from './cache/cacheSlice'
import recentColorsReducer from './recent-colors/recentColorsSlice'
import adminReducer from './admin/adminSlice'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistedWorkspace = persistReducer(
  {
    key: 'workspace',
    version: 1,
    storage,
  },
  workspaceReducer,
)

const persistedRecentColors = persistReducer(
  {
    key: 'recent-colors',
    version: 1,
    storage,
  },
  recentColorsReducer,
)

export const store = configureStore({
  reducer: {
    folders: foldersReducer,
    plan: planReducer,
    user: userReducer,
    org: orgReducer,
    theme: themeReducer,
    decks: decksReducer,
    edit: editReducer,
    canvas: canvasReducer,
    aiFlow: aiFlowReducer,
    workspace: persistedWorkspace,
    layouts: layoutsReducer,
    cache: cacheReducer,
    recentColors: persistedRecentColors,
    admin: adminReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
