import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTheme } from 'src/theme'
import { Icon, icons } from 'src/lib/icon'
import { IImageUpload } from './types'
import {
  fileInputStyles,
  imageUploadAvatarStyles,
  wrapperStyles,
} from './styles'
import { Menu } from '../menu'
import {
  useClickOutside,
  useNotification,
  useWindowSize,
  useLanguage,
} from 'src/hooks'
import { Avatar } from '../avatar'
import { APP_CONFIG } from 'src/config/appConfig'

export const ImageUpload: React.FC<IImageUpload> = React.memo(
  ({
    onChange,
    defaultImage,
    className,
    dataAttr,
    text,
    size,
    isRight,
    disabled = false,
  }) => {
    const { t } = useLanguage()
    const menuRef = useRef(null)
    const { colors } = useTheme()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [image, setImage] = useState<string | undefined>(
      defaultImage || undefined,
    )
    const [menuOpen, setMenuOpen] = useState(false)
    const { error, info } = useNotification()

    const { fileUploadList } = APP_CONFIG
    const allowedTypes = fileUploadList.map((type) => type.mime)

    useEffect(() => {
      defaultImage && setImage(defaultImage)
    }, [defaultImage])

    const windowSize = useWindowSize()

    const theme = useTheme()

    const isMobileSize = useMemo(
      () => windowSize.width < theme.breakpoints.tablet,
      [windowSize],
    )

    const clickOutsideHandler = useCallback(() => {
      setMenuOpen(false)
    }, [])

    useClickOutside(menuRef, clickOutsideHandler)

    const handleClick = useCallback(() => {
      if (disabled) return
      setMenuOpen(true)
    }, [disabled])

    const handleUploadClick = useCallback(() => {
      setMenuOpen(false)
      fileInputRef.current?.click()
    }, [])

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target?.files?.[0]) {
          if (e.target?.files?.[0].size > APP_CONFIG.uploadMediaAllowedSize) {
            info(t('common.informative.large_media_file'))
          }
          const fileType = e.target.files[0].type
          if (!allowedTypes.includes(fileType)) {
            error(t('profile.update.image_upload_error', { fileType }))
            return
          }
          setImage(URL.createObjectURL(e.target.files[0]))
          onChange(e.target.files[0])
        }
      },
      [onChange, allowedTypes],
    )

    const removeImage = () => {
      setMenuOpen(false)
      setImage(undefined)
      onChange(null)
    }

    return (
      <>
        <div
          css={wrapperStyles({ isRight, size })}
          className={className}
          {...dataAttr}
          onClick={handleClick}
        >
          {!disabled && (
            <div className="icon">
              <Icon
                icon={icons.pencil_drawing}
                size={
                  isMobileSize
                    ? theme.dimensions.iconSize.DEFAULT
                    : theme.dimensions.iconSize.medium
                }
                color={colors.white.DEFAULT}
              />
            </div>
          )}
          {menuOpen && (
            <div className="menu" ref={menuRef}>
              <Menu
                items={[
                  {
                    icon: icons.upload,
                    label: t('common.actions.upload_picture'),
                    onClick: handleUploadClick,
                  },
                  {
                    icon: icons.trash_can,
                    label: t('common.actions.remove_picture'),
                    color: colors.error.DEFAULT,
                    onClick: removeImage,
                  },
                ]}
              />
            </div>
          )}
          <div css={imageUploadAvatarStyles(disabled)}>
            <Avatar
              fontSize={
                isMobileSize
                  ? theme.dimensions.avatarFontSize.DEFAULT
                  : theme.dimensions.avatarFontSize.small
              }
              fitView
              data={[{ image, name: text || '' }]}
            />
          </div>
        </div>
        <input
          css={fileInputStyles}
          ref={fileInputRef}
          type="file"
          onChange={handleOnChange}
          accept={allowedTypes.join(',')}
        />
      </>
    )
  },
)
