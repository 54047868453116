import { css } from '@emotion/react'
import { IPlanDetail } from './types'
import { PLAN_CARD_THEME } from '../../types'
import { ITheme } from 'src/theme'

export const wrapperStyles = css``

export const buttonAreaStyles = css`
  display: flex;
  justify-content: center;
`

export const expandButtonStyles = (theme: ITheme) => css`
  color: ${theme.colors.elements.DEFAULT};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.text[2]};
  }
`

type IListStyles = Pick<IPlanDetail, 'theme' | 'featuresHeight'> & {
  expanded?: boolean
}
export const listStyles =
  (props: IListStyles, upgradeModal: boolean) => (theme: ITheme) => css`
    div {
      display: flex;
      align-items: center;
      gap: ${theme.spacing['2xsmall']};
      padding: ${theme.spacing.xsmall} ${theme.spacing.small};
      font-size: ${theme.fontSize.small};
      ${upgradeModal &&
      ` padding:  ${theme.gap['4xsmall']}px ${theme.gap.small}px;`}
    }

    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xsmall};
    ${upgradeModal && ` padding:  ${theme.gap['2xsmall']}px;`}
    color: ${props.theme === PLAN_CARD_THEME.DEFAULT
      ? theme.colors.text.DEFAULT
      : theme.colors.white.DEFAULT};

    overflow: hidden;
    transition:
      max-height 300ms,
      height 300ms;

    transition: opacity 1s;
    max-height: ${props.expanded ? `${props.featuresHeight || 1000}px` : '0'};

    &.enter {
      max-height: 0;
      opacity: 0;
    }

    &.enter-active {
      opacity: 1;
      max-height: ${props.featuresHeight || 1000}px;
    }

    &.enter-done {
      max-height: ${props.featuresHeight || 1000}px;
    }

    &.exit {
      max-height: 0;
    }

    &.exit-done {
      max-height: 0;
    }
  `
