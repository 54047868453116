import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSlideLayoutsResponse } from 'src/types/api/responseObjects'

const initialState: getSlideLayoutsResponse['data'] = {
  predefined: [],
  own: [],
  sharedOnOrg: [],
}

export const layoutsSlice = createSlice({
  name: 'layouts',
  initialState,
  reducers: {
    setLayouts: (
      state,
      action: PayloadAction<getSlideLayoutsResponse['data']>,
    ) => {
      state.predefined = action.payload.predefined
      state.own = action.payload.own
      state.sharedOnOrg = action.payload.sharedOnOrg
    },
  },
})

export const { setLayouts } = layoutsSlice.actions

export default layoutsSlice.reducer
