import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { EXPANDER_DIR, EXPANDER_THEME, IExpander } from './types'

type IExpanderStyles = Pick<IExpander, 'dir' | 'theme' | 'props'> & {
  expanded?: boolean
}
export const expanderStyles =
  ({ expanded, dir, theme, props }: IExpanderStyles) =>
  ({ colors, zIndex }: ITheme) => css`
    flex-shrink: 0;
    position: relative;
    z-index: ${zIndex.expanderMenu};
    background: ${theme === EXPANDER_THEME.DARK
      ? colors.dark2.DEFAULT
      : colors.white.DEFAULT};

    ${props[0]}: ${expanded ? props[1][1] : props[1][0]};
    transition: ${props[0]} 0.2s;

    &.enter {
      ${props[0]}: ${props[1][0]};
    }

    &.enter-active {
      ${props[0]}: ${props[1][1]};
    }

    &.enter-done {
      ${props[0]}: ${props[1][1]};
    }

    &.exit {
      ${props[0]}: ${props[1][0]};
    }

    &.exit-done {
      ${props[0]}: ${props[1][0]};
    }

    .content-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .content {
      width: 100%;
      height: 100%;
      ${props[0]}: ${props[1][1]};
    }

    .button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${theme === EXPANDER_THEME.DARK
        ? colors.dark2.DEFAULT
        : colors.white.DEFAULT};
      cursor: pointer;

      ${dir === EXPANDER_DIR.RIGHT
        ? `
					width: 16px;
					height: 80px;
					left: 100%;
					top: 50%;
					border-top-right-radius: 16px;
					border-bottom-right-radius: 16px;
					transform: translateY(-50%);
					
					img {
						transform: rotate(${expanded ? '90deg' : '270deg'});
					}`
        : dir === EXPANDER_DIR.TOP
        ? `
					width: 80px;
					height: 16px;
					bottom: 100%;
					left: 50%;
					border-top-left-radius: 16px;
					border-top-right-radius: 16px;
					transform: translateX(-50%);

					img {
						transform: rotate(${expanded ? '0deg' : '180deg'});
					}
					`
        : ``}
    }
  `
