import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setThemeColors } from 'src/store'
import { useDecksApi, useFlags, useLanguage, useThemeApi } from 'src/hooks'
import { paletteListWrapperStyles } from './styles'
import { IColorPaletteList } from './types'
import { ColorPalette } from '../color-palette/ColorPalette'
import { Button, Icon, icons } from 'src/lib'
import { colors } from 'src/theme'
import { ViewModes } from 'src/types/api/enums'
import { PropColor } from 'src/pages/deck-page/components/edit-properties/props'
import {
  GradientLikeColorSchema,
  ThemeColorDataSchema,
} from 'src/types/api/requestObjects'
import {
  isContainingCssVariable,
  cssVariableToVariable,
} from 'src/services/colorServices'

interface IHandleSelectThemeColorParams {
  themeColorId: number
  viewMode?: ViewModes
  organizationId?: number
  context?: string
}

export const ColorPaletteList: React.FC<IColorPaletteList> = React.memo(
  ({ className, dataAttr, onClick }) => {
    const { t } = useLanguage()
    const [deleteMode, setDeleteMode] = useState(false)

    const [firstColor, setFirstColor] =
      useState<GradientLikeColorSchema | null>(null)
    const [secondColor, setSecondColor] =
      useState<GradientLikeColorSchema | null>(null)
    const [thirdColor, setThirdColor] =
      useState<GradientLikeColorSchema | null>(null)
    const [fourthColor, setFourthColor] =
      useState<GradientLikeColorSchema | null>(null)

    const [isColorUploading, setIsColorUploading] = useState(false)

    const { updateDeckThemeColor } = useDecksApi()
    const { deleteDeckThemeColor, createThemeColor } = useThemeApi()
    const dispatch = useDispatch()
    const flags = useFlags()

    const {
      selectedTheme,
      themeColors,
      activeThemeColors,
      activeThemeColorId,
      deckThemeColors,
      workspaceId,
    } = useSelector(({ theme, edit, workspace }: RootState) => ({
      selectedTheme: edit.activeDeck.data?.deckData?.theme,
      themeColors: theme.themeColors,
      activeThemeColors: edit.activeDeck.data?.deckData?.themeColor.data,
      activeThemeColorId: edit.activeDeck.data?.deckData?.themeColor.id,
      deckThemeColors: edit.activeDeck.data?.deckData?.themeColor,
      workspaceId: workspace.id,
    }))

    useEffect(() => {
      if (flags.FE_75_THEME_COLORS_THEME_ID === false) {
        return
      }
      if (!themeColors) return
      const flatColors = Object.values(themeColors!)?.flatMap(
        (values) => values,
      )
      // Add selected color palette of active deck if the color palette is missing in the color palette list
      const activeDeckColorsIndexInList = flatColors.findIndex(
        (color) => color.id === deckThemeColors?.id,
      )
      if (activeDeckColorsIndexInList === -1 && deckThemeColors) {
        const updatedSharedOnOrg =
          themeColors.sharedOnOrg.concat(deckThemeColors)
        const newColors = {
          ...themeColors,
          sharedOnOrg: updatedSharedOnOrg,
        }
        sessionStorage.setItem(
          'deckThemeColorId',
          deckThemeColors.id.toString(),
        )
        dispatch(setThemeColors(newColors))
      }
    }, [flags.FE_75_THEME_COLORS_THEME_ID])

    const handleSelectThemeColor = useCallback(
      ({
        themeColorId,
        viewMode,
        organizationId,
        context,
      }: IHandleSelectThemeColorParams) => {
        if (deleteMode && selectedTheme?.themeCategoryId) {
          if (context === 'predefined') return
          deleteDeckThemeColor({
            themeColorId,
            viewMode,
            organizationId,
            themeCategoryId: selectedTheme?.themeCategoryId,
          })

          if (themeColorId === activeThemeColorId) {
            updateDeckThemeColor({
              themeColorId: themeColors?.predefined[0].id,
            })
          }

          setDeleteMode(false)
          return
        }

        if (flags.FE_75_THEME_COLORS_THEME_ID) {
          const deckThemeColorIdSS = sessionStorage.getItem('deckThemeColorId')
          const deckThemeColorId = deckThemeColorIdSS
            ? Number(deckThemeColorIdSS)
            : undefined

          if (themeColorId === deckThemeColorId || !themeColors) {
            return
          }

          const updatedSharedOnOrg = themeColors.sharedOnOrg.filter(
            (color) => color.organizationId,
          )
          const newColors = {
            ...themeColors,
            sharedOnOrg: updatedSharedOnOrg,
          }
          dispatch(setThemeColors(newColors))
          sessionStorage.removeItem('deckThemeColorId')
        }

        updateDeckThemeColor({ themeColorId })
        setFirstColor(null)
        setSecondColor(null)
        setThirdColor(null)
        setFourthColor(null)
      },
      [
        flags.FE_75_THEME_COLORS_THEME_ID,
        deleteMode,
        selectedTheme,
        themeColors,
        activeThemeColorId,
      ],
    )

    const onDeleteHandler = useCallback(() => {
      setDeleteMode(!deleteMode)
    }, [deleteMode])

    const updateColors = useCallback(async () => {
      if (!selectedTheme?.themeCategoryId) return
      setIsColorUploading(true)
      const data = {
        first: (firstColor?.colors[0] as string) ?? activeThemeColors?.first,
        second: (secondColor?.colors[0] as string) ?? activeThemeColors?.second,
        third: (thirdColor?.colors[0] as string) ?? activeThemeColors?.third,
        fourth: (fourthColor?.colors[0] as string) ?? activeThemeColors?.fourth,
      }
      const colors = await createThemeColor({
        params: {
          organizationId: workspaceId,
          data,
        },
        themeCategoryId: selectedTheme?.themeCategoryId,
      })

      updateDeckThemeColor({
        themeColorId: Object.values(colors)
          ?.flatMap((schema) => schema)
          ?.find((color) => {
            return (
              color.data.first === data.first &&
              color.data.second === data.second &&
              color.data.third === data.third &&
              color.data.fourth === data.fourth
            )
          })?.id,
      })

      setIsColorUploading(false)
    }, [
      firstColor,
      secondColor,
      thirdColor,
      fourthColor,
      activeThemeColors,
      workspaceId,
      selectedTheme,
    ])

    const onChangeHandler = useCallback(
      (newColor: GradientLikeColorSchema, index: number) => {
        const updatedColor = {
          colors: newColor.colors.map((color) => {
            if (isContainingCssVariable({ text: color }) && activeThemeColors) {
              return activeThemeColors[
                cssVariableToVariable({
                  text: color,
                }) as keyof ThemeColorDataSchema
              ]
            }
            return color
          }),
          rotation: newColor.rotation,
        } as GradientLikeColorSchema

        switch (index) {
          case 0:
            setFirstColor(updatedColor)
            break
          case 1:
            setSecondColor(updatedColor)
            break
          case 2:
            setThirdColor(updatedColor)
            break
          case 3:
            setFourthColor(updatedColor)
            break
          default:
            break
        }
      },
      [activeThemeColors],
    )

    const paletteChangerTemplate = useMemo(
      () =>
        Object.values(activeThemeColors ?? {}).flatMap((palette, index) => {
          const localColors = [firstColor, secondColor, thirdColor, fourthColor]

          return (
            <PropColor
              key={palette + '_' + index}
              className="change-color-box"
              noGradient
              onChange={(newColor: GradientLikeColorSchema) =>
                onChangeHandler(newColor, index)
              }
              color={{
                colors: [localColors[index]?.colors[0] || palette],
              }}
            />
          )
        }),
      [activeThemeColors, firstColor, secondColor, thirdColor, fourthColor],
    )

    const paletteTemplates = useMemo(
      () =>
        Object.entries(themeColors ?? {}).flatMap(([key, values]) => {
          return values.map((palette) => (
            <div
              key={`${key}-color` + palette.id}
              onClick={() =>
                handleSelectThemeColor({
                  themeColorId: palette.id,
                  viewMode: palette.viewMode,
                  organizationId: palette.organizationId || undefined,
                  context: key,
                })
              }
            >
              <ColorPalette
                id={palette.id}
                first={palette.data.first}
                second={palette.data.second}
                third={palette.data.third}
                fourth={palette.data.fourth}
                context={key}
                deleteMode={deleteMode}
              />
            </div>
          ))
        }),
      [themeColors, deleteMode],
    )

    return (
      <div css={paletteListWrapperStyles} className={className} {...dataAttr}>
        <div className="selected-color">
          <span className="style-item-name">
            {t('edit.design.color_palette')}
          </span>
          <span className="style-item-change" onClick={onClick}>
            {t('common.actions.close')}
          </span>
        </div>
        <div className="edit-colors">
          <div className="palette-color-changer">{paletteChangerTemplate}</div>

          <Button
            text={t('common.actions.create')}
            onClick={updateColors}
            isLoading={isColorUploading}
          />
        </div>
        <div className="color-palette-border" />
        <div className="color-box-wrapper">{paletteTemplates}</div>

        <div>
          <div />
          <Icon
            icon={icons.trash_can}
            color={deleteMode ? colors.error.DEFAULT : colors.outline.DEFAULT}
            size={16}
            onClick={() => onDeleteHandler()}
          />
        </div>
      </div>
    )
  },
)
