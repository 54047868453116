import React, { useCallback, useEffect, useState } from 'react'
import { useDrag, useDragDropManager } from 'react-dnd'

import { IHitPoints } from './types'
import { hitPointsStyles } from './styles'
import { CANVAS_ITEM_TYPE } from 'src/components/canvas/types'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { DeepPartial } from 'src/types'
import {
  ComponentListDataSchema,
  ComponentTextDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ComponentServices } from 'src/services'
import { APP_CONFIG } from 'src/config'

const calculateNewSize = (
  data: IHitPoints['data'],
  offset: IHitPoints['data']['positions'],
  scale: number,
  fixedAspectRatio: boolean,
  resizePoint: string,
) => {
  const currentWidth = data.positions.width || 0
  const currentHeight = data.positions.height || 0

  // Calculate the new width and height based on the mouse offset and scale
  const offsetX = resizePoint.includes('left')
    ? -offset.x / scale
    : offset.x / scale
  const offsetY = resizePoint.includes('top')
    ? -offset.y / scale
    : offset.y / scale

  let newWidth = currentWidth + offsetX
  let newHeight =
    fixedAspectRatio && resizePoint.includes('corner')
      ? newWidth / (currentWidth / currentHeight)
      : currentHeight + offsetY

  // Prevent scaling down below the threshold
  if (
    newWidth < APP_CONFIG.editPage.scaling.minValue ||
    newHeight < APP_CONFIG.editPage.scaling.minValue
  ) {
    newWidth = Math.max(newWidth, APP_CONFIG.editPage.scaling.minValue)
    newHeight = Math.max(newHeight, APP_CONFIG.editPage.scaling.minValue)
  }

  // Adjust the left value based on the resizing corner
  const newLeft = resizePoint.includes('left')
    ? data.positions.x + (currentWidth - newWidth)
    : data.positions.x

  // Adjust the top value based on the resizing corner
  const newTop = resizePoint.includes('top')
    ? data.positions.y + (currentHeight - newHeight)
    : data.positions.y

  return {
    width:
      !resizePoint.includes('corner') &&
      (resizePoint.includes('bottom') || resizePoint.includes('top'))
        ? currentWidth
        : newWidth,
    height:
      !resizePoint.includes('corner') &&
      (resizePoint.includes('left') || resizePoint.includes('right'))
        ? currentHeight
        : newHeight,
    x: newLeft,
    y: newTop,
  }
}

const HitPoint: React.FC<IHitPoints> = React.memo(
  ({
    data,
    scale = 1,
    onScale,
    onCornerScale,
    isCornerScaling,
    isScaling,
    className = '',
  }) => {
    const dispatch = useDispatch()
    const dragDropManager = useDragDropManager()
    const monitor = dragDropManager.getMonitor()
    const [offset, setOffset] = useState<{
      x: number
      y: number
    } | null>(null)

    const applyChanges = useCallback(
      (params: any) => {
        const partialUpdate: DeepPartial<UpdateComponentSchema> = {
          positions: {
            x: params.x,
            y: params.y,
            width: params.width,
            height: params.height,
          },
        }

        const { fontSize, fontBodySize } = params

        if (fontSize || fontBodySize) {
          partialUpdate.data = {
            style: {
              ...(fontSize
                ? {
                    font: {
                      size: fontSize,
                    },
                  }
                : {}),
              ...(fontBodySize
                ? {
                    fontBody: {
                      size: fontBodySize,
                    },
                  }
                : {}),
            },
          }
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponent<UpdateComponentSchema>({
            components: [data],
            partialUpdate,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [data],
    )

    const [{ isDragging }, elementDrag, preview] = useDrag(
      () => ({
        type: CANVAS_ITEM_TYPE.HITPOINT,
        end() {
          document.dispatchEvent(
            new CustomEvent('element-scale', { detail: false }),
          )
          if (offset) {
            const newSize: any = calculateNewSize(
              data,
              offset,
              scale,
              true,
              className,
            )
            if (
              className.includes('corner') &&
              (data.data as ComponentTextDataSchema)?.style?.font?.size
            ) {
              const currentFontSize = (data.data as ComponentTextDataSchema)
                .style.font.size
              const currentFontBodySize = (data.data as ComponentListDataSchema)
                ?.style?.fontBody?.size

              const newFontSize =
                currentFontSize &&
                (parseFloat(currentFontSize) * newSize.width) /
                  (data.positions.width || 1) +
                  'em'

              const newFontBodySize =
                currentFontBodySize &&
                (parseFloat(currentFontBodySize) * newSize.width) /
                  (data.positions.width || 1) +
                  'em'

              newSize.fontSize = newFontSize
              newSize.fontBodySize = newFontBodySize
            }
            applyChanges(newSize)
            isScaling?.(false)
          }
        },
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
      }),
      [offset, data, scale, className],
    )

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true })
    }, [])

    useEffect(() => {
      monitor.subscribeToOffsetChange(() => {
        if (
          monitor.getClientOffset()?.x &&
          monitor.getInitialSourceClientOffset()?.x &&
          monitor.getClientOffset()?.y &&
          monitor.getInitialSourceClientOffset()?.y
        ) {
          const diff = {
            x:
              (monitor.getClientOffset()?.x || 0) -
              (monitor.getInitialSourceClientOffset()?.x || 0),
            y:
              (monitor.getClientOffset()?.y || 0) -
              (monitor.getInitialSourceClientOffset()?.y || 0),
          }
          setOffset(diff)
        }
      })
    }, [monitor])

    useEffect(() => {
      if (isDragging && offset) {
        let newSize = calculateNewSize(data, offset, scale, true, className)
        onScale?.(newSize)
        isScaling?.(true)

        // If scaling down and hit the threshold, prevent further scaling down
        if (newSize.width === 50 || newSize.height === 50) {
          return
        }

        // Smoothly continue scaling up if already at threshold and still sizing up
        const interval = setInterval(() => {
          newSize = calculateNewSize(data, offset, scale, true, className)
          onScale?.(newSize)
          if (newSize.width === 50 || newSize.height === 50) {
            clearInterval(interval)
          }
        }, 100) // Adjust the interval as needed for smoother scaling

        if (
          className.includes('corner') &&
          (data.data as ComponentTextDataSchema)?.style?.font?.size
        ) {
          const currentFontSize = (data.data as ComponentTextDataSchema).style
            .font.size
          const newFontSize =
            currentFontSize &&
            (parseFloat(currentFontSize) * newSize.width) /
              (data.positions.width || 1) +
              'em'

          const currentBodyFontSize = (data.data as ComponentListDataSchema)
            ?.style?.fontBody?.size

          const newBodyFontSize =
            currentBodyFontSize &&
            (parseFloat(currentBodyFontSize) * newSize.width) /
              (data.positions.width || 1) +
              'em'

          newFontSize &&
            onCornerScale?.({
              fontSize: newFontSize,
              fontBodySize: newBodyFontSize,
            })
        }

        // Cleanup interval when mouse is released
        return () => clearInterval(interval)
      }
    }, [isDragging, offset])

    const handleMouseDown = useCallback(() => {
      document.dispatchEvent(new CustomEvent('element-scale', { detail: true }))
      if (className.includes('corner')) {
        isCornerScaling?.(true)
      }
    }, [isCornerScaling])

    const handleMouseUp = () => {
      isCornerScaling?.(false)
    }
    return (
      <div
        ref={elementDrag}
        css={hitPointsStyles}
        className={className}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
    )
  },
)

export const HitPoints: React.FC<IHitPoints> = React.memo(
  ({
    data,
    allowEdges = true,
    scale = 1,
    onScale,
    onCornerScale,
    isCornerScaling,
    isScaling,
  }) => {
    const points = [
      'corner top left',
      'corner top right',
      'corner bottom right',
      'corner bottom left',
      ...(allowEdges ? ['top', 'right', 'bottom', 'left'] : []),
    ]

    return (
      <>
        {points.map((className) => (
          <HitPoint
            key={className}
            className={className}
            data={data}
            scale={scale}
            onScale={onScale}
            onCornerScale={onCornerScale}
            isCornerScaling={isCornerScaling}
            isScaling={isScaling}
          />
        ))}
      </>
    )
  },
)

HitPoints.displayName = 'HitPoints'
