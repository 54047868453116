import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const categorySelectStyles = css``

export const menuStyles = ({ gap, breakpoints }: ITheme) => css`
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${gap.small}px;

  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
  }
`

export const nativeStyles = ({ gap, colors, breakpoints }: ITheme) => css`
  position: relative;
  color: ${colors.white.DEFAULT};
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
  gap: ${gap['2xsmall']}px;

  select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
