import React, { useCallback } from 'react'

import { ISlider } from './types'
import { barStyles, sliderStyles } from './styles'

export const Slider: React.FC<ISlider> = React.memo(
  ({ value, min, max, onChange, className, dataAttr }) => {
    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(parseInt(event.currentTarget.value))
      },
      [onChange],
    )

    return (
      <div css={sliderStyles} className={className} {...dataAttr}>
        <input
          css={barStyles}
          type="range"
          min={min}
          max={max}
          value={value || 0}
          onChange={handleOnChange}
        />
        <div
          className="filled-range"
          style={{ width: `${value - Math.round((value - 50) / 50)}%` }}
        />
      </div>
    )
  },
)

Slider.displayName = 'Slider'
