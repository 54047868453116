import React, { useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ISvgDecor } from './types'
import { svgDecorStyles } from './styles'
import { useRotationToCoords } from '../svg-parsed/hooks'
import { useFlags } from 'src/hooks'

export const SvgDecor: React.FC<ISvgDecor> = React.memo(
  ({ svgString = '', colorMap, width, height, alphaIndicator }) => {
    const { convert } = useRotationToCoords()
    const flags = useFlags()
    const rotationCoords = convert((colorMap?.bg?.rotation || 0) - 90)

    const addUniqueIds = useCallback(
      (svg: string): string => {
        if (!flags.FE_604_TEMPLATES_DECORS_FONTS) {
          return svg
        }
        const idMap: { [key: string]: string } = {}
        const uniqueId = uuidv4()

        // Replace occurrences of id="..." first and store the mapping
        const updatedSvg = svg.replace(/id="(.*?)"/g, (match, id) => {
          const newId = `${id}_${uniqueId}`
          idMap[id] = newId
          return `id="${newId}"`
        })

        // Replace occurrences of url(#...) based on the mapping
        const finalSvg = updatedSvg.replace(/url\(#(.*?)\)/g, (match, id) => {
          const newId = idMap[id] || `${id}_${uniqueId}`
          return `url(#${newId})`
        })

        return finalSvg
      },
      [flags.FE_604_TEMPLATES_DECORS_FONTS],
    )

    const overrideContent = useMemo(() => {
      const uuid = uuidv4()

      return addUniqueIds(svgString)
        .replaceAll('decor-bg', uuid)
        .replaceAll('$color1', colorMap?.first || '')
        .replaceAll('$color2', colorMap?.second || '')
        .replaceAll('$color3', colorMap?.third || '')
        .replaceAll('$color4', colorMap?.fourth || '')
        .replaceAll('$bg-x1', rotationCoords.x1 || '0')
        .replaceAll('$bg-x2', rotationCoords.x2 || '0')
        .replaceAll('$bg-y1', rotationCoords.y1 || '0')
        .replaceAll('$bg-y2', rotationCoords.y2 || '0')
        .replaceAll('$bg-start', colorMap?.bg?.colors[0] || '')
        .replaceAll(
          '$bg-stop',
          colorMap?.bg?.colors[1] || colorMap?.bg?.colors[0] || '',
        )
    }, [colorMap, svgString, rotationCoords])
    return (
      <div
        key={uuidv4()}
        css={svgDecorStyles({ width, height, alphaIndicator })}
        dangerouslySetInnerHTML={{ __html: overrideContent }}
      />
    )
  },
)

SvgDecor.displayName = 'SvgDecor'
