import { TKey } from 'src/i18n/types'
import { icons } from 'src/lib'
import { MediaTypes } from 'src/types/api/enums'

export interface IPropMedia {
  label?: string
  icon?: icons
  selectedType?: MediaTypes
  availableTypes: MediaTypes[]
  onChange?: (type: MediaTypes, mediaUrl: string) => void
  directUpload?: boolean
}

export interface IMenuItemType {
  name: TKey
  icon: icons
  value: MediaTypes
  defaultMedia: string
}

export const MEDIA_MENU_ITEMS: Partial<Record<MediaTypes, IMenuItemType>> = {
  [MediaTypes.IMAGE]: {
    name: 'common.files.image',
    icon: icons.picture,
    value: MediaTypes.IMAGE,
    defaultMedia:
      'https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjg3MDU4fQ&w=1920&h=1080&crop=edges&fit=fillmax',
  },
  [MediaTypes.GIF]: {
    name: 'common.files.gif',
    icon: icons.gif,
    value: MediaTypes.GIF,
    defaultMedia:
      'https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjg3MDU4fQ&w=1920&h=1080&crop=edges&fit=fillmax',
  },
  [MediaTypes.ICON]: {
    name: 'common.files.icon',
    icon: icons.medium,
    value: MediaTypes.ICON,
    defaultMedia:
      'https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjg3MDU4fQ&w=1920&h=1080&crop=edges&fit=fillmax',
  },
}

export const mapTypeToExtension = {
  [MediaTypes.IMAGE]: ['.jpg', '.jpeg', '.png'],
  [MediaTypes.GIF]: ['.gif'],
  [MediaTypes.ICON]: ['.svg'],
  [MediaTypes.LOGO]: ['.jpg', '.jpeg', '.png'],
  [MediaTypes.SHAPE]: [],
  [MediaTypes.VIDEO]: [],
  [MediaTypes.WEBSITE]: [],
}
