import React, { useCallback } from 'react'

import { ITextarea } from './types'
import { labelStyles, textAreaElementStyles, textAreaStyles } from './style'

export const Textarea: React.FC<ITextarea> = React.memo(
  ({
    value,
    placeholder,
    rows,
    label,
    onChange,
    onBlur,
    onFocus,
    className,
    dataAttr,
    note,
  }) => {
    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.currentTarget.value)
      },
      [onChange],
    )

    const handleOnBlur = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onBlur && onBlur(e.currentTarget.value)
      },
      [onBlur],
    )

    return (
      <div css={textAreaStyles} {...dataAttr} className={className}>
        {label && <div css={labelStyles}>{label}</div>}
        <textarea
          css={textAreaElementStyles(note)}
          value={value}
          placeholder={placeholder}
          onChange={handleOnChange}
          rows={rows}
          onBlur={handleOnBlur}
          onFocus={onFocus}
        />
      </div>
    )
  },
)
