import React, { useCallback, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import { EXPANDER_DIR, EXPANDER_THEME, IExpander } from './types'
import { expanderStyles } from './styles'

import expanderWhite from 'src/assets/images/expander-white.svg'
import expanderDark from 'src/assets/images/expander-dark.svg'

export const Expander: React.FC<IExpander> = React.memo(
  ({
    theme = EXPANDER_THEME.LIGHT,
    dir = EXPANDER_DIR.RIGHT,
    expanded = true,
    props,
    onStateChange,
    className,
    dataAttr,
    children,
  }) => {
    const nodeRef = useRef(null)

    const handelOnChange = useCallback(() => {
      onStateChange?.(!expanded)
    }, [expanded])

    return (
      <CSSTransition nodeRef={nodeRef} in={expanded} timeout={500}>
        <div
          ref={nodeRef}
          css={expanderStyles({ expanded, dir, theme, props })}
          className={className}
          {...dataAttr}
        >
          <div className="content-wrapper">
            <div className="content">{children}</div>
          </div>
          <div className="button" onClick={handelOnChange}>
            <img
              className="button-icon"
              src={
                theme === EXPANDER_THEME.LIGHT ? expanderDark : expanderWhite
              }
            />
          </div>
        </div>
      </CSSTransition>
    )
  },
)

Expander.displayName = 'Expander'
