import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setPaymentConfirmationModalOpen,
  setPlanDetails,
  setPossiblePlans,
  setPurchaseInformation,
} from 'src/store'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import {
  getPlansResponse,
  postPlanPurchaseResponse,
} from 'src/types/api/responseObjects'
import { postPlanPurchaseBody } from 'src/types/api/requestObjects'
import { useFlags } from '../useFlags'

interface IUsePlanApiReturn {
  getPossiblePlans: () => Promise<void>
  purchasePlan: (
    data: postPlanPurchaseBody,
  ) => Promise<postPlanPurchaseResponse['data']>
  isLoading: boolean
  isPurchaseLoading: boolean
}

export const usePlanApi = (): IUsePlanApiReturn => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPurchaseLoading, setIsPurchaseLoading] = useState<boolean>(false)
  const flags = useFlags()

  const getPossiblePlans = async () => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getPlansResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.PLAN_POSSIBLE,
        })
      dispatch(setPossiblePlans(res.data.data.plans))
    } finally {
      setIsLoading(false)
    }
  }

  const purchasePlan = useCallback(
    async (
      data: postPlanPurchaseBody,
    ): Promise<postPlanPurchaseResponse['data']> => {
      setIsPurchaseLoading(true)
      try {
        const res: RequestResponse<postPlanPurchaseResponse, any> =
          await RequestServices.callApi({
            method: 'POST',
            url: API_CONFIG.PLAN_PURCHASE,
            data,
          })
        if (
          flags.FE_450_PAYMENT_CONFIRMATION &&
          res.data.data.preview?.invoice.paymentMethod.card?.last4
        ) {
          dispatch(
            setPlanDetails({
              planName: data.planName,
              planPeriod: data.planPeriod,
              seatSize: data.seatSize,
            }),
          )
          dispatch(setPurchaseInformation(res.data.data))
          dispatch(setPaymentConfirmationModalOpen(true))
        }
        return res.data.data
      } finally {
        setIsPurchaseLoading(false)
      }
    },
    [flags.FE_450_PAYMENT_CONFIRMATION],
  )

  return { getPossiblePlans, purchasePlan, isLoading, isPurchaseLoading }
}
