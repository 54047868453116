import React from 'react'

import { IAiLoading } from './types'
import {
  aiLoadingStyles,
  animationStyles,
  buttonWrapperStyles,
  loadingTextStyles,
} from './styles'
import Lottie from 'react-lottie'
import decktoLoading from 'src/assets/lotties/decktoLoading.json'
import { useLanguage } from 'src/hooks'
import { BUTTON_SIZE, BUTTON_TYPE, Button, icons } from 'src/lib'
export const AiLoading: React.FC<IAiLoading> = React.memo(
  ({ className, dataAttr, onClose }) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: decktoLoading,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
    const { t } = useLanguage()
    return (
      <div css={aiLoadingStyles} className={className} {...dataAttr}>
        <div css={animationStyles}>
          <Lottie options={defaultOptions} />
        </div>
        <div css={loadingTextStyles}>
          {t('common.informative.ai_loading_text')}
        </div>
        <div css={buttonWrapperStyles}>
          <Button
            text={t('common.informative.wait_on_dashboard')}
            type={BUTTON_TYPE.GHOST}
            onClick={onClose}
            icon={icons.chevron_left}
            size={BUTTON_SIZE.LARGE}
          />
        </div>
      </div>
    )
  },
)

AiLoading.displayName = 'AiLoading'
