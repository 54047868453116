import { UserLanguages } from 'src/types/api/enums'

export const languageToEnum = (lang: string) => {
  switch (lang) {
    case 'en':
      return UserLanguages.EN_US
    case 'tr':
      return UserLanguages.TR_TR
    case 'fr':
      return UserLanguages.FR_CA
    default:
      return UserLanguages.EN_US
  }
}

type UserLanguagesValues = UserLanguages
export const enumToLanguage = (value: UserLanguagesValues) => {
  switch (value) {
    case UserLanguages.EN_US:
      return 'en'
    case UserLanguages.TR_TR:
      return 'tr'
    case UserLanguages.FR_CA:
      return 'fr'
    default:
      return 'en'
  }
}
