import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IWorkspace {
  id?: number
  filtering?: 'personal' | 'organization'
  paymentFailed: boolean
  isOwner: boolean
}

const initialState: IWorkspace = {
  id: undefined,
  filtering: 'personal',
  paymentFailed: false,
  isOwner: false,
}

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspaceId: (state, action: PayloadAction<IWorkspace['id']>) => {
      state.id = action.payload
    },
    setFiltering: (state, action: PayloadAction<IWorkspace['filtering']>) => {
      state.filtering = action.payload
    },
    setPaymentFailed: (
      state,
      action: PayloadAction<IWorkspace['paymentFailed']>,
    ) => {
      state.paymentFailed = action.payload
    },
    setIsOwner: (state, action: PayloadAction<IWorkspace['isOwner']>) => {
      state.isOwner = action.payload
    },
  },
})
export const { setWorkspaceId, setFiltering, setPaymentFailed, setIsOwner } =
  workspaceSlice.actions
export default workspaceSlice.reducer
