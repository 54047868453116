import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IFontFamily } from '../font-family/types'

interface IThemeCardStyles {
  isSelected?: boolean
  deleteMode?: boolean
  context?: IFontFamily['context']
  displayOnly?: boolean
}

export const paletteWrapperStyles =
  ({ isSelected, deleteMode, context, displayOnly }: IThemeCardStyles) =>
  ({ borderRadius, breakpoints, colors, spacing, zIndex }: ITheme) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: ${displayOnly ? '32px' : '48px'};
    padding: ${spacing['3xsmall']};
    background-color: ${
      displayOnly ? 'rgba(239, 242, 248, 1)' : colors.dark.DEFAULT
    };
    border-radius: ${borderRadius[2]};
    position: relative;
    width: ${displayOnly ? '113px' : '103px'};
    ${isSelected && `outline: 2px solid ${colors.primary.DEFAULT}`};
    cursor: pointer;

    @media (min-width: ${breakpoints.desktop}px) {
      width: ${displayOnly ? '125px' : '103px'};
    }

    .palette-inline {
      background-color: ${colors.outline[2]};
      border-radius: ${borderRadius[2]};
      display: flex;
      position: relative;
      width: 100%;
      column-gap: 1px;
      padding: 1px;
      overflow: hidden;
      
      & > div {
        flex-grow: 1;
        height: ${displayOnly ? '24px' : '40px'};
      }
      
      & > div:first-of-type {
        border-top-left-radius: ${borderRadius[2]};
        border-bottom-left-radius: ${borderRadius[2]};
      }
      
      & > div:last-child {
        border-top-right-radius: ${borderRadius[2]};
        border-bottom-right-radius: ${borderRadius[2]};
      }
    }

    .palette-actions {
      display: ${deleteMode ? 'flex' : 'none'};
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s;
      background: rgba(0, 0, 0, 0.6);
      z-index: ${zIndex.fontFamilyActions};
    }

    &:hover {
      & .palette-actions {
        opacity: 1;
      }
      & .palette-tooltip {
        display: ${
          context === 'predefined' && deleteMode ? 'block' : 'none !important'
        };
      }
    }

    & .palette-tooltip {
      display: none;
      position: absolute;
      bottom: -41px;
      width: 130px;
      z-index: ${zIndex.tooltip};
    }

    
    & .font-box-icon {
      position: absolute;
      top: -10px;
      right: -3px;
      border-radius: ${borderRadius.DEFAULT};
      background-color: ${colors.primary.DEFAULT};
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 1px;
      padding-left: 1px;
      z-index: ${zIndex.fontBoxIcon};
    }
  }
`
