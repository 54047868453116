import React, { useCallback, useMemo, useState } from 'react'
import {
  Button,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
  Icon,
  icons,
  Modal,
  Tooltip,
  TOOLTIP_PLACE,
} from 'src/lib'

import { IPaymentConfirmation } from './types'
import {
  paymentConfirmationStyles,
  paymentConfirmationTitleStyles,
  paymentConfirmationSubtitleStyles,
  paymentInformationSeperatorStyles,
  paymentInformationStyles,
  paymentInformationRowLabelStyles,
  paymentInformationRowValueStyles,
  paymentInformationSeatSizeStyles,
  paymentInformationRowTotalStyles,
  paymentInformationContactUsStyles,
  paymentInformationRowStyles,
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  RootState,
  setPaymentConfirmationModalOpen,
  setPurchaseInformation,
} from 'src/store'
import {
  useLanguage,
  useNotification,
  useUserApi,
  usePlanApi,
  useOrgApi,
} from 'src/hooks'
import { colors, fontSize, fontWeight, gap } from 'src/theme'
import { getEnumKeyByValue } from 'src/helpers'
import { CURRENCY_DATA } from 'src/data'
import {
  PlanNames,
  PlanPeriods,
  UserPlanPaymentStates,
} from 'src/types/api/enums'
import {
  capitalizeFirstLetter,
  ensureTwoDecimalPlaces,
  moveMinusToFront,
} from 'src/services/stringServices'
import { formatDate } from 'src/services/dateTimeServices'

export const PaymentConfirmation: React.FC<IPaymentConfirmation> = React.memo(
  ({ className, dataAttr }) => {
    const dispatch = useDispatch()
    const { t, Trans } = useLanguage()
    const { success } = useNotification()
    const { getUser, getOrganizations, isLoading } = useUserApi()
    const { purchasePlan, isPurchaseLoading } = usePlanApi()
    const { getOrgDetails } = useOrgApi()

    const {
      isPaymentConfirmationModalOpen,
      purchaseInformation,
      planName,
      planPeriod,
      seatSize,
      paymentState,
      workspaceId,
    } = useSelector(({ plan, user, workspace }: RootState) => ({
      isPaymentConfirmationModalOpen: plan.isPaymentConfirmationModalOpen,
      purchaseInformation: plan.purchaseInformation,
      planName: plan.planName,
      planPeriod: plan.planPeriod,
      seatSize: plan.seatSize,
      paymentState: user.data?.activeUserPlan?.paymentState,
      workspaceId: workspace.id,
    }))

    const paymentLines = useMemo(() => {
      if (!purchaseInformation?.preview) return

      return [...purchaseInformation.preview.invoice.lines]
        ?.reverse()
        ?.map((line, index) => (
          <div
            key={'invoice-line' + index}
            css={paymentInformationRowStyles}
            style={{
              color: colors.text[2],
            }}
          >
            <span
              css={paymentInformationRowLabelStyles}
              style={{
                fontWeight: fontWeight.regular,
                fontSize: fontSize.medium,
              }}
            >
              {index === 0 &&
                t('payment_confirmation.remaining_line', {
                  planName:
                    line.planName === PlanNames.BUSINESS
                      ? t('payment_confirmation.business')
                      : t('payment_confirmation.pro'),
                  date: formatDate(new Date()),
                })}
              {index === 1 &&
                t('payment_confirmation.unused_line', {
                  planName:
                    line.planName === PlanNames.BUSINESS
                      ? t('payment_confirmation.business')
                      : t('payment_confirmation.pro'),
                  date: formatDate(new Date()),
                })}
            </span>
            <span
              css={paymentInformationRowValueStyles}
              style={{
                fontWeight: fontWeight.regular,
                fontSize: fontSize.medium,
              }}
            >
              {ensureTwoDecimalPlaces(
                moveMinusToFront(
                  CURRENCY_DATA[line.currency].symbol +
                    (line.amount / 100).toString(),
                ),
              )}
            </span>
          </div>
        ))
    }, [purchaseInformation, seatSize, planName, planPeriod])

    const onCloseHandler = useCallback(() => {
      dispatch(setPaymentConfirmationModalOpen(false))
    }, [])

    const cancelPaymentHandler = useCallback(() => {
      dispatch(setPurchaseInformation(null))
      dispatch(setPaymentConfirmationModalOpen(false))
    }, [])

    const confirmPaymentHandler = useCallback(async () => {
      const res = await purchasePlan({
        planName: planName!,
        planPeriod: planPeriod!,
        seatSize,
        isPreview: false,
      })

      if (res.checkoutSessionUrl) {
        localStorage.setItem('payment-awaits', 'true')
        window.open(res.checkoutSessionUrl, '_blank', 'noopener,noreferrer')
      } else {
        localStorage.removeItem('payment-awaits')
        getUserAfterPurchase()
        success('common.informative.payment_successful')
        dispatch(setPaymentConfirmationModalOpen(false))
      }
    }, [planName, planPeriod, seatSize])

    const [retries, setRetries] = useState(0)
    const getUserAfterPurchase = useCallback(async () => {
      const res = await getUser()
      await getOrganizations()
      if (workspaceId) {
        await getOrgDetails(workspaceId)
      }
      if (
        (res.user?.activeUserPlan?.paymentState ===
          UserPlanPaymentStates.PAYMENT_PENDING ||
          res.user?.activeUserPlan?.paymentState ===
            UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED) &&
        retries < 3
      ) {
        setRetries(retries + 1)
        setTimeout(() => {
          getUserAfterPurchase()
        }, 3000)
      } else {
        setRetries(0)
      }
    }, [retries, paymentState])

    return (
      <Modal isOpen={isPaymentConfirmationModalOpen} onClose={onCloseHandler}>
        <div
          css={paymentConfirmationStyles}
          className={className}
          {...dataAttr}
        >
          <span css={paymentConfirmationTitleStyles}>
            {t('payment_confirmation.title', {
              planName: capitalizeFirstLetter(
                getEnumKeyByValue(PlanNames, planName!)
                  ? getEnumKeyByValue(PlanNames, planName!)!.toLocaleLowerCase()
                  : '--',
              ),
            })}
          </span>
          <span css={paymentConfirmationSubtitleStyles}>
            {t('payment_confirmation.subtitle')}
          </span>
          <div css={paymentInformationStyles}>
            <div css={paymentInformationRowStyles}>
              <span css={paymentInformationRowLabelStyles}>
                {t('payment_confirmation.plan')}
              </span>
              <div css={paymentInformationRowValueStyles}>
                <span>
                  {getEnumKeyByValue(PlanNames, planName!)?.toLocaleLowerCase()}
                </span>

                {seatSize && planName === PlanNames.BUSINESS && (
                  <>
                    <span style={{ textTransform: 'lowercase' }}> x </span>
                    <span css={paymentInformationSeatSizeStyles}>
                      {seatSize} {t('common.seats')}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div css={paymentInformationRowStyles} style={{ paddingTop: 0 }}>
              <span css={paymentInformationRowLabelStyles} />
              <span
                css={paymentInformationRowValueStyles}
                style={{
                  color: colors.text[2],
                  fontSize: fontSize.medium,
                }}
              >
                {getEnumKeyByValue(
                  PlanPeriods,
                  planPeriod!,
                )?.toLocaleLowerCase() === 'annual'
                  ? t('common.plan_period.annually')
                  : t('common.plan_period.monthly')}
              </span>
            </div>
            <div css={paymentInformationRowStyles}>
              <span css={paymentInformationRowLabelStyles}>
                {t('payment_confirmation.payment_method')}
              </span>
              <span
                css={paymentInformationRowValueStyles}
                style={{
                  textTransform: 'capitalize',
                }}
              >
                {
                  purchaseInformation?.preview?.invoice.paymentMethod.card
                    ?.brand
                }
              </span>
            </div>
            <div css={paymentInformationRowStyles}>
              <span css={paymentInformationRowLabelStyles}>
                {t('payment_confirmation.card_number')}
              </span>
              <span css={paymentInformationRowValueStyles}>
                **** **** ****{' '}
                {
                  purchaseInformation?.preview?.invoice.paymentMethod.card
                    ?.last4
                }
              </span>
            </div>
            <div css={paymentInformationRowStyles}>
              <span css={paymentInformationRowLabelStyles}>
                {t('payment_confirmation.card_date')}
              </span>
              <span css={paymentInformationRowValueStyles}>
                {purchaseInformation?.preview?.invoice.paymentMethod.card?.expMonth
                  ?.toString()
                  .padStart(2, '0')}
                /
                {purchaseInformation?.preview?.invoice.paymentMethod.card?.expYear
                  ?.toString()
                  .slice(-2)}
              </span>
            </div>
            <hr css={paymentInformationSeperatorStyles} />
            {paymentLines}
            <div css={paymentInformationRowStyles}>
              <Tooltip
                width={282}
                text={t('payment_confirmation.total_tooltip')}
                place={TOOLTIP_PLACE.BOTTOM_RIGHT}
              >
                <div css={paymentInformationRowTotalStyles}>
                  <Icon icon={icons.info} color={colors.text[2]} size={20} />
                  <span css={paymentInformationRowLabelStyles}>
                    {t('payment_confirmation.total')}
                  </span>
                </div>
              </Tooltip>
              <span css={paymentInformationRowValueStyles}>
                {
                  CURRENCY_DATA[
                    purchaseInformation?.preview?.invoice.currency ?? 1
                  ].symbol
                }
                {ensureTwoDecimalPlaces(
                  (
                    (purchaseInformation?.preview?.invoice.proratedAmount ??
                      100) / 100
                  ).toString(),
                )}
              </span>
            </div>
          </div>
          <span css={paymentInformationContactUsStyles}>
            <Trans
              i18nKey={'payment_confirmation.contact_us'}
              components={[
                <a
                  key="contact_us"
                  href="https://www.decktopus.com/contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('payment_confirmation.contact_us')}
                </a>,
              ]}
            />
          </span>
          <div
            css={paymentInformationRowStyles}
            style={{ gap: `${gap['xlarge']}px` }}
          >
            <Button
              width={BUTTON_WIDTH.FULL}
              type={BUTTON_TYPE.REVERSE}
              text={t('payment_confirmation.cancel')}
              onClick={cancelPaymentHandler}
            />
            <Button
              width={BUTTON_WIDTH.FULL}
              theme={BUTTON_THEME.GRADIENT}
              text={t('payment_confirmation.confirm')}
              onClick={confirmPaymentHandler}
              isLoading={isPurchaseLoading || isLoading}
            />
          </div>
        </div>
      </Modal>
    )
  },
)

PaymentConfirmation.displayName = 'PaymentConfirmation'
