import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  border: 1px solid ${theme.colors.outline.DEFAULT};
  border-radius: ${theme.borderRadius[2]};
  box-shadow: ${theme.boxShadow[1]};
  padding: ${theme.spacing.small} ${theme.spacing.xlarge};
  height: ${theme.dimensions.planBadgeHeight.DEFAULT}px;
  display: flex;
  align-items: center;
  width: 100%;

  .title {
    font-size: ${theme.fontSize.medium};
    font-weight: ${theme.fontWeight.medium};
    flex-grow: 1;
    display: flex;
  }

  .currency {
    display: flex;
    gap: ${theme.spacing['4xsmall']};
    font-size: ${theme.fontSize.medium};
    font-weight: ${theme.fontWeight.medium};
  }

  .price {
    font-size: ${theme.fontSize.xlarge};
    font-weight: ${theme.fontWeight.medium};
  }
`
