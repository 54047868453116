import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
  Button,
  INPUT_SIZE,
  INPUT_WIDTH,
  Input,
} from 'src/lib'
import {
  VALIDATION_RULE_TYPES,
  useAuthApi,
  useLanguage,
  useNotification,
} from 'src/hooks'
import { actionsStyles, formInner, gradientText } from './styles'
import { IResetPasswordForm } from './types'
import { LottieButton } from '../lottie-button'

export const ResetPasswordForm: React.FC<IResetPasswordForm> = React.memo(
  ({ className, dataAttr }) => {
    const navigate = useNavigate()
    const { success } = useNotification()
    const [isResetEmailSent, setIsResentEmailSent] = useState(false)
    const [searchParams] = useSearchParams()
    const resetToken = useMemo(() => searchParams.get('token'), [searchParams])
    const resetEmail = useMemo(() => searchParams.get('email'), [searchParams])

    const { sendResetEmail, resetPassword, isLoading } = useAuthApi()
    const { t } = useLanguage()

    const [inputValid, setInputValid] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [sentMail, setSentEmail] = useState('')
    const onLoginClickHandler = useCallback(() => {
      navigate('/login', { replace: true })
    }, [])

    const isSentEmailDisabled = useMemo(() => {
      if (isResetEmailSent) {
        return inputValue === sentMail || !inputValid
      } else {
        return !inputValid
      }
    }, [inputValue, inputValid, sentMail, isResetEmailSent])

    const onSubmitHandler = useCallback(() => {
      if (resetToken && resetEmail) {
        resetPassword({
          token: resetToken,
          email: resetEmail,
          newPassword: inputValue,
        }).then((res) => {
          if (res) {
            success('reset.password_set')
            navigate('/login', { replace: true })
          }
        })
      } else {
        if (!isSentEmailDisabled) {
          sendResetEmail({ email: inputValue }).then(() => {
            success('reset.password_reset_sent')
            setIsResentEmailSent(true)
            setSentEmail(inputValue)
          })
        }
      }
    }, [inputValue, isSentEmailDisabled])

    const resendHandler = useCallback(() => {
      if (!!sentMail && inputValid) {
        sendResetEmail({ email: inputValue }).then(() => {
          success('reset.password_reset_sent')
          setIsResentEmailSent(true)
          setSentEmail(inputValue)
        })
      }
    }, [sentMail, inputValue, inputValid])

    const onEmailChange = (value: string) => {
      setInputValue(value)
    }

    return (
      <div css={formInner} className={className} {...dataAttr}>
        <div css={gradientText}>{t('reset.password_reset')}</div>
        {!resetToken && (
          <div className="info-text">{t('reset.reset_password_info')}</div>
        )}
        {resetToken ? (
          <>
            <Input
              size={INPUT_SIZE.SMALL}
              width={INPUT_WIDTH.FULL}
              label={t('reset.new_password')}
              onChange={onEmailChange}
              onEnter={onSubmitHandler}
              value={inputValue}
              type={'password'}
              validation={{
                [VALIDATION_RULE_TYPES.REQUIRED]: {
                  text: t('validation.error.required', {
                    name: t('common.password'),
                  }),
                },
                [VALIDATION_RULE_TYPES.MIN]: {
                  value: 6,
                  text: t('validation.min_char', {
                    name: t('common.password'),
                    value: 6,
                  }),
                },
              }}
              onValidation={setInputValid}
              errorAlignRight
              solidError
            />
          </>
        ) : (
          <Input
            size={INPUT_SIZE.SMALL}
            width={INPUT_WIDTH.FULL}
            label={t('common.email_address')}
            onChange={onEmailChange}
            onEnter={onSubmitHandler}
            value={inputValue}
            type={'email'}
            validation={{
              [VALIDATION_RULE_TYPES.REQUIRED]: {
                text: t('validation.error.required', {
                  name: t('common.email'),
                }),
              },
              [VALIDATION_RULE_TYPES.VALIDMAIL]: {
                text: t('validation.error.email'),
              },
            }}
            onValidation={setInputValid}
            errorAlignRight
            solidError
          />
        )}
        <div css={actionsStyles}>
          {isResetEmailSent ? (
            inputValid ? (
              <LottieButton
                text={t('reset.resend')}
                isLoading={isLoading}
                onClick={resendHandler}
              />
            ) : (
              <Button
                size={BUTTON_SIZE.SMALL}
                text={t('reset.resend')}
                theme={BUTTON_THEME.GRADIENT}
                onClick={resendHandler}
                isLoading={isLoading}
                width={BUTTON_WIDTH.FULL}
                disabled={!inputValid}
              />
            )
          ) : isSentEmailDisabled ? (
            <LottieButton
              text={t(resetToken ? 'common.actions.reset' : 'reset.send_email')}
              isLoading={isLoading}
              onClick={onSubmitHandler}
            />
          ) : (
            <Button
              size={BUTTON_SIZE.SMALL}
              text={t(resetToken ? 'common.actions.reset' : 'reset.send_email')}
              theme={BUTTON_THEME.GRADIENT}
              onClick={onSubmitHandler}
              isLoading={isLoading}
              width={BUTTON_WIDTH.FULL}
              disabled={isSentEmailDisabled}
            />
          )}
          <span>
            {t('reset.back_to')}
            <Button
              isLink
              size={BUTTON_SIZE.LARGE}
              text={t('login.login')}
              theme={BUTTON_THEME.PRIMARY}
              type={BUTTON_TYPE.GHOST}
              onClick={onLoginClickHandler}
            />
          </span>
        </div>
      </div>
    )
  },
)
