import React, { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ISvgParsed } from './types'
import { svgParsedStyles } from './styles'
import { useCachedSvg } from 'src/hooks/useCachedSvg'
import { useRotationToCoords } from './hooks'
import { useFlags } from 'src/hooks'

export const SvgParsed: React.FC<ISvgParsed> = React.memo(
  ({
    isCached = true,
    url: svgUrl,
    className,
    colorReplace,
    backgroundReplace,
    dataAttr,
    distort,
    width,
    height,
  }) => {
    const flags = useFlags()
    const uuid = uuidv4()
    const { convert } = useRotationToCoords()
    const { content } = useCachedSvg(svgUrl, {
      isCached,
      id: uuid,
      distort,
    })

    // TODO: better handling
    const colorReplacedContent = useMemo(() => {
      if (!colorReplace) {
        return content
      }

      return content
        ?.replaceAll(colorReplace[0][0], colorReplace[0][1])
        .replaceAll(colorReplace[1][0], colorReplace[1][1])
        .replaceAll(colorReplace[2][0], colorReplace[2][1])
        .replaceAll(colorReplace[3][0], colorReplace[3][1])
    }, [colorReplace, content])

    const backgroundReplaceContent = useMemo(() => {
      if (backgroundReplace?.rotation !== undefined) {
        const coords = convert(backgroundReplace.rotation - 90)
        return colorReplacedContent
          ?.replaceAll('#5DDDDD', `url(#${uuid})`)
          .replaceAll('$gradient-start-color', backgroundReplace.colors[0])
          .replaceAll('$gradient-stop-color', backgroundReplace.colors[1])
          .replaceAll('$gradient-x1', coords.x1 || '0')
          .replaceAll('$gradient-x2', coords.x2 || '0')
          .replaceAll('$gradient-y1', coords.y1 || '0')
          .replaceAll('$gradient-y2', coords.y2 || '0')
      }

      return colorReplacedContent
        ?.replaceAll('#5DDDDD', backgroundReplace?.colors[0] || '#FFFFFF')
        .replaceAll('$gradient-x1', '0')
        .replaceAll('$gradient-x2', '0')
        .replaceAll('$gradient-y1', '0')
        .replaceAll('$gradient-y2', '0')
    }, [colorReplacedContent, backgroundReplace])

    return (
      <div
        css={svgParsedStyles({ width, height })}
        className={className}
        dangerouslySetInnerHTML={{
          __html:
            (flags.FE_SVG_DECOR
              ? backgroundReplaceContent
              : colorReplacedContent) || '',
        }}
        {...dataAttr}
      />
    )
  },
)

SvgParsed.displayName = 'SvgParsed'
