import React from 'react'
import { useSelector } from 'react-redux'
import { useAuthApi, useLanguage, useNotification } from 'src/hooks'
import { BUTTON_TYPE, Button } from 'src/lib'
import { RootState } from 'src/store'

export const ResetPassword: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const { success } = useNotification()
  const { sendResetEmail, isLoading } = useAuthApi()

  const { email } = useSelector(({ user }: RootState) => ({
    email: user.data?.email,
  }))

  const handlePasswordReset = () => {
    if (email) {
      sendResetEmail({ email }).then(() => {
        success('reset.password_reset_sent')
      })
    }
  }

  return (
    <>
      <div>{t('reset.change_your_password_info')}</div>
      <Button
        text={t('reset.reset_password')}
        type={BUTTON_TYPE.REVERSE}
        onClick={handlePasswordReset}
        isLoading={isLoading}
      />
    </>
  )
})
