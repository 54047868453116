import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const innersStyles = (theme: ITheme) => css`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.background.DEFAULT};
`
export const wrapperStyles = (theme: ITheme) => css`
  width: 600px;
  height: 378px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.white.DEFAULT};
  border-radius: ${theme.borderRadius[6]};
  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 336px;
    height: 256px;
  }
`

export const innerStyles = (theme: ITheme) => css`
  width: 400px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 272px;
    height: 169px;
  }
`

export const titleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
  padding: ${theme.gap.medium}px 0;
  text-align: center;
  @media (max-width: ${theme.breakpoints.tablet}px) {
    font-size: ${theme.fontSize.small};
    padding: ${theme.gap.xlarge}px 0;
  }
`

export const imageStyles = (theme: ITheme) => css`
  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100px;
    height: 100px;
  }
`
