import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: ${theme.dimensions.leaveOrganizationWidth.DEFAULT}px;
  height: ${theme.dimensions.leaveOrganizationHeight.small}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${theme.dimensions.leaveOrganizationWidth.small}px;
    height: ${theme.dimensions.leaveOrganizationHeight.DEFAULT}px;
  }
`
export const titleStyles = (theme: ITheme) => css`
  font-weight: ${theme.fontWeight.medium};
  margin-bottom: ${theme.gap.small}px;
  font-size: ${theme.fontSize.medium};
  line-height: 155%;
`
export const textStyles = (theme: ITheme) => css`
  line-height: 155%;
  margin-bottom: ${theme.gap.xlarge}px;
`
export const buttonStyles = (theme: ITheme) => css`
  width: ${theme.dimensions.buttonWidth.large}px;
  height: ${theme.dimensions.buttonHeight.xsmall}px;
  font-size: ${theme.fontSize.small};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${theme.dimensions.buttonWidth.xlarge}px;
    height: ${theme.dimensions.buttonHeight.xlarge}px;
    font-size: ${theme.fontSize.medium};
  }
`
