import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const aiLoadingStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

export const animationStyles = () => css`
  width: 235px;
  height: 193px;
  margin-top: 10px;
`

export const loadingTextStyles = (theme: ITheme) => css`
  color: ${theme.colors.primary.DEFAULT};
  font-size: ${theme.fontSize.xlarge};
  font-weight: ${theme.fontWeight.bold};
  margin-top: 100px;
`

export const buttonWrapperStyles = () => css`
  width: 100%;
  margin-top: 60px;
  margin-left: -24px;
`
