import React, { useCallback, useMemo } from 'react'
import { useLanguage, useNotification, useUserApi } from 'src/hooks'
import { ILanguageChanger } from './types'
import { languageChangerStyles } from './styles'
import { Dropdown, IDropdownItem, IDropdownValue } from 'src/lib'
import { LanguageServices, DateTimeServices } from 'src/services'
import { AVAILABLE_LANGUAGES } from 'src/i18n/locales/consts'

export const LanguageChanger: React.FC<ILanguageChanger> = React.memo(
  ({ className, dataAttr }) => {
    const { t, lang, changeLanguage, fallbackLng } = useLanguage()
    const { error } = useNotification()
    const { updateUser } = useUserApi()

    const dropdownItems = useMemo(() => {
      const languages = AVAILABLE_LANGUAGES
      return languages.map((language) => ({
        name: t(`enum.language.${LanguageServices.languageToEnum(language)}`),
        value: language,
      }))
    }, [])

    const onChangeHandler = useCallback((value?: IDropdownItem) => {
      const langValue = value?.value as IDropdownValue as string
      if (!langValue) return

      try {
        changeLanguage(langValue)
        DateTimeServices.moment.locale(langValue)
        updateUser({
          language: LanguageServices.languageToEnum(langValue),
        })
        localStorage.setItem('currentLanguage', langValue)
      } catch {
        error(t('common.informative.error_changing_language'))
        changeLanguage(fallbackLng)
        DateTimeServices.moment.locale(fallbackLng)
        updateUser({
          language: LanguageServices.languageToEnum(fallbackLng),
        })
        localStorage.setItem('currentLanguage', fallbackLng)
      }
    }, [])

    const languagePlaceholder = useMemo(
      () => t(`enum.language.${LanguageServices.languageToEnum(lang)}`),
      [lang],
    )

    return (
      <div css={languageChangerStyles} className={className} {...dataAttr}>
        <Dropdown
          placeholder={languagePlaceholder}
          items={dropdownItems}
          selected={lang}
          onChange={onChangeHandler}
        />
      </div>
    )
  },
)

LanguageChanger.displayName = 'LanguageChanger'
