import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const headerActionsStyles = ({ gap, breakpoints }: ITheme) => css`
  display: flex;
  gap: ${gap.medium}px;
  align-items: center;
  display: none;

  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
  }
`

export const userPanelStyles = (theme: ITheme) => css`
  margin-left: auto;
  @media (min-width: ${theme.breakpoints.tablet}px) {
    display: none;
  }
`
