import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthProvider } from 'src/provider/authProvider'
import { usePostHog } from 'posthog-js/react'
import Gleap from 'gleap'

// TODO: DV-332 - Delete this page

export const LogoutPage: React.FC = React.memo(() => {
  const { token, clearToken } = useAuthProvider()
  const navigate = useNavigate()
  const posthog = usePostHog()

  useEffect(() => {
    posthog.reset()
    Gleap.clearIdentity()
    clearToken()
  }, [])

  useEffect(() => {
    if (!token) {
      navigate('/login', { replace: true })
    }
  }, [token])

  return <div>Logging out...</div>
})
