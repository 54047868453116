import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const orgDetailStyles = ({
  gap,
  colors,
  breakpoints,
  dimensions,
}: ITheme) => css`
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding: ${gap.medium}px;
  background: ${colors.white.DEFAULT};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: ${gap['2xlarge']}px;
    height: calc(100% - ${dimensions.organizationHeaderHeight.DEFAULT}px);
  }

  @media (min-width: ${breakpoints.laptop}px) {
    padding: ${gap.xlarge}px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${gap['2xlarge']}px;
    height: calc(100% - ${dimensions.organizationHeaderHeight.long}px);
  }

  @media (min-width: ${breakpoints.large}px) {
    padding: ${gap['4xlarge']}px;
  }

  @media (min-width: ${breakpoints.xlarge}px) {
    padding: ${gap['4xlarge']}px ${gap['5xlarge']}px;
  }
`

export const orgDetailFormStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  height: auto;
  width: 100%;
`
export const titleStyles = (theme: ITheme) => css`
  margin-bottom: ${theme.gap['xlarge']}px;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
`
export const titleDetailStyles = (theme: ITheme) => css`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
  height: ${theme.dimensions.organizationHeaderHeight.DEFAULT}px;
  margin-bottom: ${theme.gap['xlarge']}px;
`
export const gap = (theme: ITheme) => css`
  margin-bottom: ${theme.gap['xlarge']}px;
`
export const inputStyles = (theme: ITheme) => css`
  margin-bottom: ${theme.gap['xlarge']}px;
  width: ${theme.dimensions.organizationDetailInputWidth.DEFAULT}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${theme.dimensions.organizationDetailInputWidth.small}px;
  }
`
export const firstBlockStyles = (theme: ITheme) => css`
  width: auto;
  height: auto;
  margin-bottom: ${theme.gap['xlarge']}px;
`

export const lineStyles = ({ gap, colors }: ITheme) => css`
  width: 100%;
  height: 1px;
  background-color: ${colors.outline.DEFAULT};
  margin-bottom: ${gap['xlarge']}px;
`

export const buttonStyles = ({ dimensions, breakpoints }: ITheme) => css`
  width: ${dimensions.buttonWidth.small}px;
  height: ${dimensions.buttonHeight.DEFAULT}px;
  @media (min-width: ${breakpoints.tablet}px) {
    width: ${dimensions.buttonWidth.large}px;
    height: ${dimensions.buttonHeight.xlarge}px;
  }
`
