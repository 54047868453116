import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IAiPrompt } from './types'
import {
  aiPromptStyles,
  buttonStyles,
  gapStyles,
  aiSuggestionStyles,
  exampleAreaStyles,
  viewStyles,
  seeExampleButtonStyles,
  inputStyles,
} from './styles'
import {
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  INPUT_VARIANT,
  Icon,
  Input,
  icons,
} from 'src/lib'
import { Steps } from 'src/lib/steps'
import {
  AI_FLOW_STEP,
  RootState,
  setAiFlowStep,
  setPrompt,
  setSeeExample,
} from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  VALIDATION_RULE_TYPES,
  useLanguage,
  useValidation,
  useValidatedText,
} from 'src/hooks'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'
import { pdfNameStyles } from '../styles'

export const AiPrompt: React.FC<IAiPrompt> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const { getAiFlowAudience } = useAiFlowApi()

    const { selectedPrompt, seeExample, activeState, pdfName, pdfImport } =
      useSelector(({ aiFlow }: RootState) => ({
        selectedPrompt: aiFlow.selectedPrompt,
        seeExample: aiFlow.seeExample,
        activeState: aiFlow.currentStep,
        pdfName: aiFlow.pdfFileName,
        pdfImport: aiFlow.pdfImport,
      }))

    const validatedText = useValidatedText(
      () => {
        nextButton()
      },
      selectedPrompt,
      3,
    )

    const [isValid] = useValidation({
      value: selectedPrompt,
      rules: {
        [VALIDATION_RULE_TYPES.MIN]: {
          value: 3,
          text: t('validation.min_char', {
            value: 3,
            name: selectedPrompt,
          }),
        },
      },
    })

    useEffect(() => {
      validatedText
    }, [selectedPrompt])

    const [loadingState, setLoadingState] = useState(false)

    const onTopicChange = useCallback((value: string) => {
      dispatch(setPrompt({ prompt: value }))
    }, [])

    const changeSeeExample = useCallback(() => {
      dispatch(setSeeExample(!seeExample))
    }, [])

    const backButton = useCallback(() => {
      {
        !pdfImport
          ? dispatch(setAiFlowStep(AI_FLOW_STEP.MODAL))
          : dispatch(setAiFlowStep(AI_FLOW_STEP.IMPORT))
      }
    }, [pdfImport])

    const updateSelectedPrompt = useCallback((item: string) => {
      dispatch(setPrompt({ prompt: item }))
    }, [])

    const examplePrompt = useMemo(
      () => [
        'Growth hacking strategies',
        'A SaaS platform that calculates the pricing for freelance projects',
        'Benefits of building a community',
        'Progress report for an SEO campaign',
      ],
      [],
    )

    const nextButton = useCallback(async () => {
      setLoadingState(true)
      const apiResult = await getAiFlowAudience({
        prompt: selectedPrompt,
      })
      if (apiResult) {
        dispatch(setAiFlowStep(AI_FLOW_STEP.AUDIENCE))
        dispatch(setPrompt({ prompt: selectedPrompt }))
      }
      setLoadingState(false)
    }, [selectedPrompt])
    return (
      <div css={aiPromptStyles} className={className} {...dataAttr}>
        <div css={viewStyles(true)}>
          <div css={gapStyles}>
            <Input
              placeholder={t('create_with_ai.prompt_placeholder')}
              value={selectedPrompt}
              clearButton={false}
              onChange={onTopicChange}
              css={inputStyles(selectedPrompt.length > 450)}
              variant={INPUT_VARIANT.GHOST}
              validation={{
                [VALIDATION_RULE_TYPES.MAX]: {
                  value: 450,
                  text: t('validation.max_char', {
                    name: 'common.value',
                    value: 450,
                  }),
                },
              }}
            />
            {!pdfName && <Steps steps={5} current={1} />}
            {activeState == 'pdf prompt' && (
              <div css={pdfNameStyles}>
                <Icon icon={icons.pdf}></Icon>
                {pdfName}
              </div>
            )}
            <div css={seeExampleButtonStyles} onClick={changeSeeExample}>
              {!seeExample
                ? t('create_with_ai.see_examples')
                : t('create_with_ai.hide_examples')}
            </div>
          </div>
          {seeExample && (
            <div css={exampleAreaStyles(true, pdfImport)}>
              <div css={aiPromptStyles}>
                {examplePrompt.map((item, index) => (
                  <div
                    css={aiSuggestionStyles(selectedPrompt == item)}
                    key={index}
                    onClick={() => updateSelectedPrompt(item)}
                  >
                    <div>{item}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div css={buttonStyles}>
          <Button
            type={BUTTON_TYPE.GHOST}
            text={t('common.actions.back')}
            icon={icons.chevron_left}
            isLink
            onClick={backButton}
          />
          <Button
            text={t('common.actions.lets_start')}
            theme={BUTTON_THEME.GRADIENT}
            onClick={nextButton}
            disabled={!isValid || selectedPrompt.length > 450}
            isLoading={loadingState}
          />
        </div>
      </div>
    )
  },
)

AiPrompt.displayName = 'AiPrompt'
