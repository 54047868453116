import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const contentStyles = (theme: ITheme) => css`
  max-width: 100vw;
  height: calc(100vh - ${theme.dimensions.headerHeight.DEFAULT}px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${theme.colors.background.DEFAULT};
`

export const innerStyles = (theme: ITheme) => css`
  margin: ${theme.spacing['2xlarge']} auto;
`
