import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IListCard } from './types'
import { wrapperStyles } from './styles'

export const ListCard: React.FC<IListCard> = React.memo(
  ({ items, className, dataAttr }) => {
    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        {items?.map(({ text, value }) => {
          return (
            <div className="item" key={uuidv4()}>
              <div className="text">{text}</div>
              <div className="value">{value}</div>
            </div>
          )
        })}
      </div>
    )
  },
)
