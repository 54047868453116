import React, { useState } from 'react'

import { IEditAiAssistant } from './types'
import {
  animationStyles,
  answerStyles,
  createQaStyles,
  editAiAssistantStyles,
  paddingStyles,
  qaStyles,
  questionStyles,
  tipContentStyles,
  tipStyles,
  tipTitleStyles,
} from './styles'
import { BUTTON_THEME, Button, TABS_THEME, Tabs, Tooltip, icons } from 'src/lib'
import { useAiApi, useLanguage } from 'src/hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import tipsImage from 'src/assets/images/tips.svg'
import qaImage from 'src/assets/images/qa.svg'
import qaAnimation from 'src/assets/images/qa.gif'
import tipsAnimation from 'src/assets/images/tips.gif'

export const EditAiAssistant: React.FC<IEditAiAssistant> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()

    const { generateTips, generateQA } = useAiApi()

    const { deckId, deckStatus, workspaceId, tips, qa } = useSelector(
      ({ edit, workspace }: RootState) => ({
        deckId: edit.activeDeck.data?.deck?.id,
        deckStatus: edit.activeDeck.data?.deck?.deckPrompt,
        tips: edit.tips,
        qa: edit.qa,
        workspaceId: workspace.id,
        sessionId: edit.activeDeck.data?.deckData?.sessionId,
      }),
    )

    const [activeTab, setActiveTab] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const createQA = async () => {
      setIsLoading(true)
      await generateQA({ deckId: deckId!, organizationId: workspaceId })
      setIsLoading(false)
    }

    const createTips = async () => {
      setIsLoading(true)
      await generateTips({ deckId: deckId!, organizationId: workspaceId })
      setIsLoading(false)
    }
    return (
      <div css={editAiAssistantStyles} className={className} {...dataAttr}>
        <div css={paddingStyles}>
          <Tabs
            theme={TABS_THEME.DARK}
            tabs={[
              t('edit.panel.menu_tabs.tips'),
              t('edit.panel.menu_tabs.qa'),
            ]}
            activeTab={activeTab}
            onChange={setActiveTab}
          />

          {((activeTab == 0 && !tips?.length) ||
            (activeTab == 1 && !qa?.length)) && (
            <div css={createQaStyles}>
              {!isLoading && <img src={activeTab ? qaImage : tipsImage} />}
              {isLoading && (
                <img
                  src={activeTab ? qaAnimation : tipsAnimation}
                  css={animationStyles}
                />
              )}
              <div>
                {activeTab
                  ? t('edit.ai.create_qa_text')
                  : t('edit.ai.create_tip_text')}
              </div>

              {!deckStatus && (
                <Tooltip width={300} text={t('edit.ai.create_tips_hover')}>
                  <div>
                    <Button
                      text={
                        activeTab == 0
                          ? t('edit.ai.create_tip_button')
                          : t('edit.ai.create_qa_button')
                      }
                      icon={icons.sparkling}
                      theme={BUTTON_THEME.GRADIENT}
                      onClick={
                        activeTab == 0 ? () => createTips() : () => createQA()
                      }
                      disabled={!deckStatus}
                      isLoading={isLoading}
                    />
                  </div>
                </Tooltip>
              )}

              {deckStatus && (
                <Button
                  text={
                    activeTab == 0
                      ? t('edit.ai.create_tip_button')
                      : t('edit.ai.create_qa_button')
                  }
                  icon={icons.sparkling}
                  theme={BUTTON_THEME.GRADIENT}
                  onClick={
                    activeTab == 0 ? () => createTips() : () => createQA()
                  }
                  disabled={!deckStatus}
                  isLoading={isLoading}
                />
              )}
            </div>
          )}
        </div>
        {activeTab == 1 &&
          qa?.length != undefined &&
          qa?.map((item, index) => (
            <div css={paddingStyles} key={index}>
              <div css={qaStyles}>
                <div css={questionStyles}>
                  {index + 1}.{' ' + item.question}
                </div>
                <div css={answerStyles}>{item.answer}</div>
              </div>
            </div>
          ))}

        {activeTab == 0 && tips?.length != undefined && (
          <div css={tipStyles}>
            {activeTab == 0 &&
              tips?.map((item, index) => (
                <div key={index} css={createQaStyles}>
                  <div css={tipTitleStyles}>
                    {index + 1}.{' ' + item.title}
                  </div>
                  {item.deckTips.map((tips, index) => (
                    <div key={index} css={paddingStyles}>
                      <div css={questionStyles}>{tips.title}</div>
                      <div css={tipContentStyles}>{tips.content}</div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        )}
      </div>
    )
  },
)

EditAiAssistant.displayName = 'EditAiAssistant'
