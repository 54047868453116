import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  width: 100%;
  background: ${theme.gradient.PURPLE};
  height: 67px;
  display: flex;
  align-items: center;
  padding: 0 ${theme.spacing.small};
  border-radius: ${theme.borderRadius[10]};
  gap: ${theme.spacing.medium};
  box-shadow: ${theme.boxShadow[5]};
`

export const textAreaStyles = (theme: ITheme) => css`
  color: ${theme.colors.white.DEFAULT};
  font-weight: ${theme.fontWeight.medium};
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;

  .title {
    font-size: ${theme.fontSize.large};
    font-weight: ${theme.fontWeight.medium};
    margin-bottom: ${theme.spacing['3xsmall']};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .subtitle {
    font-size: ${theme.fontSize.small};
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const planCardStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  padding: ${theme.spacing['2xsmall']} ${theme.spacing.small};
  border-radius: ${theme.borderRadius[2]};
  font-size: ${theme.fontSize.xsmall};
  color: ${theme.colors.text.DEFAULT};
  box-shadow: ${theme.boxShadow[3]};
  text-transform: uppercase;
`
