import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const headerLogoStyles = css`
  display: flex;
  cursor: pointer;
`

export const decktopusStyles = (theme: ITheme) => css`
  display: none;
  @media (min-width: ${theme.breakpoints.tablet}px) {
    display: block;
  }
`

export const decktopusLogoStyles = (theme: ITheme) => css`
  @media (min-width: ${theme.breakpoints.tablet}px) {
    display: none;
  }
`
