import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import {
  DashboardPage,
  LoginPage,
  LogoutPage,
  ResetPasswordPage,
  SignUpPage,
  OrganizationPage,
  TemplatesPage,
  OrganizationMembers,
  OrganizationDetails,
  EditPage,
  ScratchPage,
  PreviewPage,
  NotSupportedPage,
  UserClassifyPage,
  PaymentPage,
  AdminPage,
} from 'src/pages'
import {
  ProtectedPageLayout,
  MainLayout,
  InitPageLayout,
  DeckPageLayout,
  ExpiredPageLayout,
  UnprotectedPageLayout,
} from 'src/layouts'
import { DECK_PAGE_MODE } from 'src/layouts/deck-page-layout/types'

const urlParams = new URLSearchParams(window.location.search)

const aiParam = urlParams.get('ai') || null
const planParam = urlParams.get('plan') || null

const paymentParam = urlParams.get('payment') || null
const payloadParam = urlParams.get('payload') || null

const templateParam = urlParams.get('templateDeckId') || null

let paymentParams: string

if (aiParam) {
  localStorage.setItem('landingPrompt', aiParam)
}
if (planParam) {
  localStorage.setItem('plan', planParam)
}
if (templateParam) {
  localStorage.setItem('templateDeckId', templateParam)
}

if (paymentParam) {
  paymentParams = '?payment=' + paymentParam
  if (payloadParam) {
    paymentParams += '&payload=' + payloadParam
  }
}

const Routes = () => {
  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: '/',
      element: <UnprotectedPageLayout />,
      children: [
        {
          path: '/',
          element: (
            <Navigate to={`/dashboard${paymentParams ? paymentParams : ''}`} />
          ),
        },
        {
          path: '/sign-up',
          element: <SignUpPage />,
        },
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/reset',
          element: <ResetPasswordPage />,
        },
        {
          path: '/*',
          element: <Navigate to="/dashboard" />,
        },
        {
          path: '/share',
          element: <DeckPageLayout mode={DECK_PAGE_MODE.PREVIEW} />,
          children: [
            {
              path: '/share/:id/:slide?',
              element: <PreviewPage />,
            },
          ],
        },
        {
          path: '/expired-page',
          element: <ExpiredPageLayout />,
        },
        {
          path: '/payment',
          element: <PaymentPage />,
        },
      ],
    },
  ]

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <ProtectedPageLayout />,
      children: [
        {
          path: '/',
          element: <MainLayout />,
          children: [
            {
              path: '/dashboard',
              element: <DashboardPage />,
            },
            {
              path: '/logout',
              element: <LogoutPage />,
            },
            {
              path: '/organization',
              element: <OrganizationPage />,
              children: [
                {
                  path: '/organization',
                  element: <OrganizationMembers />,
                },
                {
                  path: '/organization/details',
                  element: <OrganizationDetails />,
                },
              ],
            },
            {
              path: '/admin',
              element: <AdminPage />,
            },
          ],
        },
        {
          path: '/user-classify',
          element: <UserClassifyPage />,
        },
        {
          path: '/init',
          element: <InitPageLayout />,
          children: [
            {
              path: '/init/template',
              element: <TemplatesPage />,
            },
            {
              path: '/init/scratch',
              element: <ScratchPage />,
            },
            {
              path: '/init/scratch/:category',
              element: <ScratchPage />,
            },
          ],
        },
        {
          path: '/deck',
          element: <DeckPageLayout />,
          children: [
            {
              path: '/deck/:id/:slide?',
              element: <EditPage />,
            },
          ],
        },
        {
          path: '/not-supported-page',
          element: <NotSupportedPage />,
        },
        {
          path: '/expired-page',
          element: <ExpiredPageLayout />,
        },
        {
          path: '/preview',
          element: <DeckPageLayout mode={DECK_PAGE_MODE.PREVIEW} />,
          children: [
            {
              path: '/preview/:id/:slide?',
              element: <PreviewPage />,
            },
          ],
        },
        {
          path: '/export',
          element: <DeckPageLayout mode={DECK_PAGE_MODE.EXPORT} />,
          children: [
            {
              path: '/export/:id/:slide?',
              element: <PreviewPage mode={DECK_PAGE_MODE.EXPORT} />,
            },
          ],
        },
      ],
    },
  ]

  // Define routes accessible only to authenticated users
  const routesForCustomDomains = [
    {
      path: '/',
      // TODO Use 404 Page not found layout here when its ready
      element: <Navigate to={'/expired-page'} />,
    },
    {
      path: '/expired-page',
      // TODO Use 404 Page not found layout here when its ready
      element: <ExpiredPageLayout />,
    },
    {
      path: '/:id',
      element: (
        <Navigate to={`/share/${window.location.pathname.split('/')[1]}`} />
      ),
    },
    {
      path: '/share/:id',
      element: <DeckPageLayout mode={DECK_PAGE_MODE.PREVIEW} />,
      children: [
        {
          path: '/share/:id/:slide?',
          element: <PreviewPage />,
        },
      ],
    },
  ]

  const isCustomDomain =
    !window.location.hostname.includes('.decktopus.com') &&
    !window.location.host.includes('localhost')

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...(isCustomDomain ? [] : routesForPublic),
    ...(isCustomDomain ? [] : routesForAuthenticatedOnly),
    ...(isCustomDomain ? routesForCustomDomains : []),
  ])

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />
}

export default Routes
