import React, { useMemo } from 'react'
import { iconStyles, listMediaStyles } from './styles'
import { IItemMedia } from './types'
import { ComponentRadiusTypes, ListItemImageTypes } from 'src/types/api/enums'
import { SvgMasked } from 'src/lib'
import { useFlags, useGradientColor } from 'src/hooks'

export const ItemMedia: React.FC<IItemMedia> = React.memo(
  ({
    image,
    width,
    fontSize = '1em',
    height,
    color,
    background,
    frameType = ComponentRadiusTypes.ROUNDED,
    caption,
    captionSize,
    fitCrop,
    onClick,
  }) => {
    const flags = useFlags()
    if (!image?.url) {
      return null
    }

    const { asBackground } = useGradientColor(background)

    const iconCss = useMemo(() => {
      if (frameType) {
        if (frameType === ComponentRadiusTypes.OUTLINE) {
          return {
            border: `0.1em solid ${background?.colors[0]}`,
          }
        } else if (frameType !== ComponentRadiusTypes.FLAT) {
          return asBackground
        }
      }
    }, [frameType, background, asBackground])

    if (image.type === ListItemImageTypes.ICON) {
      return (
        <div
          css={iconStyles({ radiusType: frameType })}
          style={{
            fontSize: fontSize,
            ...iconCss,
          }}
        >
          <SvgMasked url={image.url} gradientColor={color} />
        </div>
      )
    }

    return flags.FE_277_IMAGE_GRID_IMPROVEMENTS ? (
      <div css={listMediaStyles} style={{ width, height }} onClick={onClick}>
        <div
          className="list-media-item-inner"
          style={{
            backgroundImage: `url(${image.url})`,
            backgroundSize: fitCrop ? 'contain' : 'cover',
          }}
        >
          {caption && (
            <div
              className="list-media-caption"
              style={{ fontSize: captionSize }}
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          )}
        </div>
      </div>
    ) : (
      <div
        css={listMediaStyles}
        style={{
          backgroundImage: `url(${image.url})`,
          width,
          height,
          backgroundSize: height ? '100% 100%' : 'contain',
        }}
        onClick={onClick}
      >
        {caption && (
          <div
            className="list-media-caption"
            style={{ fontSize: captionSize }}
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        )}
      </div>
    )
  },
)
