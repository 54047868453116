import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles =
  (upgradeModal?: boolean) =>
  ({ gradient, dimensions, spacing, gap, colors }: ITheme) => css`
    background: ${gradient.PURPLE};
    ${upgradeModal && `width:100%`};
    padding: 1px;
    overflow: hidden;
    border-radius: ${dimensions.formElementHeight.DEFAULT +
    gap['3xsmall'] * 2}px;
    display: inline-flex;
    .border-area {
      border-radius: ${dimensions.formElementHeight.DEFAULT +
      gap['3xsmall'] * 2 -
      1}px;
      background: ${colors.white.DEFAULT};
      height: 100%;
      display: flex;
      align-items: center;
      padding: ${spacing['3xsmall']};
      width: 100%;
    }
  `
interface IItemStyles {
  isSelected?: boolean
}
export const itemStyles =
  ({ isSelected }: IItemStyles) =>
  ({
    gradient,
    colors,
    fontWeight,
    boxShadow,
    dimensions,
    spacing,
  }: ITheme) => css`
    background: ${isSelected ? gradient.PURPLE : 'transparent'};
    color: ${isSelected ? colors.white.DEFAULT : colors.primary.DEFAULT};
    width: inherit;
    justify-content: center;
    font-weight: ${fontWeight.medium};
    box-shadow: ${isSelected ? boxShadow[2] : 'none'};
    cursor: pointer;
    border-radius: ${dimensions.formElementHeight.DEFAULT / 2}px;
    height: ${dimensions.formElementHeight.DEFAULT}px;
    padding: 0 ${spacing.xlarge};
    display: flex;
    align-items: center;
  `
