import React, { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePlanApi, useUserApi } from 'src/hooks'
import { PlanNames, PlanPeriods } from 'src/types/api/enums'

export const PaymentPage: React.FC = React.memo(() => {
  const [searchParams] = useSearchParams()
  const { getPortalUrl } = useUserApi()
  const { purchasePlan } = usePlanApi()

  useEffect(() => {
    const planName = searchParams.get('name') as PlanNames
    const planPeriod = searchParams.get('period') as PlanPeriods
    const seatSize = searchParams.get('size')
    const subscriptionSettings = searchParams.get('subscriptionSettings')
    if (subscriptionSettings) {
      subscriptionSettingsHandler()
      return
    }
    if (planName && planPeriod && seatSize) {
      updatePlanHandler()
      return
    }
  }, [searchParams])

  const subscriptionSettingsHandler = useCallback(async () => {
    const res = await getPortalUrl()
    if (res) window.location.href = res
  }, [])

  const updatePlanHandler = useCallback(async () => {
    const planName = searchParams.get('name') as PlanNames
    const planPeriod = searchParams.get('period') as PlanPeriods
    const seatSize = searchParams.get('size')

    const res = await purchasePlan({
      planName,
      planPeriod,
      seatSize: Number(seatSize),
      isPreview: false,
    })
    if (res.checkoutSessionUrl) window.location.href = res.checkoutSessionUrl
    else {
      localStorage.removeItem('payment-awaits')
      window.close()
    }
  }, [searchParams])

  return <></>
})

PaymentPage.displayName = 'Payment page'
