import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, setActiveFolderId, setDecksQuery } from 'src/store'
import { useLanguage, useFolderApi } from 'src/hooks'
import { Loader } from 'src/lib'
import {
  DashboardFolder,
  DashboardNewFolder,
  DashboardFolderSharedWithMe,
} from './components'
import { areaStyles, dashboardFoldersStyles, titleStyles } from './styles'
import { IDashboardFolders } from './types'
import { useSearchParams } from 'react-router-dom'
import { FOLDER_SHARED_WITH_ME_ID } from './consts'

export const DashboardFolders: React.FC<IDashboardFolders> = React.memo(() => {
  const { t } = useLanguage()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isLoading, getFolders } = useFolderApi()
  const dispatch = useDispatch()
  const {
    folders,
    activeFolderId,
    workspaceId,
    workspaceFiltering,
    decksQuery,
  } = useSelector(({ decks, folders, workspace }: RootState) => ({
    folders: folders.list,
    activeFolderId: folders.activeFolderId,
    workspaceId: workspace.id,
    workspaceFiltering: workspace.filtering,
    decksQuery: decks.decksQuery,
  }))

  useEffect(() => {
    getFolders()
    const folderId = folders.find(
      (folder) => folder.id.toString() === searchParams.get('folderId'),
    )
    if (!folderId && decksQuery.folderId) {
      setSearchParams({ folderId: decksQuery.folderId.toString() })
    }
    if (!folderId && !decksQuery.folderId) {
      dispatch(setActiveFolderId(-1))
      searchParams.delete('folderId')
      setSearchParams(searchParams)
    }
  }, [workspaceId, workspaceFiltering])

  const folderClickHandler = useCallback(
    (folderId: number) => {
      if (folderId === activeFolderId) {
        dispatch(setActiveFolderId(-1))
        searchParams.delete('folderId')
        setSearchParams({})
        setDecksQuery({ folderId: undefined, sharedWithMe: false })
      } else {
        dispatch(setActiveFolderId(folderId))
        if (folderId === FOLDER_SHARED_WITH_ME_ID) {
          searchParams.set('sharedWithMe', 'true')
          setSearchParams({
            sharedWithMe: 'true',
          })
        } else {
          searchParams.set('folderId', folderId.toString())
          setSearchParams({
            folderId: folderId.toString(),
          })
        }

        setDecksQuery({
          folderId:
            folderId !== FOLDER_SHARED_WITH_ME_ID ? folderId : undefined,
          sharedWithMe: folderId !== FOLDER_SHARED_WITH_ME_ID ? false : true,
        })
      }
    },
    [activeFolderId, decksQuery],
  )

  return (
    <div css={dashboardFoldersStyles}>
      <div css={titleStyles}>{t('dashboard.folder.folder')}</div>
      <div css={areaStyles}>
        <DashboardNewFolder />
        {!workspaceId || (workspaceId && workspaceFiltering === 'personal') ? (
          <DashboardFolderSharedWithMe
            key={FOLDER_SHARED_WITH_ME_ID}
            isActive={activeFolderId === FOLDER_SHARED_WITH_ME_ID}
            name={t('dashboard.shared_with_me')}
            id={FOLDER_SHARED_WITH_ME_ID}
            onClick={() => folderClickHandler(FOLDER_SHARED_WITH_ME_ID)}
          />
        ) : null}
        {folders?.map((folder) => (
          <DashboardFolder
            key={folder.id}
            isActive={activeFolderId === folder.id}
            name={folder.name}
            id={folder.id}
            onClick={() => folderClickHandler(folder.id)}
          />
        ))}
        {isLoading && !folders.length && (
          <div className="area-loading">
            <Loader size={6} />
          </div>
        )}
      </div>
    </div>
  )
})

DashboardFolder.displayName = 'DashboardFolders'
