import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { ComponentSchema } from 'src/types/api/responseObjects'

export const useSelectedComponents = () => {
  const {
    selectedComponents,
    activeSlideData,
    activeSlideDataId,
    activeSlideId,
  } = useSelector(({ canvas, edit }: RootState) => ({
    selectedComponents: canvas.selectedComponents,
    activeSlideData: edit.activeSlideData,
    activeSlideDataId: edit.activeSlideDataID,
    activeSlideId: edit.activeSlideID,
  }))

  const selectedIds = useMemo(
    () => selectedComponents.map(({ id }) => id),
    [selectedComponents],
  )
  const selectedTempIds = useMemo(
    () => selectedComponents.map(({ tempId }) => tempId),
    [selectedComponents],
  )

  const getComponents = useCallback((): {
    components?: ComponentSchema[]
    slideId?: number
  } => {
    const targetSlideData = activeSlideData.data?.find(
      ({ id }) => id === activeSlideDataId,
    )

    const targetComponents = targetSlideData?.slideDataComponents
      .filter(({ component }) =>
        component.id
          ? selectedIds.includes(component.id)
          : selectedTempIds.includes(component.tempId || undefined),
      )
      .map(({ component }) => component)

    return {
      components: targetComponents,
      slideId: targetComponents?.length ? activeSlideId : undefined,
    }
  }, [
    selectedComponents,
    activeSlideData,
    activeSlideDataId,
    selectedIds,
    selectedTempIds,
    activeSlideId,
  ])

  return { getComponents }
}
