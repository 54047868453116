import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const orgHeaderStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${theme.dimensions.organizationHeaderHeight.DEFAULT}px;
  padding: 0 ${theme.gap['2xlarge']}px;
  background-color: ${theme.colors.primary.DEFAULT};
  color: ${theme.colors.white.DEFAULT};

  @media (min-width: ${theme.breakpoints.laptop}px) {
    padding: 0 ${theme.gap.xlarge}px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    height: ${theme.dimensions.organizationHeaderHeight.long}px;
    padding: 0 ${theme.gap['2xlarge']}px;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    padding: 0 ${theme.gap['4xlarge']}px;
  }

  @media (min-width: ${theme.breakpoints.xlarge}px) {
    padding: 0 ${theme.gap['5xlarge']}px;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.gap.small}px;
  }
`
export const orgHeaderIconStyles = ({ breakpoints }: ITheme) => css`
  display: block;

  @media (min-width: ${breakpoints.laptop}px) {
    display: none;
  }
`

export const orgHeaderTitle = ({ fontSize, fontWeight }: ITheme) => css`
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
`

export const orgHeaderAddMembers = ({
  breakpoints,
  fontSize,
  fontWeight,
  gap,
}: ITheme) => css`
  padding-top: ${gap['4xsmall']}px;
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
  text-decoration: underline;
  user-select: none;
  display: none;

  @media (min-width: ${breakpoints.tablet}px) {
    display: block;
  }
`
export const orgHeaderAddMembersWrapperStyles = ({ gap }: ITheme) => css`
  cursor: pointer;
  gap: ${gap['2xsmall']}px !important;
`
