import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'src/store'
import {
  Avatar,
  BUTTON_TYPE,
  Button,
  Icon,
  MODAL_POSITION,
  Modal,
  icons,
} from 'src/lib'
import { useTheme } from 'src/theme'

import { IHeaderActions } from './types'
import { headerActionsStyles, userPanelStyles } from './styles'
import { SettingsPanel } from 'src/components/settings-panel'
import { ShareView } from 'src/components/share-view'
import { UpgradeModalManager } from 'src/components/upgrade-modals'
import { useLocation, useLanguage, useFlags } from 'src/hooks'
import { sendEvent } from 'src/plugins/google/gtag'
import { GTAG_EVENTS, ACTION_CLICKS } from 'src/plugins/google/consts'
import { PaymentConfirmation } from 'src/components/payment-confirmation/payment-confirmation'

export const HeaderActions: React.FC<IHeaderActions> = React.memo(
  ({ className, dataAttr }) => {
    const { colors } = useTheme()
    const { t } = useLanguage()

    const { path } = useLocation()

    const [isSideMenuOpen, setSideMenuOpen] = useState(false)

    const [shareLinkMenuOpen, setShareLinkMenuOpen] = useState(false)

    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
    const flags = useFlags()

    const {
      userData,
      deckId,
      deckShareCode,
      userPlan,
      workspaceId,
      isPaymentConfirmationModalOpen,
    } = useSelector(({ user, edit, workspace, plan }: RootState) => ({
      userData: user.data,
      deckId: edit.activeDeck.data?.deck?.id,
      deckShareCode: edit.activeDeck.data?.deck?.deckShare?.shortCode.value,
      userPlan: user.data?.activeUserPlan,
      workspaceId: workspace.id,
      isPaymentConfirmationModalOpen: plan.isPaymentConfirmationModalOpen,
    }))

    const isShareVisible = useMemo(
      () => path.includes('deck') && deckId,
      [path, deckId],
    )

    return (
      <>
        <div css={headerActionsStyles} className={className} {...dataAttr}>
          {!userPlan && !workspaceId && (
            <Button
              text={t('subscriptions.choose_plan')}
              type={BUTTON_TYPE.REVERSE}
              onClick={() => {
                sendEvent(GTAG_EVENTS.PURCHASE_INTENT, {
                  user_email: userData?.email,
                  purchase_intent_method: ACTION_CLICKS.CHOOSE_PLAN_CLICK,
                })
                setIsUpgradeModalOpen(true)
              }}
              icon={icons.approval}
            />
          )}
          {isShareVisible && (
            <Icon
              icon={icons.share}
              color={colors.elements.DEFAULT}
              size={24}
              onClick={() => setShareLinkMenuOpen(true)}
            />
          )}
          <Avatar
            size={30}
            data={[
              { image: userData?.picture, name: userData?.fullName || '' },
            ]}
            onClick={() => setSideMenuOpen(true)}
          />
        </div>
        <Icon
          icon={icons.menu}
          color={colors.primary.DEFAULT}
          size={24}
          css={userPanelStyles}
          onClick={() => setSideMenuOpen(true)}
        />
        <Modal
          isOpen={isSideMenuOpen}
          onClose={() => setSideMenuOpen(false)}
          position={MODAL_POSITION.RIGHT}
        >
          <SettingsPanel onCloseClick={() => setSideMenuOpen(false)} />
        </Modal>
        {isUpgradeModalOpen && (
          <UpgradeModalManager
            callback={() => setIsUpgradeModalOpen(false)}
            context={ACTION_CLICKS.CHOOSE_PLAN_CLICK}
            isOpen={isUpgradeModalOpen}
            onClose={() => setIsUpgradeModalOpen(false)}
          />
        )}

        <Modal
          isOpen={shareLinkMenuOpen}
          onClose={() => setShareLinkMenuOpen(false)}
        >
          {shareLinkMenuOpen ? (
            <ShareView
              deckId={deckId}
              onClickClose={() => setShareLinkMenuOpen(false)}
              deckShareCode={deckShareCode}
            />
          ) : (
            <></>
          )}
        </Modal>

        {flags.FE_450_PAYMENT_CONFIRMATION &&
          isPaymentConfirmationModalOpen && <PaymentConfirmation />}
      </>
    )
  },
)

HeaderActions.displayName = 'HeaderActions'
