import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editNotesStyles = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.gap.medium}px;
  flex-direction: column;
  margin-top: ${theme.gap.xlarge}px;
  height: -webkit-fill-available;
  padding-bottom: ${theme.gap.xlarge}px;
  height: calc(100% - ${theme.gap.medium}px);
`
export const paddingStyles = (theme: ITheme) => css`
  padding: 0 ${theme.gap.xlarge}px;
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.medium}px;
  height: 100%;
}`

export const noteStyles = (theme: ITheme) => css`
  height: auto;
  background: ${theme.colors.dark.DEFAULT};
  height: 100%;
`
