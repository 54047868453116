import React, { useCallback, useMemo } from 'react'
import { decorItem, editSlideStyles } from './styles'
import { PropColor, PropSection } from '../edit-properties/props'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'
import { useDispatch } from 'react-redux'
import { slideBackground, slideDecor } from 'src/store'
import {
  useActiveColorMap,
  useActiveSlideData,
  useActiveSwap,
  useActiveThemeColors,
  useActiveThemeId,
  useFlags,
  useLanguage,
} from 'src/hooks'
import { decorConfig } from 'src/config'
import { SvgDecor, TOOLTIP_PLACE, Tooltip } from 'src/lib'
import { decorMap } from 'src/assets/decors'
import { SVGTypes } from 'src/types/api/enums'
import { decorPathFinder } from 'src/services/decorPathFinder'
import { colorVariableToCssVariable } from 'src/services/colorServices'

export const EditSlide: React.FC = React.memo(() => {
  const flags = useFlags()
  const { t } = useLanguage()
  const dispatch = useDispatch()
  const currentSlideData = useActiveSlideData()
  const activeColorMap = useActiveColorMap({})
  const isSwapped = useActiveSwap()
  const activeThemeColors = useActiveThemeColors()
  const activeThemeId = useActiveThemeId()

  const activeColorFromMap = useMemo<
    GradientLikeColorSchema | undefined
  >(() => {
    return activeColorMap?.background?.[isSwapped ? 'swap' : 'default']
      ? {
          colors: activeColorMap?.background?.[
            isSwapped ? 'swap' : 'default'
          ].colors.map((val) =>
            ['first', 'second', 'third', 'fourth', 'wht', 'blck'].includes(val)
              ? `var(--${val})`
              : val,
          ),
          rotation:
            activeColorMap?.background?.[isSwapped ? 'swap' : 'default']
              .rotation,
        }
      : undefined
  }, [activeColorMap?.background, isSwapped])

  const selectedColor: GradientLikeColorSchema = useMemo(() => {
    return (
      currentSlideData?.background ||
      activeColorFromMap || { colors: ['#FFFFFF'] }
    )
  }, [currentSlideData, activeColorFromMap])

  const handleOnColorChange = useCallback(
    (newColor: GradientLikeColorSchema) => {
      dispatch(slideBackground(newColor))
    },
    [],
  )

  const handleDecorChange = useCallback((newSvg: SVGTypes) => {
    dispatch(slideDecor(newSvg))
  }, [])

  const decorSvgs = useMemo(() => {
    if (activeThemeId) {
      const themeConfig = decorConfig.svgMapException[activeThemeId]
      const mergedConfig = {
        ...decorConfig.defaults,
        ...themeConfig,
      }

      return Object.entries(mergedConfig).reduce((a, c) => {
        if (!c[1].path) {
          return a
        }
        return { ...a, [c[0]]: c[1] }
      }, {})
    }

    return []
  }, [activeThemeId])

  const decorationMap = useMemo(() => {
    return {
      first: colorVariableToCssVariable({
        value:
          (activeColorMap?.decorations &&
            activeColorMap?.decorations[0]?.[isSwapped ? 'swap' : 'default']
              ?.colors?.[0]) ??
          activeThemeColors?.first,
      }),
      second: colorVariableToCssVariable({
        value:
          (activeColorMap?.decorations &&
            activeColorMap?.decorations[1]?.[isSwapped ? 'swap' : 'default']
              ?.colors?.[0]) ??
          activeThemeColors?.second,
      }),
      third: colorVariableToCssVariable({
        value:
          (activeColorMap?.decorations &&
            activeColorMap?.decorations[2]?.[isSwapped ? 'swap' : 'default']
              ?.colors?.[0]) ??
          activeThemeColors?.third,
      }),
      fourth: colorVariableToCssVariable({
        value:
          (activeColorMap?.decorations &&
            activeColorMap?.decorations[3]?.[isSwapped ? 'swap' : 'default']
              ?.colors?.[0]) ??
          activeThemeColors?.fourth,
      }),
    }
  }, [activeColorMap?.decorations, activeThemeColors, isSwapped])

  const selectedSvg = useMemo(() => {
    return decorPathFinder({
      svgType: currentSlideData?.svgType,
      themeId: activeThemeId,
    })
  }, [activeThemeId, currentSlideData?.svgType])

  // TODO: fix type any
  return (
    <div css={editSlideStyles}>
      <PropSection title={t('edit.preferences.background_color')}>
        <div className="grid">
          {currentSlideData?.svgType ? (
            <PropColor color={selectedColor} onChange={handleOnColorChange} />
          ) : (
            <Tooltip
              text={t('edit.preferences.background_tooltip')}
              place={TOOLTIP_PLACE.RIGHT}
            >
              <div className="c-1">
                <PropColor
                  disabled
                  color={selectedColor}
                  onChange={handleOnColorChange}
                />
              </div>
            </Tooltip>
          )}
        </div>
      </PropSection>
      {flags.FE_SVG_DECOR && (
        <PropSection title={t('edit.preferences.background_decor')}>
          <div className="grid">
            {Object.entries(decorSvgs).map(([key, value]: any) => {
              return (
                <div key={key} className="c-2">
                  <div
                    css={decorItem({ selected: selectedSvg === key })}
                    onClick={() => handleDecorChange(key)}
                  >
                    <SvgDecor
                      svgString={
                        activeThemeId?.toString()
                          ? decorMap[activeThemeId?.toString()][
                              value.path as SVGTypes
                            ]
                          : ''
                      }
                      colorMap={{
                        ...decorationMap,
                        bg: selectedColor,
                      }}
                      width="100%"
                      height="100%"
                      alphaIndicator={true}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </PropSection>
      )}
    </div>
  )
})
