import { css } from '@emotion/react'

export const mediaButtonsStyles = css``

export const uploadButtonStyles = css`
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }
`
