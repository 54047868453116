import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const titleStyles = (theme: ITheme) => css`
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.colors.text.DEFAULT};
  margin-bottom: ${theme.spacing.large};
`

export const areaStyles = (theme: ITheme) => css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.large};
`
