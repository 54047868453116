import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const fontListWrapperStyles = ({
  breakpoints,
  fontSize,
  fontWeight,
  spacing,
}: ITheme) => css`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.medium};

  & > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & > .selected-font > .box {
    height: 60px;
  }

  & .style-item-name {
    width: 80px;
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.small};

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: ${fontSize.medium};
      width: 103px;
    }
  }

  & .style-item-change {
    cursor: pointer;
    text-decoration: underline;
    font-size: ${fontSize.small};
    text-align: right;
  }

  & .font-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: ${spacing.small};
  }

  & .upload-font {
    text-decoration: underline;
    cursor: pointer;
    font-size: ${fontSize.small};
  }
`
