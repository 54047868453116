import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ListTypes } from 'src/types/api/enums'

export const listPropsStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${gap.xsmall}px;
  padding-bottom: ${gap['8xlarge']}px;
`

interface IListItemStyles {
  isExpanded?: boolean
  type?: ListTypes
  isLogo?: boolean
  isGrid?: boolean
}
export const listItemStyles =
  ({ isExpanded }: IListItemStyles) =>
  ({ colors, gap, borderRadius }: ITheme) => css`
    background: ${colors.dark.DEFAULT};
    padding: ${gap.small}px;
    border: 1px solid ${isExpanded ? colors.primary.DEFAULT : 'transparent'};
    overflow: hidden;
    border-radius: ${borderRadius[4]};
    transition: background 0.3s;
    ${!isExpanded &&
    `
			cursor: pointer;
			&:hover {
				background: ${colors.dark[90]};
			}
		`}
  `

export const detailStyles =
  ({ isExpanded, type, isLogo, isGrid }: IListItemStyles) =>
  ({ gap }: ITheme) => css`
    display: flex;
    flex-direction: column;
    gap: ${gap.xsmall}px;
    max-height: ${isExpanded ? 'none' : '0 '};
    overflow: hidden;
    transform: translateZ(0);
    transition: max-height 200ms;
    margin-bottom: ${isExpanded ? `${gap.xsmall}px` : 0};

    &.enter {
      max-height: 0;
      margin-bottom: ${gap.xsmall}px;
    }
    &.enter-active {
      max-height: ${type === ListTypes.MEDIA
        ? isLogo || isGrid
          ? 149
          : 253
        : 155}px;
      margin-bottom: ${gap.xsmall}px;
    }
    &.enter-done {
      max-height: none;
      margin-bottom: ${gap.xsmall}px;
    }
    &.exit {
      max-height: ${type === ListTypes.MEDIA
        ? isLogo || isGrid
          ? 149
          : 253
        : 155}px;
    }
    &.exit-active {
      max-height: 0;
    }
    &.exit-done {
      max-height: 0;
    }
  `
export const footerStyles = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const bulletItemStyles = () => css`
  max-width: 90%;
`

export const listMediaStyles = ({ colors, borderRadius, gap }: ITheme) => css`
  display: flex;
  gap: ${gap.xsmall}px;

  .media-preview {
    width: 50%;
    background: ${colors.dark2.DEFAULT};
    border-radius: ${borderRadius[4]};

    .image-preview {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .media-buttons {
    display: flex;
    flex-direction: column;
    gap: ${gap.xsmall}px;
  }
`

export const listLogoStyles = ({ colors, borderRadius, gap }: ITheme) => css`
  .logo-preview {
    height: 100px;
    width: 100%;
    background: ${colors.dark2.DEFAULT};
    border-radius: ${borderRadius[4]};

    .image-preview {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    margin-bottom: ${gap.xsmall}px;
  }
`
