import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const formInner = (theme: ITheme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.medium};
  .info-text {
    color: ${theme.colors.black[60]};
  }
`

export const actionsStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing.xsmall};

  .footer-area {
    color: ${theme.colors.text.DEFAULT};
    font-size: ${theme.fontSize.small};
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    text-align: center;

    .footer-area {
      width: 100%;
      font-size: ${theme.fontSize.small};
    }
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    gap: ${theme.spacing.xsmall};

    .footer-area {
      font-size: ${theme.fontSize.base};
    }
  }
`

export const gradientText = ({ gradient, fontSize, fontWeight }: ITheme) => css`
  background: ${gradient.DEFAULT};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: ${fontSize.xlarge};
  font-weight: ${fontWeight.bold};
  text-align: center;
  width: 100%;
`
