import React from 'react'
import { IAvatar } from './types'
import { avatarImageStyles, textStyles, wrapperStyles } from './styles'
import { ColorServices, StringServices } from 'src/services'
import { useTheme } from '@emotion/react'

export const Avatar: React.FC<IAvatar> = React.memo(
  ({
    size = 24,
    border = 1,
    fontSize = 14,
    data,
    fitView,
    onClick,
    className,
    dataAttr,
  }) => {
    const { colors } = useTheme()
    return (
      <div
        css={wrapperStyles({ fontSize, size, onClick, fitView })}
        className={className}
        onClick={onClick}
        {...dataAttr}
      >
        {data.map((avatarItem, index) => (
          <div
            key={index}
            css={avatarImageStyles({
              size,
              border,
              fitView,
              bg: avatarItem.image
                ? colors.white.DEFAULT
                : avatarItem.name
                ? ColorServices.textToColor({
                    text: avatarItem.name,
                  })
                : '',
            })}
          >
            {avatarItem.image ? (
              <div
                className="avatar-image"
                style={{ backgroundImage: `url('${avatarItem.image}')` }}
              />
            ) : (
              <span css={textStyles}>
                {avatarItem.name
                  ? StringServices.firstLetters(avatarItem.name)
                  : ''}
              </span>
            )}
          </div>
        ))}
      </div>
    )
  },
)
