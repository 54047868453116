import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const loaderStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: center;
  padding-top: ${theme.gap['6xlarge']}px;
`

export const titleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.xlarge};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 320px;
  }

  @media (min-width: ${theme.breakpoints.laptop}px) {
    width: 260px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: 360px;
    font-size: ${theme.fontSize['2xlarge']};
  }
`
export const subtitleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.large};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 380px;
  }

  @media (min-width: ${theme.breakpoints.laptop}px) {
    width: 260px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: 360px;
    font-size: ${theme.fontSize.xlarge};
  }
`

export const discountStyles = (theme: ITheme) => css`
  color: ${theme.colors.primary.DEFAULT};
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};

  @media (min-width: ${theme.breakpoints.desktop}px) {
    font-size: ${theme.fontSize.large};
  }
`

export const wrapperStyles =
  (upgradeModal: boolean) =>
  (theme: ITheme) =>
  ({ colors, gap }: ITheme) => css`
    ${colors.white.DEFAULT};
    width: 100%;
    ${upgradeModal &&
    `display: flex;
    flex-direction: column;
    gap: ${theme.gap.xlarge}px;     
    .cool-switch {
      width: fit-content;
      font-size: ${theme.fontSize.base};
    }`}

    @media (min-width: ${theme.breakpoints.tablet}px) {
      ${upgradeModal && `align-items: center; text-align: center; gap: 0px; `}
    }

    @media (min-width: ${theme.breakpoints.laptop}px) {
      ${upgradeModal &&
      `flex-direction: row; align-items: start; text-align: start; gap: ${theme.gap.xlarge}px; height:100%; overflow: auto;
      `}
    }

    .switch-area {
      display: flex;
      justify-content: center;
      margin-bottom: ${gap['5xlarge']}px;
      ${upgradeModal &&
      `display: flex; flex-direction: column; align-items: center; justify-content: start; gap: ${theme.gap.medium}px;  margin-bottom: ${gap.medium}px;`}

      @media (min-width: ${theme.breakpoints.tablet}px) {
        ${upgradeModal && `align-items: center;`}
      }

      @media (min-width: ${theme.breakpoints.laptop}px) {
        ${upgradeModal && `align-items: start;`}
      }

      @media (min-width: ${theme.breakpoints.laptop}px) {
        ${upgradeModal && `align-items: start;`}
      }
    }

    .plans-list {
      display: flex;
      flex-direction: column;
      gap: ${gap['xlarge']}px;
      ${upgradeModal && `display: flex; flex-direction: column;  `}
      @media (min-width: ${theme.breakpoints.tablet}px) {
        ${upgradeModal &&
        `width:auto; max-width: 560px; flex-direction: row; justify-content: center;`}
      }

      @media (min-width: ${theme.breakpoints.laptop}px) {
        ${upgradeModal && `width:auto; max-width: 586px; height: 480px`}
      }

      @media (min-width: ${theme.breakpoints.desktop}px) {
        ${upgradeModal && `width:auto; max-width: 668px; height: auto`}
      }
    }
  `
