export const texts = {
  // Common
  '': '',
  'common.decktopus': 'Decktopus',
  'common.decks': 'Decks',
  'common.free': 'Free',
  'common.description': 'Description',
  'common.name': 'Name',
  'common.email': 'email',
  'common.full_name': 'full name',
  'common.role': 'Role',
  'common.dashboard': 'Dashboard',
  'common.analytics': 'Analytics',
  'common.responses': 'Responses',
  'common.read_only': 'Read only',
  'common.read_write': 'Read & Write',
  'common.last': 'Last',
  'common.first': 'First',
  'common.title_asc': 'Title (A-Z) ',
  'common.title_desc': 'Title (Z-A) ',
  'common.recent': 'Recently Modified ',
  'common.oldest': 'Oldest Modified ',
  'common.personal': 'Personal',
  'common.date': 'Date',
  'common.auto': 'Auto',
  'common.rate': 'Rate',
  'common.text': 'Text',
  'common.value': 'Value',
  'common.empty': 'Empty',
  'common.or': 'or',
  'common.and': 'and',
  'common.email_address': 'Email Address',
  'common.password': 'Password',
  'common.organization': 'Organization',
  'common.free_uppercased': 'FREE',
  'common.design': 'Design',
  'common.access': 'Access',
  'common.required': 'Required',
  'common.button': 'Button',
  'common.template_name': 'Template Name',
  'common.language': 'Language',
  'common.texts': 'Texts',

  'common.files': '{{count}} files',
  'common.files_zero': '{{count}} file',
  'common.files_one': '{{count}} file',
  'common.files_other': '{{count}} files',

  'common.slides': '{{count}} slides',
  'common.slides_zero': '{{count}} slide',
  'common.slides_one': '{{count}} slide',
  'common.slides_other': '{{count}} slides',

  'common.actions.change': 'Change',
  'common.actions.close': 'Close',
  'common.actions.complete': 'Complete',
  'common.actions.back': 'Back',
  'common.actions.reset': 'Reset',
  'common.actions.delete': 'Delete',
  'common.actions.create': 'Create',
  'common.actions.share': 'Share',
  'common.actions.search': 'Search',
  'common.actions.search_with_name': 'Search {{name}}',
  'common.actions.check': 'Check',
  'common.actions.connect': 'Connect',
  'common.actions.export': 'Export',
  'common.actions.download': 'Download',
  'common.actions.sort_by': 'Sort by',
  'common.actions.edit': 'Edit',
  'common.actions.save': 'Save',
  'common.actions.cancel': 'Cancel',
  'common.actions.add': 'Add',
  'common.actions.remove': 'Remove',
  'common.actions.rename': 'Rename',
  'common.actions.discard': 'Discard',
  'common.actions.approve': 'Approve',
  'common.actions.next': 'Next',
  'common.actions.move_to': 'Move to',
  'common.actions.play': 'Play',
  'common.actions.duplicate': 'Duplicate',
  'common.actions.activate': 'Activate',
  'common.actions.save_changes': 'Save Changes',
  'common.actions.paste': 'Paste',
  'common.actions.upload': 'Upload',
  'common.actions.submit': 'Submit',
  'common.actions.listen_voice': 'Listen Voice',
  'common.actions.record_voice': 'Record Voice',
  'common.actions.lets_start': `Let's Start`,
  'common.actions.leave': 'Leave',
  'common.actions.upload_picture': 'Upload Picture',
  'common.actions.remove_picture': 'Remove Picture',
  'common.actions.select': 'Select',
  'common.actions.preview': 'Preview',

  'common.informative.copied': 'Copied!',
  'common.informative.click_here': 'click here.',
  'common.informative.coming_soon': 'Coming Soon',
  'common.informative.fail': 'Fail',
  'common.informative.processing': 'Processing',
  'common.informative.thanks': 'Thanks!',
  'common.informative.done': 'Done',
  'common.informative.payment_successful': 'Payment Successful',
  'common.informative.your_deck_is_ready': 'Your deck is ready.',
  'common.informative.ai_loading_text':
    'Crafting your masterpiece, just a moment...',
  'common.informative.ai_error_text':
    "We're experiencing exceptionally high demand.",
  'common.informative.wait_on_dashboard': 'Wait on Dashboard',
  'common.informative.back_to_first': 'Back to First',
  'common.informative.ai_high_demand_text':
    "Your deck could not be created. We're experiencing exceptionally high demand.",
  'common.informative.error_start_recording': 'Error starting recording:',
  'common.informative.error_stop_recording': 'Error stopping recording:',
  'common.informative.error_changing_language':
    'Problem occured while changing language, please contact our customer support',
  'common.informative.large_media_file':
    'Oops! Your media file is over 5MB. Please select a smaller file for a smoother experience.',

  'common.directions.left': 'Left',
  'common.directions.middle': 'Middle',
  'common.directions.right': 'Right',
  'common.directions.up': 'Up',
  'common.directions.center': 'Center',
  'common.directions.down': 'Down',

  'common.nth': '{{count}}th',
  'common.nth_one': '{{count}}st',
  'common.nth_two': '{{count}}nd',
  'common.nth_three': '{{count}}rd',
  'common.nth_other': '{{count}}th',

  'common.files.media': 'Media',
  'common.files.video': 'video',
  'common.files.image': 'Image',
  'common.files.gif': 'Gif',
  'common.files.icon': 'Icon',
  'common.files.powerpoint': 'PowerPoint',
  'common.files.pdf': 'PDF',

  'common.roles.owner': 'Owner',
  'common.roles.admin': 'Admin',
  'common.roles.member': 'Member',
  'common.roles.pending': 'Pending',

  'common.plan_period.monthly': 'Monthly',
  'common.plan_period.annually': 'Annually',

  'common.plan_card.month': ' / month',

  'common.seats': 'seat',
  'common.seats_zero': 'seat',
  'common.seats_one': 'seat',
  'common.seats_other': 'seats',

  'common.deck.add_slide': 'Add Slide',
  'common.deck.duplicate_slide': 'Duplicate Slide',
  'common.deck.delete_slide': 'Delete Slide',
  'common.deck.undo': 'Undo',
  // Common ends

  // Login page
  'login.welcome': 'Welcome!',
  'login.session_expired': 'Session expired, please login again.',
  'login.continue_with_google': 'Continue with Google',
  'login.login': 'Log in',
  'login.logout': 'Logout',
  'login.signup': 'Sign up',
  'login.dont_have_an_account': `Don't have an account?`,
  'login.forgot_password': 'Forgot your password?',
  'login.title1': '2 Mn',
  'login.text1': 'Users around the globe',
  'login.title2': '64%',
  'login.text2': 'Time saved by preparing presentations with AI',
  'login.title3': '412k',
  'login.text3': 'Average presentations created with Decktopus',
  'login.title4': '<1 min',
  'login.text4': 'Average customer support response time',
  // Login page ends

  // Expired page
  'expired.get_started': 'Get Started',
  'expired.expire_link_title': 'This link is expired',
  'expired.expire_link_description':
    'This link is no longer valid. Please get in touch with your organization for further information.',
  // Expired page ends

  // Sign up page
  'signup.terms': `Terms`,
  'signup.privacy_policy': `Privacy Policy`,
  'signup.full_name': `Full Name`,
  'signup.already_member': `Already a member?`,
  'signup.create_account': 'Create Account',
  'signup_page_title': 'Create your account and unlock your presentation',
  'signup.create_account_info': `Creating an account means you're okay with our <0>terms</0> and <1>privacy</1>`,
  'signup.banner': 'You are one click away from your presentation',
  // Sign up page ends

  // Reset password
  'reset.password_reset': 'Password Reset',
  'reset.back_to': 'Back to',
  'reset.send_email': 'Send Email',
  'reset.resend': 'Resend Email',
  'reset.new_password': 'New Password',
  'reset.password_set': 'Your password is changed, please login.',
  'reset.reset_password': 'Reset password',
  'reset.reset_password_info': `Enter your email, and we'll send you the instructions on how to reset your password`,
  'reset.password_reset_sent': `Your password reset email has been sent`,
  'reset.change_your_password_info': 'Click the button to reset your password',
  // Reset password ends

  // Not supported page
  'not_supported': 'not supported',
  'not_supported.title':
    'Unfortunately, the edit mode is not supported on this device yet. For the best experience, please switch to a desktop computer.',
  //

  // Webhook
  'webhook.endpoint_url': 'Your Endpoint URL',
  'webhook.event_form_responses': 'Event: Form Responses',
  'webhook.webhook_delete_warning':
    'Are you sure that you want to delete permanently “<strong>{{name}}</strong>” webhook?',
  'webhook.url': 'URL',
  'webhook.webhook': 'Webhook',
  'webhook.create_webhook': 'Create Webhook',
  'webhook.webhook_form_general_info':
    'Create a webhook and send real-time notifications when any of your decks get a form submission. Add your endpoint URL and name your new webhook.',
  'webhook.webhook_learn':
    'To learn more about how to use webhooks <0>click here.</0>',
  // Webhook ends

  // Main panel tabs
  'main_panel.admin': 'Admin',
  'main_panel.organization': 'Organization',
  'main_panel.profile_settings': 'Profile Settings',
  'main_panel.webhook': 'Webhook',
  'main_panel.refer_and_earn': 'Refer & Earn',
  'main_panel.subscription': 'Subscription',
  'main_panel.onboarding': 'Onboarding',
  'main_panel.logout': 'Logout',
  // Main panel tabs ends

  // Profile settings
  'profile.change_account_information': 'Change Account Information',
  'profile.edit_your_name': 'Edit your name',
  'profile.change_your_password': 'Change your password',
  'profile.update.save_changes': 'Save Changes',
  'profile.update.image_upload_success': 'Your profile picture has changed.',
  'profile.update.name_change_success': 'Your name has changed.',
  'profile.update.name_min_char_error':
    'Name can not be shorter than 3 characters.',
  'profile.update.image_upload_error': '{{fileType}} is not supported.',
  'profile.verification_success':
    'You have successfully verified your account.',
  // Profile settings ends

  // Verify email
  'verify.verify_your_email': 'Verify your email',
  'verify.verify_your_account': 'Verify your account',
  'verify.verify_your_email_info': 'Click the button to verify your account.',
  'verify.verification_email_sent': 'Verification mail sent.',
  'verify.verification_enter_code': 'Enter your code',
  'verify.verification_didnt_reveive_email': `Didn't receive the email? <0>link</0>`,
  'verify.verification_click_to_resend': 'Click to resend',
  'verify.verification_email_sent_info':
    'We sent a verification code to your email.',
  // Verify email ends

  // Validations
  'validation.min_char':
    '{{name, capitalFirstLetter}} can not be shorter than {{value}} characters.',
  'validation.max_char':
    '{{name, capitalFirstLetter}} can not be longer than {{value}} characters.',
  'validation.error.required': '{{name, capitalFirstLetter}} can not be empty',
  'validation.error.email': 'Invalid email address',
  'validation.error.url': 'Invalid URL address',
  'validation.error.video_url':
    'Invalid video url. Please write youtube or vimeo link.',
  'validation.validation_user_exists': 'This user already exists',
  // Validations ends

  // Layout modal
  'layout.save': 'Save Layout',
  'layout.save_new': 'Save as New Layout',
  'layout.name': 'Layout Name',
  'layout.blank': 'Blank',
  // Layout modal ends

  // Workspace selector
  'workspace.personal_hub': 'Personal Hub',
  // Workspace selector ends

  // Init modal
  'init.category_select_info':
    'Choose a design category that fits your presentation concept',
  'init.see_templates': 'See Templates',
  'init.start_from_scratch': 'Start from Scratch',
  'init.use_this_template': 'Use This Template',

  'init.category0': 'All',
  'init.category1': 'Business',
  'init.category2': 'Proposal',
  'init.category3': 'Online Course',
  'init.category4': 'Education',
  'init.category5': 'Community',
  'init.category6': 'Nutrition',
  'init.category7': 'Executive Summary',
  'init.category8': 'Case Study',
  'init.category9': 'Startup Pitch',
  // Init modal ends

  // Create with AI modal
  'create_with_ai.generate_presentation': 'Generate Presentation',
  'create_with_ai.create_with_ai': 'Create with AI',
  'create_with_ai.prompt_placeholder': 'Write your presentation topic here!',
  'create_with_ai.audience_placeholder': 'Write your audience',
  'create_with_ai.aim_placeholder': 'Write your aim',
  'create_with_ai.prompt_title': 'What’s your presentation about? ',
  'create_with_ai.audience_title': 'Who is your audience?',
  'create_with_ai.aim_title': 'What is your aim for this deck?',
  'create_with_ai.outline_title':
    'You can modify the outline or continue without changing it.',
  'create_with_ai.theme_title': 'Which template would you like to use?',
  'create_with_ai.theme_subtitle': ' Don’t worry! You can change it anytime.',
  'create_with_ai.ai_subtitle':
    'Inspire Deckto with a title. Try typing your presentation topic like: “History of Rome”',
  'create_with_ai.see_examples': 'See Examples',
  'create_with_ai.hide_examples': 'Hide Examples',
  'create_with_ai.import_pdf': 'Import your PDF',
  'create_with_ai.pdf_subtitle':
    'Deckto will transform your document into a deck with edited slides.',
  // Create with AI modal ends

  // Pdf import modal
  'pdf_import.import_pdf_file': 'Import your PDF file',
  'pdf_import.drag_and_drop_file': 'Drag & drop your file',
  'pdf_import.on_your_computer': 'on your computer',
  'pdf_import.browse_file': ' browse file ',
  'pdf_import.browse_file_on_your_device': 'Browse file on your device',
  'pdf_import.pdf_size_error':
    'The PDFs size that you’ve uploaded is way bigger than we support, try with a smaller one.',
  'pdf_import.use_pdf_format': 'Can’t upload. Use a file in .PDF format.',
  'pdf_import.drop_the_file_to_upload': 'Drop the file to upload',
  // Pdf import modal ends

  // Organization page
  'organization.organization_profile_update.image_upload_success':
    'Organization picture has changed.',
  'organization.organization_profile_update.name_and_description_success':
    'Organization details have changed.',
  'organization.organization_details': 'Organization Details',
  'organization.remaining_seats': 'Remaining seats',
  'organization.manage_seats': 'Manage seats',
  'organization.used_seats': '{{occupiedSeats}} of {{totalSeats}} seats used',
  'organization.adjust_seat_number': 'Adjust seat number',
  'organization.current_users': 'Current Users ({{count}}) Cost / 12',
  'organization.added_users': 'Added Users ({{count}}) Cost / 12',
  'organization.updated_annual_total': 'Updated {{period}} Total Cost / 12',
  'organization.first_charge': 'The first charge will be prorated.',
  'organization.first_charge_tooltip':
    'The first charge of the additional seats will be calculated according to the number of days left in your current billing cycle.',
  'organization.reset_invitation': 'Resent invitation',
  'organization.cancel_invitation': 'Cancel invitation',
  'organization.member_settings': 'Member Settings',
  'organization.add_members': 'Add members',
  'organization.add_members_title': 'Add members to your organization',
  'organization.not_enough_seats':
    'You do not enough seats to add more members. Adjust seat number from organization page to make room.',
  'organization.add_member_information':
    'You can always invite members to your organization from right bar in “My Organization” page.',
  'organization.organization-details': 'Organization Details',
  'organization.leave_team_title': 'Leave Team',
  'organization.leave_team_button': 'Leave the organization',
  'organization.leave_team_dialog_text':
    'By leaving an organization you can no longer access collaborated decks.',
  'organization.leave_team_text':
    'If you leave the team, you will not be able to access organization contents and business features.',
  'organization.organization_name_label': 'Organization name',
  'organization.organization_desc_label': 'Organization Description',
  'organization.organization_team_logo': 'Team Logo',

  'organization.delete_member': 'Delete Member',
  'organization.delete_member_title':
    'Are you sure you want to delete permanently  “{{name}}”?',
  'organization.delete_member_text': `“{{name}}” will not be able to see all decks and organization details`,

  'organization.resend_info':
    '{{email}} has not accepted your invitation yet. You will resend the invitation as a reminder. ',
  'organization.organization_suspended':
    'This organization is suspended. Kindly update your payment method to resolve the issue.',
  'organization.organization_suspended_member':
    'This organization is suspended. Please reach out to the organization admin for further assistance.',
  // Organization page ends

  // Refer
  'refer.invite_friend': 'Invite your friend!',
  'refer.invite_friend_text': `Both you and your friend get <strong>30</strong> credits.`,
  'refer.referred': 'We sent your friend an email',
  // Refer ends

  // Analytics
  'analytics.no_views_yet': 'No views yet',
  'analytics.share_this_deck': 'Share this deck.',
  'analytics.total_views': '{{view}} Total Views',
  'analytics.total_views_one_slide':
    '{{view}} Views for the very first slide only',
  'analytics.chart_description':
    'This chart represents your decks view performance',
  'analytics.last_time_open': 'Last time open',
  'analytics.views': '{{view}} Views',
  'analytics.openedTimes': 'Opened {{view}} times',
  // Analytics ends

  // Subscriptions
  'subscriptions.upgrade': 'Upgrade',
  'subscriptions.show_more': 'Show more',
  'subscriptions.show_less': 'Show less',
  'subscriptions.subscription': 'Subscription',
  'subscriptions.current_plan': 'Current Plan',
  'subscriptions.credit_information': 'Credit Information',
  'subscriptions.ai_credits': 'AI Credits',
  'subscriptions.choose_plan': 'Choose Plan',
  'subscriptions.plan_renew_info': 'Your plan renews on {{date}}',
  'subscriptions.plan_cancel_info': 'Your plan will be canceled on {{date}}',
  'subscriptions.plan_renew_info_free':
    'To access amazing ai features upgrade your account',
  'subscriptions.credit_renew_info': 'Your credits will be renewed on {{date}}',
  'subscriptions.credit_cancel_info': 'Your credits can be used until {{date}}',
  'subscriptions.credit_history': 'Credit History',
  'subscriptions.credit_history_info':
    'Credit history reflects spending up to the beginning of the subscription period',
  'subscriptions.subscription_settings': 'Subscription Settings',
  'subscriptions.plan.powerup_info': 'I want to power up my {{plan_name}}',
  'subscriptions.plan.choose_pro': 'Choose Pro',
  'subscriptions.plan.choose_business': 'Choose Business',
  'subscriptions.plan.discount_info': 'Save up to 40%',

  'subscriptions.subs_state.pending': 'Pending',
  'subscriptions.subs_state.action_required': 'Action Required',
  'subscriptions.subs_state.failed': 'Failed',
  'subscriptions.subs_state.canceled': 'Canceled',
  'subscriptions.subs_state.active': 'Active',
  'subscriptions.plan.popular': 'Popular',
  'subscriptions.billed_annually': ' billed annually',
  'subscriptions.update_payment': 'Update Payment Method',
  // Subscriptions ends

  // Upgrade modal
  'upgrade_modal.upgrade_to_business': 'Upgrade to Business',
  'upgrade_modal.upgrade_to_business_text':
    'Level up your presentations and increase your ROI on presentations.',
  'subscriptions.plan.cancel_anytime': 'Cancel anytime',
  'upgrade_modal.refill_your_credits': 'Refill your credits',
  'upgrade_modal.upgrade_refill_modal_subtitle':
    'Keep your momentum. Upgrade your plan to keep working.',
  'upgrade_modal.upgrade_modal_subtitle':
    'Use Decktopus as your AI co-pilot for presentations.',
  'upgrade_modal.choose_plan': 'Choose a Plan',
  // Upgrade modal ends

  // Dashboard page
  'dashboard.create.create_presentation': 'Create presentation',
  'dashboard.create.start_from_scratch': 'Start from scratch',
  'dashboard.create.create_with_a_template': 'Create with a template',

  'dashboard.folder.folder': 'Folder',
  'dashboard.shared_with_me': 'Shared with me',
  'dashboard.folder.create_new_folder': 'Create New Folder',
  'dashboard.folder.create_folder': 'Create Folder',
  'dashboard.folder.delete_title':
    'Are you sure you want to delete permanently  “{{folder_name}}” and {{folder_id}} files in it?',
  'dashboard.folder.delete_text': `Files can’t be recovered once deleted.`,
  'dashboard.folder.folder_is_created': 'Folder is created.',
  'dashboard.folder.folder_deleted': 'Folder is deleted.',
  'dashboard.folder.type_folder_name': 'Type your folder name here.',
  'dashboard.folder.rename': 'Rename Folder',
  'dashboard.folder.renamed': 'Folder is renamed.',
  'dashboard.folder.name': 'folder name',

  'dashboard.card.rename_deck': 'Rename Deck',
  'dashboard.card.transferring':
    'Your deck is transferring from old version...',
  'dashboard.card.migration': 'Migrate',
  'dashboard.card.type_deck_name': 'Type your deck name here.',
  'dashboard.card.deck_name': 'Deck name',
  'dashboard.card.deck_renamed': 'Deck is renamed.',
  'dashboard.card.deck_delete_text':
    'Are you sure you want to delete “{{deckName}}” ?',
  'dashboard.card.remove_me_text':
    'Are you sure you want to remove yourself from “{{deckName}}” ?',
  'dashboard.card.remove_me': 'Remove Me',
  'dashboard.card.file_not_recover': "Files can't be recovered once deleted.",
  'dashboard.card.deck_deleted': 'Deck is deleted',
  'dashboard.card.deck_removed': 'You are removed from the deck',
  'dashboard.card.publish_template': 'Publish Template',
  'dashboard.card.unpublish_template': 'Unpublish Template',

  'deck_not_created': "Deck couldn't created.",

  'dashboard.search.search_my_decks': 'Search my decks in Decktopus',
  'dashboard.search.results_for': 'Results for “{{searchParam}}”:',
  'dashboard.search.no_results_for':
    'No results found matching <strong>{{ searchParam }}</strong>',
  'dashboard.search.try_another_search':
    'Try adjusting your search to find what you are looking for',
  'dashboard.search.return_dashboard': 'or return to dashboard',
  'dashboard.search.search_on_decktopus': 'Search templates on Decktopus',

  'dashboard.publish.success': 'Your deck is published successfully',
  'dashboard.unpublish.success': 'Your deck is unpublished successfully',
  'dashboard.publish.error': 'Deck could not published',
  'dashboard.unpublish.error': 'Deck could not unpublished',
  // Dashboard page ends

  // Form responses
  'form_response.form_responses': 'Form Responses',
  'form_response.table.download_form': 'Download Form',
  'form_response.table.deleted_question': 'Deleted Question',
  'form_response.no_answers_yet': 'No answers yet.',
  'form_response.table.avarage_score': 'Average Score',
  // Form responses end

  // Edit page
  'edit.component_fix.detail': 'Final adjustments are being made to your deck.',
  'edit.component_fix.info': 'Please wait',
  'edit.slide_menu.layout': 'layout',

  'edit.create_slide.no_custom_layout_text': `You haven't created a custom layout yet.`,
  'edit.create_slide.no_shared_layout_text': `You don't have any shared layout.`,

  'edit.color_picker.select_color': 'Select color',
  'edit.color_picker.solid': 'Solid',
  'edit.color_picker.gradient': 'Gradient',
  'edit.color_picker.theme_colors': 'Theme Colors',
  'edit.color_picker.recent_colors': 'Recent Colors',

  'edit.media.modal.image.unsplash_library': 'Unsplash library ',
  'edit.media.modal.image.title': 'Select image from <0>Unsplash library</0>',
  'edit.media.modal.title': 'Select {{type}} from library',
  'edit.media.modal.load_more': 'Load More',
  'edit.media.modal.no_result': 'No result',
  'edit.media.modal.initial_text': 'Please enter a text to search {{type}}',
  'edit.media.modal.ai.input': 'description',
  'edit.media.modal.select_media': 'Select {{ type }}',
  'edit.media.modal.search_media': 'Search {{ type }}',

  'edit.list.submenu.placeholder': 'multi',
  'edit.list.layout.columns': 'Columns',
  'edit.list.layout.rows': 'Rows',

  'edit.create_slide.copy_from_another_deck': 'Copy from another deck',
  'edit.header.title': 'Edit Header Component',

  'edit.elements.embeds': 'Embeds',
  'edit.elements.interactive_elements': 'Interactive Elements',
  'edit.elements.see_all': 'See All',
  'edit.elements.see_less': 'See Less',
  'edit.elements.shapes': 'Shapes',
  'edit.elements.tooltip_text': 'Only 1 form can be added to a slide',
  'edit.elements.content_blocks': 'Content Blocks',

  'edit.canvas.blank': 'Blank',
  'edit.canvas.context_menu.bring_forward': 'Bring Forward',
  'edit.canvas.context_menu.send_backward': 'Send Backward',
  'edit.canvas.context_menu.bring_to_front': 'Bring to Front',
  'edit.canvas.context_menu.send_to_back': 'Send to Back',
  'edit.canvas.context_menu.send_to_background': 'Send to Background',

  'edit.canvas.context_menu.edit_with_ai': 'Edit with AI',
  'edit.canvas.context_menu.edit_with_ai.summarize': 'Summarize',
  'edit.canvas.context_menu.edit_with_ai.fix': 'Fix',
  'edit.canvas.context_menu.edit_with_ai.rewrite': 'Rewrite',
  'edit.canvas.context_menu.edit_with_ai.extend': 'Extend',
  'edit.canvas.context_menu.edit_with_ai.other': 'Other',
  'edit.canvas.context_menu.edit_with_ai.generate': 'Generate',
  'edit.canvas.context_menu.edit_with_ai.prompt_placeholder':
    'Type your prompt here.',
  'edit.canvas.context_menu.edit_with_ai.title':
    'Give Decktopus a prompt to improve your selected content',
  'edit.canvas.context_menu.edit_with_ai.input_error_message':
    'Description can not be shorter than 3 characters.',
  'edit.canvas.context_menu.edit_with_ai.nothing_improved':
    'There was nothing to fix',

  'edit.canvas.paste.processing_image': 'Processing image...',
  'edit.canvas.paste.image_pasted': 'Image uploaded!',

  'edit.ai.create_qa_text':
    'See the Q&As specialized for your presentation. The questions that might be asked during your presentation and their answers are in this section.',
  'edit.ai.create_qa_button': 'Create Q&A',
  'edit.ai.create_tip_text':
    'Slide tips are in this section to provide a better presentation experience. Icebreaker, hook, and story-like structure suggestions are here to assist you.',
  'edit.ai.create_tip_button': 'Create Tips',
  'edit.ai.create_tips_hover': 'Create AI deck to use this feature.',

  'edit.canvas_sidebar.start_show': 'Start Show',
  'edit.canvas_sidebar.start_voice': 'Start Voice',
  'edit.canvas_sidebar.swap_colors': 'Swap Colors',

  'edit.design.customize_design': 'Customize Design',
  'edit.design.font_family': 'Font Family',
  'edit.design.default_fonts_delete': 'Default fonts can not be deleted.',
  'edit.design.upload_custom_font': 'Upload Custom Font',
  'edit.design.upload_your_font': 'Upload Your Font Here',
  'edit.design.allowed_fonts_text':
    'Please upload only .ttf and .otf format fonts*.',
  'edit.design.custom_font_responsibility':
    '*All responsibility for the commercial use of the uploaded fonts belongs to the user. Decktopus does not assume any liability for commercial use.',
  'edit.design.upload_primary_font': 'Upload Primary Font',
  'edit.design.upload_secondary_font': 'Upload Secondary Font',
  'edit.design.color_palette': 'Color Palette',
  'edit.design.default_color_palettes': 'Default palettes can not be deleted.',

  'edit.preferences.background_color': 'Background Color',
  'edit.preferences.background_tooltip':
    'Select a new decor below to change background.',
  'edit.preferences.background_decor': 'Background Decor',

  'edit.properties.list.submenu.title': 'Text & Icon Properties',
  'edit.properties.list.new_list.add_new': 'Add New',

  'edit.properties.quote.alignment': 'Quote Alignment',
  'edit.properties.quote.placeholder': 'Quote',
  'edit.properties.quote.author': 'Author',
  'edit.properties.quote.size.label.text': 'Quote Text',
  'edit.properties.quote.size.label.author': 'Author',

  'edit.properties.media.image_props': 'Image Properties',
  'edit.properties.media.image_opacity': 'Opacity',
  'edit.properties.media.corner_radius': 'Corner Radius',

  'edit.properties.icon.styles': 'Styles',
  'edit.properties.icon.icon': 'Icon',
  'edit.properties.icon.background': 'Background',

  'edit.properties.data': 'Edit Data',
  'edit.properties.table': 'Edit Table',
  'edit.properties.show_header_column': 'Show Header Columnn',
  'edit.properties.show_header_row': 'Show Header Row',
  'edit.properties.form.submenu.title': 'Form Properties',
  'edit.properties.form.button_text': 'button text',
  'edit.properties.form.placeholder_text': 'Text placeholder',
  'edit.properties.form.question_text': 'question text',
  'edit.properties.form.choice': 'choice',
  'edit.properties.form.header': 'Add Here Form Type',
  'edit.properties.form.header.button_text': 'Button Text',
  'edit.properties.form.option_list': 'Option List',
  'edit.properties.form.check_list': 'Check List',
  'edit.properties.form.free_text': 'Free Text',
  'edit.properties.form.submitting_required_tooltip':
    'If this is on, the viewer cannot see the next slides until submitting this form',

  'edit.properties.button_props.paste_url': 'Paste a website url.',
  'edit.properties.button_props.outlined': 'Outlined',

  'edit.properties.chart_props.show_grid': 'Show Grid Lines',
  'edit.properties.chart_props.show_legends': 'Show Legends',
  'edit.properties.chart_props.settings': 'Settings',

  'edit.properties.chart_title.placeholder': 'Chart Title',
  'edit.properties.edit_data.error': 'not a number',

  'edit.properties.x_axis.show_x_axis': 'Show X Axis',
  'edit.properties.x_axis.x_axis': 'X Axis',
  'edit.properties.y_axis.show_y_axis': 'Show Y Axis',
  'edit.properties.y_axis.y_axis': 'Y Axis',

  'edit.properties.form_choice.choice': 'Choice ',

  'edit.properties.form_props.question': 'Question ',
  'edit.properties.form_props.description': 'Description',
  'edit.properties.form_props.choice_1': 'Choice 1',
  'edit.properties.form_props.choice_2': 'Choice 2',
  'edit.properties.form_props.choice_3': 'Choice 3',

  'edit.properties.form_question_option.add_choice': 'Add Choice',
  'edit.properties.form_question_rate.choose_icon': 'Choose Icon Style',

  'edit.properties.device.header': 'Link',
  'edit.properties.device.change_device': 'Change Device',
  'edit.properties.device.laptop': 'Laptop',
  'edit.properties.device.tablet': 'Tablet',
  'edit.properties.device.phone': 'Phone',

  'edit.properties.chart.chart_data': 'Chart Type:',

  'edit.properties.shape.placeholder': 'Shape',
  'edit.properties.shape.distort': 'Distort',

  'edit.properties.ai_image.create': 'Create with AI',
  'edit.properties.ai_image.generate_with_ai': 'Generate an Image with AI',
  'edit.properties.ai_image.describe': 'Describe your request to AI',
  'edit.properties.ai_image.generate': 'Generate',

  'edit.properties.table.placeholder': 'Settings',

  'edit.properties.text_size.placeholder': 'Multi',
  'edit.properties.text_size.big_text': 'Big Text',
  'edit.properties.text_size.title': 'Title',
  'edit.properties.text_size.subtitle': 'Subtitle',
  'edit.properties.text_size.body': 'Body',
  'edit.properties.text_size.label': 'Label',

  'edit.properties.text_props.header': 'Text',
  'edit.properties.text_props.reset': 'Reset',
  'edit.properties.text_props.fill': 'Fill',
  'edit.properties.text_props.opacity': 'Opacity',

  'edit.properties.video_props.video': 'Video',
  'edit.properties.video_props.paste': 'Paste Youtube or Vimeo video link.',

  'edit.properties.website_props.website': 'Website',
  'edit.properties.website_props.paste': 'Paste a website url.',

  'edit.properties.alignment.position': 'Position',

  'edit.properties.list.list_type': 'List Type',
  'edit.properties.list.list_direction': 'Direction',
  'edit.properties.list.media_position': 'Media Position',
  'edit.properties.list.layout': 'Layout',
  'edit.properties.list.colors': 'Colors',
  'edit.properties.list.font_size': 'Font Size',
  'edit.properties.list.size': 'Size',
  'edit.properties.list.icon_frame': 'Icon Frame',
  'edit.properties.list.color.icon': 'Icon',
  'edit.properties.list.color.icon_frame': 'Frame',
  'edit.properties.list.color.number': 'Number',
  'edit.properties.list.color.bullet': 'Bullet',
  'edit.properties.list.font_title': 'Title',
  'edit.properties.list.color.title': 'Title',
  'edit.properties.list.color.body': 'Body',
  'edit.properties.list.font_body': 'Body',
  'edit.properties.list.font_logo': 'Logo',

  'edit.properties.form.form_properties': 'Form Properties',
  'edit.properties.form.gdpr_message': 'GDPR Message',
  'edit.properties.form.submitting_required': 'Form Submitting is Required',
  'edit.properties.form.consent': 'Enable GDPR Consent',
  'edit.properties.form.gdpr_tooltip':
    'If this is on, a checkbox appears above the submit button.',
  'edit.properties.form.gdpr_prop_placeholder': 'Free Text Input Placeholder',
  'edit.properties.form.option_list_placeholder': 'Option List Placeholder',
  'edit.properties.form.rate_placeholder': 'Rate Placeholder',
  'edit.properties.form.back_button_text': 'Back Button Text',
  'edit.properties.form.next_button_text': 'Next Button Text',
  'edit.properties.form.submit_button_text': 'Submit Button Text',
  'edit.properties.form.submit_message': 'Submit Message',
  'edit.properties.font.select_option': 'Select Option',

  'edit.add_new_slide_modal.slide_layouts': 'Slide Layouts',
  'edit.add_new_slide_modal.shared_with_me': 'Shared with Me',
  'edit.add_new_slide_modal.custom_layouts': 'Custom Layouts',
  'edit.add_new_slide_modal.search_slide_type':
    'Search a slide type from your layout library e.g. “Image”',

  'edit.panel.menu_tabs.slide_notes': 'Slide Notes',
  'edit.panel.menu_tabs.transcripts': 'Transcripts',
  'edit.panel.menu_tabs.tips': 'Tips',
  'edit.panel.menu_tabs.qa': 'Q&A',
  'edit.panel.menu_tabs.themes': 'Themes',
  'edit.panel.menu_tabs.style': 'Style',
  'edit.panel.menu_tabs.colors': 'Colors',

  'edit.panel.preferences': 'Preferences',
  'edit.panel.elements': 'Elements',
  'edit.panel.notes': 'Notes',
  'edit.panel.ai_assistant': 'AI Assistant',
  'edit.panel.brand': 'Brand',

  'edit.slide_menu.warning': 'Warning',
  'edit.slide_menu.warning_text':
    'You are about to replace an existing layout, do you want to continue?',
  'edit.slide_menu.replace': 'Replace',

  'edit.properties.zoom_types.fill': 'Fill',
  'edit.properties.zoom_types.fit': 'Fit',
  'edit.properties.zoom_types.distort': 'Distort',
  'edit.properties.zoom_types.crop': 'Crop',

  'edit.definitions.bar_chart.display_name': 'Bar Chart',
  'edit.definitions.bar_chart.name1': 'Engineering',
  'edit.definitions.bar_chart.name2': 'Marketing',
  'edit.definitions.bar_chart.name3': 'Operation',
  'edit.definitions.body.text': 'New Body',
  'edit.definitions.body.display_name': 'This is your body',
  'edit.definitions.bullet_list.display_name': 'Bullet List',
  'edit.definitions.bullet_list.item_title1': 'Item 1',
  'edit.definitions.bullet_list.item_title2': 'Item 2',
  'edit.definitions.bullet_list.item_title3': 'Item 3',
  'edit.definitions.bullet_list.item_body1': 'Description of Item 1',
  'edit.definitions.bullet_list.item_body2': 'Description of Item 2',
  'edit.definitions.bullet_list.item_body3': 'Description of Item 3',
  'edit.definitions.button.display_name': 'Button',
  'edit.definitions.button.text': 'Button',
  'edit.definitions.code.display_name': 'Code',
  'edit.definitions.device.display_name': 'Device',
  'edit.definitions.donut_chart.display_name': 'Donut Chart',
  'edit.definitions.donut_chart.label': 'Series1',
  'edit.definitions.donut_chart.value1': 'Alpha',
  'edit.definitions.donut_chart.value2': 'Bravo',
  'edit.definitions.donut_chart.value3': 'Charlie',
  'edit.definitions.donut_chart.value4': 'Delta',
  'edit.definitions.donut_chart.value5': 'Echo',
  'edit.definitions.form.display_name': 'Form',
  'edit.definitions.form.title': 'Form Title',
  'edit.definitions.form.button_text': 'Submit',
  'edit.definitions.form.answers.text': 'Text type placeholder',
  'edit.definitions.form.answers.option': 'Option type placeholder',
  'edit.definitions.form.answers.rate': 'Rate type placeholder',
  'edit.definitions.form.buttons.next': 'Next',
  'edit.definitions.form.buttons.back': 'Back',
  'edit.definitions.form.submit': 'Thank you',
  'edit.definitions.form.gdpr_consent_message': 'GDPR Message',
  'edit.definitions.form.question1': 'Question 1',
  'edit.definitions.form.question2': 'Question 2',
  'edit.definitions.form.question3': 'Question 3',
  'edit.definitions.form.question2.description': 'Description',
  'edit.definitions.form.choice1': 'Choice 1',
  'edit.definitions.form.choice2': 'Choice 2',
  'edit.definitions.form.choice3': 'Choice 3',
  'edit.definitions.gif.display_name': 'Gif',
  'edit.definitions.icon.display_name': 'Icon',
  'edit.definitions.icon_list.display_name': 'Icon List',
  'edit.definitions.icon_list.title1': 'Item 1',
  'edit.definitions.icon_list.body1': 'Description of Item 1',
  'edit.definitions.icon_list.title2': 'Item 2',
  'edit.definitions.icon_list.body2': 'Description of Item 2',
  'edit.definitions.icon_list.title3': 'Item 3',
  'edit.definitions.icon_list.body3': 'Description of Item 3',
  'edit.definitions.image.display_name': 'Image',
  'edit.definitions.image_grid.display_name': 'Image Grid',
  'edit.definitions.image_grid.title1': 'Item 1',
  'edit.definitions.image_grid.body1': 'Description of Item 1',
  'edit.definitions.image_grid.title2': 'Item 2',
  'edit.definitions.image_grid.body2': 'Description of Item 2',
  'edit.definitions.image_grid.title3': 'Item 3',
  'edit.definitions.image_grid.body3': 'Description of Item 3',
  'edit.definitions.image_list.display_name': 'Image List',
  'edit.definitions.image_list.title1': 'Item 1',
  'edit.definitions.image_list.body1': 'Description of Item 1',
  'edit.definitions.image_list.title2': 'Item 2',
  'edit.definitions.image_list.body2': 'Description of Item 2',
  'edit.definitions.image_list.title3': 'Item 3',
  'edit.definitions.image_list.body3': 'Description of Item 3',
  'edit.definitions.jumbo.text': 'New Jumbo Text',
  'edit.definitions.jumbo.display_name': 'This is big text',
  'edit.definitions.label.text': 'New Label',
  'edit.definitions.label.display_name': 'This is your label',
  'edit.definitions.line_chart.display_name': 'Line Chart',
  'edit.definitions.line_chart.label1': 'Line 1',
  'edit.definitions.line_chart.label1.name1': '09 Feb',
  'edit.definitions.line_chart.label1.name2': '10 Feb',
  'edit.definitions.line_chart.label1.name3': '11 Feb',
  'edit.definitions.line_chart.label1.name4': '12 Feb',
  'edit.definitions.line_chart.label1.name5': '13 Feb',
  'edit.definitions.line_chart.label2': 'Line 2',
  'edit.definitions.line_chart.label2.name1': '09 Feb',
  'edit.definitions.line_chart.label2.name2': '10 Feb',
  'edit.definitions.line_chart.label2.name3': '11 Feb',
  'edit.definitions.line_chart.label2.name4': '12 Feb',
  'edit.definitions.line_chart.label2.name5': '13 Feb',
  'edit.definitions.links.display_name': 'Link',
  'edit.definitions.links.text': 'Button text',
  'edit.definitions.logo.display_name': 'Logo',
  'edit.definitions.logo_list.display_name': 'Logo List',
  'edit.definitions.logo_list.title1': 'Logo 1',
  'edit.definitions.logo_list.body1': 'Description of Logo 1',
  'edit.definitions.logo_list.title2': 'Logo 2',
  'edit.definitions.logo_list.body2': 'Description of Logo 2',
  'edit.definitions.logo_list.title3': 'Logo 3',
  'edit.definitions.logo_list.body3': 'Description of Logo 3',
  'edit.definitions.pie_chart.display_name': 'Pie Chart',
  'edit.definitions.pie_chart.label': 'Series 1',
  'edit.definitions.pie_chart.value1': 'Alpha',
  'edit.definitions.pie_chart.value2': 'Bravo',
  'edit.definitions.pie_chart.value3': 'Charlie',
  'edit.definitions.pie_chart.value4': 'Delta',
  'edit.definitions.pie_chart.value5': 'Echo',
  'edit.definitions.quote.display_name': 'Quote',
  'edit.definitions.quote.quote': `Imagination is everything. It is the preview of life's coming attractions. `,
  'edit.definitions.quote.author': 'Albert Einstein',
  'edit.definitions.rating.display_name': 'Rating',
  'edit.definitions.rating.title': 'Form Title',
  'edit.definitions.rating.button_text': 'Submit',
  'edit.definitions.rating.placeholder.text': 'Text type placeholder',
  'edit.definitions.rating.placeholder.options': 'Option type placeholder',
  'edit.definitions.rating.placeholder.rate': 'Rate type placeholder',
  'edit.definitions.rating.next': 'Next',
  'edit.definitions.rating.back': 'Back',
  'edit.definitions.rating.submit': 'Thank you',
  'edit.definitions.rating.gdpr_consent': 'GDPR Message',
  'edit.definitions.rating.question_text': 'Rate your experience!',
  'edit.definitions.rating.question_description': 'Description',
  'edit.definitions.subtitle.text': 'New Subtitle',
  'edit.definitions.subtitle.display_name': 'This is your subtitle',
  'edit.definitions.showcase.display_name': 'Showcase',
  'edit.definitions.table.display_name': 'Table',
  'edit.definitions.table.header1': 'Categories',
  'edit.definitions.table.header2': 'Values 1',
  'edit.definitions.table.header3': 'Values 2',
  'edit.definitions.table.header4': 'Values 3',
  'edit.definitions.table.header5': 'Values 4',
  'edit.definitions.table.row1.value1': 'Categories 1',
  'edit.definitions.table.row1.value2': '30',
  'edit.definitions.table.row1.value3': '40',
  'edit.definitions.table.row1.value4': '50',
  'edit.definitions.table.row1.value5': '60',
  'edit.definitions.table.row2.value1': 'Categories 2',
  'edit.definitions.table.row2.value2': '10',
  'edit.definitions.table.row2.value3': '20',
  'edit.definitions.table.row2.value4': '30',
  'edit.definitions.table.row2.value5': '40',
  'edit.definitions.table.row3.value1': 'Categories 3',
  'edit.definitions.table.row3.value2': '7',
  'edit.definitions.table.row3.value3': '23',
  'edit.definitions.table.row3.value4': '100',
  'edit.definitions.table.row3.value5': '70',
  'edit.definitions.table.row4.value1': 'Categories 4',
  'edit.definitions.table.row4.value2': '97',
  'edit.definitions.table.row4.value3': '2',
  'edit.definitions.table.row4.value4': '13',
  'edit.definitions.table.row4.value5': '55',
  'edit.definitions.timeline.display_name': 'Timeline',
  'edit.definitions.timeline.item1': 'Item 1',
  'edit.definitions.timeline.description1': 'Description of Item 1',
  'edit.definitions.timeline.item2': 'Item 2',
  'edit.definitions.timeline.description2': 'Description of Item 2',
  'edit.definitions.timeline.item3': 'Item 3',
  'edit.definitions.timeline.description3': 'Description of Item 3',
  'edit.definitions.title.text': 'New Title',
  'edit.definitions.title.display_name': 'This is your title',
  'edit.definitions.video.display_name': 'Video',
  'edit.definitions.website.display_name': 'Website',

  'edit.background.options': 'Background Options',

  'edit.color_mapping.swap_colors': 'Swap Colors',
  'edit.color_mapping.restore': 'Restore to Default Colors',
  'edit.color_mapping.restore_short': 'Restore Colors',
  'edit.color_mapping.texts': 'Texts',
  'edit.color_mapping.texts.item1': 'Big Text',
  'edit.color_mapping.texts.item2': 'Title',
  'edit.color_mapping.texts.item3': 'Subtitle',
  'edit.color_mapping.texts.item4': 'Body',
  'edit.color_mapping.texts.item5': 'Label',
  'edit.color_mapping.background': 'Background',
  'edit.color_mapping.quote': 'Quote',
  'edit.color_mapping.quote.item1': 'Quote',
  'edit.color_mapping.quote.item2': 'Author',
  'edit.color_mapping.icons': 'Icons',
  'edit.color_mapping.icons.item1': 'Icon',
  'edit.color_mapping.icons.item2': 'Icon Fill',
  'edit.color_mapping.lists': 'Lists',
  'edit.color_mapping.lists.item1': 'List Bullet',
  'edit.color_mapping.lists.item2': 'List Title',
  'edit.color_mapping.lists.item3': 'List Body',
  'edit.color_mapping.table': 'Table',
  'edit.color_mapping.table.item1': 'Content',
  'edit.color_mapping.table.item2': 'Highlight',
  'edit.color_mapping.buttons': 'Buttons',
  'edit.color_mapping.buttons.item1': 'Text',
  'edit.color_mapping.buttons.item2': 'Button Fill',
  'edit.color_mapping.buttons.item3': 'Border',
  'edit.color_mapping.timeline': 'Timeline',
  'edit.color_mapping.timeline.item1': 'Timeline Bar',
  'edit.color_mapping.timeline.item2': 'Timeline Dot',
  'edit.color_mapping.form': 'Form',
  'edit.color_mapping.form.item1': 'Question',
  'edit.color_mapping.form.item2': 'Button Text',
  'edit.color_mapping.form.item3': 'Button Fill',
  'edit.color_mapping.form.item4': 'Icon',
  'edit.color_mapping.chart': 'Chart',
  'edit.color_mapping.chart.items': 'Color',
  'edit.color_mapping.text_highlight': 'Text highlight',
  'edit.color_mapping.text_highlight.item1': 'Highlight',
  'edit.color_mapping.text_highlight.item2': 'Highlight Background',
  'edit.color_mapping.shape': 'Shape',
  'edit.color_mapping.shape.item1': 'Shape Fill',
  'edit.color_mapping.decoration': 'Decorations',
  'edit.color_mapping.decoration.items': 'Decoration',
  // Edit page ends

  // User classify page
  'user_classify.business': 'Business',
  'user_classify.continue_with_decktopus': 'Continue with Decktopus',
  'user_classify.customer_service': 'Customer Service',
  'user_classify.education': 'Education',
  'user_classify.educator': 'Educator',
  'user_classify.engineering': 'Engineering',
  'user_classify.finance': 'Finance',
  'user_classify.friends': 'Friends',
  'user_classify.google_search': 'Google Search',
  'user_classify.hr': 'HR',
  'user_classify.legal': 'Legal',
  'user_classify.management': 'Management',
  'user_classify.other': 'Other',
  'user_classify.product': 'Product',
  'user_classify.sales_marketing': 'Sales Marketing',
  'user_classify.see_other_options': 'See other options',
  'user_classify.social_media': 'Social Media',
  'user_classify.student': 'Student',
  'user_classify.tell_us_about_yourself': 'Tell us about yourself',
  'user_classify.classify_question':
    'For what purpose are you using Decktopus?',
  'user_classify.business_question': 'What kind of work do you do?',
  'user_classify.how_to_hear_about_us': 'How did you hear about us?',
  // User classify page ends

  // Share modal
  'share_modal.embed.code': 'Get Embed Code',
  'share_modal.share_link': 'Share Link',
  'share_modal.get_share_link': 'Get Share Link',
  'share_modal.make_the_share_link_public': 'Make the share link public',
  'share_modal.select_from_list': 'Select from user list',

  'share_modal.share_link_updated': 'Share link updated successfully.',
  'share_modal.invite_people_to_collaborate': 'Invite People to Collaborate',
  'share_modal.invitations_sent': 'Invitations sent',
  'share_modal.organization_deck_permission': 'Organization Permission',
  'share_modal.valid_email_message':
    'Please enter a valid email address and hit enter',
  'share_modal.add_collaborators': 'Add Collaborator',
  'share_modal.not_collaborating_message':
    'You are not collaborating with anyone, yet.',
  'share_modal.collaborators': 'Collaborators',
  'share_modal.can_edit': 'Can Edit',
  'share_modal.can_view': 'Can View',
  'share_modal.embed_code': 'Embed Code',
  'share_modal.collaborated_users_updated': 'Collaborated users updated',
  'share_modal.save_complete': 'Changes are saved',
  'share_modal.pdf_export': 'Export PDF',
  'share_modal.pptx_export': 'Export PPTX',
  'share_modal.latest_exports': 'My latest Exports',

  'share_modal.custom_domain.status': 'Status',
  'share_modal.custom_domain.host': 'Host',
  'share_modal.custom_domain.completed': 'COMPLETED',
  'share_modal.custom_domain.pending': 'PENDING',
  'share_modal.custom_domain.record': 'Record',
  'share_modal.custom_domain.data': 'Data',
  'share_modal.custom_domain.cname': 'CNAME',
  'share_modal.custom_domain.share_link_placeholder':
    'e.g. https://document.yourdomain.com',
  'share_modal.custom_domain.domain_successfully': 'Domain saved successfully.',
  'share_modal.custom_domain.domain_deleted': 'Domain deleted successfully.',
  'share_modal.custom_domain.domain_failed':
    'The domain has not been verified yet. It may take up to 72 hours depending on your domain server',
  'share_modal.custom_domain.dns_record':
    'To connect your custom domain to your Decktopus, you need to update your DNS records based on the table below.',
  'share_modal.custom_domain.add_your_subdomain': 'Add your subdomain:',
  'share_modal.custom_domain.info': 'Info',
  'share_modal.custom_domain.verified': 'Verified',
  'share_modal.custom_domain.unverified': 'Unverified',
  'share_modal.custom_domain.connect_your_custom_domain':
    'Connect Your Custom Domain',
  // Share modal ends

  // Admin page
  'admin.menu.user_info': 'User Info',
  'admin.menu.back_to_search': 'Back to Search',
  'admin.search.welcome_support_panel':
    'Welcome to the <strong>Support Panel</strong>',
  'admin.search.you_can_search_by':
    'You can search by email, deck id or share key to list what you need.',
  'admin.search.share_key': 'Share Key',
  'admin.search.deck_id': 'Deck ID',
  'admin.search.user_not_found': 'User not found.',
  'admin.table.e_mail': 'Email',
  'admin.table.delete_user': 'Delete User',
  'admin.table.created_date': 'Created Date',
  'admin.confirm.cannot_undo_delete_user':
    'You can not undo this function. Are you sure you want to delete this user?',
  'admin.table.actions': 'Actions',
  'admin.action_menu.login_as_user': 'Login as user',
  'admin.dashboard.header.back_to_support': 'Back to Support panel',
  'admin.dashboard.header.logged_in_as_user': 'You have logged in as a user.',
  // Admin page ends

  // Table component menu items
  'table.add_row_above': 'Add row above',
  'table.add_row_below': 'Add row below',
  'table.add_column_left': 'Add column left',
  'table.add_column_right': 'Add column right',
  'table.delete_row': 'Delete row',
  'table.delete_column': 'Delete column',
  // Table component menu items ends

  // Payment confirmation modal
  'payment_confirmation.title': 'Upgrade your plan to {{ planName }}',
  'payment_confirmation.subtitle':
    'You are just a step away from enjoying Decktopus’s exclusive features',
  'payment_confirmation.plan': 'Plan:',
  'payment_confirmation.payment_method': 'Payment Method:',
  'payment_confirmation.card_number': 'Card Number:',
  'payment_confirmation.card_date': 'Card Date:',
  'payment_confirmation.total': 'Total:',
  'payment_confirmation.contact_us':
    'If you have any questions or need assistance, feel free to <0>contact us.</0>',
  'payment_confirmation.cancel': 'Cancel Payment',
  'payment_confirmation.confirm': 'Confirm and Pay',
  'payment_confirmation.seat': 'Seat',
  'payment_confirmation.pro': 'Pro AI',
  'payment_confirmation.business': 'Business AI',
  'payment_confirmation.remaining_line':
    'Remaining time on {{ planName }} after {{ date }}',
  'payment_confirmation.unused_line':
    'Unused time on {{ planName }} after {{ date }}',
  'payment_confirmation.total_tooltip':
    'The payment will be prorated based on the remaining days in your current billing cycle.',
  // Payment confirmation modal ends
}
