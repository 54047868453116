import React, { useMemo } from 'react'

import { IChart } from './types'
import { chartStyles } from './styles'
import { CHART_TYPE, ChartSeries, Chart as LibChart } from 'src/lib/chart'
import { ComponentChartDataSchema } from 'src/types/api/requestObjects'
import { ComponentChartTypes } from 'src/types/api/enums'

export const Chart: React.FC<IChart> = React.memo(
  ({ data, scale, className, dataAttr }) => {
    const componentData: ComponentChartDataSchema = useMemo(
      () => data.data as ComponentChartDataSchema,
      [data.data],
    )
    const fontSize = useMemo(() => {
      const minVal = Math.min(
        data.positions.width || 1,
        data.positions.height || 1,
      )
      return minVal / 100
    }, [data])

    const chartTypeConvert = useMemo(() => {
      switch (componentData.options.type) {
        case ComponentChartTypes.BAR:
          return CHART_TYPE.BAR_CHART
        case ComponentChartTypes.PIE:
          return CHART_TYPE.PIE_CHART
        case ComponentChartTypes.DONUT:
          return CHART_TYPE.DONUT_CHART
        case ComponentChartTypes.LINE:
          return CHART_TYPE.LINE_CHART
        default:
          return CHART_TYPE.PIE_CHART
      }
    }, [componentData.options.type])

    return (
      <div
        css={chartStyles}
        className={className}
        {...dataAttr}
        style={{ fontSize: `${fontSize}em` }}
      >
        <LibChart
          width={(data.positions.width || 1) / (scale || 1)}
          height={(data.positions.height || 1) / (scale || 1)}
          size={{
            width: data.positions.width,
            height: data.positions.height,
          }}
          chartType={chartTypeConvert}
          series={componentData.series as ChartSeries[]}
          options={{
            title: componentData.options.title,
            titleSize: fontSize * 10,
            stroke: {
              width: fontSize * 0.75,
            },
            bullet: {
              size: fontSize * 2,
              marker: 50,
            },
            labels: {
              x: componentData.options.labels.x,
              showX: componentData.options.labels.showX,
              y: componentData.options.labels.y,
              showY: componentData.options.labels.showY,
            },
            legends: componentData.options.legends,
            showGrids: componentData.options.showGrids,
          }}
        />
      </div>
    )
  },
)

Chart.displayName = 'Chart'
