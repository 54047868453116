import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import { RootState } from 'src/store'
import {
  setOutline,
  updateAimSuggestion,
  updateAudienceSuggestion,
  setPromptId,
  setTemplates,
} from 'src/store'
import {
  postSaveAudienceBody,
  postSavePDFAimBody,
  postSavePDFContentBody,
  postSaveTopicAimBody,
  postSaveTopicOutlineBody,
  postSaveTopicPromptBody,
} from 'src/types/api/requestObjects'
import {
  postGetAIThemesResponse,
  postGetAimResponse,
  postGetAudienceResponse,
  postGetOutlineResponse,
} from 'src/types/api/responseObjects'
import { useFlags } from 'src/hooks'

interface IUseAiFlowApiReturn {
  getPdfImportAudience: (pdfContent: postSavePDFContentBody) => Promise<boolean>
  getAiFlowAudience: (promptValue: postSaveTopicPromptBody) => Promise<boolean>
  getAiFlowAim: (params: postSaveAudienceBody) => Promise<boolean>
  getAiFlowOutline: (params: postSaveTopicAimBody) => Promise<boolean>
  getAiFlowTheme: (params: postSaveTopicOutlineBody) => Promise<boolean>
  getPdfImportTheme: (params: postSavePDFAimBody) => Promise<boolean>
  isLoading: boolean
}

export const useAiFlowApi = (): IUseAiFlowApiReturn => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const flags = useFlags()

  const { aiFlowId } = useSelector(({ aiFlow }: RootState) => ({
    aiFlowId: aiFlow.aiFlowId,
  }))

  const getPdfImportAudience = async (pdfContent: postSavePDFContentBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAudienceResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/pdf/content`,
          data: {
            pdfContent: pdfContent.pdfContent,
          },
        })

      if (flags.FE_124_AI_FLOW_FIX) {
        const currentAiFlowId = sessionStorage.getItem('aiFlowId')
        if (currentAiFlowId !== aiFlowId) {
          return false
        }
      }
      dispatch(setPromptId(res.data.data.deckPromptId))
      dispatch(updateAudienceSuggestion(res.data.data.audiences))
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getAiFlowAudience = async (promptValue: postSaveTopicPromptBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAudienceResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/topic/prompt`,
          data: {
            prompt: promptValue.prompt,
          },
        })

      if (flags.FE_124_AI_FLOW_FIX) {
        const currentAiFlowId = sessionStorage.getItem('aiFlowId')
        const landPrompt = localStorage.getItem('landingPrompt')
        if (currentAiFlowId !== aiFlowId && !landPrompt) {
          return false
        }
      }
      dispatch(setPromptId(res.data.data.deckPromptId))
      dispatch(updateAudienceSuggestion(res.data.data.audiences))
      localStorage.removeItem('landingPrompt')
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getAiFlowAim = async (params: postSaveAudienceBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAimResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/audience`,
          data: {
            deckPromptId: params.deckPromptId,
            audience: params.audience,
          },
        })

      if (flags.FE_124_AI_FLOW_FIX) {
        const currentAiFlowId = sessionStorage.getItem('aiFlowId')
        if (currentAiFlowId !== aiFlowId) {
          return false
        }
      }
      dispatch(updateAimSuggestion(res.data.data.aims))
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getAiFlowOutline = async (params: postSaveTopicAimBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetOutlineResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/topic/aim`,
          data: {
            deckPromptId: params.deckPromptId,
            aim: params.aim,
          },
        })

      if (flags.FE_124_AI_FLOW_FIX) {
        const currentAiFlowId = sessionStorage.getItem('aiFlowId')
        if (currentAiFlowId !== aiFlowId) {
          return false
        }
      }
      dispatch(setOutline(res.data.data.sections))
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getPdfImportTheme = async (params: postSavePDFAimBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAIThemesResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/pdf/aim`,
          data: {
            deckPromptId: params.deckPromptId,
            aim: params.aim,
          },
        })
      if (flags.FE_124_AI_FLOW_FIX) {
        const currentAiFlowId = sessionStorage.getItem('aiFlowId')
        if (currentAiFlowId !== aiFlowId) {
          return false
        }
      }
      dispatch(setTemplates(res.data))
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getAiFlowTheme = async (params: postSaveTopicOutlineBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAIThemesResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/topic/outline`,
          data: {
            deckPromptId: params.deckPromptId,
            outline: params.outline,
          },
        })
      if (flags.FE_124_AI_FLOW_FIX) {
        const currentAiFlowId = sessionStorage.getItem('aiFlowId')
        if (currentAiFlowId !== aiFlowId) {
          return false
        }
      }
      dispatch(setTemplates(res.data))
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  return {
    getPdfImportAudience,
    getAiFlowAudience,
    getAiFlowAim,
    getAiFlowOutline,
    getAiFlowTheme,
    getPdfImportTheme,
    isLoading,
  }
}
