import { SVGTypes } from 'src/types/api/enums'

export type SVG_MAP_ENTRY = Record<
  SVGTypes,
  {
    path?: string
    alias?: SVGTypes
  }
>

export type SVG_MAP = Record<number, Partial<SVG_MAP_ENTRY>>

const defaults: SVG_MAP_ENTRY = {
  [SVGTypes.BLANK]: {
    path: 'BLANK',
  },
  [SVGTypes.GENERIC_1]: {
    path: 'GENERIC_1',
  },
  [SVGTypes.GENERIC_2]: {
    path: 'GENERIC_2',
  },
  [SVGTypes.GENERIC_3]: {
    path: 'GENERIC_3',
  },
  [SVGTypes.GENERIC_4]: {
    path: 'GENERIC_4',
  },
  [SVGTypes.COVER_ALPHA_1]: {
    path: 'COVER_ALPHA_1',
  },
  [SVGTypes.COVER_LEFT_1]: {
    path: 'COVER_LEFT_1',
  },
  [SVGTypes.COVER_LEFT_2]: {
    path: 'COVER_LEFT_2',
  },
  [SVGTypes.COVER_RIGHT_1]: {
    path: 'COVER_RIGHT_1',
  },
  [SVGTypes.COVER_RIGHT_2]: {
    path: 'COVER_RIGHT_2',
  },
  [SVGTypes.MASK_BOTTOM_1]: {
    path: 'MASK_BOTTOM_1',
  },
  [SVGTypes.MASK_BOTTOM_2]: {
    path: 'MASK_BOTTOM_2',
  },
  [SVGTypes.MASK_LEFT_1]: {
    path: 'MASK_LEFT_1',
  },
  [SVGTypes.MASK_LEFT_2]: {
    path: 'MASK_LEFT_2',
  },
  [SVGTypes.MASK_RIGHT_1]: {
    path: 'MASK_RIGHT_1',
  },
  [SVGTypes.MASK_RIGHT_2]: {
    path: 'MASK_RIGHT_2',
  },
}

const svgMapException: SVG_MAP = {
  1: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  2: {
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.MASK_LEFT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.MASK_RIGHT_1,
    },
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  3: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  4: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  5: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.MASK_BOTTOM_2]: {
      alias: SVGTypes.MASK_BOTTOM_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  6: {
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  7: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  8: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.MASK_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.MASK_LEFT_1,
    },
    [SVGTypes.MASK_BOTTOM_2]: {
      alias: SVGTypes.MASK_BOTTOM_1,
    },
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  9: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  10: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_BOTTOM_2]: {
      alias: SVGTypes.MASK_BOTTOM_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  11: {
    [SVGTypes.BLANK]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.COVER_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.COVER_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_BOTTOM_2]: {
      alias: SVGTypes.MASK_BOTTOM_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  12: {
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  13: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.MASK_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.MASK_LEFT_1,
    },
    [SVGTypes.MASK_BOTTOM_2]: {
      alias: SVGTypes.MASK_BOTTOM_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  14: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.MASK_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.MASK_LEFT_1,
    },
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  15: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.MASK_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.MASK_LEFT_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  16: {
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  17: {
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  18: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  19: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  20: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  21: {
    [SVGTypes.COVER_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.COVER_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_BOTTOM_2]: {
      alias: SVGTypes.MASK_BOTTOM_1,
    },
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  22: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  23: {
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  24: {
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  25: {
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  26: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.MASK_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.MASK_LEFT_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  27: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  28: {
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  29: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.MASK_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.MASK_LEFT_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  30: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.MASK_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.MASK_LEFT_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  31: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.MASK_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.MASK_LEFT_1,
    },
  },
  32: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  33: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  34: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  35: {
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  36: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.MASK_BOTTOM_2]: {
      alias: SVGTypes.MASK_BOTTOM_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  37: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  38: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.MASK_BOTTOM_2]: {
      alias: SVGTypes.MASK_BOTTOM_1,
    },
    [SVGTypes.GENERIC_1]: {
      alias: SVGTypes.BLANK,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  39: {
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  40: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.MASK_BOTTOM_2]: {
      alias: SVGTypes.MASK_BOTTOM_1,
    },
    [SVGTypes.GENERIC_2]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  41: {
    [SVGTypes.MASK_RIGHT_1]: {
      alias: SVGTypes.COVER_RIGHT_1,
    },
    [SVGTypes.MASK_RIGHT_2]: {
      alias: SVGTypes.COVER_RIGHT_2,
    },
    [SVGTypes.MASK_LEFT_1]: {
      alias: SVGTypes.COVER_LEFT_1,
    },
    [SVGTypes.MASK_LEFT_2]: {
      alias: SVGTypes.COVER_LEFT_2,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
  42: {
    [SVGTypes.GENERIC_3]: {
      alias: SVGTypes.GENERIC_1,
    },
    [SVGTypes.GENERIC_4]: {
      alias: SVGTypes.GENERIC_1,
    },
  },
}

export const decorConfig = {
  defaults,
  svgMapException,
}
