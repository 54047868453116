import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ITooltip, TOOLTIP_THEME } from './types'

type ITooltipStyles = Pick<ITooltip, 'width' | 'theme' | 'height'>
export const tooltipStyles =
  ({ width, height }: ITooltipStyles) =>
  (theme: ITheme) => css`
    position: absolute;
    box-shadow: ${theme.boxShadow[3]};
    font-size: ${theme.fontSize.small} !important;
    z-index: ${theme.zIndex.tooltip};
    border-radius: ${theme.borderRadius[2]};
    padding: ${theme.spacing['2xsmall']} ${theme.spacing.small} !important;
    max-width: ${width}px;
    max-height: ${height}px;
  `

type ITooltipViewStyles = Pick<ITooltip, 'theme'>
export const tooltipViewStyles =
  ({ theme: tooltipTheme }: ITooltipViewStyles) =>
  (theme: ITheme) => css`
    width: 100%;
    box-shadow: ${theme.boxShadow[3]};
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.regular};
    border-radius: ${theme.borderRadius[2]};
    padding: ${theme.spacing['2xsmall']} ${theme.spacing.small};

    background: ${tooltipTheme === TOOLTIP_THEME.LIGHT
      ? theme.colors.background.DEFAULT
      : theme.colors.dark.DEFAULT};

    color: ${tooltipTheme === TOOLTIP_THEME.LIGHT
      ? theme.colors.text.DEFAULT
      : theme.colors.white.DEFAULT};
  `
