import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { VALIDATION_RULE_TYPES, useAiApi, useLanguage } from 'src/hooks'
import {
  BUTTON_THEME,
  BUTTON_WIDTH,
  Button,
  Dialog,
  Modal,
  icons,
} from 'src/lib'
import { setAllowDeleteShortcut } from 'src/store'
import { IPropAiImage } from './types'

export const PropAiImage: React.FC<IPropAiImage> = React.memo(
  ({ onChange, disabled }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const { generateAiImage, isLoading } = useAiApi()

    const [isModalOpen, setModalOpen] = useState(false)
    const [aiPrompt, setAiPrompt] = useState('')

    const handleAiClick = useCallback(async () => {
      const res = await generateAiImage([aiPrompt])
      onChange(res)
      setModalOpen(false)
    }, [aiPrompt, onChange])

    return (
      <>
        <Button
          theme={BUTTON_THEME.GRADIENT}
          width={BUTTON_WIDTH.FULL}
          icon={icons.sparkling}
          text={t('edit.properties.ai_image.create')}
          onClick={() => {
            setModalOpen(true)
            dispatch(setAllowDeleteShortcut(false))
          }}
          isLoading={isLoading}
          disabled={disabled || isLoading}
        />
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <Dialog
            title={t('edit.properties.ai_image.generate_with_ai')}
            onSubmit={handleAiClick}
            promptPlaceholder={t('edit.properties.ai_image.describe')}
            onPromptChange={setAiPrompt}
            onCloseClick={() => setModalOpen(false)}
            submitLabel={t('edit.properties.ai_image.generate')}
            submitTheme={BUTTON_THEME.GRADIENT}
            promptValue={aiPrompt}
            submitIcon={icons.sparkling}
            submitDisabled={isLoading}
            promptValidationRules={{
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  name: t('edit.media.modal.ai.input'),
                  value: 3,
                }),
              },
            }}
          />
        </Modal>
      </>
    )
  },
)
