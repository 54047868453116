import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const adjustSeatsStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.colors.white.DEFAULT};
  border-radius: ${theme.borderRadius[4]};
  border: 1px solid ${theme.colors.outline.DEFAULT};
  padding: ${theme.gap.xlarge}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${theme.dimensions.adjustSeatsWidth.DEFAULT}px;
  }
`

export const adjustSeatsTitleStyles = (theme: ITheme) => css`
  color: ${theme.colors.black[80]};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  padding-bottom: ${theme.gap.xlarge}px;
`

export const adjustSeatsRowStyles = (theme: ITheme) => css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.text[2]};
  font-weight: ${theme.fontWeight.medium};
  padding-bottom: ${theme.gap.xsmall}px;

  &.first-row {
    padding-top: ${theme.gap.xlarge}px;
  }
`

export const adjustSeatsInfoStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: ${theme.gap.xsmall}px;
  color: ${theme.colors.text[2]};
  font-weight: ${theme.fontWeight.medium};

  & > span {
    padding-left: ${theme.gap['3xsmall']}px;
  }
`

export const adjustSeatsTotalStyles = ({ colors }: ITheme) => css`
  color: ${colors.text.DEFAULT};
`

export const seperatorStyles = ({ colors, gap }: ITheme) => css`
  padding-bottom: ${gap.xsmall}px;
  &::after,
  &::before {
    border-color: ${colors.outline.DEFAULT};
  }
`

export const adjustSeatsActionsRowStyle = ({ gap }: ITheme) => css`
  display: flex;
  width: 100%;
  justify-content: end;
  padding-top: ${gap.medium}px;
`

export const adjustSeatsCloseStyles = ({ breakpoints, gap }: ITheme) => css`
  display: block;
  position: absolute;
  top: ${gap.small}px;
  right: ${gap.small}px;

  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
