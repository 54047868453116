import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import loginBg from 'src/assets/images/login-bg.png'
import { AUTH_PAGE_LAYOUT, IAuthPageLayout } from './types'

export const contentAreaStyles = css``

export const formStyles = (theme: ITheme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.gap['medium']}px;

  @media (min-width: ${theme.breakpoints.small}px) {
    width: 300px;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    width: 380px;
  }

  @media (min-width: ${theme.breakpoints.xlarge}px) {
    width: 450px;
  }
`

export const bannerStyles = ({
  fontSize,
  fontWeight,
  colors,
  gap,
}: ITheme) => css`
  display: flex;
  justify-content: center;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize['4xlarge']};
  color: ${colors.white.DEFAULT};
  text-align: center;
  padding: 0 ${gap['3xlarge']}px;
  max-width: 820px;
`

export const fullAbsoluteStyles = `
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

export const backgroundStyles = css`
  z-index: 1;
  position: relative;
  background-image: url(${loginBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const wrapperStyles = css`
  height: 100vh;
  position: relative;
  ${backgroundStyles};
`

export const bgImageStyles = css`
  ${fullAbsoluteStyles};
  width: 100%;
  height: 100%;
`

type IBackgroundOverlayStyles = Pick<IAuthPageLayout, 'type'>
export const backgroundOverlayStyles =
  ({ type }: IBackgroundOverlayStyles) =>
  (theme: ITheme) => `
  ${fullAbsoluteStyles};
	background: ${
    type === AUTH_PAGE_LAYOUT.SIGN_UP
      ? theme.gradient.SOFT
      : theme.gradient.OVERLAY
  };
`

export const contentStyles = css`
  ${fullAbsoluteStyles};
  display: flex;
  align-items: stretch;
  height: 100vh;
  z-index: 2;
`

export const leftAreaStyles = (theme: ITheme) => css`
  display: none;
  position: relative;
  ${backgroundStyles};

  @media (min-width: ${theme.breakpoints.desktop}px) {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
`

export const leftAreaImageStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  img {
    @media (min-width: ${theme.breakpoints.desktop}px) {
      max-height: 50%;
    }
  }
`

export const leftAwardWrapperStyles = (theme: ITheme) => css`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 34px;
  color: ${theme.colors.white.DEFAULT};
  display: none;

  @media (min-width: ${theme.breakpoints.desktop}px) {
    display: flex;
    justify-content: center;
  }
`

export const rightAreaStyles = (theme: ITheme) => css`
  width: 100%;
  background: ${theme.colors.dark.DEFAULT};
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.xlarge};
  overflow-y: auto;
  min-height: max-content;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    background: transparent;
    display: flex;
    align-items: center;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: auto;
    align-items: center;
    padding-top: 0;
  }
`

export const formCardStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${theme.gap['6xlarge']}px 0;
  background: ${theme.colors.white.DEFAULT};
  margin-top: initial;

  @media (max-width: ${theme.breakpoints.small}px) {
    padding: ${theme.gap['xlarge']}px;
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    height: auto;
    padding: ${theme.gap['3xlarge']}px;
    border-radius: ${theme.borderRadius[8]};
    width: auto;
    margin-top: auto;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    padding: ${theme.gap['6xlarge']}px;
    border-radius: 0;
    height: 100vh;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    padding: 66px;
  }

  @media (min-width: ${theme.breakpoints.xlarge}px) {
    padding: 95px;
  }
`

export const awardContainerStyles = (theme: ITheme) => css`
  display: none;
  justify-content: flex-end;
  margin-top: auto;
  padding-bottom: ${theme.spacing['4xlarge']};
  max-height: ${theme.dimensions.awardHeight.DEFAULT}px;
  color: ${theme.colors.white.DEFAULT};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    display: block;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    display: none;
  }
`
