import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getAICreditSpendSummarizedResponse,
  getAICreditsTotalResponse,
  getCustomerStripePortalResponse,
  getUserDetailsResponse,
  getUserOrganizationsResponse,
} from 'src/types/api/responseObjects'

interface IUserSliceState {
  data: getUserDetailsResponse['data']['user'] | null
  meta: getUserDetailsResponse['data']['meta'] | null
  aicredits: {
    total: getAICreditsTotalResponse['data']['totalCredits'] | null
    summary: getAICreditSpendSummarizedResponse['data'] | null
  }
  portalUrl?: getCustomerStripePortalResponse['data']['portalUrl'] | null
  organizationUsers?:
    | getUserOrganizationsResponse['data']['organizationUsers']
    | null
}

const initialState: IUserSliceState = {
  data: null,
  meta: null,
  aicredits: {
    total: null,
    summary: null,
  },
  portalUrl: null,
  organizationUsers: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<getUserDetailsResponse['data']>) => {
      state.data = action.payload.user
      state.meta = action.payload.meta
    },
    setAICredits: (
      state,
      action: PayloadAction<getAICreditsTotalResponse['data']['totalCredits']>,
    ) => {
      state.aicredits.total = action.payload
    },
    setAICreditsSummary: (
      state,
      action: PayloadAction<getAICreditSpendSummarizedResponse['data']>,
    ) => {
      state.aicredits.summary = action.payload
    },
    setPortalUrl: (
      state,
      action: PayloadAction<
        getCustomerStripePortalResponse['data']['portalUrl']
      >,
    ) => {
      state.portalUrl = action.payload
    },
    setSeatSize: (state, action: PayloadAction<number>) => {
      if (state.data?.activeUserPlan) {
        state.data.activeUserPlan.seatSize = action.payload
      }
    },
    setOrganizations: (
      state,
      action: PayloadAction<
        getUserOrganizationsResponse['data']['organizationUsers']
      >,
    ) => {
      state.organizationUsers = action.payload
    },
  },
})

export const {
  setUser,
  setAICredits,
  setAICreditsSummary,
  setPortalUrl,
  setSeatSize,
  setOrganizations,
} = userSlice.actions

export default userSlice.reducer
