import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'src/store'
import { CANVAS_DATA_TYPE, CANVAS_TYPE, Canvas } from 'src/components/canvas'
import {
  previewPageWrapperStyles,
  headerStyles,
  slideStyles,
  footerStyles,
  slideNavigations,
} from './styles'
import { EditSlides } from './components'
import { Icon, icons } from 'src/lib'
import { colors } from 'src/theme'
import { VoiceRecording } from 'src/components/voice-recording'
import watermark from 'src/assets/images/watermark.svg'
import { IPreviewPage } from './types'
import { DECK_PAGE_MODE } from 'src/layouts/deck-page-layout/types'
import { FontImporter } from './components/font-importer'

export const PreviewPage: React.FC<IPreviewPage> = React.memo(({ mode }) => {
  const isExportMode = useMemo(() => mode === DECK_PAGE_MODE.EXPORT, [])
  const params = useParams()
  const navigate = useNavigate()

  const [isFullScreen, setIsFullScreen] = useState(false)
  const [activeSlide, setActiveSlide] = useState<number | ''>(
    params.slide ? parseInt(params.slide) : 1,
  )

  const {
    activeSlides,
    deckName,
    soundUrl,
    isNextSlideBlocked,
    deckOrganizationId,
  } = useSelector(({ edit, canvas }: RootState) => ({
    activeSlidesData: edit.activeSlideData,
    activeSlideDataID: edit.activeSlideDataID,
    activeSlides: edit.activeDeck.data?.deckData?.data.slides,
    deckName: edit.activeDeck.data?.deck?.name,
    soundUrl: edit.activeDeck.data?.deckData?.data.slides.find(
      ({ slideId }) => slideId === edit.activeSlideID,
    )?.soundUrl,
    isNextSlideBlocked: canvas.isNextSlideBlocked,
    deckOrganizationId: edit.activeDeck.data?.deck?.organizationId,
  }))

  const maxSlide = useMemo(
    () => activeSlides?.filter(({ isDeleted }) => !isDeleted)?.length || 0,
    [activeSlides],
  )

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const targetNumber =
        e.currentTarget.value === '' ? '' : parseInt(e.currentTarget.value)
      setActiveSlide(targetNumber)
    },
    [],
  )

  const locationContext = useMemo(() => {
    const contextArray = location.pathname.split('/')
    return contextArray[1]
  }, [location.pathname])

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const targetSlide =
          activeSlide === ''
            ? 1
            : activeSlide > maxSlide
            ? maxSlide
            : activeSlide
        setActiveSlide(targetSlide)
        navigate(`/${locationContext}/${params.id}/${targetSlide}`)
      }
    },
    [activeSlide],
  )

  const onInputClickHandler = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      e.currentTarget.select()
    },
    [],
  )

  useEffect(() => {
    params.slide && setActiveSlide(parseInt(params.slide))
  }, [params.slide])

  if (!maxSlide) {
    return null
  }

  const handleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }
    }
    setIsFullScreen(!isFullScreen)
  }, [isFullScreen])

  const handleSlideNavigation = useCallback(
    (param: 'decrement' | 'increment') => {
      if (param === 'decrement' && Number(activeSlide) > 1) {
        setActiveSlide(Number(activeSlide) - 1)
        navigate(`/${locationContext}/${params.id}/${Number(activeSlide) - 1}`)
      } else {
        if (!isNextSlideBlocked) {
          setActiveSlide(Number(activeSlide) + 1)
          navigate(
            `/${locationContext}/${params.id}/${Number(activeSlide) + 1}`,
          )
        }
      }
    },
    [activeSlide, params, isNextSlideBlocked],
  )

  const fullScreenChangeHandler = useCallback(() => {
    document.fullscreenElement ? setIsFullScreen(true) : setIsFullScreen(false)
  }, [])

  useEffect(() => {
    document.addEventListener('fullscreenchange', fullScreenChangeHandler)

    return () => {
      document.removeEventListener('fullscreenchange', fullScreenChangeHandler)
    }
  }, [])

  const showWatermark = useMemo(() => !deckOrganizationId, [deckOrganizationId])

  return (
    <div css={previewPageWrapperStyles}>
      <div css={headerStyles({ isFullScreen, isExportMode })}>
        <div className="deck-name">{deckName}</div>
        <div className="voice">
          {soundUrl && <VoiceRecording audioUrl={soundUrl} noUpload={true} />}
        </div>
      </div>
      <div css={slideStyles({ isFullScreen, isExportMode })}>
        {activeSlide && activeSlide > 1 && (
          <div
            css={slideNavigations({
              direction: 'left',
              isFullScreen,
              isExportMode,
            })}
            className="slide-navigation-area"
            onClick={() => handleSlideNavigation('decrement')}
          >
            <div className="navigation-area">
              <Icon icon={icons.chevron_left} color={colors.white.DEFAULT} />
            </div>
          </div>
        )}
        <Canvas
          canvasType={CANVAS_TYPE.PREVIEW}
          dataType={CANVAS_DATA_TYPE.ACTIVE_SLIDE}
        />
        <EditSlides
          canvasType={CANVAS_TYPE.PREVIEW}
          className="preview-edit-slides-styles"
        />
        {activeSlide && activeSlide < maxSlide && (
          <div
            css={slideNavigations({
              direction: 'right',
              isFullScreen,
              isExportMode,
            })}
            className="slide-navigation-area"
            onClick={() => handleSlideNavigation('increment')}
          >
            <div className="navigation-area">
              <Icon icon={icons.chevron_right} color={colors.white.DEFAULT} />
            </div>
          </div>
        )}
      </div>
      <div css={footerStyles({ isFullScreen, isExportMode })}>
        <div className="watermark">
          {showWatermark && (
            <img
              src={watermark}
              onClick={() =>
                window.location.replace('https://www.decktopus.com/')
              }
            />
          )}
        </div>
        <div className="slide-control">
          <input
            value={activeSlide}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onClick={onInputClickHandler}
          />
          <span>/</span>
          <div className="max-slides">{maxSlide}</div>
        </div>
        <div className="icon-wrapper" onClick={() => handleFullscreen()}>
          <Icon
            icon={isFullScreen ? icons.exit_fullscreen : icons.fullscreen}
            color={colors.white.DEFAULT}
          />
        </div>
      </div>
      <FontImporter />
    </div>
  )
})
