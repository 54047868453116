import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { FontServices } from 'src/services'
import { colorMapSchemaHandler } from 'src/services/colorServices'
import { RootState } from 'src/store'

export const useActiveCssVariables = (): string => {
  const { activeColorMap, themeColor, themeFont } = useSelector(
    ({ edit }: RootState) => ({
      activeColorMap: edit.activeDeck.data?.deckData?.theme.data.colorMap,
      themeColor: edit.activeDeck.data?.deckData?.themeColor.data,
      themeFont: edit.activeDeck.data?.deckData?.themeFont.data,
    }),
  )

  const colorMap = useCallback(
    (swapped?: boolean) => {
      return Object.entries(activeColorMap || {})
        .map(([key, values]) => colorMapSchemaHandler({ values, key, swapped }))
        .filter((v) => v)
        .flatMap((colorMap) => colorMap)
        .join(' ')
    },
    [activeColorMap],
  )

  return `
		--first: ${themeColor?.first};
		--second: ${themeColor?.second};
		--third: ${themeColor?.third};
		--fourth: ${themeColor?.fourth};
		--wht: #ffffff;
		--blck: #000000;
		
		--font-primary: ${FontServices.fontFamilyCleaner(themeFont?.primary)};
		--font-primary-weight: ${FontServices.fontWeightCalculator(themeFont?.primary)};
		--font-secondary: ${FontServices.fontFamilyCleaner(themeFont?.secondary)};
		--font-secondary-weight: ${FontServices.fontWeightCalculator(
      themeFont?.secondary,
    )};

		&.theme-default {
			${colorMap()};
		}

		&.theme-swapped {
			${colorMap(true)};
		}
`
}
