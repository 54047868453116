import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const exportPageStyles = css`
  min-height: 27px;
  display: flex;
  align-items: center;
`

export const wrapperStyles = css`
  width: 100%;
  overflow: hidden;
`

export const iconStyles = (theme: ITheme) => css`
  margin-right: ${theme.spacing['2xsmall']};
  margin-left: ${theme.spacing['3xsmall']};
`

export const latestExportStyles =
  (myLatestExport: boolean) => (theme: ITheme) => css`
    font-size: ${theme.fontSize.small};
    color: ${!myLatestExport
      ? `${theme.colors.deactive.DEFAULT}`
      : `${theme.colors.text.DEFAULT}`};
    margin-bottom: ${theme.gap.medium}px;
    font-weight: ${theme.fontWeight.medium};
  `

export const tabStyles = (theme: ITheme) => css`
  @media (min-width: ${theme.breakpoints.small}px) {
    width: 80%;
    height: 38px;
    font-size: ${theme.fontSize.xsmall};
  }
  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
    font-size: ${theme.fontSize.small};
  }
`
export const spanStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.small};
  max-width: ${theme.dimensions.spanWidth.DEFAULT}px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const spanAndTextStyles = (theme: ITheme) => css`
  margin-right: ${theme.gap.small}px;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
`

export const checkButtonStyles = (theme: ITheme) => css`
  margin-right: ${theme.spacing['small']};
`
export const deckNameFontWeight = (theme: ITheme) => css`
  font-weight: ${theme.fontWeight.regular};
`

export const underlineStyles = (theme: ITheme) => css`
  text-decoration: underline;
  color: ${theme.colors.primary.DEFAULT};
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
  pointer: cursor;
  padding: ${theme.gap['4xsmall']}px 0px !important;
`
export const bottomSpaceWrapper = (theme: ITheme) => css`
  margin-bottom: ${theme.gap.small}px;

  & > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    max-width: 350px;
    text-overflow: ellipsis;
  }
`
export const exportTitleStyles = (theme: ITheme) => css`
  display: flex;
  margin-bottom: ${theme.gap.small}px;
`
