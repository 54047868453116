import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useActiveColorMap, useActiveThemeColors } from 'src/hooks'
import { v4 as uuidv4 } from 'uuid'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { IChartAltered } from './types'
import { chartStyles } from './styles'
import { ColorMapSchema } from 'src/types/api/requestObjects'
import { colors, theme } from 'src/theme'
import { isContainingCssVariable } from 'src/services/colorServices'

export const ChartXYBar: React.FC<IChartAltered> = React.memo(
  ({ series, options, size, width, height, className, dataAttr }) => {
    const chartId = uuidv4()
    const colorMap = useActiveColorMap({})
    const activeThemeColors = useActiveThemeColors()
    const chartXYBarChartRef = useRef<am5xy.XYChart | null>(null)
    const chartXYBarSeriesRef = useRef<am5xy.ColumnSeries | null>(null)
    const chartXYBarLegendRef = useRef<am5.Legend | null>(null)
    const chartXYBarXAxisRef =
      useRef<am5xy.CategoryAxis<am5xy.AxisRenderer> | null>(null)
    const chartXYBarYAxisRef =
      useRef<am5xy.ValueAxis<am5xy.AxisRenderer> | null>(null)
    const chartXYBarRootRef = useRef<am5.Root | null>(null)
    const [chartRendered, setChartRendered] = useState(false)

    const chartColors = useMemo(() => {
      return (colorMap?.charts as ColorMapSchema[])
        ?.flatMap((value) => value.default.colors)
        .map((color) => {
          if (color.includes('first'))
            return activeThemeColors?.first ?? colors.black.DEFAULT
          if (color.includes('second'))
            return activeThemeColors?.second ?? colors.black.DEFAULT
          if (color.includes('third'))
            return activeThemeColors?.third ?? colors.black.DEFAULT
          if (color.includes('fourth'))
            return activeThemeColors?.fourth ?? colors.black.DEFAULT
          if (color.includes('wht')) return '#ffffff'
          if (color.includes('blck')) return '#000000'

          return color.startsWith('#') ||
            isContainingCssVariable({ text: color })
            ? color
            : '#' + color
        })
    }, [colorMap?.charts, activeThemeColors])
    const DEFAULT_COLORS = useMemo(() => Object.values(theme.colors.data), [])

    useLayoutEffect(() => {
      if (!series.length) return

      const root = am5.Root.new(chartId)
      root._logo?.dispose()
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          layout: root.verticalLayout,
          paddingBottom: options.labels.y ? 30 : 0,
          paddingLeft: options.labels.x ? 30 : 0,
          ...(width ? { width: size?.width || width } : {}),
          ...(height ? { height: size?.height || height } : {}),
        }),
      )

      chart.children.unshift(
        am5.Label.new(root, {
          text: options.title,
          paddingBottom: 50,
          fontSize: options.titleSize,
        }),
      )

      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          categoryField: 'name',
        }),
      )

      xAxis.children.push(
        am5.Label.new(root, {
          text: options.labels.showX ? options.labels.x : '',
          x: am5.p50,
          centerX: am5.p50,
        }),
      )
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      )

      yAxis.children.push(
        am5.Label.new(root, {
          text: options.labels.showY ? options.labels.y : '',
          x: options.labels.y ? -30 : 0,
          y: am5.p50,
          centerY: am5.p50,
          rotation: -90,
        }),
      )

      xAxis.data.setAll(series[0].values)

      if (!options.showGrids) {
        yAxis.get('renderer').grid.template.set('forceHidden', true)
        xAxis.get('renderer').grid.template.set('forceHidden', true)
      }

      series.forEach(({ label, values }, index) => {
        // Create series
        const currentSeries = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: label,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            categoryXField: 'name',
            fill: am5.color(
              chartColors?.length ? chartColors[index] : DEFAULT_COLORS[index],
            ),
            stroke: am5.color(
              chartColors?.length ? chartColors[index] : DEFAULT_COLORS[index],
            ),
          }),
        )
        currentSeries.data.setAll(values)
        chartXYBarSeriesRef.current = currentSeries
      })

      chartXYBarChartRef.current = chart
      chartXYBarXAxisRef.current = xAxis
      chartXYBarYAxisRef.current = yAxis
      chartXYBarRootRef.current = root

      // Add legend
      if (options.legends) {
        const legend = chart.children.push(
          am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
          }),
        )
        legend.data.setAll(chart.series.values)
        legend.markers.template.setAll({
          width: options.bullet.marker,
          height: options.bullet.marker,
        })
        chartXYBarLegendRef.current = legend
      }

      setChartRendered(true)

      return () => {
        root.dispose()
      }
    }, [])

    useEffect(() => {
      if (
        !chartXYBarChartRef.current ||
        !chartXYBarChartRef.current!.series.values[0] ||
        !chartRendered
      ) {
        return
      }
      chartXYBarChartRef.current!.series.values.forEach((value, index) => {
        // Set bars color on series
        // @ts-expect-error columns not typed
        value.columns.values.forEach((value) => {
          value.setAll({
            fill: am5.color(chartColors[index]),
            stroke: am5.color(chartColors[index]),
          })
        })

        // Set legend marker colors
        chartXYBarLegendRef.current?.markers.values.forEach(
          (legend, legIndex) => {
            legend.children.values.forEach((value) => {
              value.setAll({
                // @ts-expect-error fill not typed
                fill: am5.color(chartColors[legIndex]),
                stroke: am5.color(chartColors[legIndex]),
              })
            })
          },
        )
      })
    }, [chartColors])

    const [jsonOptions, setJsonOptions] = useState('')
    useEffect(() => {
      if (!chartRendered || jsonOptions === JSON.stringify(options)) return
      setJsonOptions(JSON.stringify(options))
      chartXYBarChartRef.current?.set(
        'paddingBottom',
        options.labels.y ? 30 : 0,
      )
      chartXYBarChartRef.current?.set('paddingLeft', options.labels.x ? 30 : 0)

      // update title and title size
      const titleIndex = chartXYBarChartRef.current?.children.values.findIndex(
        (value) => value.className === 'Label',
      )
      if (titleIndex !== undefined) {
        chartXYBarChartRef.current?.children.values[titleIndex].set(
          // @ts-expect-error text not typed
          'text',
          options.title,
        )
        chartXYBarChartRef.current?.children.values[titleIndex].set(
          // @ts-expect-error text not typed
          'fontSize',
          options.titleSize,
        )
      }

      // show & hide Grid lines
      if (!options.showGrids) {
        chartXYBarYAxisRef.current
          ?.get('renderer')
          .grid.template.set('forceHidden', true)
        chartXYBarXAxisRef.current
          ?.get('renderer')
          .grid.template.set('forceHidden', true)
      } else {
        chartXYBarYAxisRef.current
          ?.get('renderer')
          .grid.template.set('forceHidden', false)
        chartXYBarXAxisRef.current
          ?.get('renderer')
          .grid.template.set('forceHidden', false)
      }

      // show & hide Legends
      if (options.legends) {
        chartXYBarLegendRef.current?.show()
      } else {
        chartXYBarLegendRef.current?.hide()
      }

      // show & hide X Axis Label
      const labelXIndex = chartXYBarXAxisRef.current?.children.values.findIndex(
        (value) => value.className === 'Label',
      )
      if (options.labels.showX) {
        if (labelXIndex !== undefined) {
          chartXYBarXAxisRef.current?.children.values[labelXIndex].show()
          chartXYBarXAxisRef.current?.children.values[labelXIndex].set(
            // @ts-expect-error text not typed
            'text',
            options.labels.x,
          )
        }
      } else {
        if (labelXIndex !== undefined)
          chartXYBarXAxisRef.current?.children.values[labelXIndex].hide()
      }

      // show & hide Y Axis Label
      const labelYIndex = chartXYBarYAxisRef.current?.children.values.findIndex(
        (value) => value.className === 'Label',
      )
      if (options.labels.showY) {
        if (labelYIndex !== undefined) {
          chartXYBarYAxisRef.current?.children.values[labelYIndex].show()
          chartXYBarYAxisRef.current?.children.values[labelYIndex].set(
            // @ts-expect-error text not typed
            'text',
            options.labels.y,
          )
        }
      } else {
        if (labelYIndex !== undefined)
          chartXYBarYAxisRef.current?.children.values[labelYIndex].hide()
      }
    }, [options])

    useEffect(() => {
      if (!chartRendered) return

      chartXYBarChartRef.current!.series.clear()
      series.forEach(({ label, values }, index) => {
        // Update series dynamically
        const currentSeries = chartXYBarChartRef.current!.series.push(
          am5xy.ColumnSeries.new(chartXYBarRootRef.current!, {
            name: label,
            xAxis: chartXYBarXAxisRef.current!,
            yAxis: chartXYBarYAxisRef.current!,
            valueYField: 'value',
            categoryXField: 'name',
            fill: am5.color(
              chartColors?.length ? chartColors[index] : DEFAULT_COLORS[index],
            ),
            stroke: am5.color(
              chartColors?.length ? chartColors[index] : DEFAULT_COLORS[index],
            ),
          }),
        )
        currentSeries.data.setAll(values)
      })
    }, [series])

    return (
      <div
        id={chartId}
        css={chartStyles}
        className={className}
        {...dataAttr}
        style={{
          width: width ? `${width}px` : '100%',
          height: height ? `${height}px` : '100%',
        }}
      />
    )
  },
)

ChartXYBar.displayName = 'ChartXYBar'
