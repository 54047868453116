import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IPlanCard, PLAN_CARD_THEME } from './types'

export const wrapperStyles = css`
  width: 100%;
  position: relative;

  padding-top: 39px;
`

export const avatarStyles = css`
  position: absolute;
  top: 39px;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    height: 68px;
    width: 68px;
  }
`

export const popularTag = (theme: ITheme) => css`
  position: absolute;
  width: 70px;
  height: 35px;
  background: ${theme.colors.primary[10]};
  border-radius: ${theme.borderRadius.xlarge};
  display: flex;
  padding: ${theme.gap.xsmall}px ${theme.gap.small}px;
  justify-content: center;
  align-items: center;
  gap: ${theme.gap['2xsmall']}px;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.bold};
  left: ${theme.gap.xlarge}px;
  top: ${theme.gap.large}px;

  .popular-text {
    color: ${theme.colors.primary.DEFAULT};
  }
`
export const cancelText = (theme: ITheme) => css`
  color: ${theme.colors.text[2]};
  font-size: ${theme.fontSize.xsmall};
  margin-top: ${theme.gap['3xsmall']}px;
  display: flex;
  justify-content: center;
`
type IInnerStyles = Pick<IPlanCard, 'theme'>
export const innerStyles =
  (props: IInnerStyles, upgradeModal: boolean | undefined) =>
  (theme: ITheme) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${upgradeModal
      ? ` padding: ${theme.gap['3xlarge']}px 0 ${theme.gap.large}px;`
      : ` padding: 63px 0 ${theme.gap.xlarge}px;`}

    gap: ${theme.spacing.xsmall};
    background: ${props.theme === PLAN_CARD_THEME.DEFAULT
      ? theme.colors.background.DEFAULT
      : theme.gradient.PURPLE};

    color: ${props.theme === PLAN_CARD_THEME.DEFAULT
      ? theme.colors.text.DEFAULT
      : theme.colors.white.DEFAULT};

    border-radius: ${theme.borderRadius[6]};
    box-shadow: ${theme.boxShadow[1]};

    .title {
      font-weight: ${theme.fontWeight.medium};
      ${upgradeModal
        ? ` font-size: ${theme.fontSize.medium};`
        : ` font-size: ${theme.fontSize.large};`}
    }

    .price {
      font-size: ${theme.fontSize.xlarge};
      font-weight: ${theme.fontWeight.medium};
    }

    .billed-annualy {
      font-size: ${theme.fontSize.xsmall};
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      margin-left: ${theme.gap['3xsmall']}px;
    }
    .features-title {
      font-weight: ${theme.fontWeight.medium};
      ${upgradeModal
        ? ` font-size: ${theme.fontSize.medium};`
        : ` font-size: ${theme.fontSize.large};`}
    }

    .button-area {
      ${upgradeModal
        ? `margin-top: 0px;`
        : `margin-top: ${theme.gap.medium}px;`}
    }

    .description {
      ${upgradeModal
        ? `font-size: ${theme.fontSize.small};`
        : `font-size: ${theme.fontSize.base};`}
    }

    @media (min-width: ${theme.breakpoints.tablet}px) {
      ${upgradeModal ? `width: 281px` : ` width: 100%`};
      .price {
        font-size: ${theme.fontSize.large};
        line-height: 155%;
        display: flex;
      }

      .title {
        font-size: ${theme.fontSize.medium};
      }
    }

    @media (min-width: ${theme.breakpoints.laptop}px) {
      ${upgradeModal && `width: 281px; `}
      .price {
        ${upgradeModal
          ? `font-size: ${theme.fontSize.medium};`
          : `font-size: ${theme.fontSize.large};`}
      }

      .title {
        font-size: ${theme.fontSize.large};
        ${upgradeModal
          ? `font-size: ${theme.fontSize.medium};`
          : `font-size: ${theme.fontSize.large};`}
      }
    }

    @media (min-width: ${theme.breakpoints.desktop}px) {
      ${upgradeModal &&
      `width: 322px;  padding: 63px 0 ${theme.gap.xlarge}px; font-size: ${theme.fontSize.large};`}
      .price {
        font-size: ${theme.fontSize.large};
      }

      .title {
        font-size: ${theme.fontSize.large};
      }

      .button-area {
        margin-top: 16px;
      }
    }
  `

export const buttonLikeProStyles = ({
  colors,
  borderRadius,
  fontWeight,
  fontSize,
  spacing,
  gradient,
}: ITheme) => css`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  padding: ${spacing.small} ${spacing.xlarge};
  box-shadow: none;
  font-weight: ${fontWeight.medium};
  border-radius: ${borderRadius[4]};
  font-size: ${fontSize.small};
  height: 41px;
  background: ${colors.white.DEFAULT};
  border-color: ${colors.white.DEFAULT};
  text-decoration: none;

  &:hover {
    background: ${gradient.V_10};
  }

  &.disabled {
    border-color: ${colors.deactive.DEFAULT};
    pointer-events: none;
    cursor: default;
  }

  & span {
    background: ${gradient.DEFAULT};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const buttonLikeBusinessStyles = ({
  colors,
  borderRadius,
  fontWeight,
  fontSize,
  spacing,
  gradient,
}: ITheme) => css`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  padding: ${spacing.small} ${spacing.xlarge};
  box-shadow: none;
  font-weight: ${fontWeight.medium};
  border-radius: ${borderRadius[4]};
  font-size: ${fontSize.small};
  height: 41px;
  color: ${colors.white.DEFAULT};
  background: ${gradient.DEFAULT};
  border-color: ${colors.white.DEFAULT};
  text-decoration: none;

  &.disabled {
    border-color: ${colors.deactive.DEFAULT};
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    background: ${gradient.V_80};
  }
`
