import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const initPageStyles = ({
  colors,
  gap,
  dimensions,
  breakpoints,
}: ITheme) => css`
  background: ${colors.background.DEFAULT};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${gap['3xlarge']}px;
  gap: ${gap['2xlarge']}px;

  img {
    width: ${dimensions.deckInitImageWidth.small}px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    gap: ${gap['3xlarge']}px;
    img {
      width: ${dimensions.deckInitImageWidth.DEFAULT}px;
    }
  }
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
