import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.spacing['4xlarge']};
`

export const lineStyles = (theme: ITheme) => css`
  height: 50px;
  border-left: 1px solid ${theme.colors.white.DEFAULT};
`
