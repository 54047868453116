import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const paymentConfirmationStyles = ({
  borderRadius,
  breakpoints,
  colors,
  dimensions,
  gap,
}: ITheme) => css`
  background: ${colors.white.DEFAULT};
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: ${gap['2xlarge']}px;
  border-radius: 0;
  gap: ${gap.medium}px;

  @media (min-width: ${breakpoints.tablet}px) {
    width: ${dimensions.paymentConfirmation.width}px;
    height: auto;
    border-radius: ${borderRadius[8]};
    overflow-y: hidden;
  }
`

export const paymentConfirmationTitleStyles = ({
  fontWeight,
  fontSize,
}: ITheme) => css`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.large};
`

export const paymentConfirmationSubtitleStyles = ({
  colors,
  fontSize,
}: ITheme) => css`
  color: ${colors.text[2]};
  font-size: ${fontSize.medium};
`

export const paymentInformationSeperatorStyles = ({ colors }: ITheme) => css`
  border: 0.5px solid ${colors.deactive[2]};
`

export const paymentInformationStyles = ({
  borderRadius,
  colors,
  gap,
}: ITheme) => css`
  border-radius: ${borderRadius[8]};
  background-color: ${colors.background.DEFAULT};
  padding: ${gap.small}px ${gap.xlarge}px;
`

export const paymentInformationRowStyles = ({ gap }: ITheme) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${gap.small}px 0;
  gap: ${gap.small}px;
`

export const paymentInformationRowLabelStyles = ({
  fontSize,
  fontWeight,
}: ITheme) => css`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.medium};
`

export const paymentInformationRowValueStyles = ({ fontSize }: ITheme) => css`
  font-size: ${fontSize.large};
  text-transform: capitalize;
  min-width: max-content;
`

export const paymentInformationSeatSizeStyles = ({ colors }: ITheme) => css`
  color: ${colors.primary.DEFAULT};
`

export const paymentInformationRowTotalStyles = ({ gap }: ITheme) => css`
  display: flex;
  align-items: center;
  gap: ${gap['3xsmall']}px;

  & span {
    padding-top: 2px;
  }
`

export const paymentInformationContactUsStyles = ({
  colors,
  fontWeight,
}: ITheme) => css`
  color: ${colors.text[2]};

  & a {
    color: ${colors.primary.DEFAULT};
    font-weight: ${fontWeight.medium};
    text-decoration: none;
  }
`
