import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import {
  decksWrapperStyles,
  decksHeaderStyles,
  areaStyles,
  loaderWrapperStyles,
  subTitleStyles,
  titleStyles,
  directoryWrapperStyles,
  directoryStyles,
} from './styles'
import { useLanguage } from 'src/hooks'
import {
  icons,
  Loader,
  Dropdown,
  DROPDOWN_TYPE,
  IDropdownItem,
  Icon,
} from 'src/lib'
import { RootState, setDecksQuery, setActiveFolderId } from 'src/store'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { IDashboardDecks } from './types'
import { DashboardCard } from './DashboardCard'
import { DecksSortByFields, SortDirections } from 'src/types/api/enums'
import { theme } from 'src/theme'
import { FOLDER_SHARED_WITH_ME_ID } from '../DashboardFolders/consts'
import { FontImporter } from 'src/pages/deck-page/components/font-importer'

export const DashboardDecks: React.FC<IDashboardDecks> = React.memo(
  ({ isLoading }) => {
    const { t, lang } = useLanguage()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    // FIXME Decks are not reloading after collabrated users updates states
    const { decks, decksCount, decksQuery, folders, activeFolderId } =
      useSelector(({ decks, folders }: RootState) => ({
        decks: decks.list.data,
        decksCount: decks.totalCount,
        decksQuery: decks.decksQuery,
        folders: folders.list,
        activeFolderId: folders.activeFolderId,
      }))

    const breadcrumbHandler = useCallback(() => {
      dispatch(setActiveFolderId(-1))
      searchParams.delete('folderId')
      setSearchParams({})
    }, [])

    const folderDirectory = useMemo(() => {
      if (activeFolderId === -1) {
        return <div css={titleStyles}>{t('common.decks')}</div>
      }
      const activeFolder =
        activeFolderId !== FOLDER_SHARED_WITH_ME_ID
          ? folders.find((folder) => folder.id === activeFolderId)
          : {
              name: t('dashboard.shared_with_me'),
            }
      const activeFolderName = activeFolder?.name

      return (
        <div onClick={() => breadcrumbHandler()} css={directoryWrapperStyles}>
          <div css={titleStyles}>{t('common.decks')}</div>
          <div css={directoryStyles}>
            {activeFolderName && (
              <Icon
                icon={icons.chevron_right}
                size={20}
                color={theme.colors.text.DEFAULT}
              />
            )}
            <div>{activeFolderName}</div>
          </div>
        </div>
      )
    }, [activeFolderId, folders, lang])

    const onSortingChange = (value?: IDropdownItem) => {
      switch (value!.value) {
        case 'title-asc':
          dispatch(
            setDecksQuery({
              ...decksQuery,
              sortBy: DecksSortByFields.NAME,
              sortDirection: SortDirections.ASC,
            }),
          )
          break
        case 'title-desc':
          dispatch(
            setDecksQuery({
              ...decksQuery,
              sortBy: DecksSortByFields.NAME,
              sortDirection: SortDirections.DESC,
            }),
          )
          break
        case 'recent':
          dispatch(
            setDecksQuery({
              ...decksQuery,
              sortBy: DecksSortByFields.UPDATED_AT,
              sortDirection: SortDirections.DESC,
            }),
          )
          break
        case 'oldest':
          dispatch(
            setDecksQuery({
              ...decksQuery,
              sortBy: DecksSortByFields.UPDATED_AT,
              sortDirection: SortDirections.ASC,
            }),
          )
          break
        default:
          break
      }
    }

    return (
      <div css={decksWrapperStyles}>
        <div css={decksHeaderStyles}>
          <div>
            {!decksQuery.search && (
              <>
                {folderDirectory}
                <div css={subTitleStyles}>
                  {t('common.files', { count: decksCount })}
                </div>
              </>
            )}
          </div>
          <div>
            <Dropdown
              type={DROPDOWN_TYPE.GHOST}
              icon={icons.sort}
              items={[
                { name: t('common.title_asc'), value: 'title-asc' },
                { name: t('common.title_desc'), value: 'title-desc' },
                { name: t('common.recent'), value: 'recent' },
                { name: t('common.oldest'), value: 'oldest' },
              ]}
              placeholder={t('common.actions.sort_by')}
              onChange={onSortingChange}
            />
          </div>
        </div>

        <div css={areaStyles}>
          {isLoading ? (
            <div css={loaderWrapperStyles}>
              <Loader />
            </div>
          ) : (
            decks?.map((deck, index) => (
              <DashboardCard
                id={deck.deck.id}
                data={deck}
                key={deck.deck.id}
                deckListIndex={index}
                title={deck.deck.name}
                subtitle={
                  deck.meta.permission === '0'
                    ? t('common.read_only')
                    : t('common.read_write')
                }
                subtitleInfo={moment(deck.deck.updatedAt).fromNow()}
                footerIcon={deck.deck.folderDeck?.folder && icons.form}
                footerText={deck.deck.folderDeck?.folder.name}
                footerTextInfo={t('common.slides', {
                  count: deck.meta.slidesCount,
                })}
                folderDeck={deck.deck.folderDeck}
                thumbnailUrl={deck.deck.thumbnailUrl}
                avatar={[
                  ...deck.deck.userDecks.map((userDeck) => {
                    return {
                      name: userDeck.user.fullName,
                      image: userDeck.user.picture,
                    }
                  }),
                  {
                    name: deck.deck.ownerUser.fullName,
                    image: deck.deck.ownerUser.picture,
                  },
                ]}
                state={deck.deck.state}
                viewMode={deck.deck.viewMode}
                permission={deck.meta.permission}
              />
            ))
          )}
        </div>

        <FontImporter />
      </div>
    )
  },
)

DashboardDecks.displayName = 'DashboardDecks'
