import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ISideMenuItem } from './types'

export const wrapperStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.medium};
  width: 100%;

  span {
    width: 100%;
    background: ${theme.colors.outline.DEFAULT};
    height: 1px;
  }
`

type ISideMenuItemStyles = Pick<ISideMenuItem, 'isActive'>

export const sideMenuItemStyles =
  ({ isActive }: ISideMenuItemStyles) =>
  (theme: ITheme) => css`
    display: flex;
    align-items: center;
    height: ${theme.dimensions.sideMenuItemHeight.DEFAULT}px;
    color: ${isActive ? theme.colors.primary.DEFAULT : theme.colors.text[2]};
    gap: ${theme.spacing['2xsmall']};
    padding: ${theme.spacing.xsmall} ${theme.spacing.small};
    cursor: pointer;
    border-radius: ${theme.borderRadius[5]};
    ${isActive ? `background: ${theme.colors.primary[10]}` : ``};
    transition: background 0.3s;

    .icon path {
      fill: ${isActive ? theme.colors.primary.DEFAULT : theme.colors.text[2]};
    }

    &:hover {
      background: ${theme.colors.primary[10]};
    }
  `
