import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const formResponsesStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 336px;
  height: 600px;
  margin: auto;
  align-self: center;
  border-radius: ${theme.borderRadius[10]};
  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 696px;
    height: 648px;
  }

  @media (min-width: ${theme.breakpoints.laptop}px) {
    width: 1044px;
    height: 643px;
  }
`

export const headerStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  padding: ${theme.gap.xlarge}px;
  position: relative;
  border-bottom: 1px solid ${theme.colors.elements.DEFAULT};
  border-radius: ${theme.borderRadius[10]} ${theme.borderRadius[10]} 0 0;
`

export const titleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
`
export const closeWrapperStyles = (theme: ITheme) => css`
  height: 16px;
  width: 16px;
  position: absolute;
  top: ${theme.gap.medium}px;
  right: ${theme.gap.medium}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    right: ${theme.gap['xlarge']}px;
    top: ${theme.gap['xlarge']}px;
  }
`

export const bodyStyles = () => css`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
`

export const noAnswerStyles = () => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

export const noAnswersDesprictionStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  margin-top: ${theme.gap.xsmall}px;
`
