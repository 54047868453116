import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const remainingSeatsStyles = ({ colors, gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  row-gap: ${gap.medium}px;
  width: 100%;
  background-color: ${colors.white.DEFAULT};
  padding-top: ${gap.xlarge}px !important;
`

export const remainingSeatsTitleStyles = ({ fontWeight }: ITheme) => css`
  font-weight: ${fontWeight.medium};
`

export const seatsWrapperStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const usedSeatsStyles =
  (isFull: boolean) =>
  ({ colors, fontSize, fontWeight }: ITheme) => css`
    color: ${isFull ? colors.error[90] : colors.text[2]};
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.small};
  `

export const manageSeatsStyles = css`
  position: relative;
`

export const manageSeatsToggleStyles =
  (isOpen: boolean) =>
  ({ colors, fontSize, gap }: ITheme) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: ${gap['2xsmall']}px;
    font-size: ${fontSize.small};
    color: ${colors.primary.DEFAULT};
    cursor: pointer;
    pointer-events: ${isOpen ? 'none' : 'auto'};
    user-select: none;

    & > span {
      text-decoration: underline;
    }
  `

export const adjustSeatsStyles =
  (isOpen: boolean) =>
  ({ zIndex }: ITheme) => css`
    position: fixed;
    display: ${isOpen ? 'block' : 'none'};
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: ${zIndex.popUpMenu};
  `

export const adjustSeatsOverlayStyles = ({
  breakpoints,
  colors,
}: ITheme) => css`
  width: 100%;
  height: 100%;
  background: ${colors.overlay.modal};
  opacity: 0.5;

  @media (min-width: ${breakpoints.tablet}px) {
    background: transparent;
  }
`

export const adjustSeatsContainerStyles =
  (isOpen: boolean, topValueOfAdjustSeats: string) =>
  ({ boxShadow, breakpoints, gap, zIndex }: ITheme) => css`
    position: absolute;
    display: flex;
    opacity: ${isOpen ? '1' : '0'};
    pointer-events: ${isOpen ? 'auto' : 'none'};
    z-index: ${isOpen ? zIndex.modal : -1};
    top: unset;
    right: 0;
    left: 0;
    bottom: 0;
    box-shadow: ${boxShadow[7]};
    overflow: hidden;

    @media (min-width: ${breakpoints.tablet}px) {
      top: ${topValueOfAdjustSeats};
      bottom: unset;
      left: unset;
      right: ${gap['2xlarge']}px;
    }
  `
