import React, { useMemo, useState } from 'react'

import { IColorItem } from './types'
import { colorItemStyles } from './styles'
import { Icon, icons } from 'src/lib'
import { useTheme } from 'src/theme'
import { isContainingCssVariable } from 'src/services/colorServices'

export const ColorItem: React.FC<IColorItem> = React.memo(
  ({
    color,
    isActive,
    isDeleteMode,
    disabled,
    onClick,
    className,
    dataAttr,
  }) => {
    const randAnim = Math.ceil(Math.random() * 5)
    const { colors } = useTheme()

    const colorStyles = useMemo(() => {
      if (color?.colors[1]) {
        return {
          background: `linear-gradient(${color?.rotation || 0}deg, ${color
            ?.colors[0]} 0%, ${color?.colors[1] || color?.colors[0]} 100%)`,
        }
      } else {
        if (!color?.colors[0]) {
          return
        }
        return {
          backgroundColor:
            color?.colors[0].startsWith('#') ||
            isContainingCssVariable({ text: color?.colors[0] })
              ? color?.colors[0]
              : '#' + color?.colors[0],
        }
      }
    }, [color])

    const [isHover, onHover] = useState(false)

    return (
      <div
        css={colorItemStyles({ isActive, disabled, isDeleteMode })}
        className={`${className} ${isDeleteMode ? `shake sh-${randAnim}` : ``}`}
        style={{ ...colorStyles }}
        {...dataAttr}
        onClick={() => !disabled && onClick?.(color)}
        onMouseOver={() => onHover?.(true)}
        onMouseLeave={() => onHover?.(false)}
      >
        {!color && <Icon icon={icons.plus} color={colors.text[2]} />}
        {isDeleteMode && isHover && (
          <Icon icon={icons.trash_can} color={colors.white.DEFAULT} size={14} />
        )}
      </div>
    )
  },
)

ColorItem.displayName = 'PropColor'
