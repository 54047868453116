import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { HelmetProvider } from 'src/provider'
import { useLDClient } from 'launchdarkly-react-client-sdk'

export const UnprotectedPageLayout: React.FC = React.memo(() => {
  const ldClient = useLDClient()

  useEffect(() => {
    ldClient?.identify({
      kind: 'user',
      key: 'USER_ANNMYS',
      name: 'User Anonymous',
      email: 'user_annmys@decktopus.com',
    })
  }, [])

  return (
    <>
      <HelmetProvider />
      <Outlet />
    </>
  )
})

export default UnprotectedPageLayout
