export const flags = {
  FE_13_CHANGE_FOLDER_DIALOG: true,
  FE_USER_CLASSIFICATION: true,
  FE_124_AI_FLOW_FIX: true,
  FE_343_GAUTH_TAGMANAGER: true,
  FE_292_MULTIPLE_CUSTOMDOMAINS_ENABLED: true,
  FE_DECK_THUMBNAIL: true,
  FE_211_HEADER_SEARCH: true,
  FE_412_PERSIST_DASHBOARD_FILTER: true,
  FE_COLLABORATED_DECK_UPLOADED_FONT: true,
  FE_80_AI_DECK_SLIDE_NOTES: true,
  FE_TAS41_NAME_DATE_ON_COVER_SLIDE_ENABLED: true,
  FE_60_SLIDE_SETTINGS_MENU: true,
  FE_54_ADMIN_PANEL: true,
  FE_GLEAP_INTEGRATION: true,
  FE_110_LANGUAGE_SELECTION: true,
  FE_SVG_DECOR: true,
  FE_75_THEME_COLORS_THEME_ID: true,
  FE_71_COLOR_MAPPING: true,
  FE_170_PREVENT_TEXT_DRAG_ON_EDIT: true,
  FE_CANVAS_REFACTOR: true,
  FE_278_QUOTE_QUILL: true,
  FE_TEMPLATES: true,
  FE_261_AI_EDIT_TEXT: true,
  FE_PLEASE_WAIT_SCREEN_STUCK: true,
  FE_FIX_DEFAULT_COLOR_SELECTION: true,
  FE_304_QUOTE_IMPROVEMENTS_2: true,
  FE_DUPLICATE_TEXT: true,
  FE_422_IMAGE_OPACITY: true,
  FE_421_IMAGE_CORNER_RADIUS: true,
  FE_277_IMAGE_GRID_IMPROVEMENTS: true,
  FE_419_ADMIN_LOGIN_AS_USER: true,
  FE_259_COPY_PASTE: true,
  FE_604_TEMPLATES_DECORS_FONTS: true,
  FE_591_SWAP_BACKGROUND_COLOR_FIX: true,
  FE_579_TEXT_BOX_COLOR_CHANGE: true,
  FE_450_PAYMENT_CONFIRMATION: true,
}
