import { useCallback, useEffect, useState } from 'react'

export function useInfiniteScroll<T extends HTMLElement>(
  ref: React.RefObject<T>,
  callback: () => Promise<any>,
  limit: number,
  isDomLoading: boolean,
) {
  const [isOnScreen, setIsOnScreen] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(false)
  const [responseData, setResponseData] = useState<any[]>([])

  const options = {
    rootMargin: '1px',
    threshold: 0.1,
  }

  useEffect(() => {
    if (isDomLoading) {
      return
    }
    const observer = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting)
    }, options)

    const currentRef = ref.current
    if (currentRef) {
      observer.observe(currentRef)
    }
    return () => {
      if (currentRef) {
        observer?.unobserve(currentRef)
      }
    }
  }, [ref, isDomLoading])

  const callbackFn = useCallback(async () => {
    const responseData = await callback()

    if (responseData.length < limit) {
      setHasMoreData(false)
    } else {
      setHasMoreData(true)
    }

    setResponseData(responseData)
  }, [setResponseData, responseData, setHasMoreData, limit])

  useEffect(() => {
    if (isOnScreen && hasMoreData && !isDomLoading) {
      callbackFn()
    }
  }, [isOnScreen])

  useEffect(() => {
    callbackFn()
  }, [])

  return { hasMoreData, responseData }
}
