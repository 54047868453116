import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  border-radius: ${theme.borderRadius[6]};
  background-color: ${theme.colors.white.DEFAULT};

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: relative;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: ${theme.breakpoints.small}px) {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    display: block;
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 625px;
    height: 356px;
    display: flex;
    overflow: auto;
  }
`

export const leftWrapperStyles = (theme: ITheme) => css`
  position: sticky;
  top: 0;
  font-weight: ${theme.fontWeight.medium};
  @media (min-width: ${theme.breakpoints.small}px) {
    width: 100%;
    padding: ${theme.spacing.medium};
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: ${theme.spacing.medium};
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 205px;
    height: 60%;
    margin-top: 0;
    padding: ${theme.spacing.xlarge};
  }
`
export const frameStyles = (theme: ITheme) => css`
  padding-bottom: ${theme.spacing.xlarge};
`

export const rightWrapperStyles = (theme: ITheme) => css`
  width: 100%;
  height: 100%;
  padding: ${theme.spacing.xlarge};
  @media (min-width: ${theme.breakpoints.small}px) {
    width: 100%;
    padding: ${theme.spacing.medium};
  }
  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: calc(100% - 205px);
    height: 100%;
    padding: ${theme.spacing.xlarge};
  }
`

export const bottomSpaceWrapper = (theme: ITheme) => css`
  margin-bottom: ${theme.spacing.xlarge};
  padding-right: 20px;

  & > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    max-width: 350px;
    text-overflow: ellipsis;
  }
`
export const inputBorderStyle = (theme: ITheme) => css`
  border-color: ${theme.colors.outline[2]};
  height: 38px !important;
`

export const menuStyles = (isSelected: boolean) => (theme: ITheme) => css`
  display: flex;
  align-items: center;
  border-radius: ${theme.borderRadius[4]};
  column-gap: ${theme.spacing['2xsmall']};
  cursor: pointer;
  ${isSelected &&
  `
    background-color: ${theme.colors.background[90]};
    color: ${theme.colors.primary.DEFAULT};
    fill: ${theme.colors.primary.DEFAULT};`}

  &:hover {
    background-color: ${theme.colors.background[90]};
  }
  @media (min-width: ${theme.breakpoints.small}px) {
    width: 50% !important;
    height: 36px;
    font-size: ${theme.fontSize.small};
  }
  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 185px !important;
    height: 38px;
  }
`
export const iconStyles = (theme: ITheme) => css`
  margin-left: ${theme.spacing['xsmall']};
`

export const spanWrapperStyles = (theme: ITheme) => css`
  margin-right: ${theme.spacing['2xsmall']};
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
`

export const deckNameStyles = css`
  white-space: nowrap;
  overflow: hidden;
  max-width: 350px;
  text-overflow: ellipsis;
`

export const closeWrapperStyles = (theme: ITheme) => css`
  height: 16px;
  position: absolute;
  display: flex;
  right: ${theme.spacing['medium']};
  top: ${theme.spacing['medium']};
  width: 16px;
  z-index: ${theme.zIndex.icon};
  @media (min-width: ${theme.breakpoints.tablet}px) {
    right: ${theme.spacing['xlarge']};
    top: ${theme.spacing['xlarge']};
  }
`
export const copyTextStyles = (theme: ITheme) => css`
  color: ${theme.colors.primary.DEFAULT};
  font-size: ${theme.fontSize.small};
`
export const customDomainContainerStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: ${theme.spacing.xlarge};
`
export const inputContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 8px;
  gap: 24px;
`
export const linkButtonStyles = css`
  padding: 2px 0;
  text-decoration: underline;
  justify-content: flex-start;
`
export const separatorStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.medium};
  color: ${theme.colors.outline[2]};
  font-weight: ${theme.fontWeight.medium};
`
export const shareModalInnerStyles = (theme: ITheme) => css`
  border-color: ${theme.colors.outline[2]};
  width: 137px;
`
export const shareModalWrapperStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: ${theme.spacing.xlarge};
  gap: 7px;
`
export const shareTextStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.black.DEFAULT};
`
export const shareTitleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.black.DEFAULT};
  font-weight: ${theme.fontWeight.bold};
`
export const switchContainerStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: ${theme.spacing.xlarge};
  gap: 12px;
`
export const tooltipStyles = (theme: ITheme) => css`
  position: absolute;
  width: 282px;
  height: 32px;
  top: 110px;
  z-index: ${theme.zIndex.tooltip};
`
export const tableWrapperStyles = (theme: ITheme) => css`
  color: ${theme.colors.primary.DEFAULT};
  font-size: ${theme.fontSize.small};
  margin-top: ${theme.spacing.large};
  border-radius: 8px;
  overflow: hidden;
  table {
    width: 100%;
    height: 86px;
    border-collapse: collapse;
    border-right: 1px solid ${theme.colors.background.DEFAULT};
    border-left: 1px solid ${theme.colors.background.DEFAULT};
    background: ${theme.colors.white.DEFAULT};
  }
  thead {
    background: ${theme.colors.background[90]};
    height: 40px;
  }
  th {
    font-weight: ${theme.fontWeight.regular};
    width: 187px;
  }
  tbody {
    color: gray;
    margin-top: ${theme.spacing.xlarge};
    margin-bottom: ${theme.spacing.xlarge};
    background: ${theme.colors.white.DEFAULT};
    height: 40px;
  }
`
