import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

// TODO: update 36px with 3xlarge
export const wrapperStyles = (theme: ITheme) => css`
  position: relative;
  background: ${theme.colors.white.DEFAULT};

  padding: ${theme.gap.medium}px;
  width: 100%;
  height: 100%;
  border-radius: 0;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 582px;
    padding: ${theme.gap['2xlarge']}px;
    border-radius: ${theme.borderRadius[10]};
    height: auto;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: 710px;
    padding: 36px;
  }
`

export const closeButtonAreaStyles = (theme: ITheme) => css`
  text-align: right;
  margin-bottom: ${theme.gap.medium}px;
  height: 20px;
  @media (min-width: ${theme.breakpoints.tablet}px) {
    position: absolute;
    top: ${theme.gap.xlarge}px;
    right: ${theme.gap.xlarge}px;
  }
`

export const titleStyles = (aiFlow: boolean) => (theme: ITheme) => css`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
  ${aiFlow
    ? `margin-bottom: ${theme.gap.xlarge}px;   text-align: center;`
    : `margin-bottom: ${theme.gap.small}px;   text-align: left; `};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    text-align: left;
  }

  @media (max-width: ${theme.breakpoints.laptop}px) {
    ${aiFlow
      ? `margin-bottom: ${theme.gap.xlarge}px; font-size: ${theme.fontSize.large}`
      : `margin-bottom: ${theme.gap.xsmall}px; font-size: ${theme.fontSize.medium} `};
  }
`

export const buttonAreaStyles = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.gap['3xlarge']}px;

  @media (max-width: ${theme.breakpoints.tablet - 1}px) {
    flex-direction: column;
    gap: 18px;
  }
`

export const buttonStyles = (theme: ITheme) => css`
  box-sizing: border-box;
  cursor: pointer;
  flex-basis: auto;
  padding: 18px;
  border: 1px solid ${theme.colors.elements.DEFAULT};
  border-radius: ${theme.borderRadius[6]};
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: ${theme.boxShadow[4]};
  }

  &:active {
    box-shadow: inset ${theme.boxShadow[6]};
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.gap.xlarge}px;
    width: 100%;

    img {
      width: 200px;
      height: 150px;
    }
  }

  .title {
    font-size: ${theme.fontSize.large};
    font-weight: ${theme.fontWeight.medium};
  }

  .text {
    margin-top: ${theme.gap.xsmall}px;
    font-size: ${theme.fontSize.medium};
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    flex-basis: 50%;
    flex-grow: 1;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    padding: ${theme.gap.xlarge}px 28px;
    .image {
      img {
        width: 250px;
        height: 180px;
      }
    }
  }
`
