import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { API_CONFIG } from 'src/config'
import { RequestServices } from 'src/services'
import { ICanvasState, RootState } from 'src/store'

export const usePublicApi = () => {
  const { deckId, slideId, slideDataId } = useSelector(
    ({ edit }: RootState) => ({
      deckId: edit.activeDeck.data?.deck?.id,
      slideId: edit.activeSlideID,
      slideDataId: edit.activeSlideDataID,
    }),
  )

  const postFormResponse = useCallback(
    (formData: ICanvasState['slideForms'][0]) => {
      if (!deckId) {
        return
      }
      try {
        RequestServices.callApi({
          method: 'POST',
          url: API_CONFIG.DECK_RESPONSE(deckId.toString()),
          data: {
            slideId,
            slideDataId,
            formId: formData.id,
            answers: Object.entries(formData.data).map(
              ([questionId, answer]) => ({
                questionId,
                answer:
                  typeof answer === 'number'
                    ? answer.toString()
                    : typeof answer === 'object'
                    ? answer?.join('|')
                    : answer,
              }),
            ),
          },
        })
      } catch {
        return false
      }
    },
    [deckId, slideId, slideDataId],
  )

  return { postFormResponse }
}
