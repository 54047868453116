import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CANVAS_TYPE, ICanvasStatic } from './types'
import { NonDragController } from './components/drag-controller/NonDragController'

export const CanvasStatic: React.FC<ICanvasStatic> = React.memo(
  ({ canvasType = CANVAS_TYPE.STATIC, components, slideDataId, scale }) => {
    const zIndexList: number[] =
      components
        ?.map(({ positions: { zIndex } }) => zIndex || 5000)
        .filter((v) => v)
        .sort() || []
    return (
      <>
        {components?.map((component, index) => (
          <NonDragController
            canvasType={canvasType}
            scale={scale}
            key={canvasType === CANVAS_TYPE.PREVIEW ? index : uuidv4()}
            data={component}
            slideDataId={slideDataId}
            zIndexList={zIndexList}
          />
        ))}
      </>
    )
  },
)

CanvasStatic.displayName = 'CanvasStatic'
