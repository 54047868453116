import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SvgDecor, SvgParsed } from 'src/lib'
import { RootState } from 'src/store'
import {
  GradientLikeColorSchema,
  ThemeColorDataSchema,
} from 'src/types/api/requestObjects'
import { canvasCoverStyles } from './styles'
import { useFlags } from 'src/hooks'

export const CanvasCover: React.FC<{
  url?: string | null
  svgDecorString?: string
  svgDecorColorMap?: {
    first?: string
    second?: string
    third?: string
    fourth?: string
    bg?: GradientLikeColorSchema | null
  }
  isSwapColor?: boolean
  themeColors?: ThemeColorDataSchema
  backgroundColor?: GradientLikeColorSchema | null
}> = React.memo(
  ({
    url,
    isSwapColor,
    themeColors,
    backgroundColor,
    svgDecorString,
    svgDecorColorMap,
  }) => {
    const flags = useFlags()
    const { themeColorsFromState } = useSelector(({ edit }: RootState) => ({
      themeColorsFromState: edit.activeDeck.data?.deckData?.themeColor.data,
    }))

    // const newSvgColorReplace: string[][] = useMemo(() => {
    //   return [
    //     [
    //       '#111AAA',
    //       (isSwapColor ? svgDecorColorMap?.fourth : svgDecorColorMap?.first) ||
    //         '#111AAA',
    //     ],
    //     [
    //       '#BBB222',
    //       (isSwapColor ? svgDecorColorMap?.third : svgDecorColorMap?.second) ||
    //         '#BBB222',
    //     ],
    //     [
    //       '#3C3C3C',
    //       (isSwapColor ? svgDecorColorMap?.second : svgDecorColorMap?.third) ||
    //         '#3C3C3C',
    //     ],
    //     [
    //       '#EF444E',
    //       (isSwapColor ? svgDecorColorMap?.first : svgDecorColorMap?.fourth) ||
    //         '#EF444E',
    //     ],
    //   ]
    // }, [isSwapColor, url, svgDecorColorMap])

    const colorReplace: string[][] = useMemo(() => {
      const selectedColors = themeColors || themeColorsFromState
      return [
        [
          '#111AAA',
          (isSwapColor ? selectedColors?.fourth : selectedColors?.first) ||
            '#111AAA',
        ],
        [
          '#BBB222',
          (isSwapColor ? selectedColors?.third : selectedColors?.second) ||
            '#BBB222',
        ],
        [
          '#3C3C3C',
          (isSwapColor ? selectedColors?.second : selectedColors?.third) ||
            '#3C3C3C',
        ],
        [
          '#EF444E',
          (isSwapColor ? selectedColors?.first : selectedColors?.fourth) ||
            '#EF444E',
        ],
      ]
    }, [themeColors, isSwapColor, url, themeColorsFromState])

    return flags.FE_SVG_DECOR ? (
      <div css={canvasCoverStyles}>
        {svgDecorString ? (
          <SvgDecor
            svgString={svgDecorString}
            colorMap={svgDecorColorMap}
            width="100%"
            height="100%"
          />
        ) : (
          <SvgParsed
            width="100%"
            height="100%"
            colorReplace={colorReplace}
            backgroundReplace={backgroundColor}
            url={url}
          />
        )}
      </div>
    ) : (
      <div
        style={{
          position: 'absolute',
          zIndex: 3,
          top: 0,
          left: 0,
        }}
      >
        <SvgParsed
          width="100%"
          height="100%"
          colorReplace={colorReplace}
          url={url}
        />
      </div>
    )
  },
)

CanvasCover.displayName = 'CanvasCover'
