import { useCallback, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { APP_CONFIG } from 'src/config'
import { useDebouncer, useFlags } from 'src/hooks'
import { ComponentServices } from 'src/services'
import {
  RootState,
  SAVE_STATE,
  componentUpdateFromAnotherSlide,
  finishAutoResize,
  removeFirstItemFromComponentFixQueue,
  setComponentFixState,
  setSaveState,
} from 'src/store'
import {
  ComponentDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'

export const useComponentFit = (enabled?: boolean) => {
  const dispatch = useDispatch()
  const flags = useFlags()

  useEffect(() => {
    dispatch(setComponentFixState(enabled || false))
  }, [enabled])

  const { componentFixer, isSlidesLoaded, isLoading } = useSelector(
    ({ canvas, edit }: RootState) => ({
      componentFixer: canvas.componentFixer,
      isSlidesLoaded:
        edit.activeDeck.data?.deckData?.data.slides.length &&
        edit.activeDeck.data.deckData.data.slides.length ===
          edit.activeSlideData.data?.length,

      isLoading: canvas.componentFixInProgress,
    }),
  )

  useEffect(() => {
    if (isLoading && flags.FE_PLEASE_WAIT_SCREEN_STUCK) {
      setTimeout(() => {
        stopIsLoading()
      }, APP_CONFIG.editPage.autoFixTimeout)
    }
  }, [isLoading, flags])

  const runFixer = useCallback(() => {
    componentFixer.forEach((changeDetail) => {
      dispatch(removeFirstItemFromComponentFixQueue())

      const updatedComponents: UpdateComponentSchema[] =
        ComponentServices.updateComponentData<ComponentDataSchema>({
          components: [changeDetail.data],
          partialData: changeDetail.updateData,
        })

      dispatch(
        componentUpdateFromAnotherSlide({
          component: updatedComponents[0],
          slideDataId: changeDetail.slideDataId,
        }),
      )
    })
  }, [componentFixer])

  const runFixerDebounce = useDebouncer(
    () => {
      runFixer()

      dispatch(setComponentFixState(true))
    },
    { delay: 1000 },
  )

  const stopIsLoading = useCallback(() => {
    if (componentFixer.length === 0) {
      dispatch(setComponentFixState(false))
      dispatch(finishAutoResize())
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    }
  }, [componentFixer.length])

  const checkFixerDebounce = useDebouncer(
    () => {
      stopIsLoading()
    },
    { delay: 1000 },
  )

  useLayoutEffect(() => {
    if (componentFixer.length > 0) {
      runFixerDebounce()
    }

    if (isSlidesLoaded) {
      checkFixerDebounce()
    }
  }, [componentFixer, isSlidesLoaded])

  return isLoading
}
