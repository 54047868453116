import React from 'react'
import { useLanguage } from 'src/hooks'
import { Icon, icons } from 'src/lib'
import { IProfileSettings } from './types'
import {
  wrapperStyles,
  accountInfoStyles,
  accountInfoHeaderStyles,
  accountInfoFormAreaStyles,
  accountInfoFormAreaBlockStyles,
  accountInfoFormAreaBlockHeaderStyles,
} from './styles'
import { useTheme } from 'src/theme'
import {
  NameEdit,
  ResetPassword,
  UserData,
  VerifyEmail,
  LanguageChanger,
} from './components'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { UserProviders } from 'src/types/api/enums'
import { useFlags } from 'src/hooks'

export const ProfileSettings: React.FC<IProfileSettings> = React.memo(
  ({ className, dataAttr }) => {
    const { colors } = useTheme()
    const { t } = useLanguage()
    const flags = useFlags()

    const { isVerified, isDectopusAccount } = useSelector(
      ({ user }: RootState) => ({
        isVerified: user.data?.verificationDate,
        isDectopusAccount: user.data?.provider === UserProviders.DECKTOPUS,
      }),
    )

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <UserData />
        <div css={accountInfoStyles}>
          <div css={accountInfoHeaderStyles}>
            <Icon icon={icons.user} size={20} color={colors.elements.DEFAULT} />
            {t('profile.change_account_information')}
          </div>
          <div css={accountInfoFormAreaStyles}>
            <div css={accountInfoFormAreaBlockStyles}>
              <NameEdit />
            </div>
            {flags.FE_110_LANGUAGE_SELECTION && (
              <div css={accountInfoFormAreaBlockStyles}>
                <div css={accountInfoFormAreaBlockHeaderStyles}>
                  {t('common.language')}
                </div>
                <LanguageChanger />
              </div>
            )}
            {isDectopusAccount && (
              <div css={accountInfoFormAreaBlockStyles}>
                <div css={accountInfoFormAreaBlockHeaderStyles}>
                  {t('profile.change_your_password')}
                </div>
                <ResetPassword />
              </div>
            )}

            <div css={accountInfoFormAreaBlockStyles}>
              <div css={accountInfoFormAreaBlockHeaderStyles}>
                {t('verify.verify_your_email')}
              </div>
              {isVerified ? t('profile.verification_success') : <VerifyEmail />}
            </div>
          </div>
        </div>
      </div>
    )
  },
)
