import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useActiveColorMap, useActiveThemeColors } from 'src/hooks'
import { v4 as uuidv4 } from 'uuid'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'

import { IChartAltered } from './types'
import { chartStyles } from './styles'
import { ColorMapSchema } from 'src/types/api/requestObjects'
import { colors, theme } from 'src/theme'
import { isContainingCssVariable } from 'src/services/colorServices'

export interface DataItem {
  name: string
  value: number
}

export const ChartPercentage: React.FC<IChartAltered> = React.memo(
  ({ series, options, size, width, height, className, dataAttr }) => {
    const chartId = uuidv4()
    const colorMap = useActiveColorMap({})
    const activeThemeColors = useActiveThemeColors()

    const chartPieChartRef = useRef<am5percent.PieChart | null>(null)
    const chartPieLegendRef = useRef<am5.Legend | null>(null)
    const chartPieRootRef = useRef<am5.Root | null>(null)
    const [chartRendered, setChartRendered] = useState(false)

    const chartColors = useMemo(
      () =>
        (colorMap?.charts as ColorMapSchema[])
          ?.flatMap((value) => value.default.colors)
          .map((color) => {
            if (color.includes('first'))
              return activeThemeColors?.first ?? colors.black.DEFAULT
            if (color.includes('second'))
              return activeThemeColors?.second ?? colors.black.DEFAULT
            if (color.includes('third'))
              return activeThemeColors?.third ?? colors.black.DEFAULT
            if (color.includes('fourth'))
              return activeThemeColors?.fourth ?? colors.black.DEFAULT
            if (color.includes('wht')) return '#ffffff'
            if (color.includes('blck')) return '#000000'

            return color.startsWith('#') ||
              isContainingCssVariable({ text: color })
              ? color
              : '#' + color
          }),
      [colorMap?.charts, activeThemeColors],
    )
    const DEFAULT_COLORS = useMemo(() => Object.values(theme.colors.data), [])

    useLayoutEffect(() => {
      if (!series.length) return
      const root = am5.Root.new(chartId)
      root._logo?.dispose()

      const chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          endAngle: 270,
          paddingRight: 50,
          paddingTop: 50,
          paddingBottom: 50,
          paddingLeft: 50,
          width: size?.width || width,
          height: size?.height || height,
          ...(options?.donutInnerRadius
            ? {
                innerRadius: am5.percent(options.donutInnerRadius),
              }
            : {}),
        }),
      )

      chart.children.unshift(
        am5.Label.new(root, {
          text: options.title,
          x: 20,
          y: 20,
          fontSize: options.titleSize,
        }),
      )

      const currentSeries = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: 'value',
          categoryField: 'name',
        }),
      )
      currentSeries
        .get('colors')
        ?.set(
          'colors',
          chartColors?.length
            ? chartColors.map((cl) => am5.color(cl))
            : DEFAULT_COLORS.map((cl) => am5.color(cl)),
        )

      const flattenedSeries = series
        .flatMap((value) => value.values)
        .reduce<DataItem[]>((acc, curr) => {
          const found = acc.find((item) => item.name === curr.name)
          if (found) {
            found.value += curr.value!
          } else {
            acc.push({ name: curr.name, value: curr.value! })
          }
          return acc
        }, [])

      currentSeries.data.setAll(flattenedSeries)
      chartPieChartRef.current = chart
      chartPieRootRef.current = root

      // Add legend
      if (options.legends) {
        const legend = chart.children.push(
          am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            marginTop: 15,
            marginBottom: 15,
          }),
        )
        legend.markerRectangles.template.adapters.add(
          'fillGradient',
          function () {
            return undefined
          },
        )
        legend.data.setAll(chart.series.values)
        chartPieLegendRef.current = legend
      }

      setChartRendered(true)

      return () => {
        root.dispose()
      }
    }, [])

    useEffect(() => {
      if (
        !chartPieChartRef.current ||
        !chartPieChartRef.current!.series.values[0] ||
        !chartRendered
      ) {
        return
      }
      // Set pie slices color on series
      chartPieChartRef.current!.series.values.forEach((value) => {
        value.slices.values.forEach((value, sliceIndex) => {
          value.setAll({
            fill: am5.color(chartColors[sliceIndex]),
            stroke: am5.color(chartColors[sliceIndex]),
          })
        })
      })
    }, [chartColors])

    useEffect(() => {
      if (!chartRendered) return

      const titleIndex = chartPieChartRef.current?.children.values.findIndex(
        (value) => value.className === 'Label',
      )
      if (titleIndex !== undefined) {
        chartPieChartRef.current?.children.values[titleIndex].set(
          // @ts-expect-error text not typed
          'text',
          options.title,
        )
      }

      chartPieChartRef.current!.set(
        'innerRadius',
        options?.donutInnerRadius ? am5.percent(options.donutInnerRadius) : 0,
      )
    }, [options])

    useEffect(() => {
      if (!chartRendered) return

      chartPieChartRef.current!.series.clear()

      const currentSeries = chartPieChartRef.current!.series.push(
        am5percent.PieSeries.new(chartPieRootRef.current!, {
          valueField: 'value',
          categoryField: 'name',
        }),
      )
      const flattenedSeries = series
        .flatMap((value) => value.values)
        .reduce<DataItem[]>((acc, curr) => {
          const found = acc.find((item) => item.name === curr.name)
          if (found) {
            found.value += curr.value!
          } else {
            acc.push({ name: curr.name, value: curr.value! })
          }
          return acc
        }, [])

      currentSeries.data.setAll(flattenedSeries)
    }, [series])

    return (
      <div
        id={chartId}
        css={chartStyles}
        className={className}
        {...dataAttr}
        style={{ width: `${width}px`, height: `${height}px` }}
      />
    )
  },
)

ChartPercentage.displayName = 'ChartPercentage'
