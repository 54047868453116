import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const chartPropsStyles = css``

export const chartEditModalStyles = ({
  colors,
  gap,
  borderRadius,
}: ITheme) => css`
  background: ${colors.white.DEFAULT};
  padding: ${gap.xlarge}px ${gap['2xlarge']}px;
  border-radius: ${borderRadius[10]};
  height: calc(100vh - 100px);
  width: calc(100vw - 100px);

  .inner {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .chart-edit-header {
    display: flex;
    align-items: center;
    gap: ${gap.xlarge}px;
    margin-bottom: ${gap.small}px;

    .chart-edit-title {
      flex-grow: 1;
    }

    .chart-edit-type {
      display: flex;
      align-items: center;
      gap: ${gap.xsmall}px;
    }
  }

  .table-area {
    max-width: 100%;
    overflow: auto;
    height: calc(100% - 105px);
  }

  .action-button {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    gap: ${gap.xsmall}px;
  }
`
interface IChartTypeButtonStyles {
  isActive?: boolean
}
export const chartTypeButtonStyles =
  ({ isActive }: IChartTypeButtonStyles) =>
  ({ colors, borderRadius }: ITheme) => css`
    width: 40px;
    height: 32px;
    background: ${colors.background.DEFAULT};
    border: 2px solid ${colors.background.DEFAULT};
    border-radius: ${borderRadius[4]};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${isActive &&
    `
			border: 2px solid ${colors.primary.DEFAULT};
		`}
  `
