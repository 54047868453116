import React, { useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import { chartPropsStyles } from './styles'
import { EditData } from './EditData'
import { GridLines } from './GridLines'
import { Legends } from './Legends'
import { XAxis } from './XAxis'
import { YAxis } from './YAxis'
import { ChartTitle } from './ChartTitle'
import { IEditPropSection } from '../../types'
import { PropHeader } from 'src/pages/deck-page/components/edit-properties/props'
import {
  ComponentChartDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ComponentChartTypes } from 'src/types/api/enums'

export const ChartProps: React.FC<IEditPropSection> = React.memo(
  ({ components, className, dataAttr }) => {
    const { t } = useLanguage()

    const selectedChartType = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const type = (c.data as ComponentChartDataSchema).options.type
          if (a === '' && type) {
            return type
          }

          return type !== a ? '' : a
        }, ''),
      [components],
    )
    return (
      <div css={chartPropsStyles} className={className} {...dataAttr}>
        <div className="grid">
          <div className="c-full control-row">
            <EditData components={components} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.chart_props.settings')} />
        <div className="grid">
          <div className="c-full control-row">
            <ChartTitle components={components} />
          </div>
          {selectedChartType !== ComponentChartTypes.DONUT &&
            selectedChartType !== ComponentChartTypes.PIE && (
              <>
                <div className="c-full control-row">
                  <span>{t('edit.properties.chart_props.show_grid')}</span>
                  <GridLines components={components} />
                </div>
                <div className="c-full control-row">
                  <span>{t('edit.properties.chart_props.show_legends')}</span>
                  <Legends components={components} />
                </div>
                <XAxis components={components} />
                <YAxis components={components} />
              </>
            )}
        </div>
      </div>
    )
  },
)

ChartProps.displayName = 'ChartProps'
