import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editSideMenuStyles = ({ colors, gap, breakpoints }: ITheme) => css`
  color: ${colors.white.DEFAULT};
  width: 100%;
  height: 100%;
  position: relative;

  .inner {
    background: ${colors.dark2.DEFAULT};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow-y: auto;
  }

  .grid {
    width: 100%;
    display: grid;
    grid-gap: ${gap.xsmall}px;
    grid-template-columns: repeat(
      3,
      calc((100% / 3) - ${(gap.xsmall * 2) / 3}px)
    );

    .control-row {
      display: flex;
      align-items: center;
      gap: ${gap.medium}px;

      > span {
        flex-grow: 1;
      }

      &-big {
        min-height: 46px;
      }
    }

    &:not(:last-child) {
      margin-bottom: ${gap.small}px;
    }

    .c-full {
      grid-column: 1 / -1;
    }

    .c-1 {
      grid-column: 1 span;
    }

    .c-2 {
      grid-column: 2 span;
    }

    .c-3 {
      grid-column: 3 span;
    }

    .c-4 {
      grid-column: 4 span;
    }

    .input-error {
      top: -13px;
      font-size: 10px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      grid-template-columns: repeat(4, calc(25% - ${(gap.xsmall * 3) / 4}px));
    }

    @media (max-width: ${breakpoints.desktop - 1}px) {
      .c-t-1 {
        grid-column: 1 span;
      }

      .c-t-2 {
        grid-column: 2 span;
      }

      .c-t-3 {
        grid-column: 3 span;
      }

      .c-t-4 {
        grid-column: 4 span;
      }

      .c-t-full {
        grid-column: 1 / -1;
      }
    }
  }
`
