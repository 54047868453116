import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles =
  ({ isMainPanel }: { isMainPanel?: boolean }) =>
  (theme: ITheme) => css`
    position: relative;
    background: ${theme.colors.white.DEFAULT};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      width: ${theme.dimensions.settingsPanelWidth.DEFAULT}px;
    }

    .close-button-area {
      display: flex;
      justify-content: ${isMainPanel ? 'flex-end' : 'flex-start'};
      top: ${theme.spacing.small};

      ${isMainPanel
        ? `right: ${theme.spacing.small};`
        : `left: ${theme.spacing.small};`}
      padding-top: ${theme.spacing.small};
      padding-right: ${theme.spacing.small};
      padding-left: ${theme.spacing.small};

      @media (min-width: ${theme.breakpoints.tablet}px) {
        position: absolute;
        padding: 0;
      }
    }
  `

export const contentAreaStyles =
  (upgradeModal: boolean) => (theme: ITheme) => css`
    padding: ${theme.spacing.medium};
    overflow-y: auto;
    ${upgradeModal &&
    `padding: ${theme.spacing.medium}; height: 100%; overflow-y: auto;`}

    @media (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing['4xlarge']};
      ${upgradeModal &&
      `padding: ${theme.gap['3xlarge']}px;
      height: 100%;`}
    }

    @media (min-width: ${theme.breakpoints.laptop}px) {
      ${upgradeModal &&
      `padding: ${theme.gap['3xlarge']}px;
      height: 100%;`}
    }

    @media (min-width: ${theme.breakpoints.desktop}px) {
      ${upgradeModal && `padding: ${theme.gap['5xlarge']}px;`}
    }
  `
