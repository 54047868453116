import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  bottomSpaceWrapper,
  frameStyles,
  spanWrapperStyles,
} from '../../styles'
import {
  ACTION_TYPE,
  Button,
  Collaborator,
  COLLABORATOR_TYPE,
  Dropdown,
  Icon,
  icons,
  IDropdownItem,
  Input,
  INPUT_SIZE,
  INPUT_THEME,
  INPUT_WIDTH,
} from 'src/lib'
import {
  useDecksApi,
  useLanguage,
  useNotification,
  useOrgApi,
  useWindowSize,
  VALIDATION_RULE_TYPES,
} from 'src/hooks'
import {
  addCollaboratorStyles,
  collaboratorStyles,
  hasNotCollaboratorStyles,
  pendingCollaboratorStyles,
  smallSpanWrapperStyles,
} from './styles'
import { theme } from 'src/theme'
import { IAddCollabrator } from './types'
import {
  DeckInvitePermissions,
  DeckPermissions,
  ViewModes,
} from 'src/types/api/enums'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/index'
import { getPendingDeckInvites } from 'src/types/api/responseObjects'

export const AddCollaborator: React.FC<IAddCollabrator> = React.memo(
  ({
    deckId,
    workspaceId,
    viewMode,
    deckPermission,
    userDecks,
    ownerUserId,
    activeDeckOwner,
  }) => {
    const { t } = useLanguage()
    const [seeCollaborator, setSeeCollaborator] = useState(true)
    const [emailValid, setEmailValid] = useState(false)
    const [email, setEmail] = useState('')

    const [dropdownState, setDropdownState] = useState(deckPermission)
    const [collabratedUsers, setCollabratedUsers] = useState<
      {
        id: number
        email: string
        picture: string | null
        deckPermission: DeckPermissions
      }[]
    >([])

    const [tempCollabEmails, setTempCollabEmails] = useState<
      {
        email: string
        deckPermission: DeckInvitePermissions
      }[]
    >([])
    const [pendingDeckInvites, setPendingDeckInvites] =
      useState<getPendingDeckInvites['data']['pendingDeckInvites']>()

    const size = useWindowSize()

    const {
      updateDeck,
      getDeckPendingInvites,
      postDeckInvite,
      deleteDeckInvite,
      putUpdateDeckInvite,
      deleteDeckCollabrator,
      putUpdateDeckCollaboratorPermission,
      isLoading,
    } = useDecksApi()
    const { getOrgUsers } = useOrgApi()
    const { success } = useNotification()

    const loadPendingDeckInvites = useCallback(async () => {
      if (!deckId) return
      const res = await getDeckPendingInvites(
        {
          deckId: deckId,
        },
        workspaceId,
      )

      setPendingDeckInvites(res?.pendingDeckInvites)
    }, [deckId, workspaceId])

    useEffect(() => {
      loadPendingDeckInvites()

      if (!userDecks?.length) return
      setCollabratedUsers(
        userDecks.map((ud) => {
          return {
            id: ud.user.id,
            email: ud.user.email,
            picture: ud.user.picture,
            deckPermission: ud.permission,
          }
        }),
      )
    }, [userDecks])

    let { orgUsers } = useSelector(({ org }: RootState) => ({
      orgUsers: org.users,
    }))

    useEffect(() => {
      if (workspaceId) {
        getOrgUsers(workspaceId)
      } else {
        orgUsers = null
      }
    }, [workspaceId])

    const smallInputSize = useMemo(
      () => size.width <= theme.breakpoints.tablet,
      [size],
    )

    const onEmailChange = useCallback((value: string) => {
      setEmail(value)
    }, [])

    const onPermissionChange = useCallback((value?: IDropdownItem) => {
      setDropdownState(value?.value as DeckPermissions)
    }, [])

    const onTempEmailSelect = useCallback(
      (value?: IDropdownItem) => {
        const tempCollab = tempCollabEmails.find(
          (tc) => tc.email === value?.name,
        )
        if (value?.name && !tempCollab && value.value !== '0') {
          setTempCollabEmails([
            ...tempCollabEmails,
            { email: value.name, deckPermission: DeckInvitePermissions.READ },
          ])
        }
      },
      [tempCollabEmails],
    )

    const possibleUserEmails = useMemo(() => {
      return [
        {
          name: t('share_modal.select_from_list'),
          value: '0',
        },
        ...((orgUsers
          ?.map((user) => {
            // Do not include self
            if (user.user.id === ownerUserId) {
              return null
            }

            // Do not list alrady selected emails
            const tempCollab = tempCollabEmails.find(
              (tc) => tc.email === user.user.email,
            )
            if (tempCollab) {
              return null
            }

            // Do not list pending invites
            const pendingUser = pendingDeckInvites?.find((pi) => {
              return pi.invitedEmail === user.user.email
            })
            if (pendingUser) {
              return null
            }

            const isCollabrated = collabratedUsers?.some(
              (col) => col.email === user.user.email,
            )
            if (isCollabrated) {
              return null
            }

            return {
              name: user.user.email,
              value: user.user.id.toString(),
            }
          })
          ?.filter((el) => !!el)
          ?.sort((a, b) => {
            if (a!.name < b!.name) {
              return -1
            }
            if (a!.name > b!.name) {
              return 1
            }
            return 0
          }) as IDropdownItem[]) || []),
      ]
    }, [orgUsers, tempCollabEmails, pendingDeckInvites, collabratedUsers])

    useEffect(() => {
      if (
        viewMode != ViewModes.ORGANIZATION ||
        deckPermission == dropdownState ||
        !deckId
      ) {
        return
      }

      updateDeck(
        { deckId: deckId },
        {
          organizationId: workspaceId,
          viewMode: ViewModes.ORGANIZATION,
          deckPermission: dropdownState,
        },
      ).then(() => {
        success(t('share_modal.save_complete'))
      })
    }, [deckId, workspaceId, dropdownState, deckPermission])

    const onCollabratorAction = useCallback(
      async (email: string, action: ACTION_TYPE) => {
        const isTempCollab = tempCollabEmails.some((tc) => tc.email === email)
        if (isTempCollab) {
          if (action === ACTION_TYPE.REMOVE) {
            setTempCollabEmails(
              tempCollabEmails.filter((tc) => tc.email !== email),
            )
          }
          if (action === ACTION_TYPE.PERMISSION_R) {
            setTempCollabEmails(
              tempCollabEmails.map((tc) => {
                if (tc.email === email) {
                  return {
                    ...tc,
                    deckPermission: DeckInvitePermissions.READ,
                  }
                }

                return tc
              }),
            )
          }
          if (action === ACTION_TYPE.PERMISSION_RW) {
            setTempCollabEmails(
              tempCollabEmails.map((tc) => {
                if (tc.email === email) {
                  return {
                    ...tc,
                    deckPermission: DeckInvitePermissions.READWRITE,
                  }
                }

                return tc
              }),
            )
          }
        }

        if (!deckId) return

        const pendingInvite = pendingDeckInvites?.find(
          (pi) => pi.invitedEmail === email,
        )
        if (pendingInvite) {
          if (action === ACTION_TYPE.REMOVE) {
            const resSuccess = await deleteDeckInvite(
              deckId,
              pendingInvite.id,
              workspaceId,
            )
            if (resSuccess) {
              success(t('share_modal.collaborated_users_updated'))
              setPendingDeckInvites(
                pendingDeckInvites?.filter(
                  (pi) => pi.id !== pendingInvite.id,
                ) || [],
              )
            }
          }
          if (action === ACTION_TYPE.PERMISSION_R) {
            const resSuccess = await putUpdateDeckInvite(
              deckId,
              pendingInvite.id,
              {
                organizationId: workspaceId,
                permission: DeckInvitePermissions.READ,
              },
            )
            if (resSuccess) {
              success(t('share_modal.collaborated_users_updated'))
              setPendingDeckInvites(
                pendingDeckInvites?.map((pi) => {
                  if (pi.id === pendingInvite.id) {
                    return {
                      ...pi,
                      permission: DeckInvitePermissions.READ,
                    }
                  }

                  return pi
                }) || [],
              )
            }
          }
          if (action === ACTION_TYPE.PERMISSION_RW) {
            const resSuccess = await putUpdateDeckInvite(
              deckId,
              pendingInvite.id,
              {
                organizationId: workspaceId,
                permission: DeckInvitePermissions.READWRITE,
              },
            )
            if (resSuccess) {
              success(t('share_modal.collaborated_users_updated'))
              setPendingDeckInvites(
                pendingDeckInvites?.map((pi) => {
                  if (pi.id === pendingInvite.id) {
                    return {
                      ...pi,
                      permission: DeckInvitePermissions.READWRITE,
                    }
                  }

                  return pi
                }) || [],
              )
            }
          }
        }

        const collabratedUser = collabratedUsers?.find(
          (cu) => cu.email === email,
        )
        if (collabratedUser) {
          if (action === ACTION_TYPE.REMOVE) {
            const resSuccess = await deleteDeckCollabrator(
              deckId,
              collabratedUser.id,
              workspaceId,
            )
            if (resSuccess) {
              success(t('share_modal.collaborated_users_updated'))
              setCollabratedUsers(
                collabratedUsers?.filter(
                  (cu) => cu.id !== collabratedUser.id,
                ) || [],
              )
            }
          }
          if (action === ACTION_TYPE.PERMISSION_R) {
            const resSuccess = await putUpdateDeckCollaboratorPermission(
              deckId,
              collabratedUser.id,
              {
                organizationId: workspaceId,
                newPermission: DeckPermissions.READ,
              },
            )
            if (resSuccess) {
              success(t('share_modal.collaborated_users_updated'))
              setCollabratedUsers(
                collabratedUsers.map((cu) => {
                  if (cu.id === collabratedUser.id) {
                    return {
                      ...cu,
                      deckPermisson: DeckPermissions.READ,
                    }
                  }

                  return cu
                }) || [],
              )
            }
          }
          if (action === ACTION_TYPE.PERMISSION_RW) {
            const resSuccess = await putUpdateDeckCollaboratorPermission(
              deckId,
              collabratedUser.id,
              {
                organizationId: workspaceId,
                newPermission: DeckPermissions.READWRITE,
              },
            )
            if (resSuccess) {
              success(t('share_modal.collaborated_users_updated'))
              setCollabratedUsers(
                collabratedUsers.map((cu) => {
                  if (cu.id === collabratedUser.id) {
                    return {
                      ...cu,
                      deckPermisson: DeckPermissions.READWRITE,
                    }
                  }

                  return cu
                }) || [],
              )
            }
          }
        }
      },
      [
        deckId,
        orgUsers,
        tempCollabEmails,
        pendingDeckInvites,
        collabratedUsers,
        workspaceId,
      ],
    )

    const onEmailInputEnter = useCallback(() => {
      if (!emailValid) {
        return
      }

      const existingTempCollabFound =
        tempCollabEmails.some((tc) => tc.email === email) ||
        pendingDeckInvites?.some((pi) => pi.invitedEmail === email) ||
        collabratedUsers?.some((cu) => cu.email === email)
      if (!existingTempCollabFound) {
        setTempCollabEmails([
          ...tempCollabEmails,
          { email, deckPermission: DeckInvitePermissions.READ },
        ])
      }

      setEmail('')
    }, [
      email,
      emailValid,
      tempCollabEmails,
      pendingDeckInvites,
      collabratedUsers,
    ])

    const onAddButtonClick = useCallback(async () => {
      if (!deckId) return
      try {
        await postDeckInvite(deckId, {
          organizationId: workspaceId,
          invites: tempCollabEmails.map((tc) => {
            return {
              email: tc.email,
              permission: tc.deckPermission,
            }
          }),
        })

        success(t('share_modal.invitations_sent'))

        // Reload pending invites
        await loadPendingDeckInvites()
      } finally {
        setTempCollabEmails([])
      }
    }, [tempCollabEmails, pendingDeckInvites, collabratedUsers, workspaceId])

    return (
      <div css={frameStyles}>
        <div css={bottomSpaceWrapper}>
          <span css={spanWrapperStyles}>
            {t('share_modal.invite_people_to_collaborate')}
          </span>
        </div>
        {viewMode == ViewModes.ORGANIZATION && (
          <div css={bottomSpaceWrapper}>
            <span css={smallSpanWrapperStyles}>
              {t('share_modal.organization_deck_permission')}
            </span>
            <div>
              <Dropdown
                items={[
                  { name: t('common.read_only'), value: DeckPermissions.READ },
                  {
                    name: t('common.read_write'),
                    value: DeckPermissions.READWRITE,
                  },
                ]}
                onChange={onPermissionChange}
                selected={dropdownState}
              />
            </div>
          </div>
        )}
        <div css={bottomSpaceWrapper}>
          <span css={smallSpanWrapperStyles}>
            {t('share_modal.add_collaborators')}
          </span>
          {workspaceId ? (
            <Dropdown
              items={possibleUserEmails}
              onChange={onTempEmailSelect}
              selected={'0'}
            />
          ) : (
            <Input
              width={INPUT_WIDTH.FULL}
              name={t('common.email')}
              theme={INPUT_THEME.LIGHT}
              size={smallInputSize ? INPUT_SIZE.MEDIUM : INPUT_SIZE.BIG}
              onChange={onEmailChange}
              placeholder={t('share_modal.valid_email_message')}
              value={email}
              solidError
              validation={{
                [VALIDATION_RULE_TYPES.REQUIRED]: {
                  text: t('validation.error.required', {
                    name: t('common.email'),
                  }),
                },
                [VALIDATION_RULE_TYPES.VALIDMAIL]: {
                  text: t('validation.error.email'),
                },
              }}
              onValidation={setEmailValid}
              onEnter={onEmailInputEnter}
              disabledCheckOnBlur={true}
            />
          )}
        </div>
        {tempCollabEmails.length ? (
          <div css={addCollaboratorStyles}>
            {tempCollabEmails.map((item, index) => (
              <Collaborator
                key={index}
                email={item.email}
                css={pendingCollaboratorStyles}
                collaboratorType={COLLABORATOR_TYPE.SMALL}
                onAction={onCollabratorAction}
                invitePermission={item.deckPermission}
              />
            ))}
          </div>
        ) : null}
        <div css={bottomSpaceWrapper}>
          <Button
            text={t('common.actions.add')}
            disabled={isLoading || !tempCollabEmails.length}
            onClick={onAddButtonClick}
          />
        </div>
        <div
          css={collaboratorStyles}
          onClick={() => {
            setSeeCollaborator(!seeCollaborator)
          }}
        >
          <span>{t('share_modal.collaborators')}</span>
          {seeCollaborator && <Icon icon={icons.chevron_down} size={16} />}
          {!seeCollaborator && <Icon icon={icons.chevron_up} size={16} />}
        </div>
        {seeCollaborator ? (
          collabratedUsers.length || pendingDeckInvites?.length ? (
            <div>
              <Collaborator
                css={pendingCollaboratorStyles}
                email={activeDeckOwner?.email ?? ''}
                collaboratorType={COLLABORATOR_TYPE.BIG}
                disabled={true}
                deckPermission={DeckPermissions.READWRITE}
                imageUrl={activeDeckOwner?.picture}
                isDeckOwner={!!activeDeckOwner?.id}
              />
              {collabratedUsers.map((item, index) => (
                <Collaborator
                  key={index}
                  css={pendingCollaboratorStyles}
                  email={item.email}
                  collaboratorType={COLLABORATOR_TYPE.BIG}
                  onAction={onCollabratorAction}
                  deckPermission={item.deckPermission}
                  imageUrl={item.picture}
                />
              ))}
              {pendingDeckInvites?.map((item, index) => (
                <Collaborator
                  key={index}
                  css={pendingCollaboratorStyles}
                  email={item.invitedEmail}
                  collaboratorType={COLLABORATOR_TYPE.BIG}
                  onAction={onCollabratorAction}
                  invitePermission={item.permission}
                  label={t('subscriptions.subs_state.pending')}
                  imageUrl={
                    orgUsers?.find((ou) => ou.user.email === item.invitedEmail)
                      ?.user.picture
                  }
                />
              ))}
            </div>
          ) : (
            <span css={hasNotCollaboratorStyles}>
              {t('share_modal.not_collaborating_message')}
            </span>
          )
        ) : null}
      </div>
    )
  },
)
