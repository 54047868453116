export const GTAG_EVENTS = {
  PURCHASE: 'purchase',
  ADD_TO_CART: 'add_to_cart',
  CANCEL: 'cancel',
  SIGN_IN: 'sign_in',
  PURCHASE_INTENT: 'purchase_intent',
  SIGN_OUT: 'sign_out',
  SIGN_UP: 'sign_up',
  SIGN_UP_PAGE_VIEW: 'sign_up_page_view',
}

export const GTAG_EVENT_CATEGORIES = {
  ENGAGEMENT: 'engagement',
}

export const PURCHASE_EVENTS = {
  PRO_AI_MONTHLY: 'pro_ai_monthly_purchased',
  PRO_AI_ANNUAL: 'pro_ai_annual_purchased',
  BUSINESS_AI_MONTHLY: 'business_ai_monthly_purchased',
  BUSINESS_AI_ANNUAL: 'business_ai_annual_purchased',
}

export const CANCEL_EVENTS = {
  PRO_AI_MONTHLY: 'pro_ai_monthly_canceled',
  PRO_AI_ANNUAL: 'pro_ai_annual_canceled',
  BUSINESS_AI_MONTHLY: 'business_ai_monthly_canceled',
  BUSINESS_AI_ANNUAL: 'business_ai_annual_canceled',
}

export const PROVIDER = {
  DECKTOPUS: 'Decktopus',
  GOOGLE: 'Google',
}

export const ACTION_CLICKS = {
  AI_CLICK: 'ai_click',
  UPGRADE_CLICK: 'upgrade_click',
  WEBHOOK_CLICK: 'webhook_click',
  EXPORT_CLICK: 'export_click',
  CUSTOM_DOMAIN_CLICK: 'custom_domain_click',
  ANALYTICS_CLICK: 'analytics_click',
  CHOOSE_PLAN_CLICK: 'choose_plan_click',
}
