import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import {
  ComponentSchema,
  getDeckDetailedResponse,
  getSlideDataResponse,
  putUpdateDeckDataResponse,
} from 'src/types/api/responseObjects'
import { LoaderState, SAVE_STATE } from '../types'
import {
  ComponentTextDataSchema,
  DeckData_DataSchema,
  DeleteComponentSchema,
  GradientLikeColorSchema,
  NewComponentSchema,
  putUpdateDeckDataBody,
  ThemeColorMapSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import {
  COMPONENT_ALIGN,
  COMPONENT_DIST,
  EDIT_ACTION_TYPE,
  IEditInitialState,
} from './types'
import {
  alignComponents,
  distributeComponents,
  getComponent,
  getSlideDataIndex,
  getSlideOrder,
  placeToNextRange,
  placeToPreviousRange,
  shiftSlideOrder,
  slideOrderChanges,
  slideOrderInsert,
  slideOrderRevise,
} from './helpers'
import { looseObject } from 'src/types'

import { IDeckTips } from 'src/components/edit-ai-assistant'
import {
  ComponentTextFormats,
  ComponentTextTypes,
  ComponentTypes,
  SVGTypes,
} from 'src/types/api/enums'
import {
  slideBackgroundColor,
  slideDecorSvg,
  UNDO_REDO_ACTION,
  undoRedoAction,
} from './reducers'
import { ISetSlideNotesPayload } from 'src/components/edit-notes'

const initialState: IEditInitialState = {
  activeDeck: {
    data: null,
    isLoading: false,
  },
  activeSlideData: {
    data: [],
    isLoading: false,
  },
  activeSlideID: undefined,
  activeSlideDataID: undefined,
  toBeSaved: {
    data: [],
    isLoading: false,
  },
  instantSave: {
    data: [],
    isLoading: false,
  },
  customDomainId: null,
  savingStack: [],
  slideNotes: '',
  speakerNotes: '',
  tips: [],
  qa: [],
  clickedButton: '',
  history: [],
  undoHistory: {
    past: [],
    future: [],
  },
  cachedColorMaps: [],
}

export const editSlice = createSlice({
  name: 'edit',
  initialState,
  reducers: {
    setActiveDeck: (
      state,
      action: PayloadAction<LoaderState<getDeckDetailedResponse['data']>>,
    ) => {
      if (action.payload.data) {
        state.activeDeck.data = action.payload.data
      } else {
        state.activeDeck.isLoading = !!action.payload.isLoading
      }
    },
    setSlideData: (
      state,
      action: PayloadAction<
        LoaderState<getSlideDataResponse['data']['slideData']>
      >,
    ) => {
      if (action.payload.data && state.activeSlideData.data) {
        const slideDataIndex = state.activeSlideData.data.findIndex(
          ({ id }) => id === action.payload.data?.id,
        )

        // This is crucial to make sure that all the components has tempId so the client side processes can be kept tracked
        const slideData = action.payload.data
        slideData.slideDataComponents = slideData.slideDataComponents.map(
          ({ component }) => ({
            component: {
              ...component,
              tempId: uuidv4(),
            },
          }),
        )

        if (slideDataIndex !== -1) {
          state.activeSlideData.data[slideDataIndex] = slideData
        } else {
          state.activeSlideData.data.push(slideData)
        }
      } else {
        state.activeSlideData.isLoading = action.payload.isLoading
      }
    },
    setActiveSlideID: (state, action: PayloadAction<number>) => {
      state.activeSlideID = action.payload
      state.activeSlideDataID =
        state.activeDeck.data?.deckData?.data.slides.find(
          ({ slideId }) => slideId === action.payload,
        )?.slideDataId
    },
    setCustomDomainId: (state, action: PayloadAction<number>) => {
      state.customDomainId = action.payload
    },
    setTips: (state, action: PayloadAction<IDeckTips[]>) => {
      state.tips = action.payload
    },
    setQA: (state, action: PayloadAction<any>) => {
      state.qa = action.payload
    },
    slideCreate: (
      state,
      action: PayloadAction<
        | {
            targetSlide?: DeckData_DataSchema['slides'][0]
            isDuplicate?: boolean
            layoutComponents?: NewComponentSchema[]
            tempSvg?: looseObject
            background?: GradientLikeColorSchema | null
            extra?: {
              userFullname?: string
              fullDate?: string
            }
            isSwapColor?: boolean
            svgType?: SVGTypes | null
          }
        | undefined
      >,
    ) => {
      if (state.activeDeck.data?.deckData?.data?.slides) {
        const slides = state.activeDeck.data.deckData.data.slides
          .filter(({ isDeleted }) => !isDeleted)
          .sort((a, b) => a.orderIndex - b.orderIndex)
        const targetItem = slides.find(
          ({ slideId }) => slideId === action.payload?.targetSlide?.slideId,
        )
        const orderFromTargetSlide = targetItem
          ? targetItem.orderIndex + 1
          : undefined

        const orderFromLastItem =
          (slides[slides.length - 1]?.orderIndex || 0) + 1

        const orderIndex = orderFromTargetSlide || orderFromLastItem

        const newSlideTempId = uuidv4()

        const newSlide: DeckData_DataSchema['slides'][0] = {
          slideDataId: 0,
          slideTempId: newSlideTempId,
          orderIndex,
          isMaster: false,
          isDeleted: false,
        }

        const newSlides = slideOrderInsert(
          state.activeDeck.data.deckData.data.slides,
          newSlide,
        )

        const orderChanges = slideOrderChanges(
          newSlides,
          state.activeDeck.data.deckData.data.slides,
        )

        const targetComponentData = action.payload?.isDuplicate
          ? state.activeSlideData.data?.find(
              ({ id }) => id === action.payload?.targetSlide?.slideDataId,
            )
          : undefined

        const duplicatedComponents: NewComponentSchema[] | undefined =
          targetComponentData?.slideDataComponents.map(({ component }) => ({
            ...component,
            tempId: uuidv4(),
            id: undefined,
            sessionId: undefined,
          }))

        const newComponentsFromLayout: NewComponentSchema[] | undefined =
          action.payload?.layoutComponents?.map((component) => {
            // For some reason react not allowing to modify components in place
            const componentCopy = JSON.parse(JSON.stringify(component))

            // If component is text and has format of name or date
            if (componentCopy.type === ComponentTypes.TEXT) {
              const compData = componentCopy.data as ComponentTextDataSchema
              if (
                compData.textType === ComponentTextTypes.BODY &&
                compData.textFormat === ComponentTextFormats.NAME &&
                action.payload?.extra?.userFullname
              ) {
                compData.text = action.payload.extra.userFullname
              }

              if (
                compData.textType === ComponentTextTypes.BODY &&
                compData.textFormat === ComponentTextFormats.DATE &&
                action.payload?.extra?.fullDate
              ) {
                compData.text = action.payload.extra.fullDate
              }
            }

            return {
              ...componentCopy,
              id: undefined,
              tempId: uuidv4(),
            }
          })

        const componentData = targetComponentData
          ? duplicatedComponents
          : newComponentsFromLayout

        // TODO: strange structure, improve this
        const instantSaveData = [
          ...state.instantSave.data,
          ...orderChanges,
          ...(componentData
            ? [
                {
                  tempId: newSlideTempId,
                  orderIndex,
                  tempSvg:
                    action.payload?.tempSvg ||
                    (targetComponentData?.tempSvg as looseObject),
                  background: action.payload?.background,
                  components: componentData,
                  isSwapColor: action.payload?.isSwapColor,
                  svgType: action.payload?.svgType,
                },
              ]
            : [
                {
                  tempId: newSlideTempId,
                  orderIndex,
                  svgType: action.payload?.svgType,
                  background: action.payload?.background,
                  isSwapColor: action.payload?.isSwapColor,
                },
              ]),
        ]

        state.instantSave.data = instantSaveData
        state.activeDeck.data.deckData.data.slides = newSlides
      }
    },
    slideCreateUpdate: (
      state,
      action: PayloadAction<
        putUpdateDeckDataResponse['data']['newSlides'],
        any
      >,
    ) => {
      if (
        state.activeDeck.data?.deckData?.data.slides &&
        action.payload.length
      ) {
        const targetIndex =
          state.activeDeck.data.deckData.data.slides.findIndex(
            ({ slideTempId }) => slideTempId === action.payload[0].slideTempId,
          )

        if (targetIndex !== -1) {
          state.activeDeck.data.deckData.data.slides[targetIndex].slideId =
            action.payload[0].slideId
          state.activeDeck.data.deckData.data.slides[targetIndex].slideDataId =
            action.payload[0].slideDataId

          state.activeSlideID =
            state.activeDeck.data.deckData.data.slides[targetIndex].slideId
          state.activeSlideDataID =
            state.activeDeck.data.deckData.data.slides[targetIndex].slideDataId
        }
      }
    },
    slideDelete: (state, action: PayloadAction<number>) => {
      if (state.activeDeck.data?.deckData?.data.slides) {
        const isActiveSlide = action.payload === state.activeSlideID

        const deleteSlideIndex =
          state.activeDeck.data.deckData.data.slides.findIndex(
            ({ slideId }) => slideId === action.payload,
          )

        const newSlidesArray = [...state.activeDeck.data.deckData.data.slides]
        newSlidesArray[deleteSlideIndex].isDeleted = true

        const slideOrderRevised = slideOrderRevise(newSlidesArray)

        const toBeSaved = slideOrderChanges(
          slideOrderRevised,
          state.activeDeck.data?.deckData?.data.slides,
        )

        state.activeDeck.data.deckData.data.slides = slideOrderRevised

        if (isActiveSlide) {
          const currentSlideOrder =
            state.activeDeck.data.deckData.data.slides.find(
              ({ slideId }) => slideId === action.payload,
            )?.orderIndex || 1

          const notDeletedSlides = slideOrderRevised.filter(
            ({ isDeleted }) => !isDeleted,
          )

          const nextSlide = notDeletedSlides.find(
            ({ orderIndex }) => orderIndex === currentSlideOrder,
          )

          const prevSlide = notDeletedSlides.find(
            ({ orderIndex }) => orderIndex === currentSlideOrder - 1,
          )

          if (nextSlide || prevSlide) {
            state.activeSlideID = nextSlide
              ? nextSlide.slideId
              : prevSlide?.slideId
          }
        }

        state.toBeSaved.data = [
          ...state.toBeSaved.data,
          ...toBeSaved,
          {
            id: action.payload,
            isDeleted: true,
            orderIndex: 1,
          },
        ]
      }
    },
    slideShift: (
      state,
      action: PayloadAction<{
        from?: number
        to?: number
        fromNumber?: number
        toNumber?: number
      }>,
    ) => {
      const slideData = state.activeDeck.data?.deckData?.data.slides
      if (slideData) {
        const fromOrder =
          action.payload.fromNumber ||
          slideData.find(({ slideId }) => slideId === action.payload.from)
            ?.orderIndex ||
          0
        const toOrder =
          action.payload.toNumber ||
          slideData.find(({ slideId }) => slideId === action.payload.to)
            ?.orderIndex ||
          0

        const capToOrder =
          toOrder > slideData.length ? slideData.length : toOrder

        if (state.activeDeck.data?.deckData?.data.slides) {
          const notDeleted = [
            ...slideData.filter(({ isDeleted }) => !isDeleted),
          ]
          const deletedSlides = [
            ...slideData.filter(({ isDeleted }) => isDeleted),
          ]

          const changedOrders = notDeleted.map((slide) => {
            const newOrder = shiftSlideOrder({
              currentOrder: slide.orderIndex,
              from: fromOrder,
              to: capToOrder,
            })

            return {
              ...slide,
              orderIndex: newOrder,
            }
          })

          const reviseOrders = slideOrderRevise(changedOrders)
          const toBeSaved = slideOrderChanges(
            reviseOrders,
            state.activeDeck.data?.deckData?.data.slides,
          )

          state.toBeSaved.data = [...state.toBeSaved.data, ...toBeSaved]
          state.activeDeck.data.deckData.data.slides = [
            ...reviseOrders,
            ...deletedSlides,
          ]
        }
      }
    },
    slideSwapColor: (
      state,
      action: PayloadAction<{
        slideId: number
        slideDataId: number
        state: boolean
      }>,
    ) => {
      const targetSlide = state.activeSlideData.data?.find(
        ({ id }) => id === action.payload.slideDataId,
      )

      if (targetSlide?.isSwapColor !== undefined) {
        targetSlide.isSwapColor = action.payload.state
      }

      state.instantSave.data.push({
        id: action.payload.slideId,
        isSwapColor: action.payload.state,
      })
    },
    slideTempSvg: (
      state,
      action: PayloadAction<{
        slideId: number
        slideDataId: number
        key: number
        url: string | null
      }>,
    ) => {
      const targetSlide = state.activeSlideData.data?.find(
        ({ id }) => id === action.payload.slideDataId,
      )

      const tempSvg = {
        ...(targetSlide?.tempSvg || {}),
        [action.payload.key]: action.payload.url,
      }

      targetSlide!.tempSvg = tempSvg

      state.toBeSaved.data.push({
        id: action.payload.slideId,
        tempSvg: {
          [action.payload.key]: action.payload.url,
        },
      })
    },
    setDeckSave: (state, action: PayloadAction<SAVE_STATE>) => {
      switch (action.payload) {
        case SAVE_STATE.SAVING:
          if (state.toBeSaved.data) {
            state.savingStack.push(...state.toBeSaved.data)
            state.savingStack.push(...state.instantSave.data)
            state.instantSave.data = initialState.instantSave.data
            state.toBeSaved.data = initialState.toBeSaved.data
          }
          break
        case SAVE_STATE.SAVED:
          state.savingStack = initialState.savingStack
          break
      }
    },
    clearEdit: (state) => {
      state.activeDeck = initialState.activeDeck
      state.activeSlideData = initialState.activeSlideData
      state.activeSlideDataID = initialState.activeSlideDataID
      state.activeSlideID = initialState.activeSlideID
      state.savingStack = initialState.savingStack
      state.instantSave.data = initialState.instantSave.data
      state.toBeSaved.data = initialState.toBeSaved.data
    },
    clearEditSoft: (state) => {
      state.activeSlideData = initialState.activeSlideData
      state.activeSlideDataID = initialState.activeSlideDataID
      state.activeSlideID = initialState.activeSlideID
    },
    changeDeckName: (state, action: PayloadAction<string>) => {
      if (state.activeDeck.data?.deck) {
        state.activeDeck.data.deck.name = action.payload
      }
    },
    componentsUpdate: (
      state,
      action: PayloadAction<{
        components: UpdateComponentSchema[]
        isAutoProcess?: boolean
      }>,
    ) => {
      const slideIndex = getSlideDataIndex(state)
      if (action.payload && slideIndex !== -1) {
        const updatedComponents: UpdateComponentSchema[] =
          action.payload.components.map((component) => {
            const componentData = getComponent(
              state,
              component.id,
              component.tempId,
            )

            // client update
            const newComponent: UpdateComponentSchema = {
              id: component.id || componentData?.id,
              type: component.type || componentData?.type,
              tempId: component.tempId || componentData?.tempId,
              positions: component.positions || componentData?.positions,
              style: component.style || componentData?.style,
              data: component.data || componentData?.data,
            }

            return newComponent
          })

        // for undo history
        const prevComponents: UpdateComponentSchema[] =
          action.payload.components.map((component) => {
            const componentData = getComponent(
              state,
              component.id,
              component.tempId,
            )

            const newComponent: UpdateComponentSchema = {
              id: componentData?.id,
              type: componentData?.type,
              tempId: componentData?.tempId,
              positions: componentData?.positions,
              style: componentData?.style,
              data: componentData?.data,
            }

            return newComponent
          })

        if (state.activeSlideData?.data?.[slideIndex]) {
          const newSlideDataComponents = state.activeSlideData.data[
            slideIndex
          ].slideDataComponents.map((comp) => {
            const newComp = updatedComponents.find(
              ({ id, tempId }) =>
                (id && id === comp.component.id) ||
                (tempId && tempId === comp.component.tempId),
            )

            return newComp
              ? {
                  component: {
                    ...newComp,
                    sessionId: comp.component.sessionId,
                  } as ComponentSchema,
                }
              : comp
          })

          state.activeSlideData.data!.find(
            ({ id }) => id === state.activeSlideDataID,
          )!.slideDataComponents = newSlideDataComponents

          // save chunk entry
          const slideOrderIndex =
            state.activeDeck.data?.deckData?.data.slides.find(
              ({ slideId }) => slideId === state.activeSlideID,
            )?.orderIndex || 1

          const slidesData: NonNullable<
            putUpdateDeckDataBody['deckData']['slides']
          >[0] = {
            id: state.activeSlideID,
            orderIndex: slideOrderIndex,
            components: updatedComponents,
          }

          state.toBeSaved.data.push(slidesData)

          if (action.payload.isAutoProcess) return
          // add undo history
          const prevSlidesData: NonNullable<
            putUpdateDeckDataBody['deckData']['slides']
          >[0] = {
            id: state.activeSlideID,
            orderIndex: slideOrderIndex,
            components: prevComponents,
          }

          state.undoHistory.future = []
          state.undoHistory.past.push({
            oldValue: prevSlidesData,
            newValue: slidesData,
            action: EDIT_ACTION_TYPE.COMPONENT_UPDATE,
          })
        }
      }
    },
    componentUpdateFromAnotherSlide: (
      state,
      action: PayloadAction<{
        component: UpdateComponentSchema
        slideDataId: number
      }>,
    ) => {
      state.instantSave.isLoading = false
      const targetSlideData = state.activeSlideData.data?.find(
        ({ id }) => id === action.payload.slideDataId,
      )
      const targetComponent = targetSlideData?.slideDataComponents.find(
        ({ component }) =>
          (component.id && component.id === action.payload.component.id) ||
          (component.tempId &&
            component.tempId === action.payload.component.tempId),
      )

      if (targetComponent?.component) {
        // client update
        targetComponent.component = {
          ...action.payload.component,
          sessionId: targetComponent.component.sessionId,
        } as ComponentSchema
        // save
        const updatedComponent: UpdateComponentSchema = {
          id: action.payload.component.id,
          type: action.payload.component.type,
          ...(action.payload.component.tempId
            ? { tempId: action.payload.component.tempId }
            : {}),
          positions: action.payload.component.positions,
          style: action.payload.component.style,
          data: action.payload.component.data,
        }
        const slidesData: NonNullable<
          putUpdateDeckDataBody['deckData']['slides']
        >[0] = {
          id: targetSlideData?.slide.id,
          components: [updatedComponent],
        }

        state.instantSave.data.push(slidesData)
      }
    },
    componentCreate: (
      state,
      action: PayloadAction<(ComponentSchema | NewComponentSchema)[]>,
    ) => {
      const slideIndex = getSlideDataIndex(state)

      if (state.activeSlideData?.data?.[slideIndex]) {
        const hasForm = state.activeSlideData.data[
          slideIndex
        ].slideDataComponents.find(
          ({ component }) => component.type === ComponentTypes.FORM,
        )
        action.payload.forEach((component) => {
          if (component.type === ComponentTypes.FORM && hasForm) {
            return
          }
          const tempId = component.tempId || uuidv4()

          const maxZIndex =
            state?.activeSlideData?.data?.[slideIndex].slideDataComponents
              .map(
                ({
                  component: {
                    positions: { zIndex },
                  },
                }) => zIndex,
              )
              .sort()
              .reverse()[0] || 5000

          // save chunk entry
          const newComponent: NewComponentSchema = {
            tempId,
            type: component.type,
            positions: {
              ...component.positions,
              zIndex: maxZIndex + 1,
            },
            style: component.style,
            data: component.data,
          }
          const slideOrderIndex = getSlideOrder(state)

          const slidesData: NonNullable<
            putUpdateDeckDataBody['deckData']['slides']
          >[0] = {
            id: state.activeSlideID,
            orderIndex: slideOrderIndex,
            components: [newComponent],
          }
          state.toBeSaved.data.push(slidesData)

          state.undoHistory.future = []

          // for undo history
          const prevComponent: DeleteComponentSchema = {
            tempId,
            isDeleted: true,
          }

          // add undo history
          const prevSlidesData: NonNullable<
            putUpdateDeckDataBody['deckData']['slides']
          >[0] = {
            id: state.activeSlideID,
            orderIndex: slideOrderIndex,
            components: [prevComponent],
          }

          state.undoHistory.past.push({
            oldValue: prevSlidesData,
            newValue: slidesData,
            action: EDIT_ACTION_TYPE.COMPONENT_CREATE,
          })

          // client update
          state.activeSlideData?.data?.[slideIndex].slideDataComponents.push({
            component: {
              ...(component as ComponentSchema),
              tempId,
              positions: { ...component.positions, zIndex: maxZIndex + 1 },
            },
          })
        })
      }
    },
    componentsDelete: (
      state,
      action: PayloadAction<Omit<DeleteComponentSchema, 'isDeleted'>[]>,
    ) => {
      const slideIndex = getSlideDataIndex(state)
      const targetComponents: DeleteComponentSchema[] = action.payload.map(
        ({ id, tempId }) => ({ id, tempId, isDeleted: true }),
      )

      const prevComponents: NewComponentSchema[] = action.payload
        .map(({ id, tempId }) => {
          const comp = state.activeSlideData?.data?.[
            slideIndex
          ].slideDataComponents.find(
            ({ component }) =>
              (id && id === component.id) ||
              (tempId && tempId === component.tempId),
          )?.component

          if (comp) {
            return {
              id: comp.id,
              tempId: comp.tempId,
              type: comp.type,
              data: comp.data,
              positions: comp.positions,
              style: comp.style,
            }
          }
        })
        .filter((v) => v !== undefined) as NewComponentSchema[]

      const slideOrderIndex = getSlideOrder(state)
      const slidesData: NonNullable<
        putUpdateDeckDataBody['deckData']['slides']
      >[0] = {
        id: state.activeSlideID,
        orderIndex: slideOrderIndex,
        components: targetComponents,
      }

      if (targetComponents.length) {
        state.toBeSaved.data.push(slidesData)
      }

      // client only component
      // if a component newly created, there is no network request needed. Just remove it from local state
      const clientComponents = targetComponents.filter(
        ({ id, tempId }) => !id && tempId,
      )
      if (clientComponents.length) {
        state.toBeSaved.data =
          state.toBeSaved.data.filter(
            (slide) =>
              !slide.components?.some((component) =>
                clientComponents.find(
                  ({ tempId, isDeleted }) =>
                    tempId === component.tempId && !isDeleted,
                ),
              ),
          ) || []
        state.savingStack =
          state.savingStack.filter(
            (slide) =>
              !slide.components?.some((component) =>
                clientComponents.find(
                  ({ tempId, isDeleted }) =>
                    tempId === component.tempId && !isDeleted,
                ),
              ),
          ) || []
      }

      // client update
      const idsList = targetComponents.map(({ id }) => id)
      const tempIdsList = targetComponents.map(({ tempId }) => tempId)
      if (state.activeSlideData?.data?.[slideIndex]) {
        state.activeSlideData.data[slideIndex].slideDataComponents =
          state.activeSlideData.data[slideIndex].slideDataComponents.filter(
            ({ component }) =>
              component.id
                ? !idsList.includes(component.id)
                : !tempIdsList.includes(component.tempId),
          )
      }

      const prevSlidesData: NonNullable<
        putUpdateDeckDataBody['deckData']['slides']
      >[0] = {
        id: state.activeSlideID,
        orderIndex: slideOrderIndex,
        components: prevComponents,
      }

      state.undoHistory.past.push({
        oldValue: prevSlidesData,
        newValue: slidesData,
        action: EDIT_ACTION_TYPE.COMPONENT_DELETE,
      })
    },
    componentAlign: (
      state,
      action: PayloadAction<{
        components: { id?: number; tempId?: string }[]
        align?: COMPONENT_ALIGN
        distribute?: COMPONENT_DIST
      }>,
    ) => {
      const slideIndex = getSlideDataIndex(state)
      const components = action.payload.components.reduce(
        (
          a: { index: number; component: ComponentSchema }[],
          c: {
            id?: number | undefined
            tempId?: string | undefined
          },
        ) => {
          if (!state.activeSlideData?.data?.[slideIndex]) {
            return [...a]
          }

          const index = state.activeSlideData.data[
            slideIndex
          ].slideDataComponents.findIndex(
            ({ component }) =>
              (c.id && component.id === c.id) ||
              (c.tempId && component.tempId === c.tempId),
          )

          const component =
            state.activeSlideData.data[slideIndex].slideDataComponents[index]
              .component

          return [...a, { index, component }]
        },
        [],
      )

      const prevComponents: ComponentSchema[] = JSON.parse(
        JSON.stringify(
          components.map(({ component }) => ({
            id: component.id,
            tempId: component.tempId,
            type: component.type,
            positions: component.positions,
            style: component.style,
            isLocked: component.isLocked,
            data: component.data,
          })),
        ),
      )

      const updatedComponents = action.payload.align
        ? alignComponents(components, action.payload.align)
        : distributeComponents(components, action.payload.distribute)

      const updatedComps: UpdateComponentSchema[] = updatedComponents
        .map(({ index, component }) => {
          if (state.activeSlideData.data?.[slideIndex]) {
            const updateComponent: UpdateComponentSchema = {
              id: component.id,
              type: component.type,
              tempId: component.tempId,
              positions: component.positions,
              style: component.style,
              data: component.data,
            }

            state.activeSlideData.data[slideIndex].slideDataComponents[
              index
            ].component = component

            return updateComponent
          }
        })
        .filter((v) => v !== undefined) as UpdateComponentSchema[]

      const slideOrderIndex = getSlideOrder(state)
      const slidesData: NonNullable<
        putUpdateDeckDataBody['deckData']['slides']
      >[0] = {
        id: state.activeSlideID,
        orderIndex: slideOrderIndex,
        components: updatedComps,
      }
      state.toBeSaved.data.push(slidesData)

      // add undo history
      const prevSlidesData: NonNullable<
        putUpdateDeckDataBody['deckData']['slides']
      >[0] = {
        id: state.activeSlideID,
        orderIndex: slideOrderIndex,
        components: prevComponents,
      }

      state.undoHistory.future = []
      state.undoHistory.past.push({
        oldValue: prevSlidesData,
        newValue: slidesData,
        action: EDIT_ACTION_TYPE.COMPONENT_UPDATE,
      })
    },
    componentOrder: (
      state,
      action: PayloadAction<{
        data: ComponentSchema
        to: 'front' | 'back' | 'forward' | 'backward' | 'mask'
      }>,
    ) => {
      const { to, data } = action.payload
      const slideIndex = getSlideDataIndex(state)

      if (state.activeSlideData?.data?.[slideIndex]) {
        const zIndexList =
          state.activeSlideData?.data?.[slideIndex].slideDataComponents.map(
            ({
              component: {
                positions: { zIndex },
              },
            }) => zIndex,
          ) || []

        const newZIndex =
          to === 'mask'
            ? 2
            : to === 'front'
            ? ([...zIndexList].sort().reverse()[0] || 5000) + 1
            : to === 'back'
            ? ([...zIndexList].sort()[0] || 5000) - 1
            : to === 'forward'
            ? placeToNextRange(data.positions.zIndex || 1, zIndexList)
            : to === 'backward'
            ? placeToPreviousRange(data.positions.zIndex || 1, zIndexList)
            : 1

        state.activeSlideData!.data[slideIndex].slideDataComponents.find(
          ({ component }) =>
            (component.id && component.id === data.id) ||
            (component.tempId && component.tempId === data.tempId),
        )!.component.positions.zIndex = newZIndex

        const updateComponent: UpdateComponentSchema = {
          id: data.id,
          type: data.type,
          tempId: data.tempId,
          positions: {
            ...data.positions,
            zIndex: newZIndex,
          },
          style: data.style,
          data: data.data,
        }

        const slidesData: NonNullable<
          putUpdateDeckDataBody['deckData']['slides']
        >[0] = {
          id: state.activeSlideID,
          components: [updateComponent],
        }
        state.toBeSaved.data.push(slidesData)

        // add undo history
        const prevSlidesData: NonNullable<
          putUpdateDeckDataBody['deckData']['slides']
        >[0] = {
          id: state.activeSlideID,
          components: [
            {
              id: action.payload.data.id,
              tempId: action.payload.data.tempId,
              positions: action.payload.data.positions,
              style: action.payload.data.style,
              data: action.payload.data.data,
              isLocked: action.payload.data.isLocked,
            },
          ],
        }
        state.undoHistory.future = []
        state.undoHistory.past.push({
          oldValue: prevSlidesData,
          newValue: slidesData,
          action: EDIT_ACTION_TYPE.COMPONENT_UPDATE,
        })
      }
    },
    historyUndo: undoRedoAction(UNDO_REDO_ACTION.UNDO),
    historyRedo: undoRedoAction(UNDO_REDO_ACTION.REDO),
    slideBackground: slideBackgroundColor,
    slideDecor: slideDecorSvg,
    finishAutoResize: (state) => {
      state.activeDeck.data!.deckData!.data.autoResizeNeeded = false
    },
    setSlideNotes: (state, action: PayloadAction<ISetSlideNotesPayload>) => {
      const activeSlideData = state.activeSlideData.data?.find(
        (slideData) => slideData.id === action.payload.activeSlideDataId,
      )
      if (activeSlideData && action.payload.slideNote) {
        activeSlideData.slide.slideNote = action.payload.slideNote
      }
      if (activeSlideData && action.payload.speakerNote) {
        activeSlideData.slide.speakerNote = action.payload.speakerNote
      }
    },

    setCachedColorMapping: (
      state,
      action: PayloadAction<{
        id: string
        mapping: ThemeColorMapSchema
      }>,
    ) => {
      const mappingIndex = state.cachedColorMaps.findIndex(
        (cachedMap) => cachedMap.id === action.payload.id,
      )
      const mappingCacheObj = {
        id: action.payload.id,
        mapping: action.payload.mapping,
      }
      if (mappingIndex === -1) {
        state.cachedColorMaps.push(mappingCacheObj)
      } else {
        state.cachedColorMaps[mappingIndex] = mappingCacheObj
      }
    },
    deleteCachedColorMapping: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      state.cachedColorMaps = state.cachedColorMaps.filter(
        (cachedMap) => cachedMap.id !== action.payload.id,
      )
    },
  },
})

export const {
  setActiveDeck,
  setTips,
  setQA,
  setSlideData,
  setActiveSlideID,
  slideCreate,
  slideCreateUpdate,
  slideDelete,
  slideShift,
  slideSwapColor,
  slideBackground,
  slideDecor,
  setDeckSave,
  clearEdit,
  clearEditSoft,
  changeDeckName,
  componentsUpdate,
  componentCreate,
  componentsDelete,
  componentAlign,
  componentOrder,
  setCustomDomainId,
  slideTempSvg,
  componentUpdateFromAnotherSlide,
  historyUndo,
  historyRedo,
  finishAutoResize,
  setSlideNotes,
  setCachedColorMapping,
  deleteCachedColorMapping,
} = editSlice.actions

export default editSlice.reducer
