import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDecksApi, useLanguage, useNotification } from 'src/hooks'
import { RootState, setPivotDecks, setPivotLength } from 'src/store'
import {
  templatesPageStyles,
  cardWrapperStyles,
  templateModalWrapperStyles,
  templateModalCloseButtonAreaStyles,
  templateModalTitleStyles,
  templateModalSlidesCentererStyles,
  templateModalSlidesWrapperStyles,
  templateModalFooterStyles,
  deckNameStyles,
  deckNameTextStyles,
  infiniteObserverStyles,
} from './styles'
import {
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  Loader,
  Modal,
  TOOLTIP_THEME,
  Tooltip,
  icons,
} from 'src/lib'
import { CardListLayout } from 'src/layouts/card-list-layout/CardListLayout'
import { CategorySelect } from 'src/lib/category-select'
import { CARD_LIST_ALIGN } from 'src/layouts/card-list-layout/type'
import { DeckStates, PivotDeckCategories } from 'src/types/api/enums'
import {
  getDeckDetailedResponse,
  getPivotDecksResponse,
} from 'src/types/api/responseObjects'
import { TemplateCard } from './TemplateCard'
import { CANVAS_DATA_TYPE } from 'src/components/canvas'
import { useInfiniteScroll } from 'src/hooks/useInfiniteScroll'
import { APP_CONFIG } from 'src/config'
import { useNavigate } from 'react-router-dom'

export const TemplatesPage: React.FC = React.memo(() => {
  const infiniteObserverRef = useRef<HTMLDivElement>(null)

  const [selectedCategory, setSelectedCategory] = useState(
    PivotDeckCategories.ALL,
  )
  const [selectedDeckId, setSelectedDeckId] = useState<number | null>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { getPivotDecks, getPublicDeckData, duplicateDeck, getSingleDeck } =
    useDecksApi()
  const { t } = useLanguage()
  const { success, error } = useNotification()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { decksQuery, workspaceId, aiCreatedDeckId } = useSelector(
    ({ decks, workspace, aiFlow }: RootState) => ({
      decksQuery: decks.decksQuery,
      workspaceId: workspace.id,
      aiCreatedDeckId: aiFlow.aiCreatedDeckId,
    }),
  )

  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false)
  const { hasMoreData, responseData } = useInfiniteScroll(
    infiniteObserverRef,
    async () => {
      return await getPivotDecks()
    },
    10,
    isLoading || isTemplateModalOpen,
  )

  const [localPivotDecks, setLocalPivotDecks] = useState<
    getPivotDecksResponse['data']['pivotDecks']
  >([])
  const [deckDatas, setDeckDatas] = useState<getDeckDetailedResponse['data'][]>(
    [],
  )
  const prepareDecks = useCallback(
    async (deckDatasWithoutCaching: getDeckDetailedResponse['data'][]) => {
      setIsLoading(true)
      const mergedPivotData = localPivotDecks?.concat(responseData)
      setLocalPivotDecks(mergedPivotData)
      const newDeckDataArray: any = []

      let publicDeckData
      for (const deck of responseData) {
        publicDeckData = getPublicDeckData({
          shareKey: deck.deck.deckShare.shortCode.value,
          doNotStoreData: true,
        })
        newDeckDataArray.push(publicDeckData)
      }
      const resolved = await Promise.all(newDeckDataArray)

      setDeckDatas(deckDatasWithoutCaching.concat(resolved))
    },
    [responseData, localPivotDecks, deckDatas, setDeckDatas],
  )

  useEffect(() => {
    if (responseData) {
      prepareDecks(deckDatas)
    }
  }, [responseData])

  const handleCategoryChange = useCallback(async (category: string) => {
    const typedCategory = category as unknown as PivotDeckCategories
    setSelectedCategory(typedCategory)
  }, [])

  const isTemplateExistingPerCategory = useCallback(
    (category: PivotDeckCategories) => {
      return localPivotDecks.some((deck) => deck.categories.includes(category))
    },
    [localPivotDecks],
  )

  const categoryItems = useMemo(() => {
    return [
      {
        label: t('init.category0'),
        value: PivotDeckCategories.ALL,
        hidden: isTemplateExistingPerCategory(PivotDeckCategories.ALL),
      },
      {
        label: t('init.category1'),
        value: PivotDeckCategories.BUSINESS,
        hidden: isTemplateExistingPerCategory(PivotDeckCategories.BUSINESS),
      },
      {
        label: t('init.category2'),
        value: PivotDeckCategories.PROPOSAL,
        hidden: isTemplateExistingPerCategory(PivotDeckCategories.PROPOSAL),
      },
      {
        label: t('init.category3'),
        value: PivotDeckCategories.ONLINE_COURSE,
        hidden: isTemplateExistingPerCategory(
          PivotDeckCategories.ONLINE_COURSE,
        ),
      },
      {
        label: t('init.category4'),
        value: PivotDeckCategories.EDUCATION,
        hidden: isTemplateExistingPerCategory(PivotDeckCategories.EDUCATION),
      },
      {
        label: t('init.category5'),
        value: PivotDeckCategories.FORM,
        hidden: isTemplateExistingPerCategory(PivotDeckCategories.FORM),
      },
      {
        label: t('init.category6'),
        value: PivotDeckCategories.NUTRITION,
        hidden: isTemplateExistingPerCategory(PivotDeckCategories.NUTRITION),
      },
      {
        label: t('init.category7'),
        value: PivotDeckCategories.EXECUTIVE_SUMMARY,
        hidden: isTemplateExistingPerCategory(
          PivotDeckCategories.EXECUTIVE_SUMMARY,
        ),
      },
      {
        label: t('init.category8'),
        value: PivotDeckCategories.CASE_STUDY,
        hidden: isTemplateExistingPerCategory(PivotDeckCategories.CASE_STUDY),
      },
      {
        label: t('init.category9'),
        value: PivotDeckCategories.STARTUP_PITCH,
        hidden: isTemplateExistingPerCategory(
          PivotDeckCategories.STARTUP_PITCH,
        ),
      },
    ]
  }, [localPivotDecks])

  const handleTemplateSelection = useCallback(
    async (providedId?: number) => {
      const resp = await duplicateDeck({
        id: providedId ?? selectedDeckId!,
        workspaceId,
      })
      if (resp) {
        window.open(`/deck/${resp}`, '_blank')
      }
    },
    [selectedDeckId, workspaceId],
  )

  const [templateSlides, setTemplateSlides] =
    useState<getDeckDetailedResponse['data']>()
  const sortedSlides = useMemo(() => {
    return templateSlides?.deckData?.data.slides
      ?.slice()
      .filter(({ isDeleted }) => !isDeleted)
      .sort((a, b) => a.orderIndex - b.orderIndex)
  }, [templateSlides])

  const findPivotDeckSlide = useCallback(
    (id: number) =>
      deckDatas
        ?.find((deckData) => deckData.deck?.id === id)
        ?.deckData?.data.slides.find(
          (slide) => slide.orderIndex === 1 && slide.isDeleted === false,
        ),
    [deckDatas],
  )

  const findPivotDeckId = useCallback(
    (id: number) =>
      deckDatas?.find((deckData) => deckData.deck?.id === id)?.deck?.id,
    [deckDatas],
  )

  const findPivotDeckData = useCallback(
    (id: number) =>
      deckDatas?.find((deckData) => deckData.deck?.id === id)?.deckData,
    [deckDatas],
  )

  const findPivotDeck = useCallback(
    (id: number) => deckDatas?.find((deckData) => deckData.deck?.id === id),
    [deckDatas],
  )

  const templateModalCloseHandler = useCallback(() => {
    setIsTemplateModalOpen(false)
    setSelectedDeckId(undefined)
    setTemplateSlides(undefined)
  }, [])

  const templateModal = useMemo(
    () => (
      <Modal
        isOpen={isTemplateModalOpen}
        onClose={() => templateModalCloseHandler()}
      >
        <div css={templateModalWrapperStyles}>
          <div css={templateModalCloseButtonAreaStyles}>
            <div css={templateModalTitleStyles}>
              {templateSlides?.deck?.name}
            </div>
            <Button
              icon={icons.close}
              theme={BUTTON_THEME.WHITE}
              type={BUTTON_TYPE.GHOST}
              isLink
              iconSize={20}
              onClick={() => templateModalCloseHandler()}
            />
          </div>
          <div css={templateModalSlidesCentererStyles}>
            <div css={templateModalSlidesWrapperStyles}>
              {sortedSlides?.map((slide, key) => (
                <TemplateCard
                  key={key + slide.slideDataId}
                  data={slide}
                  dataType={CANVAS_DATA_TYPE.ACTIVE_DECK}
                  deck={findPivotDeck(selectedDeckId!)}
                  deckId={selectedDeckId!}
                  shareKey={templateSlides?.deck?.deckShare?.shortCode.value}
                />
              ))}
            </div>
          </div>
          <div css={templateModalFooterStyles}>
            <Button
              type={BUTTON_TYPE.DEFAULT}
              theme={BUTTON_THEME.PRIMARY}
              text={t('init.use_this_template')}
              onClick={() => handleTemplateSelection()}
            />
          </div>
        </div>
      </Modal>
    ),
    [templateSlides, sortedSlides, selectedDeckId, isTemplateModalOpen],
  )

  const handleTemplatePreview = useCallback(
    async ({
      shareKey,
      deckId,
    }: {
      shareKey: string
      deckId?: number | null
    }) => {
      setIsTemplateModalOpen(true)
      const publicDeckData = await getPublicDeckData({
        shareKey,
      })
      setSelectedDeckId(deckId)
      setTemplateSlides(publicDeckData)
    },
    [],
  )

  const onPreparedHandler = useCallback(() => {
    setIsLoading(false)
  }, [])

  const timeRef = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (aiCreatedDeckId) {
      timeRef.current = setInterval(async () => {
        const deckState = await getSingleDeck({ deckId: aiCreatedDeckId })
        if (deckState === DeckStates.FINAL) {
          clearInterval(timeRef.current)
          success('common.informative.your_deck_is_ready')
          dispatch(setPivotDecks([]))
          dispatch(setPivotLength(0))
          navigate('/dashboard', { replace: true })
        } else if (deckState === DeckStates.ERRORED) {
          clearInterval(timeRef.current)
          error('common.informative.ai_high_demand_text')
        }
      }, APP_CONFIG.aiDeckInterval)
    }

    return () => clearInterval(timeRef.current)
  }, [aiCreatedDeckId])

  return (
    <div css={templatesPageStyles}>
      <CardListLayout align={CARD_LIST_ALIGN.CENTER}>
        <CategorySelect
          items={categoryItems}
          selected={selectedCategory}
          onChange={handleCategoryChange}
        />
      </CardListLayout>
      <CardListLayout>
        {localPivotDecks?.map((deck, index) => (
          <div
            css={cardWrapperStyles}
            key={deck.deck.id + index}
            style={{
              display:
                deck.categories.includes(selectedCategory) &&
                (!decksQuery.search ||
                  deck.deck.name
                    .toLocaleLowerCase()
                    .includes(decksQuery.search.toLocaleLowerCase()))
                  ? 'flex'
                  : 'none',
            }}
          >
            {findPivotDeckSlide(deck.deck.id) && (
              <TemplateCard
                data={findPivotDeckSlide(deck.deck.id)!}
                deck={findPivotDeck(deck.deck.id)}
                deckId={findPivotDeckId(deck.deck.id)}
                defaultColorMap={
                  findPivotDeckData(deck.deck.id)?.theme.data.colorMap
                }
                colorMap={
                  findPivotDeckData(deck.deck.id)?.data.userThemePreferences
                    ?.colorMap
                }
                themeColors={findPivotDeckData(deck.deck.id)?.themeColor.data}
                shareKey={deck.deck.deckShare.shortCode.value}
                providedThemeFontData={
                  findPivotDeckData(deck.deck.id)?.themeFont
                }
                onPrepared={onPreparedHandler}
                useActions={true}
                onTemplateSelection={() =>
                  handleTemplateSelection(deck.deck.id)
                }
                onPreviewSelection={() =>
                  handleTemplatePreview({
                    shareKey: deck.deck.deckShare.shortCode.value,
                    deckId: findPivotDeckId(deck.deck.id),
                  })
                }
              />
            )}
            <div css={deckNameStyles}>
              <Tooltip
                text={deck.deck.name}
                theme={TOOLTIP_THEME.LIGHT}
                width={270}
              >
                <div css={deckNameTextStyles}>{deck.deck.name}</div>
              </Tooltip>
            </div>
          </div>
        ))}
      </CardListLayout>
      {(hasMoreData || isLoading) && <Loader size={32} />}
      <div ref={infiniteObserverRef} css={infiniteObserverStyles} />
      {isTemplateModalOpen && templateModal}
    </div>
  )
})
