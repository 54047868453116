import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editPropertiesStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  padding: ${gap.xlarge}px;
  gap: ${gap['2xlarge']}px;
`
export const editSubmenuStyles = ({ gap }: ITheme) => css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: ${gap.xlarge}px;
  gap: ${gap['2xlarge']}px;
`
