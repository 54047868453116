import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const aiOutlineStyles = () => (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  border: 2px solid ${theme.colors.purple.DEFAULT};
  margin-top: ${theme.gap.medium}px;
  display: flex;
  width: ${theme.dimensions.aiModalWidth.medium}px;
  padding: ${theme.gap.small}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.gap.xsmall}px;
  border-radius: ${theme.borderRadius[6]};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    max-height: ${theme.dimensions.aiModalOutlineHeight.DEFAULT}px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: ${theme.dimensions.aiModalWidth.large}px;
    margin-top: ${theme.gap.xlarge}px;
    max-height: ${theme.dimensions.aiModalOutlineHeight.small}px;
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: auto;
    margin-top: ${theme.gap.small}px;
  }
`

export const dot = (theme: ITheme) => css`
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin-right: ${theme.gap.xsmall}px;
`

export const lineStyles = () => css`
  height: auto;
  display: flex;
  align-items: center;
  width: 100%;
`

export const outlineStyles = () => css`
  width: 100%;
`
