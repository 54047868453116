import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setThemeFonts } from 'src/store'
import { fontListWrapperStyles } from './styles'
import { Icon, Modal, icons } from 'src/lib'
import { colors } from 'src/theme'
import { ViewModes } from 'src/types/api/enums'
import { useDecksApi, useLanguage, useThemeApi, useFlags } from 'src/hooks'
import { FontFamily } from '../font-family'
import { IFontsList } from './types'
import { FontUploadModal } from '../font-upload'

export const FontsList: React.FC<IFontsList> = React.memo(
  ({ className, dataAttr, onClick }) => {
    const [deleteMode, setDeleteMode] = useState(false)

    const { updateDeckThemeFont } = useDecksApi()
    const { deleteDeckThemeFont } = useThemeApi()
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const flags = useFlags()

    const {
      selectedTheme,
      themeFonts,
      workspaceId,
      deckThemeFont,
      activeThemeFontId,
    } = useSelector(({ theme, edit, workspace }: RootState) => ({
      selectedTheme: edit.activeDeck.data?.deckData?.theme,
      themeFonts: theme.themeFonts,
      workspaceId: workspace.id,
      deckThemeFont: edit.activeDeck.data?.deckData?.themeFont,
      activeThemeFontId: edit.activeDeck.data?.deckData?.themeFont.id,
    }))

    useEffect(() => {
      if (flags.FE_COLLABORATED_DECK_UPLOADED_FONT === false) {
        return
      }
      if (!themeFonts) return
      const flatFonts = Object.values(themeFonts!)?.flatMap((values) => values)
      // Add selected font of active deck if the font is missing in the font list
      const activeDeckFontIndexInList = flatFonts.findIndex(
        (font) => font.id === deckThemeFont?.id,
      )
      if (activeDeckFontIndexInList === -1 && deckThemeFont) {
        const updatedSharedOnOrg = themeFonts.sharedOnOrg.concat(deckThemeFont)
        const newFonts = {
          ...themeFonts,
          sharedOnOrg: updatedSharedOnOrg,
        }
        sessionStorage.setItem('deckThemeFontId', deckThemeFont.id.toString())
        dispatch(setThemeFonts(newFonts))
      }
    }, [flags.FE_COLLABORATED_DECK_UPLOADED_FONT])

    const handleSelectThemeFont = useCallback(
      ({
        themeFontId,
        viewMode,
        organizationId,
        context,
      }: {
        themeFontId: number
        viewMode?: ViewModes
        organizationId?: number
        context?: string
      }) => {
        if (deleteMode && selectedTheme?.themeCategoryId) {
          if (context === 'predefined') return
          deleteDeckThemeFont({
            themeFontId,
            viewMode,
            organizationId,
            themeCategoryId: selectedTheme?.themeCategoryId,
          })
          if (themeFontId === activeThemeFontId) {
            updateDeckThemeFont({
              themeFontId: themeFonts?.predefined[0]?.id,
            })
          }

          setDeleteMode(false)
          return
        }

        if (flags.FE_COLLABORATED_DECK_UPLOADED_FONT) {
          const deckThemeFontIdSS = sessionStorage.getItem('deckThemeFontId')
          const deckThemeFontId = deckThemeFontIdSS
            ? Number(deckThemeFontIdSS)
            : undefined

          if (themeFontId === deckThemeFontId || !themeFonts) {
            return
          }

          const updatedSharedFonts = themeFonts.sharedOnOrg.filter(
            (font) => font.organizationId,
          )
          const newFonts = {
            ...themeFonts,
            sharedOnOrg: updatedSharedFonts,
          }
          dispatch(setThemeFonts(newFonts))
          sessionStorage.removeItem('deckThemeFontId')
        }
        updateDeckThemeFont({ themeFontId })
      },
      [
        flags.FE_COLLABORATED_DECK_UPLOADED_FONT,
        deleteMode,
        selectedTheme,
        themeFonts,
        activeThemeFontId,
      ],
    )

    const onDeleteHandler = useCallback(() => {
      setDeleteMode(!deleteMode)
    }, [deleteMode])

    const [isUploadFontModalOpen, setIsUploadFontModalOpen] = useState(false)
    const uploadFontModal = useMemo(
      () => (
        <Modal
          isOpen={isUploadFontModalOpen}
          onClose={() => setIsUploadFontModalOpen(false)}
        >
          <FontUploadModal
            themeCategoryId={selectedTheme?.themeCategoryId}
            onClose={() => setIsUploadFontModalOpen(false)}
          />
        </Modal>
      ),
      [selectedTheme, isUploadFontModalOpen],
    )

    const fontTemplates = useMemo(
      () =>
        Object.entries(themeFonts ?? {}).flatMap(([key, values]) => {
          return values.map((font, index) => (
            <div
              key={`${key}-font1` + index}
              onClick={() =>
                handleSelectThemeFont({
                  themeFontId: font.id,
                  viewMode: font.viewMode,
                  organizationId: font.organizationId || undefined,
                  context: key,
                })
              }
            >
              <FontFamily
                id={font.id}
                primary={font.data.primary}
                secondary={font.data.secondary}
                context={key}
                deleteMode={deleteMode}
              />
            </div>
          ))
        }),
      [themeFonts, deleteMode],
    )

    return (
      <div css={fontListWrapperStyles} className={className} {...dataAttr}>
        <div className="selected-font">
          <span className="style-item-name">
            {t('edit.design.font_family')}
          </span>
          <span className="style-item-change" onClick={onClick}>
            {t('common.actions.close')}
          </span>
        </div>
        <div className="font-box-wrapper">{fontTemplates}</div>
        <div>
          <div>
            {workspaceId && (
              <span
                className="upload-font"
                onClick={() => setIsUploadFontModalOpen(true)}
              >
                {t('edit.design.upload_your_font')}
              </span>
            )}
          </div>
          {isUploadFontModalOpen && uploadFontModal}
          <Icon
            icon={icons.trash_can}
            color={deleteMode ? colors.error.DEFAULT : colors.outline.DEFAULT}
            size={16}
            onClick={() => onDeleteHandler()}
          />
        </div>
      </div>
    )
  },
)
